import { Col, DatePicker, Divider, Form, InputNumber, Modal, Row, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import dayjs, { Dayjs } from "dayjs";
import { StoreValue } from "rc-field-form/lib/interface";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ActionButton from "../../../../../common/components/buttons/ActionButton";
import InputAddon from "../../../../../common/components/form/addons/InputAddon";
import HiddenInput from "../../../../../common/components/form/components/HiddenInput";
import DeleteIcon from "../../../../../common/components/icons/DeleteIcon";
import { accessTreePathDelimiter, ModalSizes, rowGutter } from "../../../../../common/constants";
import {
  datePickerFormItemProps,
  datePickerStandardProps,
  dateToIsoDateString,
  disableDatePickerOutOfMinDate,
  inputNumberDecimalStandardProps,
  resolveFormValidationError,
  selectStandardProps,
  selectTagsStandardProps,
  toDate,
  useFormErrorHandler
} from "../../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import { contains } from "../../../../../common/utils/utils";
import { validations } from "../../../../../common/utils/validationUtils";
import { TopAgentType } from "../../../../agent/enums";
import { Agent } from "../../../../agent/types";
import AgentSelect from "../../../../enumerations/components/form/AgentSelect";
import InstitutionSelect from "../../../../enumerations/components/form/InstitutionSelect";
import ProductGroupSelect from "../../../../enumerations/components/form/ProductGroupSelect";
import ProductSelect from "../../../../enumerations/components/form/ProductSelect";
import { ProductFinancialSector } from "../../../../product/enums";
import { PRODUCT_SECTOR_TO_INSTITUTION_TYPE_MAP } from "../../../../product/utils";
import { requests } from "../../api";
import { createCommissionsSettingsRuleActions, updateCommissionsSettingsRuleActions } from "../../ducks";
import { CommissionSource, commissionSourceTMap } from "../../enums";
import {
  CommissionsSettingsRule,
  CreateCommissionsSettingsRule,
  CreateUpdateCommissionsSettingsInterval,
  UpdateCommissionsSettingsRule
} from "../../types";
import CommissionSourceTag from "../CommissionSourceTag";
import CommissionsSettingsRuleView from "../views/CommissionsSettingsRuleView";

interface Props {
  open: boolean;
  rule?: CommissionsSettingsRule;
  agent: Agent;
  onCreate: typeof createCommissionsSettingsRuleActions.request;
  onUpdate: typeof updateCommissionsSettingsRuleActions.request;
  onFormCancel: () => void;
}

export const CommissionsSettingsRuleForm = ({ rule, agent, open, onCreate, onUpdate, onFormCancel }: Props) => {
  const { t } = useTranslation();

  const isTopAgentTypePlus = agent?.topAgentType === TopAgentType.PLUS;
  const isTopAgent = agent?.id === agent?.accessTreePath;

  const colSpan = 4;
  const colSpanBig = 8;

  const [form] = Form.useForm<CreateCommissionsSettingsRule | UpdateCommissionsSettingsRule>();

  useFormErrorHandler(form, "commissions.settings.attrs", [
    requests.CREATE_COMMISSIONS_SETTINGS_RULE,
    requests.UPDATE_COMMISSIONS_SETTINGS_RULE
  ]);

  useEffect(() => {
    if (!open) {
      return;
    }

    if (!rule) {
      form.setFieldsValue({ intervals: [{ startDate: undefined }] });
    }

    if (rule) {
      form.setFieldsValue({
        optimisticLockVersion: rule.optimisticLockVersion,
        intervals: rule.intervals.map(interval => ({
          ...interval,
          superiorAgentId: interval.superiorAgent?.id,
          superiorAgent: undefined
        }))
      });
    } else if (isTopAgentTypePlus && !isTopAgent) {
      form.setFieldValue("sector", ProductFinancialSector.ALL);
    }
  }, [rule, open, form]);

  const inProgress = useRequestFinishedCallback(
    [requests.CREATE_COMMISSIONS_SETTINGS_RULE, requests.UPDATE_COMMISSIONS_SETTINGS_RULE],
    onFormCancel
  );

  const handleSectorChange = (): void => {
    form.setFieldsValue({
      productGroupId: undefined,
      institutionId: undefined,
      productId: undefined
    });
  };

  const handleProductGroupIdChange = (): void => {
    form.setFieldsValue({ productId: undefined });
  };

  const handleIntervalAdd = (add: (defaultValue?: StoreValue) => void): void => {
    const intervals = [...form.getFieldValue(["intervals"])] as CreateUpdateCommissionsSettingsInterval[];
    const lastEndDate = intervals[intervals.length - 1]?.endDate;

    if (lastEndDate) {
      intervals[intervals.length] = {
        ...intervals[intervals.length],
        startDate: dateToIsoDateString(dayjs(lastEndDate).add(1, "days"))
      } as CreateUpdateCommissionsSettingsInterval;

      form.setFieldsValue({ intervals });
    } else {
      add();
    }
  };

  const handleIntervalEndDateChange = (endDate: Dayjs, index: number): void => {
    if (dayjs.isDayjs(endDate)) {
      const intervals = [...form.getFieldValue(["intervals"])] as CreateUpdateCommissionsSettingsInterval[];

      if (intervals.length > index + 1) {
        intervals[index + 1] = {
          ...intervals[index + 1],
          startDate: dateToIsoDateString(dayjs(endDate).add(1, "days"))
        } as CreateUpdateCommissionsSettingsInterval;

        form.setFieldsValue({ intervals });
      }
    }
  };

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        const processedValues = {
          ...values,
          intervals: values.intervals.map(interval => ({
            ...interval,
            superiorAgentId:
              interval.source === CommissionSource.COMMISSION_AMOUNT ? interval.superiorAgentId : undefined
          }))
        } as CreateCommissionsSettingsRule | UpdateCommissionsSettingsRule;

        if (isTopAgentTypePlus && !isTopAgent) {
          processedValues.intervals = processedValues.intervals.map(interval => ({
            ...interval,
            superiorAgentId: undefined,
            subsequentCommissionRate: undefined,
            source: undefined,
            initialCommissionRate: undefined
          }));
        }

        if (rule) {
          onUpdate({ id1: agent.id, id2: rule.id, object: processedValues as UpdateCommissionsSettingsRule });
        } else {
          onCreate({ id: agent.id, object: processedValues as CreateCommissionsSettingsRule });
        }
      })
      .catch(resolveFormValidationError);
  };

  const sector = Form.useWatch("sector", form as FormInstance<CreateCommissionsSettingsRule>);
  const productGroupId = Form.useWatch("productGroupId", form as FormInstance<CreateCommissionsSettingsRule>);

  const startDates = Form.useWatch("intervals", form)?.map(interval => interval?.startDate);
  const endDates = Form.useWatch("intervals", form)?.map(interval => interval?.endDate);
  const sources = Form.useWatch("intervals", form)?.map(interval => interval?.source);

  return (
    <Modal
      width={ModalSizes.HUGE}
      open={open}
      title={rule ? t("commissions.settings.titles.updateRule") : t("commissions.settings.titles.createRule")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <Form form={form} layout="vertical" name="commissionsSettingsRuleForm">
        <HiddenInput name="optimisticLockVersion" />

        {rule ? (
          <>
            <Divider>{t("commissions.settings.sections.rule")}</Divider>
            <CommissionsSettingsRuleView rule={rule} />
          </>
        ) : (
          <>
            <Divider>{t("commissions.settings.sections.ruleSettings")}</Divider>

            <Row gutter={rowGutter}>
              <Col span={colSpanBig}>
                <Form.Item
                  name="sector"
                  label={t("product.enums.financialSector._label")}
                  rules={[validations.notNull]}
                >
                  <Select
                    {...selectStandardProps}
                    options={Object.keys(ProductFinancialSector).map(sector => ({
                      value: sector,
                      label: t("product.enums.financialSector." + sector)
                    }))}
                    onChange={handleSectorChange}
                  />
                </Form.Item>
              </Col>
            </Row>

            {sector !== ProductFinancialSector.ALL && (
              <Row gutter={rowGutter}>
                <Col span={colSpanBig}>
                  <ProductGroupSelect
                    formItemProps={{
                      name: "productGroupId",
                      label: t("commissions.settings.attrs.productGroupId"),
                      rules: [validations.none]
                    }}
                    selectProps={{
                      allowClear: true,
                      placeholder: !sector ? t("commissions.settings.helpers.productGroupPlaceholder") : undefined,
                      onChange: handleProductGroupIdChange
                    }}
                    optionsProps={{ filterSectors: [sector] }}
                  />
                </Col>

                <Col span={colSpanBig}>
                  <InstitutionSelect
                    formItemProps={{
                      name: "institutionId",
                      label: t("commissions.settings.attrs.institutionId"),
                      rules: [validations.none]
                    }}
                    selectProps={{
                      allowClear: true,
                      placeholder: !productGroupId
                        ? t("commissions.settings.helpers.institutionPlaceholder")
                        : undefined
                    }}
                    optionsProps={{
                      hideAll: !productGroupId,
                      selected: undefined,
                      filterType: PRODUCT_SECTOR_TO_INSTITUTION_TYPE_MAP.get(sector)
                    }}
                  />
                </Col>

                <Col span={colSpanBig}>
                  <ProductSelect
                    formItemProps={{
                      name: "productId",
                      label: t("commissions.settings.attrs.productId"),
                      rules: [validations.none]
                    }}
                    selectProps={{
                      allowClear: true,
                      placeholder: !productGroupId ? t("commissions.settings.helpers.productPlaceholder") : undefined
                    }}
                    optionsProps={{
                      productGroupId,
                      filterSectors: [sector],
                      groupByProductGroup: true,
                      hideAll: !productGroupId
                    }}
                  />
                </Col>
              </Row>
            )}
          </>
        )}

        <Divider>{t("commissions.settings.sections.intervalsSettings")}</Divider>

        <Form.List name={["intervals"]} initialValue={[{}]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, ...field }) => {
                const startDate = startDates ? startDates[field.name] : undefined;
                const endDate = endDates ? endDates[field.name] : undefined;
                const source = sources ? sources[field.name] : undefined;

                return (
                  <Row gutter={rowGutter} key={key}>
                    <Col span={23}>
                      <HiddenInput name={[field.name, "id"]} />
                      <HiddenInput name={[field.name, "optimisticLockVersion"]} />

                      <Row gutter={rowGutter}>
                        <Col span={colSpan}>
                          <Form.Item
                            {...field}
                            name={[field.name, "startDate"]}
                            label={t("commissions.settings.attrs.settings.startDate")}
                            rules={[validations.notNull]}
                            {...datePickerFormItemProps}
                          >
                            <DatePicker {...datePickerStandardProps} disabled={field.name >= 1} />
                          </Form.Item>
                        </Col>

                        <Col span={colSpan}>
                          <Form.Item
                            {...field}
                            name={[field.name, "endDate"]}
                            label={t("commissions.settings.attrs.settings.endDate")}
                            rules={[
                              fields.length > 1 && field.name < fields.length - 1
                                ? validations.notNull
                                : validations.none,
                              validations.notBefore(startDate, t("commissions.settings.attrs.settings.startDate"))
                            ]}
                            {...datePickerFormItemProps}
                          >
                            <DatePicker<Dayjs>
                              {...datePickerStandardProps}
                              defaultPickerValue={toDate(endDate || startDate)}
                              allowClear={!(fields.length > 1 && field.name < fields.length - 1)}
                              disabledDate={current =>
                                startDate ? disableDatePickerOutOfMinDate(current, startDate) : false
                              }
                              onChange={value => (value ? handleIntervalEndDateChange(value, field.name) : undefined)}
                            />
                          </Form.Item>
                        </Col>

                        {isTopAgentTypePlus && !isTopAgent ? (
                          <Col span={5}>
                            <Form.Item
                              {...field}
                              name={[field.name, "commissionAmountPerPoint"]}
                              label={t("commissions.settings.attrs.settings.commissionAmountPerPoint")}
                              rules={[validations.notNull, validations.minNumber(0)]}
                            >
                              <InputNumber
                                {...inputNumberDecimalStandardProps}
                                addonAfter={<InputAddon type="euro" />}
                              />
                            </Form.Item>
                          </Col>
                        ) : (
                          <>
                            <Col span={colSpan}>
                              <Form.Item
                                {...field}
                                name={[field.name, "source"]}
                                label={t("commissions.settings.enums.commissionSource._label")}
                                rules={[validations.notNull]}
                              >
                                <Select
                                  {...selectTagsStandardProps(commissionSourceTMap)}
                                  options={Object.keys(CommissionSource).map(source => ({
                                    value: source,
                                    label: (
                                      <CommissionSourceTag
                                        source={CommissionSource[source as keyof typeof CommissionSource]}
                                      />
                                    )
                                  }))}
                                />
                              </Form.Item>
                            </Col>

                            {source === CommissionSource.COMMISSION_AMOUNT ? (
                              <Col span={colSpan}>
                                <AgentSelect
                                  formItemProps={{
                                    ...field,
                                    name: [field.name, "superiorAgentId"],
                                    label: t("commissions.settings.attrs.settings.superiorAgentId"),
                                    rules: [validations.notNull]
                                  }}
                                  optionsProps={{
                                    filter: a =>
                                      contains(agent.accessTreePath?.split(accessTreePathDelimiter) ?? [], a.id) &&
                                      agent.id !== a.id
                                  }}
                                />
                              </Col>
                            ) : undefined}

                            <Col span={colSpan}>
                              <Form.Item
                                {...field}
                                name={[field.name, "initialCommissionRate"]}
                                label={t("commissions.settings.attrs.settings.initialCommissionRate")}
                                rules={[validations.notNull, validations.minNumber(0)]}
                              >
                                <InputNumber
                                  {...inputNumberDecimalStandardProps}
                                  addonAfter={<InputAddon type="percentage" />}
                                />
                              </Form.Item>
                            </Col>

                            <Col span={colSpan}>
                              <Form.Item
                                {...field}
                                name={[field.name, "subsequentCommissionRate"]}
                                label={t("commissions.settings.attrs.settings.subsequentCommissionRate")}
                                rules={[validations.notNull, validations.minNumber(0)]}
                              >
                                <InputNumber
                                  {...inputNumberDecimalStandardProps}
                                  addonAfter={<InputAddon type="percentage" />}
                                />
                              </Form.Item>
                            </Col>
                          </>
                        )}
                      </Row>
                    </Col>

                    <Col span={1}>{fields.length > 1 && <DeleteIcon onClick={() => remove(field.name)} />}</Col>
                  </Row>
                );
              })}

              <ActionButton icon="plus" className="margin-top-small" onClick={() => handleIntervalAdd(add)}>
                {t("commissions.settings.actions.addInterval")}
              </ActionButton>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};
