import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { PageSizes } from "../../../common/constants";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import { ActionProps, RootState } from "../../../common/types";
import { appendSearchParamsToURL, numberOrZero } from "../../../common/utils/utils";
import { selectRouterLocationSearch } from "../../ducks";
import ConfigPropertyForm from "../components/forms/ConfigPropertyForm";
import ConfigPropertyFilterView from "../components/views/ConfigPropertyFilterView";
import ConfigPropertyTableView from "../components/views/ConfigPropertyTableView";
import {
  createConfigPropertyActions,
  deleteConfigPropertyActions,
  deleteStateConfigPropertiesPageAction,
  filterConfigPropertiesActions,
  selectConfigPropertiesCurrentPage,
  updateConfigPropertyActions
} from "../ducks";
import {
  DynamicConfigProperty,
  DynamicConfigPropertyFilterPageRequest,
  DynamicConfigPropertyFilterPageResult
} from "../types";

interface StateProps {
  propertiesCurrentPage: DynamicConfigPropertyFilterPageResult;
  urlSearchQuery: string;
}

interface ActionsMap {
  filterConfigProperties: typeof filterConfigPropertiesActions.request;
  createConfigProperty: typeof createConfigPropertyActions.request;
  updateConfigProperty: typeof updateConfigPropertyActions.request;
  deleteConfigProperty: typeof deleteConfigPropertyActions.request;
  deleteStateConfigPropertiesPage: typeof deleteStateConfigPropertiesPageAction;
}

const ConfigPropertyListContainer = ({
  propertiesCurrentPage,
  urlSearchQuery,
  actions
}: StateProps & ActionProps<ActionsMap>) => {
  const navigate = useNavigate();

  const [configPropertyFormOpen, setConfigPropertyFormOpen] = useState<boolean>(false);
  const [configPropertyToUpdate, setConfigPropertyToUpdate] = useState<DynamicConfigProperty>();

  useEffect(() => {
    const urlParams = new URLSearchParams(urlSearchQuery);
    actions.filterConfigProperties({
      pageIndex: numberOrZero(urlParams.get("pageIndex")),
      pageSize: PageSizes.HUGE,
      keyword: urlParams.get("keyword") ?? undefined,
      ownedByIds: urlParams.getAll("ownedByIds") ?? []
    });
    return () => {
      actions.deleteStateConfigPropertiesPage();
    };
  }, []);

  const handleSearchSubmit = (filter: DynamicConfigPropertyFilterPageRequest): void => {
    navigate(
      appendSearchParamsToURL({
        ...filter,
        pageIndex: undefined,
        keyword: filter.keyword ?? undefined
      }),
      { replace: true }
    );

    actions.filterConfigProperties({
      pageIndex: 0,
      pageSize: propertiesCurrentPage.pageSize,
      keyword: filter.keyword,
      ownedByIds: filter.ownedByIds
    });
  };

  const handleTablePageChange = (pageNumber: number): void => {
    navigate(appendSearchParamsToURL({ pageIndex: pageNumber - 1 }), { replace: true });
    actions.filterConfigProperties({
      pageIndex: pageNumber - 1,
      pageSize: PageSizes.HUGE,
      keyword: propertiesCurrentPage.keyword,
      ownedByIds: propertiesCurrentPage.ownedByIds
    });
  };

  const handleCreateClick = (): void => {
    setConfigPropertyFormOpen(true);
  };

  const handleUpdateClick = (property: DynamicConfigProperty): void => {
    setConfigPropertyFormOpen(true);
    setConfigPropertyToUpdate(property);
  };

  const handleFormCancel = (): void => {
    setConfigPropertyFormOpen(false);
    setConfigPropertyToUpdate(undefined);
  };

  return (
    <ContentWrapper>
      <ConfigPropertyFilterView
        initialFilter={propertiesCurrentPage}
        onCreateClick={handleCreateClick}
        onFilterSubmit={handleSearchSubmit}
      />

      <ConfigPropertyTableView
        propertiesCurrentPage={propertiesCurrentPage}
        onPageChange={handleTablePageChange}
        onUpdateClick={handleUpdateClick}
        onDelete={actions.deleteConfigProperty}
      />

      <ConfigPropertyForm
        open={configPropertyFormOpen}
        property={configPropertyToUpdate}
        onCreate={actions.createConfigProperty}
        onUpdate={actions.updateConfigProperty}
        onFormCancel={handleFormCancel}
      />
    </ContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  propertiesCurrentPage: selectConfigPropertiesCurrentPage(state),
  urlSearchQuery: selectRouterLocationSearch(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      filterConfigProperties: filterConfigPropertiesActions.request,
      createConfigProperty: createConfigPropertyActions.request,
      updateConfigProperty: updateConfigPropertyActions.request,
      deleteConfigProperty: deleteConfigPropertyActions.request,
      deleteStateConfigPropertiesPage: deleteStateConfigPropertiesPageAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, Record<string, any>, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(ConfigPropertyListContainer);
