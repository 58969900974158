import { Tag, Tooltip } from "antd";
import t from "../../../app/i18n";
import { PresetColors } from "../../constants";
import { Status } from "../../types";
import AntIcon from "../icons/AntIcon";

interface Props {
  status: Status;
  tooltip?: string;
  onClick?(status: Status): void;
}

const StatusTag = ({ status, tooltip, onClick }: Props) => {
  let icon;

  switch (status) {
    case PresetColors.SUCCESS:
      icon = <AntIcon type="check-circle" />;
      break;
    case PresetColors.PROCESSING:
      icon = <AntIcon type="sync" spin />;
      break;
    case PresetColors.WARNING:
      icon = <AntIcon type="exclamation" />;
      break;
    case PresetColors.ERROR:
      icon = <AntIcon type="close-circle" />;
      break;
  }

  const tag = (
    <Tag
      color={status}
      icon={icon}
      style={onClick ? { cursor: "pointer" } : undefined}
      onClick={() => onClick?.(status)}
    >
      {t("common.status." + status)}
    </Tag>
  );

  return tooltip ? <Tooltip title={tooltip}>{tag}</Tooltip> : tag;
};

export default StatusTag;
