import { Card, Modal, Tabs, TabsProps } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import t from "../../../app/i18n";
import AntIcon from "../../../common/components/icons/AntIcon";
import BackNavigationArrow from "../../../common/components/views/BackNavigationArrow";
import { PageSizes } from "../../../common/constants";
import HistoryView from "../../../common/modules/history/HistoryView";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import DisplayWrapper from "../../../common/modules/wrappers/DisplayWrapper";
import { Permission } from "../../../common/security/authorization/enums";
import { ActionProps, EntityIdObject, PageResult, RootState } from "../../../common/types";
import { useRequestFinishedCallback } from "../../../common/utils/hooksUtils";
import { appendSearchParamsToURL, cssVariables } from "../../../common/utils/utils";
import { selectHasPermissions } from "../../auth/ducks";
import { selectRouterLocationSearchParam } from "../../ducks";
import { requests, requests as contractRequests } from "../api";
import DepositContractForm from "../components/forms/deposit/DepositContractForm";
import GenericContractForm from "../components/forms/generic/GenericContractForm";
import InsuranceContractForm from "../components/forms/insurance/InsuranceContractForm";
import InvestmentContractForm from "../components/forms/investment/InvestmentContractForm";
import LoanContractForm from "../components/forms/loan/LoanContractForm";
import SecondPillarContractForm from "../components/forms/secondpillar/SecondPillarContractForm";
import ThirdPillarContractForm from "../components/forms/thirdpillar/ThirdPillarContractForm";
import ContractCommissionTableView from "../components/views/detail/ContractCommissionTableView";
import DepositContractDetailView from "../components/views/detail/deposit/DepositContractDetailView";
import GenericContractDetailView from "../components/views/detail/generic/GenericContractDetailView";
import InsuranceContractDetailView from "../components/views/detail/insurance/InsuranceContractDetailView";
import InvestmentContractDetailView from "../components/views/detail/investment/InvestmentContractDetailView";
import LoanContractDetailView from "../components/views/detail/loan/LoanContractDetailView";
import SecondPillarContractDetailView from "../components/views/detail/secondpillar/SecondPillarContractDetailView";
import ThirdPillarContractDetailView from "../components/views/detail/thirdpillar/ThirdPillarContractDetailView";
import {
  changeContractVerificationStatusActions,
  deleteContractActions,
  deleteContractAttachmentActions,
  deleteStateContractDetailAction,
  downloadContractAttachmentsAsZipActions,
  filterContractCommissionsActions,
  getContractActions,
  getContractExternalViewActions,
  selectContractCommissionsPage,
  selectContractDetail,
  toggleVerificationExportInclusionActions,
  updateContractActions,
  uploadContractAttachmentsActions
} from "../ducks";
import { ContractType } from "../enums";
import { Contract, ContractCommission, ContractDetailViewProps, ContractFormProps } from "../types";

interface StateProps {
  contract?: Contract;
  contractCommissionsPage: PageResult<ContractCommission>;
  hasProfileCommissionsPermission: boolean;
  urlTabKey: string;
}

interface ActionsMap {
  getContract: typeof getContractActions.request;
  filterContractCommissions: typeof filterContractCommissionsActions.request;
  changeContractVerificationStatus: typeof changeContractVerificationStatusActions.request;
  toggleVerificationExportInclusion: typeof toggleVerificationExportInclusionActions.request;
  getContractExternalView: typeof getContractExternalViewActions.request;
  updateContract: typeof updateContractActions.request;
  deleteContract: typeof deleteContractActions.request;
  downloadContractAttachmentsAsZip: typeof downloadContractAttachmentsAsZipActions.request;
  uploadContractAttachments: typeof uploadContractAttachmentsActions.request;
  deleteContractAttachment: typeof deleteContractAttachmentActions.request;
  deleteStateContractDetail: typeof deleteStateContractDetailAction;
}

type Props = StateProps & ActionProps<ActionsMap>;

const TAB = {
  CONTRACT: "contract",
  HISTORY: "history",
  COMMISSIONS: "commissions"
};

const ContractDetailContainer = ({ contract, ...props }: Props) => {
  const { id } = useParams<EntityIdObject>() as { id: string };
  const navigate = useNavigate();

  const [tabKey, setTabKey] = useState<string>(TAB.CONTRACT);
  const [updateMode, setUpdateMode] = useState<boolean>(false);

  useRequestFinishedCallback([requests.UPDATE_CONTRACT], () => setUpdateMode(false));

  useEffect(() => {
    if (contract?.id !== id) {
      props.actions.getContract({ id });
    }

    switch (props.urlTabKey) {
      case TAB.CONTRACT:
      case TAB.HISTORY:
        setTabKey(props.urlTabKey);
        break;
      case TAB.COMMISSIONS:
        setTabKey(props.hasProfileCommissionsPermission ? props.urlTabKey : TAB.CONTRACT);
        break;
      default:
        setTabKey(TAB.CONTRACT);
        break;
    }

    return () => {
      props.actions.deleteStateContractDetail();
    };
  }, [id]);

  const handleTabKeyChange = (key: string): void => {
    if (tabKey === TAB.CONTRACT && updateMode) {
      Modal.confirm({
        title: t("contract.helpers.unsavedChanges"),
        okText: t("contract.helpers.unsavedChangesConfirm"),
        cancelText: t("common.back"),
        onOk: () => moveViewToTab(key)
      });
    } else {
      moveViewToTab(key);
    }
  };

  const moveViewToTab = (tabKey: string): void => {
    setTabKey(tabKey);
    navigate(appendSearchParamsToURL({ tab: tabKey }), { replace: true });
  };

  const handleUpdateModeToggle = (): void => {
    setUpdateMode(!updateMode);
  };

  const handleContractCommissionsFilter = (contractId: string): void => {
    props.actions.filterContractCommissions({
      id: contractId,
      object: { pageIndex: 0, pageSize: PageSizes.SMALL }
    });
  };

  const handleContractCommissionsTablePageChange = (pageNumber: number, contractId: string): void => {
    props.actions.filterContractCommissions({
      id: contractId,
      object: { pageIndex: pageNumber - 1, pageSize: props.contractCommissionsPage.pageSize }
    });
  };

  const contractFormProps: ContractFormProps<any> = {
    initialContract: contract,
    onUpdateFormSubmit: props.actions.updateContract,
    onCancelClick: handleUpdateModeToggle
  };

  const contractViewProps: ContractDetailViewProps<any> = {
    contract: contract,
    onUpdateClick: handleUpdateModeToggle,
    onVerificationStatusChange: props.actions.changeContractVerificationStatus,
    onVerificationExportToggle: props.actions.toggleVerificationExportInclusion,
    onExternalViewGet: props.actions.getContractExternalView,
    onDelete: props.actions.deleteContract,
    onDownloadAsZip: props.actions.downloadContractAttachmentsAsZip,
    onAttachmentsUpload: props.actions.uploadContractAttachments,
    onAttachmentDelete: props.actions.deleteContractAttachment
  };

  let contractTabContent: React.ReactNode;
  if (contract) {
    switch (contract.type) {
      case ContractType.INSURANCE_CONTRACT:
        contractTabContent = updateMode ? (
          <InsuranceContractForm {...contractFormProps} />
        ) : (
          <InsuranceContractDetailView {...contractViewProps} />
        );
        break;
      case ContractType.LOAN_CONTRACT:
        contractTabContent = updateMode ? (
          <LoanContractForm {...contractFormProps} />
        ) : (
          <LoanContractDetailView {...contractViewProps} />
        );
        break;
      case ContractType.INVESTMENT_CONTRACT:
        contractTabContent = updateMode ? (
          <InvestmentContractForm {...contractFormProps} />
        ) : (
          <InvestmentContractDetailView {...contractViewProps} />
        );
        break;
      case ContractType.DEPOSIT_CONTRACT:
        contractTabContent = updateMode ? (
          <DepositContractForm {...contractFormProps} />
        ) : (
          <DepositContractDetailView {...contractViewProps} />
        );
        break;
      case ContractType.SECOND_PILLAR_CONTRACT:
        contractTabContent = updateMode ? (
          <SecondPillarContractForm {...contractFormProps} />
        ) : (
          <SecondPillarContractDetailView {...contractViewProps} />
        );
        break;
      case ContractType.THIRD_PILLAR_CONTRACT:
        contractTabContent = updateMode ? (
          <ThirdPillarContractForm {...contractFormProps} />
        ) : (
          <ThirdPillarContractDetailView {...contractViewProps} />
        );
        break;
      case ContractType.GENERIC_CONTRACT:
        contractTabContent = updateMode ? (
          <GenericContractForm {...contractFormProps} />
        ) : (
          <GenericContractDetailView {...contractViewProps} />
        );
        break;
    }
  }

  const getItems = (contract: Contract, hasProfileCommissionsPermission: boolean): TabsProps["items"] => {
    const items: TabsProps["items"] = [];

    items.push({
      key: TAB.CONTRACT,
      label: (
        <span>
          <AntIcon type="solution" className="margin-right-tiny" />
          {t("contract.titles.contractData")}
        </span>
      ),
      children: contractTabContent
    });

    items.push({
      key: TAB.HISTORY,
      label: (
        <span>
          <AntIcon type="history" className="margin-right-tiny" />
          {t("contract.titles.history")}
        </span>
      ),
      children: (
        <Card className="margin-top-small">
          <HistoryView
            item={contract}
            translationRootPath="contract.attrs"
            type="contract"
            excludedFields={["commissionPaid"]}
          />
        </Card>
      )
    });

    if (hasProfileCommissionsPermission) {
      items.push({
        key: TAB.COMMISSIONS,
        label: (
          <span>
            <AntIcon type="euro-circle" className="margin-right-tiny" />
            {t("contract.titles.commissions")}
          </span>
        ),
        children: (
          <Card className="margin-top-small">
            <ContractCommissionTableView
              commissionsPage={props.contractCommissionsPage}
              onFilter={() => handleContractCommissionsFilter(contract.id)}
              onPageChange={pageNumber => handleContractCommissionsTablePageChange(pageNumber, contract.id)}
            />
          </Card>
        )
      });
    }

    return items;
  };

  return (
    <ContentWrapper>
      <DisplayWrapper itemLoaded={!!contract} notFoundCheckRequest={contractRequests.GET_CONTRACT}>
        {contract && (
          <Tabs
            tabBarStyle={{
              backgroundColor: "white",
              padding: "0 24px",
              marginBottom: 24,
              borderRadius: 8,
              boxShadow: `0 1px 3px 0 ${cssVariables.colorShadow}, 0 1px 2px 0 ${cssVariables.colorShadow}`,
              border: `1px solid ${cssVariables.colorHover}`,
              overflow: "hidden"
            }}
            tabBarExtraContent={{ left: <BackNavigationArrow /> }}
            activeKey={tabKey}
            onChange={handleTabKeyChange}
            items={getItems(contract, props.hasProfileCommissionsPermission)}
          />
        )}
      </DisplayWrapper>
    </ContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  contract: selectContractDetail(state),
  contractCommissionsPage: selectContractCommissionsPage(state),
  hasProfileCommissionsPermission: selectHasPermissions(Permission.PROFILE_COMMISSIONS)(state),
  urlTabKey: selectRouterLocationSearchParam(state, "tab") || TAB.CONTRACT
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      getContract: getContractActions.request,
      filterContractCommissions: filterContractCommissionsActions.request,
      changeContractVerificationStatus: changeContractVerificationStatusActions.request,
      toggleVerificationExportInclusion: toggleVerificationExportInclusionActions.request,
      getContractExternalView: getContractExternalViewActions.request,
      updateContract: updateContractActions.request,
      deleteContract: deleteContractActions.request,
      downloadContractAttachmentsAsZip: downloadContractAttachmentsAsZipActions.request,
      uploadContractAttachments: uploadContractAttachmentsActions.request,
      deleteContractAttachment: deleteContractAttachmentActions.request,
      deleteStateContractDetail: deleteStateContractDetailAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, Record<string, any>, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(ContractDetailContainer);
