import { Card, Col, Row, Tabs } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import t from "../../../app/i18n";
import AntIcon from "../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../common/constants";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import DisplayWrapper from "../../../common/modules/wrappers/DisplayWrapper";
import { ActionProps, PageResult, RootState } from "../../../common/types";
import { formatAgentAggregatedName } from "../../../common/utils/formatUtils";
import { appendSearchParamsToURL } from "../../../common/utils/utils";
import { AgentCommissionsSummaryView } from "../../agent/components/views/commissions/AgentCommissionsSummaryView";
import { AgentBase } from "../../agent/types";
import { selectUserAccount } from "../../auth/ducks";
import BailAccountMovementTableView from "../../commissions/bailaccount/components/views/BailAccountMovementTableView";
import BailAccountSettingsView from "../../commissions/bailaccount/components/views/BailAccountSettingsView";
import {
  deleteStateBailAccountMovementsPageAction,
  filterBailAccountMovementsActions,
  selectBailAccountMovementsPage
} from "../../commissions/bailaccount/ducks";
import { BailAccountMovementFilterPageResult } from "../../commissions/bailaccount/types";
import { CommissionsBatchOutputAttachment } from "../../commissions/batch/types";
import DebtAccountMovementTableView from "../../commissions/debtaccount/components/views/DebtAccountMovementTableView";
import {
  deleteStateDebtAccountMovementsPageAction,
  filterDebtAccountMovementsActions,
  selectDebtAccountMovementsPage
} from "../../commissions/debtaccount/ducks";
import { DebtAccountMovementFilterPageResult } from "../../commissions/debtaccount/types";
import CommissionsOutputsTableView from "../../commissions/outputs/components/CommissionsOutputsTableView";
import {
  deleteStateCommissionsOutputsPageAction,
  deleteStateOutputCalculatedCommissionsPageAction,
  deleteStateOutputSpecialCommissionsPageAction,
  filterCommissionsOutputsActions,
  filterOutputCalculatedCommissionsActions,
  filterOutputSpecialCommissionsActions,
  selectCommissionsOutputsPage,
  selectOutputCalculatedCommissionsPage,
  selectOutputSpecialCommissionsPage
} from "../../commissions/outputs/ducks";
import { CalculatedCommissionsFilterPageResult } from "../../commissions/outputs/types";
import {
  deleteStateCommissionsSettingsAction,
  getCommissionsSettingsActions,
  selectCommissionsSettingsData
} from "../../commissions/settings/ducks";
import { CommissionsSettingsAgentProfile } from "../../commissions/settings/types";
import { SpecialCommissionBase, SpecialCommissionsFilterPageResult } from "../../commissions/special/types";
import { selectRouterLocationSearch } from "../../ducks";
import { COMMISSIONS_PROFILE_TABS } from "../../globalsearch/utils";
import { requests } from "../api";

interface StateProps {
  agent?: AgentBase;
  commissionsSettings: CommissionsSettingsAgentProfile;
  commissionsOutputsPage: PageResult<CommissionsBatchOutputAttachment>;
  outputCalculatedCommissionsPage: CalculatedCommissionsFilterPageResult;
  outputSpecialCommissionsPage: SpecialCommissionsFilterPageResult<SpecialCommissionBase>;
  bailAccountMovementsPage: BailAccountMovementFilterPageResult;
  debtAccountMovementsPage: DebtAccountMovementFilterPageResult;
  urlSearchQuery: string;
}

interface ActionsMap {
  getCommissionsSettings: typeof getCommissionsSettingsActions.request;
  deleteStateCommissionsSettings: typeof deleteStateCommissionsSettingsAction;
  filterCommissionsOutputs: typeof filterCommissionsOutputsActions.request;
  deleteStateCommissionsOutputsPage: typeof deleteStateCommissionsOutputsPageAction;
  filterOutputCalculatedCommissions: typeof filterOutputCalculatedCommissionsActions.request;
  deleteStateOutputCalculatedCommissions: typeof deleteStateOutputCalculatedCommissionsPageAction;
  filterOutputSpecialCommissions: typeof filterOutputSpecialCommissionsActions.request;
  deleteStateOutputSpecialCommissions: typeof deleteStateOutputSpecialCommissionsPageAction;
  filterBailAccountMovements: typeof filterBailAccountMovementsActions.request;
  deleteStateBailAccountMovementsPage: typeof deleteStateBailAccountMovementsPageAction;
  filterDebtAccountMovements: typeof filterDebtAccountMovementsActions.request;
  deleteStateDebtAccountMovementsPage: typeof deleteStateDebtAccountMovementsPageAction;
}

type Props = StateProps & ActionProps<ActionsMap>;

const UserCommissionsContainer = ({
  agent,
  commissionsSettings,
  commissionsOutputsPage,
  outputCalculatedCommissionsPage,
  outputSpecialCommissionsPage,
  bailAccountMovementsPage,
  debtAccountMovementsPage,
  urlSearchQuery,
  actions
}: Props) => {
  const navigate = useNavigate();

  const [tabKey, setTabKey] = useState<string>(COMMISSIONS_PROFILE_TABS.OUTPUTS);

  useEffect(() => {
    if (!agent) {
      return;
    }

    const urlParams = new URLSearchParams(urlSearchQuery);

    const urlTabKey = urlParams.get("COMMISSIONS_PROFILE_TABS") || COMMISSIONS_PROFILE_TABS.OUTPUTS;
    switch (urlTabKey) {
      case COMMISSIONS_PROFILE_TABS.OUTPUTS:
      case COMMISSIONS_PROFILE_TABS.BAIL_ACCOUNT:
      case COMMISSIONS_PROFILE_TABS.DEBT_ACCOUNT:
        setTabKey(urlTabKey);
        break;
      default:
        setTabKey(COMMISSIONS_PROFILE_TABS.OUTPUTS);
        break;
    }

    actions.getCommissionsSettings({ id: agent.id });

    return () => {
      actions.deleteStateCommissionsSettings();
    };
  }, []);

  const handleTabKeyChange = (key: string): void => {
    setTabKey(key);
    navigate(appendSearchParamsToURL({ pageIndex: undefined, key }), { replace: true });
  };

  return agent ? (
    <DisplayWrapper itemLoaded={!!commissionsSettings} notFoundCheckRequest={requests.USER_GET_USER_PROFILE}>
      {commissionsSettings && (
        <Card
          className="card-box"
          title={
            <h2 className="left-float">{`${t("user.titles.commissions")} | ${formatAgentAggregatedName(agent)}`}</h2>
          }
        >
          <AgentCommissionsSummaryView agent={agent} commissionsSettings={commissionsSettings} />

          <Tabs
            activeKey={tabKey}
            onChange={handleTabKeyChange}
            className="margin-top-small"
            items={[
              {
                key: COMMISSIONS_PROFILE_TABS.OUTPUTS,
                label: (
                  <span>
                    <AntIcon type="euro-circle" className="margin-right-tiny" />
                    {t("commissions.settings.sections.outputs")}
                  </span>
                ),
                children: (
                  <CommissionsOutputsTableView
                    agentId={agent.id}
                    outputsPage={commissionsOutputsPage}
                    filterCommissionsOutputs={actions.filterCommissionsOutputs}
                    deleteStateCommissionsOutputsPage={actions.deleteStateCommissionsOutputsPage}
                    outputCalculatedCommissionsPage={outputCalculatedCommissionsPage}
                    filterOutputCalculatedCommissions={actions.filterOutputCalculatedCommissions}
                    deleteStateOutputCalculatedCommissions={actions.deleteStateOutputCalculatedCommissions}
                    outputSpecialCommissionsPage={outputSpecialCommissionsPage}
                    filterOutputSpecialCommissions={actions.filterOutputSpecialCommissions}
                    deleteStateOutputSpecialCommissions={actions.deleteStateOutputSpecialCommissions}
                    urlSearchQuery={urlSearchQuery}
                  />
                )
              },
              {
                key: COMMISSIONS_PROFILE_TABS.BAIL_ACCOUNT,
                label: (
                  <span>
                    <AntIcon type="plus-square" className="margin-right-tiny" />
                    {t("commissions.bailAccount.titles.page")}
                  </span>
                ),
                children: (
                  <Row gutter={rowGutter}>
                    <Col span={7}>
                      <BailAccountSettingsView
                        settings={commissionsSettings.bailAccountSettings}
                        showActions={false}
                        noSettingsLabel={t("commissions.bailAccount.helpers.noSettings")}
                      />
                    </Col>

                    <Col span={17}>
                      <BailAccountMovementTableView
                        agentId={agent.id}
                        movementsPage={bailAccountMovementsPage}
                        filterBailAccountMovements={actions.filterBailAccountMovements}
                        deleteStateBailAccountMovements={actions.deleteStateBailAccountMovementsPage}
                        showActions={false}
                        showCreateMovementAction={false}
                        showFilterSwitch={false}
                        urlSearchQuery={urlSearchQuery}
                      />
                    </Col>
                  </Row>
                )
              },
              {
                key: COMMISSIONS_PROFILE_TABS.DEBT_ACCOUNT,
                label: (
                  <span>
                    <AntIcon type="minus-square" className="margin-right-tiny" />
                    {t("commissions.debtAccount.titles.page")}
                  </span>
                ),
                children: (
                  <ContentWrapper>
                    <DebtAccountMovementTableView
                      agentId={agent.id}
                      movementsPage={debtAccountMovementsPage}
                      filterDebtAccountMovements={actions.filterDebtAccountMovements}
                      deleteStateDebtAccountMovements={actions.deleteStateDebtAccountMovementsPage}
                      showFilterSwitch={false}
                      urlSearchQuery={urlSearchQuery}
                    />
                  </ContentWrapper>
                )
              }
            ]}
          />
        </Card>
      )}
    </DisplayWrapper>
  ) : null;
};

const mapStateToProps = (state: RootState): StateProps => ({
  agent: selectUserAccount(state)?.agent,
  commissionsSettings: selectCommissionsSettingsData(state) as CommissionsSettingsAgentProfile,
  commissionsOutputsPage: selectCommissionsOutputsPage(state),
  outputCalculatedCommissionsPage: selectOutputCalculatedCommissionsPage(state),
  outputSpecialCommissionsPage: selectOutputSpecialCommissionsPage(state),
  bailAccountMovementsPage: selectBailAccountMovementsPage(state),
  debtAccountMovementsPage: selectDebtAccountMovementsPage(state),
  urlSearchQuery: selectRouterLocationSearch(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      getCommissionsSettings: getCommissionsSettingsActions.request,
      deleteStateCommissionsSettings: deleteStateCommissionsSettingsAction,
      filterCommissionsOutputs: filterCommissionsOutputsActions.request,
      deleteStateCommissionsOutputsPage: deleteStateCommissionsOutputsPageAction,
      filterOutputCalculatedCommissions: filterOutputCalculatedCommissionsActions.request,
      deleteStateOutputCalculatedCommissions: deleteStateOutputCalculatedCommissionsPageAction,
      filterOutputSpecialCommissions: filterOutputSpecialCommissionsActions.request,
      deleteStateOutputSpecialCommissions: deleteStateOutputSpecialCommissionsPageAction,
      filterBailAccountMovements: filterBailAccountMovementsActions.request,
      deleteStateBailAccountMovementsPage: deleteStateBailAccountMovementsPageAction,
      filterDebtAccountMovements: filterDebtAccountMovementsActions.request,
      deleteStateDebtAccountMovementsPage: deleteStateDebtAccountMovementsPageAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, Record<string, any>, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(UserCommissionsContainer);
