import {
  CommissionsSettingsLevel,
  CommissionsSettingsLevelRule,
  CreateUpdateCommissionsSettingsLevel,
  CreateUpdateCommissionsSettingsLevelRule
} from "./types";

export const convertCommissionsSettingsLevelToCreateUpdateObject = (
  level: CommissionsSettingsLevel
): CreateUpdateCommissionsSettingsLevel => {
  const { id, nextLevels, formerLevels, rules, bailAccountSettings, attachments, ...data } = level;

  return { ...data, nextLevelIds: level.nextLevels?.map(nextLevel => nextLevel.id) };
};

export const convertCommissionsSettingsLevelRuleToCreateUpdateObject = (
  rule: CommissionsSettingsLevelRule
): CreateUpdateCommissionsSettingsLevelRule => {
  const { id, createdAt, updatedAt, productGroup, institution, product, ...data } = rule;

  return {
    ...data,
    productGroupId: rule.productGroup?.id,
    institutionId: rule.institution?.id,
    productId: rule.product?.id
  };
};
