import { useMutation } from "@tanstack/react-query";
import { productCommissionsSettingsApi } from "../../../modules/commissions/productcommissionssettings/api";
import { UpdateProductCommissionsSettings } from "../../../modules/commissions/productcommissionssettings/types";
import { EntityObject } from "../../types";

export const PRODUCT_COMMISSIONS_SETTINGS_QUERY_KEYS = {
  updateProductCommissionsSettings: () => ["updateProductCommissionsSettings"]
};

export const useUpdateProductCommissionsSettings = () => {
  return useMutation({
    mutationKey: PRODUCT_COMMISSIONS_SETTINGS_QUERY_KEYS.updateProductCommissionsSettings(),
    mutationFn: async (payload: EntityObject<UpdateProductCommissionsSettings>) => {
      const { data } = await productCommissionsSettingsApi.updateProductCommissionsSettings(payload);

      return data;
    }
  });
};
