import { AutoComplete, Spin } from "antd";
import debounce from "lodash/debounce";
import { DefaultOptionType } from "rc-select/lib/Select";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import t, { DEFAULT_LOCALE } from "../../../app/i18n";
import { GoogleMapsAPIContext } from "../../../app/providers/GoogleMapsApiProvider";
import AntIcon from "../../components/icons/AntIcon";
import { BratislavaCityDistricts, KosiceCityDistricts } from "../../constants";
import { getCountryByTwoLetterCode, removeStringWhiteSpaces } from "../../utils/utils";
import { Address } from "../types";

const MIN_SEARCH_VALUE_LENGTH = 4;

interface Props {
  hide?: boolean;
  onAddressFind: (address: Address) => void;
}

export const AddressAutocomplete = ({ hide, onAddressFind }: Props) => {
  const { googleApi } = useContext(GoogleMapsAPIContext);
  const divContainerRef = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState<string>();
  const [options, setOptions] = useState<DefaultOptionType[]>([]);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken>();

  useEffect(() => {
    if (googleApi) {
      setSessionToken(new googleApi.AutocompleteSessionToken());
    }
  }, [googleApi]);

  const handleSearchDebounced = useMemo(
    () =>
      debounce((value: string): void => {
        if (!googleApi) {
          console.error("Google Maps API is not initialized.");
          return;
        }

        if (value.length < MIN_SEARCH_VALUE_LENGTH) {
          return;
        }

        setInProgress(true);

        googleApi.AutocompleteSuggestion.fetchAutocompleteSuggestions({
          input: value,
          sessionToken,
          language: DEFAULT_LOCALE,
          region: DEFAULT_LOCALE
        })
          .then(({ suggestions }) => {
            setOptions(
              suggestions
                .filter(suggestion => !!suggestion.placePrediction)
                .map<DefaultOptionType>(suggestion => ({
                  label: suggestion.placePrediction?.text?.text,
                  value: suggestion.placePrediction?.placeId
                }))
            );
          })
          .finally(() => {
            setInProgress(false);
          });
      }, 500),
    [sessionToken]
  );

  const handleSearch = useCallback(
    (value: string): void => {
      handleSearchDebounced(value);
    },
    [handleSearchDebounced]
  );

  const handleSelect = (value: string): void => {
    if (!googleApi) {
      console.error("Google Maps API is not initialized.");
      return;
    }

    const googleApiPlace = new googleApi.Place({
      id: value,
      requestedLanguage: DEFAULT_LOCALE,
      requestedRegion: DEFAULT_LOCALE
    });

    googleApiPlace
      .fetchFields({
        fields: ["addressComponents"]
      })
      .then(({ place }) => {
        const address: Partial<Address> = {};

        place.addressComponents?.forEach(component => {
          switch (component.types[0]) {
            case "route":
              address.street = component.longText ?? undefined;
              break;
            case "premise":
              address.descriptiveNumber = component.longText ?? undefined;
              break;
            case "street_number":
              address.orientationNumber = component.shortText ?? undefined;
              break;
            case "sublocality_level_1":
              address.cityDistrict = component.longText ?? undefined;
              break;
            case "locality":
              address.city = component.longText ?? undefined;
              break;
            case "postal_code":
              address.zipCode = removeStringWhiteSpaces(component.longText ?? undefined);
              break;
            case "country":
              address.country = component.shortText ? getCountryByTwoLetterCode(component.shortText) : undefined;
              break;
          }
        });

        if (address.cityDistrict) {
          if (!address.city) {
            address.city = address.cityDistrict;
            address.cityDistrict = undefined;
          } else if (
            !(
              (address.city === "Bratislava" && BratislavaCityDistricts.includes(address.cityDistrict)) ||
              (address.city === "Košice" && KosiceCityDistricts.includes(address.cityDistrict))
            )
          ) {
            address.cityDistrict = undefined;
          }
        }

        onAddressFind(address as Address);
      })
      .catch(() => console.error("Error occurred while executing place details request."))
      .finally(() => {
        handleClear();
      });
  };

  const handleClear = (): void => {
    setValue(undefined);
    setOptions([]);
    setInProgress(false);

    if (googleApi) {
      setSessionToken(new googleApi.AutocompleteSessionToken());
    }
  };

  return googleApi && !hide ? (
    <>
      <Spin spinning={inProgress} style={{ marginTop: "-3px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <AutoComplete
            style={{ width: 230 }}
            popupMatchSelectWidth={false}
            value={value}
            options={options}
            onChange={setValue}
            onSearch={handleSearch}
            onSelect={handleSelect}
            onBlur={handleClear}
            onClear={handleClear}
            notFoundContent={
              (value || "").length >= MIN_SEARCH_VALUE_LENGTH && (
                <div className="sub-header-info center-align">{t("address.search.noResult")}</div>
              )
            }
            placeholder={
              <>
                <AntIcon type="search" />
                {t("address.search.placeholder")}
              </>
            }
          />
        </div>
      </Spin>
      <div ref={divContainerRef} />
    </>
  ) : null;
};
