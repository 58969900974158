import { TableProps } from "antd";
import { PaginationConfig } from "antd/lib/pagination";
import { TablePaginationConfig } from "antd/lib/table";
import { RcFile } from "antd/lib/upload";
import { AxiosResponse } from "axios";
import Big from "big.js";
import classNames from "classnames";
import dayjs, { Dayjs } from "dayjs";
import { saveAs } from "file-saver";
import { HTMLAttributeAnchorTarget } from "react";
import t from "../../app/i18n";
import customCssVariables from "../../app/styles/exports.module.scss";
import { HttpContentType } from "../api/http";
import type { AntIconType } from "../components/icons/AntIcon";
import { SlovakHolidays } from "../constants";
import { Country, CountryTwoLetterCode } from "../modules/enums";
import { StorageFile } from "../modules/types";
import { Permission, permissionsPrerequisitesMap } from "../security/authorization/enums";
import { dateToIsoDateString } from "./formUtils";

export const ALL_WHITE_SPACES_PATTERN = new RegExp(/\s/, "g");
export const ATTACHMENT_VIEWER_ID = "attachment-viewer";

/**
 * Function determines whether the project is in localhost DEV mode (project was started with command "npm start" and not as build)
 * with "dev" active profile.
 * @return <i>true</i> if project is in localhost DEV mode, <i>false</i> otherwise
 */
export const isLocalhostDevMode = (): boolean => import.meta.env.DEV && getActiveEnvProfile() === "dev";

export const getApiBaseUrl = () => import.meta.env.VITE_API_BASE_URL;

export const getActiveEnvProfile = () => import.meta.env.VITE_ACTIVE_PROFILE;

export const getGoogleAnalyticsKey = () => import.meta.env.VITE_GOOGLE_ANALYTICS_KEY;

export const getGoogleMapsApiKey = () => import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

/**
 * Returns first part of input pathname (e.g. input string '/abc/xyz' returns '/abc' in lower case).
 * @param pathname - pathname to be parsed
 * @return first part of input pathname
 */
export const parseFirstPartOfPathname = (pathname: string): string => {
  const firstLevelPathname = pathname.split("/")[1];
  if (firstLevelPathname) {
    return "/" + firstLevelPathname.toLowerCase();
  }

  return "/";
};

/**
 * Check requested permission.
 * @param accountPermissions - permissions of checked account
 * @param checked - permission to be checked
 * @return <i>true</i> if checked permission is present in account permissions, <i>false</i> otherwise
 */
export const hasPermission = (accountPermissions: Permission[], checked: Permission): boolean => {
  return accountPermissions.includes(checked);
};

/**
 * Check if all permissions are present in account permissions.
 * @param accountPermissions - permissions of checked account
 * @param checked - permissions to be checked
 * @return <i>true</i> if all checked permissions are present in account permissions, <i>false</i> otherwise
 */
export const hasAllPermissions = (accountPermissions: Permission[], checked: Permission[]): boolean => {
  return checked.every(perm => perm && accountPermissions.includes(perm));
};

/**
 * Check if any of given permissions is present in account permissions.
 * @param accountPermissions - permissions of checked account
 * @param checked - permissions to be checked
 * @return <i>true</i> if any of checked permissions is present in account permissions, <i>false</i> otherwise
 */
export const hasAnyPermission = (accountPermissions: Permission[], checked: Permission[]): boolean => {
  return checked.some(perm => perm && accountPermissions.includes(perm));
};

export const getAllPermissionPrerequisites = (permission: Permission): Permission[] => {
  const parsePrerequisites = (permission: Permission, prerequisites: Set<Permission>): void => {
    const permissionsPrerequisites = permissionsPrerequisitesMap.get(permission);
    if (permissionsPrerequisites) {
      permissionsPrerequisites.forEach(prerequisite => {
        prerequisites.add(prerequisite);
        parsePrerequisites(prerequisite, prerequisites);
      });
    }
  };

  const prerequisitesSet = new Set<Permission>();
  parsePrerequisites(permission, prerequisitesSet);
  return Array.from(prerequisitesSet).reverse();
};

export const appendSearchParamsToURL = (
  searchParams: Record<string, string | number | boolean | undefined | string[]>
): string => {
  const outputSearch = new URLSearchParams(window.location.search);

  Object.keys(searchParams).forEach(searchParamKey => {
    const paramValue = searchParams[searchParamKey];

    if (!isDefined(paramValue) || outputSearch.has(searchParamKey)) {
      outputSearch.delete(searchParamKey);
    }

    if (Array.isArray(paramValue)) {
      paramValue.forEach(value => {
        outputSearch.append(searchParamKey, value);
      });
    } else if (paramValue) {
      outputSearch.set(searchParamKey, paramValue.toString());
    }
  });

  const outputSearchString = outputSearch.toString();
  return outputSearchString.length > 0 ? `${window.location.pathname}?${outputSearchString}` : window.location.pathname;
};

const isMobile = () => {
  const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

  return toMatch.some(toMatchItem => {
    return navigator.userAgent.match(toMatchItem);
  });
};

export const isJson = (content: string): boolean => {
  try {
    JSON.parse(content);
  } catch {
    return false;
  }
  return true;
};

export const isLowerCase = (input: string) => input === String(input).toLowerCase();

export const isUpperCase = (input: string) => input === String(input).toUpperCase();

export const isObject = (value: any): boolean => Object.prototype.toString.call(value) === "[object Object]";

export const isDefined = <T = any>(value: any): value is T => value !== undefined && value !== null;

export const isEmptyOrUndefined = (value: any): boolean => {
  return !isDefined(value)
    ? true
    : !(
        (typeof value === "string" && value.length) ||
        (Array.isArray(value) && value.length) ||
        (isObject(value) && Object.keys(value).length)
      );
};

/**
 * @deprecated - use isDefined instead
 */
export const isDefinedValue = (value: any): boolean => value !== undefined && value !== null;

export const isNumber = (value: any): boolean =>
  !(Array.isArray(value) || value === null) &&
  (typeof value === "number"
    ? Number.isFinite(value)
    : typeof value === "string" && value.trim() !== "" && !isNaN(Number(value)));

export const numberOrZero = (value?: any): number =>
  !isDefined<number>(value) || !isNumber(value) ? 0 : Number.parseFloat(value.toString());

export const bigToFloat = (value: Big): number => parseFloat(value.round(2, 1).valueOf());

export const tryToExtractBankNameFromIban = (iban: string): string | undefined => {
  if (!iban || iban.length < 4) {
    return undefined;
  }
  switch (removeStringWhiteSpaces(iban)?.substring(4, 8)) {
    case "0200":
      return "Všeobecná úverová banka, a.s.";
    case "0900":
      return "Slovenská sporiteľňa, a.s.";
    case "1100":
      return "Tatra banka, a.s.";
    case "1111":
      return "UniCredit Bank Czech Republic and Slovakia, a.s., pobočka zahraničnej banky";
    case "5200":
      return "OTP Banka Slovensko, a.s.";
    case "5600":
      return "Prima banka Slovensko, a.s.";
    case "7500":
      return "Československá obchodná banka, a.s.";
    case "8130":
      return "Citibank Europe plc, pobočka zahraničnej banky";
    case "6500":
      return "365.bank, a.s.";
    default:
      return undefined;
  }
};

export const saveTextFile = (content: string, filename: string): void => {
  saveAs(
    new Blob([content], { type: "text/plain;charset=utf-8" }),
    filename.endsWith(".txt") ? filename : filename + ".txt"
  );
};

export const openBlobFile = (response: AxiosResponse<Blob>, forceSave?: boolean): void => {
  let fileName: string;
  if (response.headers["content-disposition"]) {
    const disposition: string = response.headers["content-disposition"];
    const keyword = 'filename="';
    fileName = disposition.substring(
      disposition.indexOf(keyword) + keyword.length,
      disposition.indexOf('"', disposition.indexOf(keyword) + keyword.length)
    );
  } else {
    fileName = "download";
  }

  const contentType = response.headers["content-type"] as string;
  const file = new File([response.data], fileName, { type: contentType });

  if (
    forceSave ||
    contentType === "text/csv" ||
    (contentType !== "application/pdf" && !contentType.startsWith("image") && !contentType.startsWith("text")) ||
    isMobile()
  ) {
    saveAs(file, fileName);
  } else {
    const fileUrl = URL.createObjectURL(file);
    document.getElementById(ATTACHMENT_VIEWER_ID)?.setAttribute("src", fileUrl);
  }
};

export const contains = <T = any>(array: T[], item: T): boolean => array.includes(item);

export const containsAny = <T = any>(array: T[], ...items: T[]): boolean => items.some(item => array.includes(item));

export const containsAll = <T = any>(array: T[], ...items: T[]): boolean => items.every(item => array.includes(item));

/**
 * Replaces item in input array.
 * @param array - array to be modified
 * @param selector - function that identifies item to be replaced
 * @param newValue - value to be added to array
 */
export const replaceInArray = <T = any>(
  array: T[],
  selector: (item: T) => boolean,
  newValue: (current: T) => T
): T[] => {
  const idx = array.findIndex(selector);
  return idx === -1 ? array : [...array.slice(0, idx), newValue(array[idx] as T), ...array.slice(idx + 1)];
};

/**
 * Removes item from input array.
 * @param array - array to be modified
 * @param selector - function that identifies item to be replaced
 */
export const removeFromArray = <T = any>(array: T[], selector: (item: T) => boolean): T[] => {
  const idx = array.findIndex(selector);
  return idx === -1 ? array : [...array.slice(0, idx), ...array.slice(idx + 1)];
};

export const distinctArray = <T = any>(array: T[]): T[] => {
  return isNotEmptyArray(array) ? array.filter((value, index) => array.indexOf(value) === index) : array;
};

export const isNotEmptyArray = <T = any>(array?: T[]): boolean => !!array && array.length > 0;

export const insertToString = (modifiedValue: string, insertedValue: string, index: number): string => {
  return modifiedValue.slice(0, index) + insertedValue + modifiedValue.slice(index);
};

export const removeStringWhiteSpaces = (value?: string): string | undefined =>
  value ? value.replace(ALL_WHITE_SPACES_PATTERN, "") : undefined;

export const createLinkHref = (link: string): string => (link.startsWith("http") ? link : "http://" + link);

export const getCountryByTwoLetterCode = (countryCode: string): Country | undefined => {
  const countryKey = Object.entries(CountryTwoLetterCode).find(([, value]) => value === countryCode.toUpperCase())?.[0];

  if (countryKey) {
    return Country[countryKey as keyof typeof Country];
  }

  return;
};

export const parseBirthDateFromPin = (pin: string): string => {
  const y = pin.substring(0, 2);
  const year =
    pin.length === 9 ? parseInt("19" + y) : parseInt((dayjs().year() - parseInt(y) < 2000 ? "19" : "20") + y);

  const m = parseInt(pin.substring(2, 4));
  const month = m > 50 ? m - 51 : m - 1;

  const day = parseInt(pin.substring(4, 6));

  return dateToIsoDateString(dayjs().year(year).month(month).date(day).startOf("day"));
};

export const getClosestPreviousWorkDay = (inputDate: Dayjs): Dayjs => {
  let date = dayjs(inputDate);
  while (isHolidayOrWeekend(date)) {
    date = date.subtract(1, "day");
  }
  return date;
};

export const isHolidayOrWeekend = (date: Dayjs): boolean => {
  if (date.day() === 6 || date.day() === 0) {
    return true;
  }

  if (SlovakHolidays.some(holiday => holiday.d === date.date() && holiday.m === date.month() + 1)) {
    return true;
  }

  if (date.month() === 2 || date.month() === 3) {
    let m;
    let n;
    if (date.year() <= 1699) {
      m = 22;
      n = 2;
    } else if (date.year() <= 1799) {
      m = 23;
      n = 3;
    } else if (date.year() <= 1899) {
      m = 23;
      n = 4;
    } else if (date.year() <= 2099) {
      m = 24;
      n = 5;
    } else if (date.year() <= 2199) {
      m = 24;
      n = 6;
    } else if (date.year() <= 2299) {
      m = 25;
      n = 0;
    } else if (date.year() <= 2399) {
      m = 26;
      n = 1;
    } else if (date.year() <= 2499) {
      m = 25;
      n = 1;
    } else {
      console.error("Unsupported year for Easter date calculation.");
      return false;
    }

    const a = date.year() % 19;
    const b = date.year() % 4;
    const c = date.year() % 7;
    const d = (m + 19 * a) % 30;
    const e = (n + 2 * b + 4 * c + 6 * d) % 7;
    const marchEasterSunday = 22 + d + e;
    let aprilEasterSunday = d + e - 9;

    let easterFriday;
    let easterMonday;

    if (marchEasterSunday > 31) {
      if (aprilEasterSunday === 26) {
        aprilEasterSunday = 19;
      } else if (aprilEasterSunday === 25 && d === 28 && a > 10) {
        aprilEasterSunday = 18;
      }
      easterFriday = dayjs(date).month(3).date(aprilEasterSunday).subtract(2, "day");
      easterMonday = dayjs(date).month(3).date(aprilEasterSunday).add(1, "day");
    } else {
      easterFriday = dayjs(date).month(2).date(marchEasterSunday).subtract(2, "day");
      easterMonday = dayjs(date).month(2).date(marchEasterSunday).add(1, "day");
    }

    return date.isSame(easterFriday, "day") || date.isSame(easterMonday, "day");
  }

  return false;
};

export const paginationStandardProps: PaginationConfig = {
  showTotal: (total, [from, to]) => t("common.pagination", { from, to, total }),
  showQuickJumper: true,
  showSizeChanger: false
};

export const paginationTableProps: TablePaginationConfig = {
  ...paginationStandardProps,
  position: ["topRight", "bottomRight"]
};

export const tableStandardProps = <T = any>(
  stripped: boolean = true,
  noHeaderBackground: boolean = false,
  additionalClassName?: string
): Partial<TableProps<T>> => ({
  size: "small",
  className: classNames(
    "standard-table",
    { stripped: stripped },
    { "no-header-background": noHeaderBackground },
    additionalClassName
  ),
  rowKey: "id",
  rowClassName: (_: any, index: number) => {
    if (!stripped) {
      return "";
    }
    return index % 2 === 0 ? "table-row-light" : "table-row-dark";
  },
  locale: { emptyText: t("common.noData") },
  scroll: { x: "max-content" }
});

export const openUrl = (url: string, target: HTMLAttributeAnchorTarget = "_self"): void => {
  window.open(url, target, "noopener, noreferrer");
};

export const generateRandomToken = (length: number): string => {
  const randomNumbers = new Uint8Array(length / 2);
  window.crypto.getRandomValues(randomNumbers);
  return Array.from(randomNumbers, dec => dec.toString(16).padStart(2, "0")).join("");
};

export const resolveFileIconType = (contentType: string): AntIconType => {
  switch (contentType) {
    case HttpContentType.RAR[0]:
    case HttpContentType.RAR[1]:
    case HttpContentType.ZIP[0]:
      return "file-zip";
    case HttpContentType.PDF[0]:
      return "file-pdf";
    case HttpContentType.DOCX[0]:
      return "file-word";
    case HttpContentType.XLSX[0]:
      return "file-excel";
    case HttpContentType.PPTX[0]:
      return "file-ppt";
    default:
      if (!contentType) {
        return "file-unknown";
      } else if (contentType.startsWith("image") || contentType.startsWith("video")) {
        return "file-image";
      } else if (contentType.startsWith("text")) {
        return "file-text";
      }
      return "file";
  }
};

export const stripAccents = (str?: string): string => {
  return str?.normalize("NFD").replace(/\p{Diacritic}/gu, "") ?? "";
};

export const storageFileToRcFile = (file: StorageFile): Partial<RcFile> => {
  return {
    name: file.filename,
    uid: file.id,
    type: file.contentType,
    size: file.size
  };
};

export const makePropertiesRequired = <T, K extends keyof T>(obj: T, keys: K[]): obj is T & Required<Pick<T, K>> => {
  return keys.every(key => obj[key] !== undefined);
};

export const filterWithRequiredProps = <T, K extends keyof T>(data: T[], keys: K[]): (T & Required<Pick<T, K>>)[] => {
  return data.filter((item): item is T & Required<Pick<T, K>> => makePropertiesRequired(item, keys));
};

export const isDeepEqual = (a: any, b: any): boolean => {
  if (a === b) {
    return true;
  }

  if (a === null || b === null || a === undefined || b === undefined) {
    return false;
  }

  const aIsArray = Array.isArray(a);
  const bIsArray = Array.isArray(b);

  if (aIsArray !== bIsArray) {
    return false;
  }

  if (aIsArray) {
    return a.length === b.length ? a.every((value, index) => isDeepEqual(value, b[index])) : false;
  }

  if (typeof a === "object" && typeof b === "object") {
    return (
      Object.keys(a).length === Object.keys(b).length &&
      Object.keys(a).every(key => Object.hasOwn(a, key) && Object.hasOwn(b, key) && isDeepEqual(a[key], b[key]))
    );
  }

  return false;
};

type CSSVariables = {
  readonly colorPrimary: string;
  readonly colorText: string;
  readonly colorRed: string;
  readonly colorGreen: string;
  readonly colorBlue: string;
  readonly colorYellow: string;
  readonly colorGrey: string;
  readonly colorOrange: string;
  readonly colorShadow: string;
  readonly colorHover: string;
  readonly sideMenuWidth: string;
  readonly sideMenuResponsiveWidth: string;
  readonly tinySpace: string;
  readonly smallSpace: string;
};

export const cssVariables = customCssVariables as CSSVariables;
