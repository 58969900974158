import { Button, Col, Divider, Popconfirm, Row, Space, Switch, Upload } from "antd";
import { RcFile } from "antd/lib/upload";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import FileThumbnailCardView from "../../../../../common/components/views/FileThumbnailCardView";
import { rowGutter } from "../../../../../common/constants";
import {
  formatCommissionsLevelName,
  formatLocaleCurrencyWithNullAsZero,
  formatLocalePercentageNumberWithNullAsZero
} from "../../../../../common/utils/formatUtils";
import { COMMISSIONS_LEVEL_ROUTE_PATHS } from "../../../../commissions/level/paths";
import {
  deleteCommissionsSettingsAttachmentActions,
  uploadCommissionsSettingsAttachmentsActions
} from "../../../../commissions/settings/ducks";
import { CommissionsSettings, CommissionsSettingsAgentProfile } from "../../../../commissions/settings/types";
import { TopAgentType } from "../../../enums";
import { AGENT_ROUTE_PATHS } from "../../../paths";
import { AgentBase } from "../../../types";

interface Props {
  agent: AgentBase;
  commissionsSettings: CommissionsSettings | CommissionsSettingsAgentProfile;
  hasTopAgentCommissionsPermission?: boolean;
  hasManageAgentCommissionsPermission?: boolean;
  onAssignLevelClick?: () => void;
  onUnassignLevelClick?: () => void;
  onIncludeInBulkPaymentChange?: (checked: boolean) => void;
  onUseAsHeadChange?: (checked: boolean) => void;
  onUnifyPointsCalculationChange?: (checked: boolean) => void;
  uploadCommissionsSettingsAttachment?: typeof uploadCommissionsSettingsAttachmentsActions.request;
  deleteCommissionsSettingsAttachment?: typeof deleteCommissionsSettingsAttachmentActions.request;
}

export const AgentCommissionsSummaryView = ({
  agent,
  commissionsSettings,
  hasTopAgentCommissionsPermission,
  hasManageAgentCommissionsPermission,
  onAssignLevelClick,
  onUnassignLevelClick,
  onIncludeInBulkPaymentChange,
  onUseAsHeadChange,
  onUnifyPointsCalculationChange,
  uploadCommissionsSettingsAttachment,
  deleteCommissionsSettingsAttachment
}: Props) => {
  const { t } = useTranslation();

  const isTopAgentTypePlus = agent?.topAgentType === TopAgentType.PLUS;

  const handleAttachmentsUpload = (_: RcFile, fileList: RcFile[]): boolean => {
    if (fileList.length) {
      const formData = new FormData();
      fileList.forEach(file => formData.append("files", file));
      fileList.splice(0, fileList.length);

      uploadCommissionsSettingsAttachment?.({ id: agent.id, object: formData });
    }

    return false;
  };

  return (
    <>
      <Row gutter={rowGutter}>
        <Col span={10}>
          <b style={{ fontSize: 18 }}>{t("commissions.settings.helpers.assignedLevel")}:</b>{" "}
          {commissionsSettings.commissionsSettingsLevel ? (
            <Space>
              {hasTopAgentCommissionsPermission ? (
                <Link
                  to={generatePath(COMMISSIONS_LEVEL_ROUTE_PATHS.detail.to, {
                    id: commissionsSettings.commissionsSettingsLevel.id
                  })}
                  target="_blank"
                >
                  {formatCommissionsLevelName(commissionsSettings.commissionsSettingsLevel)}
                </Link>
              ) : (
                formatCommissionsLevelName(commissionsSettings.commissionsSettingsLevel)
              )}
              {hasManageAgentCommissionsPermission && onAssignLevelClick && onUnassignLevelClick && (
                <div className="margin-left-small">
                  <ActionTextIcon icon="edit" color="blue" text={t("common.change")} onClick={onAssignLevelClick} />

                  <Divider type="vertical" />

                  <Popconfirm
                    title={t("commissions.settings.helpers.unassignLevelConfirm")}
                    icon={<AntIcon type="question" />}
                    okText={t("common.yes")}
                    cancelText={t("common.no")}
                    okType="primary"
                    onConfirm={onUnassignLevelClick}
                  >
                    <span>
                      <ActionTextIcon icon="close" color="orange" text={t("common.cancel")} />
                    </span>
                  </Popconfirm>
                </div>
              )}
            </Space>
          ) : (
            <Space>
              <span className="sub-header-info dashed">{t("common.none")}</span>
              {hasManageAgentCommissionsPermission && onAssignLevelClick && (
                <div className="margin-left-small">
                  <ActionTextIcon
                    icon="sister-node"
                    color="blue"
                    text={t("common.assign")}
                    onClick={onAssignLevelClick}
                  />
                </div>
              )}
            </Space>
          )}
        </Col>

        <Col span={14} className="right-align">
          {hasManageAgentCommissionsPermission &&
            onIncludeInBulkPaymentChange &&
            onUseAsHeadChange &&
            onUnifyPointsCalculationChange && (
              <Space size="small">
                <Switch
                  checked={(commissionsSettings as CommissionsSettings).includeInBulkPayment}
                  checkedChildren={<AntIcon type="check" />}
                  unCheckedChildren={<AntIcon type="close" />}
                  onChange={onIncludeInBulkPaymentChange}
                />
                <span>{t("commissions.settings.attrs.includeInBulkPayment")}</span>

                {isTopAgentTypePlus ? undefined : (
                  <>
                    <Switch
                      className="margin-left-medium"
                      checked={(commissionsSettings as CommissionsSettings).useAsHead}
                      checkedChildren={<AntIcon type="check" />}
                      unCheckedChildren={<AntIcon type="close" />}
                      onChange={onUseAsHeadChange}
                    />
                    <span>{t("commissions.settings.attrs.useAsHead")}</span>

                    <Switch
                      className="margin-left-medium"
                      checked={(commissionsSettings as CommissionsSettings).unifyPointsCalculation}
                      checkedChildren={<AntIcon type="check" />}
                      unCheckedChildren={<AntIcon type="close" />}
                      onChange={onUnifyPointsCalculationChange}
                    />
                    <span>{t("commissions.settings.attrs.unifyPointsCalculation")}</span>
                  </>
                )}
              </Space>
            )}
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={24}>
          <div className="margin-bottom-tiny">
            <b style={{ fontSize: 18 }}>{t("commissions.level.sections.documents")}:</b>
          </div>
          <Row gutter={rowGutter}>
            {commissionsSettings.commissionsSettingsLevel?.attachments.map(attachment => (
              <Col xs={12} md={8} lg={6} xl={6} xxl={4} key={attachment.id} className="margin-bottom-tiny">
                <FileThumbnailCardView
                  file={attachment.file}
                  orientation="vertical"
                  iconSize={50}
                  linkPath={generatePath(COMMISSIONS_LEVEL_ROUTE_PATHS.attachment.to, {
                    id1: commissionsSettings.commissionsSettingsLevel?.id,
                    id2: attachment.id
                  })}
                  linkTarget="_blank"
                />
              </Col>
            ))}
            {commissionsSettings.attachments.map(attachment => (
              <Col xs={12} md={8} lg={6} xl={6} xxl={4} key={attachment.id} className="margin-bottom-tiny">
                <FileThumbnailCardView
                  file={attachment.file}
                  orientation="vertical"
                  iconSize={50}
                  linkPath={generatePath(AGENT_ROUTE_PATHS.commissionsSettingsAttachment.to, {
                    id1: agent.id,
                    id2: attachment.id
                  })}
                  linkTarget="_blank"
                  onDelete={
                    hasManageAgentCommissionsPermission && deleteCommissionsSettingsAttachment
                      ? () => {
                          deleteCommissionsSettingsAttachment({ id1: agent.id, id2: attachment.id });
                        }
                      : undefined
                  }
                />
              </Col>
            ))}
            {hasManageAgentCommissionsPermission && uploadCommissionsSettingsAttachment && (
              <Col flex="68px" className="margin-bottom-tiny">
                <Upload multiple showUploadList={false} beforeUpload={handleAttachmentsUpload}>
                  <Button size="large" type="dashed" icon={<AntIcon type="plus" />} style={{ width: 68, height: 68 }} />
                </Upload>
              </Col>
            )}
          </Row>
        </Col>
      </Row>

      <div className="margin-bottom-small" />

      {commissionsSettings.bailAccountSettings && (
        <Row gutter={rowGutter}>
          <Col span={24}>
            <b style={{ fontSize: 18 }}>{t("commissions.bailAccount.titles.page")}: </b>
            <i>{t("commissions.bailAccount.helpers.currentBalance") + ": "}</i>
            <b>{formatLocaleCurrencyWithNullAsZero(commissionsSettings.bailAccountBalance)}</b>
            <Divider type="vertical" />
            <i>{t("commissions.bailAccount.attrs.targetAmount") + ": "}</i>
            <b>{formatLocaleCurrencyWithNullAsZero(commissionsSettings.bailAccountSettings.targetAmount)}</b>
            <Divider type="vertical" />
            <i>{t("commissions.bailAccount.attrs.rate") + ": "}</i>
            <b>{formatLocalePercentageNumberWithNullAsZero(commissionsSettings.bailAccountSettings.rate)}</b>
          </Col>
        </Row>
      )}

      <Row gutter={rowGutter}>
        <Col span={24}>
          <b style={{ fontSize: 18 }}>{t("commissions.debtAccount.titles.page")}: </b>
          <i>{t("commissions.debtAccount.helpers.currentBalance") + ": "}</i>
          <b>{formatLocaleCurrencyWithNullAsZero(commissionsSettings.debtAccountBalance)}</b>
        </Col>
      </Row>
    </>
  );
};
