import { keepPreviousData, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { productApi } from "../../../modules/product/api";
import {
  CreateUpdateProduct,
  CreateUpdateProductGroup,
  ProductFilterExportRequest,
  ProductFilterPageRequest
} from "../../../modules/product/types";
import { UUID } from "../../../typings/global";
import { EntityObject, SearchPageRequest } from "../../types";
import messageUtils from "../../utils/messageUtils";
import { openBlobFile } from "../../utils/utils";

type CreateUpdateProps = {
  invalidateFilter?: boolean;
};

export const PRODUCT_QUERY_KEYS = {
  filterProductGroups: (filter?: SearchPageRequest) =>
    filter ? ["filterProductGroups", filter] : ["filterProductGroups"],
  createProductGroup: () => ["createProductGroup"],
  updateProductGroup: () => ["updateProductGroup"],
  deleteProductGroup: () => ["deleteProductGroup"],
  filterProducts: (filter?: ProductFilterPageRequest) => (filter ? ["filterProducts", filter] : ["filterProducts"]),
  downloadProductsExport: () => ["downloadProductsExport"],
  createProduct: () => ["createProduct"],
  updateProduct: () => ["updateProduct"],
  deleteProduct: () => ["deleteProduct"]
};

export const useFilterProductGroups = (filter: SearchPageRequest, isEnabled?: boolean) => {
  return useQuery({
    queryKey: PRODUCT_QUERY_KEYS.filterProductGroups(filter),
    queryFn: async () => {
      const { data } = await productApi.filterProductGroups(filter);

      return data;
    },
    enabled: isEnabled,
    placeholderData: keepPreviousData
  });
};

export const useCreateProductGroup = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: PRODUCT_QUERY_KEYS.createProductGroup(),
    mutationFn: async (payload: CreateUpdateProductGroup) => {
      const { data } = await productApi.createProductGroup(payload);

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: PRODUCT_QUERY_KEYS.filterProductGroups() });
      messageUtils.itemCreatedNotification();
    }
  });
};

export const useUpdateProductGroup = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: PRODUCT_QUERY_KEYS.updateProductGroup(),
    mutationFn: async (payload: EntityObject<CreateUpdateProductGroup>) => {
      const { data } = await productApi.updateProductGroup(payload);

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: PRODUCT_QUERY_KEYS.filterProductGroups() });
      messageUtils.itemUpdatedNotification();
    }
  });
};

export const useDeleteProductGroup = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: PRODUCT_QUERY_KEYS.deleteProductGroup(),
    mutationFn: async (id: UUID) => {
      return productApi.deleteProductGroup({ id });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: PRODUCT_QUERY_KEYS.filterProductGroups() });
      messageUtils.itemDeletedNotification();
    }
  });
};

export const useFilterProducts = (filter: ProductFilterPageRequest, isEnabled?: boolean) => {
  return useQuery({
    queryKey: PRODUCT_QUERY_KEYS.filterProducts(filter),
    queryFn: async () => {
      const { data } = await productApi.filterProducts(filter);

      return data;
    },
    enabled: isEnabled,
    placeholderData: keepPreviousData
  });
};

export const useDownloadProductsExport = () => {
  return useMutation({
    mutationKey: PRODUCT_QUERY_KEYS.downloadProductsExport(),
    mutationFn: async (payload: ProductFilterExportRequest) => {
      const response = await productApi.downloadProductsExport(payload);
      openBlobFile(response, true);
    }
  });
};

export const useCreateProduct = ({ invalidateFilter }: CreateUpdateProps = { invalidateFilter: true }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: PRODUCT_QUERY_KEYS.createProduct(),
    mutationFn: async (payload: CreateUpdateProduct) => {
      const { data } = await productApi.createProduct(payload);

      return data;
    },
    onSuccess: () => {
      if (invalidateFilter) {
        queryClient.invalidateQueries({ queryKey: PRODUCT_QUERY_KEYS.filterProducts() });
      }
      messageUtils.itemCreatedNotification();
    }
  });
};

export const useUpdateProduct = ({ invalidateFilter }: CreateUpdateProps = { invalidateFilter: true }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: PRODUCT_QUERY_KEYS.updateProduct(),
    mutationFn: async (payload: EntityObject<CreateUpdateProduct>) => {
      const { data } = await productApi.updateProduct(payload);

      return data;
    },
    onSuccess: () => {
      if (invalidateFilter) {
        queryClient.invalidateQueries({ queryKey: PRODUCT_QUERY_KEYS.filterProducts() });
      }
      messageUtils.itemUpdatedNotification();
    }
  });
};

export const useDeleteProduct = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: PRODUCT_QUERY_KEYS.deleteProduct(),
    mutationFn: async (id: UUID) => {
      const { data } = await productApi.deleteProduct({ id });

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: PRODUCT_QUERY_KEYS.filterProducts() });
      messageUtils.itemDeletedNotification();
    }
  });
};
