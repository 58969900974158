import { Card, Form } from "antd";
import { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import t from "../../../app/i18n";
import BackNavigationArrow from "../../../common/components/views/BackNavigationArrow";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import { ActionProps, RootState } from "../../../common/types";
import { useFormErrorHandler } from "../../../common/utils/formUtils";
import { requests } from "../api";
import ClientFormBody from "../components/forms/ClientFormBody";
import { createClientActions } from "../ducks";
import { CreateUpdateClient } from "../types";
import { useClientSearch } from "../utils";

interface ActionsMap {
  createClient: typeof createClientActions.request;
}

const ClientCreateContainer = (props: ActionProps<ActionsMap>) => {
  const [form] = Form.useForm<CreateUpdateClient>();
  useFormErrorHandler(form, "client.attrs", [requests.CREATE_CLIENT]);

  const clientSearch = useClientSearch();

  useEffect(() => {
    return () => {
      clientSearch.onResultDelete();
    };
  }, []);

  return (
    <ContentWrapper>
      <Card
        className="card-box"
        title={
          <BackNavigationArrow>
            <h2>{t("client.titles.create")}</h2>
          </BackNavigationArrow>
        }
      >
        <Form form={form} layout="vertical" name="createClientForm">
          <ClientFormBody
            form={form}
            viewType="form"
            clientSearch={clientSearch}
            onFormSubmit={props.actions.createClient}
          />
        </Form>
      </Card>
    </ContentWrapper>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      createClient: createClientActions.request
    },
    dispatch
  )
});

export default connect<object, ActionProps<ActionsMap>, Record<string, any>, RootState>(
  null,
  mapDispatchToProps
)(ClientCreateContainer);
