import { Button, Card, Descriptions, Popconfirm, Space } from "antd";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import LabelWithTooltip from "../../../../../../common/components/form/labels/LabelWithTooltip";
import AntIcon from "../../../../../../common/components/icons/AntIcon";
import PopconfirmDeleteIcon from "../../../../../../common/components/icons/PopconfirmDeleteIcon";
import ItemCreatedUpdatedInfoView from "../../../../../../common/components/views/ItemCreatedUpdatedInfoView";
import MultiLineText from "../../../../../../common/components/views/MultiLineText";
import { PageHeader } from "../../../../../../common/components/views/PageHeader";
import { Permission } from "../../../../../../common/security/authorization/enums";
import { RootState } from "../../../../../../common/types";
import { formatCommissionsLevelName, formatLocaleNumber } from "../../../../../../common/utils/formatUtils";
import { useBackNavigation } from "../../../../../../common/utils/hooksUtils";
import { tBoolean } from "../../../../../../common/utils/translationUtils";
import { cssVariables } from "../../../../../../common/utils/utils";
import { selectHasPermissions } from "../../../../../auth/ducks";
import BailAccountSettingsForm from "../../../../bailaccount/components/forms/BailAccountSettingsForm";
import BailAccountSettingsView from "../../../../bailaccount/components/views/BailAccountSettingsView";
import { CreateUpdateBailAccountSettings } from "../../../../bailaccount/types";
import CommissionsSettingsRuleHelperView from "../../../../settings/components/views/CommissionsSettingsRuleHelperView";
import {
  createCommissionsLevelBailAccountSettingsActions,
  createCommissionsLevelRuleActions,
  deleteCommissionsLevelActions,
  deleteCommissionsLevelAttachmentActions,
  deleteCommissionsLevelBailAccountSettingsActions,
  deleteCommissionsLevelRuleActions,
  updateCommissionsLevelActions,
  updateCommissionsLevelBailAccountSettingsActions,
  updateCommissionsLevelRuleActions,
  uploadCommissionsLevelAttachmentsActions
} from "../../../ducks";
import { CommissionsSettingsLevelType } from "../../../enums";
import { CommissionsSettingsLevel, CommissionsSettingsLevelRule } from "../../../types";
import CommissionsLevelTypeTag from "../../CommissionsLevelTypeTag";
import { CommissionsLevelForm } from "../../forms/CommissionsLevelForm";
import { CommissionsLevelRuleForm } from "../../forms/CommissionsLevelRuleForm";
import CommissionsLevelAttachmentsTableView from "./CommissionsLevelAttachmentsTableView";
import { CommissionsLevelRuleTableView } from "./CommissionsLevelRuleTableView";

interface Props {
  level: CommissionsSettingsLevel;
}

export const CommissionsLevelDetailView = ({ level }: Props) => {
  const { t } = useTranslation();

  const onBackClick = useBackNavigation();

  const hasTopAgentCommissionsManagePermission = useSelector<RootState, boolean>(state =>
    selectHasPermissions(Permission.TOP_AGENT_COMMISSIONS_MANAGE)(state)
  );

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          onUpdate: updateCommissionsLevelActions.request,
          onDelete: deleteCommissionsLevelActions.request,
          onCreateRule: createCommissionsLevelRuleActions.request,
          onUpdateRule: updateCommissionsLevelRuleActions.request,
          onDeleteRule: deleteCommissionsLevelRuleActions.request,
          onCreateBailAccountSettings: createCommissionsLevelBailAccountSettingsActions.request,
          onUpdateBailAccountSettings: updateCommissionsLevelBailAccountSettingsActions.request,
          onDeleteBailAccountSettings: deleteCommissionsLevelBailAccountSettingsActions.request,
          onUploadAttachments: uploadCommissionsLevelAttachmentsActions.request,
          onDeleteAttachment: deleteCommissionsLevelAttachmentActions.request
        },
        dispatch
      ),
    [dispatch]
  );

  const [levelFormOpen, setLevelFormOpen] = useState<boolean>(false);

  const [ruleFormOpen, setRuleFormOpen] = useState<boolean>(false);
  const [ruleToUpdate, setRuleToUpdate] = useState<CommissionsSettingsLevelRule>();

  const [bailAccountSettingsFormOpen, setBailAccountSettingsFormOpen] = useState<boolean>(false);

  const handleLevelUpdateClick = (): void => {
    setLevelFormOpen(true);
  };

  const handleLevelFormCancel = (): void => {
    setLevelFormOpen(false);
  };

  const handleRuleCreateClick = (): void => {
    setRuleFormOpen(true);
  };

  const handleRuleUpdateClick = (rule: CommissionsSettingsLevelRule): void => {
    setRuleFormOpen(true);
    setRuleToUpdate(rule);
  };

  const handleRuleFormCancel = (): void => {
    setRuleFormOpen(false);
    setRuleToUpdate(undefined);
  };

  const handleBailAccountSettingsCreateUpdateClick = (): void => {
    setBailAccountSettingsFormOpen(true);
  };

  const handleBailAccountSettingsDeleteClick = (): void => {
    actions.onDeleteBailAccountSettings({ id: level.id });
  };

  const handleBailAccountSettingsFormCreateSubmit = (settings: CreateUpdateBailAccountSettings): void => {
    actions.onCreateBailAccountSettings({ id: level.id, object: settings });
  };

  const handleBailAccountSettingsFormUpdateSubmit = (settings: CreateUpdateBailAccountSettings): void => {
    actions.onUpdateBailAccountSettings({ id: level.id, object: settings });
  };

  const handleBailAccountSettingsFormCancel = (): void => {
    setBailAccountSettingsFormOpen(false);
  };

  return (
    <>
      <PageHeader
        title={`${t("commissions.level.titles.detail")} ${formatCommissionsLevelName(level)}`}
        tags={<CommissionsLevelTypeTag type={level.type} />}
        onBack={onBackClick}
        extra={
          hasTopAgentCommissionsManagePermission && (
            <Space>
              <Button type="primary" icon={<AntIcon type="edit" />} onClick={handleLevelUpdateClick}>
                {t("common.edit")}
              </Button>

              <Popconfirm
                title={t("commissions.level.helpers.deleteLevelConfirm")}
                icon={<PopconfirmDeleteIcon />}
                okText={t("common.yes")}
                cancelText={t("common.no")}
                okType="danger"
                onConfirm={() => actions.onDelete({ id: level.id })}
              >
                <Button danger icon={<AntIcon type="delete" />}>
                  {t("common.delete")}
                </Button>
              </Popconfirm>
            </Space>
          )
        }
      >
        <ItemCreatedUpdatedInfoView item={level} style={{ marginTop: "-15px" }} />

        <Descriptions
          className="margin-top-small"
          styles={{ label: { color: cssVariables.colorText } }}
          items={[
            {
              key: "category",
              label: t("commissions.level.attrs.category"),
              children: level.category ?? <span className="sub-header-info dashed">{t("common.none")}</span>
            }
          ]}
        />

        {level.type === CommissionsSettingsLevelType.POINT ? (
          <>
            <div>
              <Descriptions
                className="margin-top-medium"
                styles={{
                  label: { color: cssVariables.colorText },
                  root: { display: "inline-block", width: "auto" },
                  content: { textAlign: "right" }
                }}
                column={1}
                size="small"
                bordered
                items={[
                  {
                    key: "careerPromotionPoints",
                    label: t("commissions.level.attrs.careerPromotionPoints"),
                    children: (
                      <div>
                        {formatLocaleNumber(level.careerPromotionPoints)}{" "}
                        <LabelWithTooltip tooltip={t("commissions.level.helpers.careerPromotionPointsDesc")} />
                      </div>
                    )
                  },
                  {
                    key: "careerDeclineAvoidmentPoints",
                    label: t("commissions.level.attrs.careerDeclineAvoidmentPoints"),
                    children: (
                      <div>
                        {formatLocaleNumber(level.careerDeclineAvoidmentPoints)}{" "}
                        <LabelWithTooltip tooltip={t("commissions.level.helpers.careerDeclineAvoidmentPointsDesc")} />
                      </div>
                    )
                  },
                  {
                    key: "careerDeclineDefinitivePoints",
                    label: t("commissions.level.attrs.careerDeclineDefinitivePoints"),
                    children: (
                      <div>
                        {formatLocaleNumber(level.careerDeclineDefinitivePoints)}{" "}
                        <LabelWithTooltip tooltip={t("commissions.level.helpers.careerDeclineDefinitivePointsDesc")} />
                      </div>
                    )
                  }
                ]}
              />
            </div>

            <div>
              <Descriptions
                className="margin-top-medium"
                styles={{
                  label: { color: cssVariables.colorText, width: 280 },
                  root: { display: "inline-block", width: "auto" }
                }}
                column={1}
                size="small"
                bordered
                items={[
                  {
                    key: "includeGroupPointsIntoPromotion",
                    label: t("commissions.level.attrs.includeGroupPointsIntoPromotion"),
                    children: (
                      <div>
                        {tBoolean(level.includeGroupPointsIntoPromotion)}{" "}
                        <LabelWithTooltip
                          tooltip={t("commissions.level.helpers.includeGroupPointsIntoPromotionDesc")}
                        />
                      </div>
                    )
                  },
                  {
                    key: "nextLevelIds",
                    label: t("commissions.level.attrs.nextLevelIds"),
                    children: level.nextLevels?.length ? (
                      <MultiLineText
                        tokens={level.nextLevels.map(nextLevel => formatCommissionsLevelName(nextLevel))}
                      />
                    ) : (
                      <span className="sub-header-info dashed">{t("common.none")}</span>
                    )
                  },
                  {
                    key: "formerLevels",
                    label: t("commissions.level.helpers.formerLevels"),
                    children: level.formerLevels?.length ? (
                      <MultiLineText
                        tokens={level.formerLevels.map(formerLevel => formatCommissionsLevelName(formerLevel))}
                      />
                    ) : (
                      <span className="sub-header-info dashed">{t("common.none")}</span>
                    )
                  }
                ]}
              />
            </div>
          </>
        ) : undefined}

        {level.type === CommissionsSettingsLevelType.CAREER ? (
          <Descriptions
            className="margin-top-medium"
            styles={{ label: { color: cssVariables.colorText }, root: { display: "inline-block", width: "auto" } }}
            column={1}
            size="small"
            bordered
            items={[
              {
                key: "nextLevelIds",
                label: t("commissions.level.attrs.nextLevelIds"),
                children: level.nextLevels?.length ? (
                  <MultiLineText tokens={level.nextLevels.map(nextLevel => formatCommissionsLevelName(nextLevel))} />
                ) : (
                  <span className="sub-header-info dashed">{t("common.none")}</span>
                )
              },
              {
                key: "formerLevels",
                label: t("commissions.level.helpers.formerLevels"),
                children: level.formerLevels?.length ? (
                  <MultiLineText
                    tokens={level.formerLevels.map(formerLevel => formatCommissionsLevelName(formerLevel))}
                  />
                ) : (
                  <span className="sub-header-info dashed">{t("common.none")}</span>
                )
              }
            ]}
          />
        ) : undefined}
      </PageHeader>

      <Card type="inner" className="card-box" title={t("commissions.level.sections.rules")}>
        <CommissionsLevelRuleTableView
          level={level}
          showActions={hasTopAgentCommissionsManagePermission}
          onUpdateClick={handleRuleUpdateClick}
          onDelete={actions.onDeleteRule}
        />

        {hasTopAgentCommissionsManagePermission && (
          <div className={level.rules?.length ? "table-footer-margin" : "margin-top-small"}>
            <Space>
              <Button type="primary" size="small" icon={<AntIcon type="plus" />} onClick={handleRuleCreateClick}>
                {t("commissions.level.actions.addRule")}
              </Button>
              <CommissionsSettingsRuleHelperView />
            </Space>
          </div>
        )}
      </Card>

      <Card type="inner" className="card-box" title={t("commissions.level.sections.bailAccountSettings")}>
        <BailAccountSettingsView
          settings={level.bailAccountSettings}
          showActions={hasTopAgentCommissionsManagePermission}
          noSettingsLabel={t("commissions.level.helpers.bailAccount.noSettings")}
          onCreateUpdateClick={handleBailAccountSettingsCreateUpdateClick}
          onDeleteClick={handleBailAccountSettingsDeleteClick}
        />
      </Card>

      <Card type="inner" className="card-box" title={t("commissions.level.sections.documentsAndAttachments")}>
        <CommissionsLevelAttachmentsTableView
          level={level}
          onUpload={hasTopAgentCommissionsManagePermission ? actions.onUploadAttachments : undefined}
          onDelete={hasTopAgentCommissionsManagePermission ? actions.onDeleteAttachment : undefined}
        />
      </Card>

      <CommissionsLevelForm
        open={levelFormOpen}
        level={level}
        onUpdate={actions.onUpdate}
        onFormCancel={handleLevelFormCancel}
      />

      <CommissionsLevelRuleForm
        open={ruleFormOpen}
        rule={ruleToUpdate}
        levelId={level.id}
        onCreate={actions.onCreateRule}
        onUpdate={actions.onUpdateRule}
        onFormCancel={handleRuleFormCancel}
      />

      <BailAccountSettingsForm
        settings={level.bailAccountSettings}
        open={bailAccountSettingsFormOpen}
        onCreateSubmit={handleBailAccountSettingsFormCreateSubmit}
        onUpdateSubmit={handleBailAccountSettingsFormUpdateSubmit}
        onFormCancel={handleBailAccountSettingsFormCancel}
      />
    </>
  );
};
