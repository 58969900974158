import { Button, Col, Form, Input, Row, Space } from "antd";
import { useEffect, useRef, useState } from "react";
import t from "../../../../app/i18n";
import { SecondaryButton } from "../../../../common/components/buttons/SecondaryButton";
import AntIcon from "../../../../common/components/icons/AntIcon";
import { formatPhoneNumber } from "../../../../common/utils/formatUtils";
import { phoneNumberNormalizeFunction, useFormErrorHandler } from "../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import { regexPatterns, validations } from "../../../../common/utils/validationUtils";
import { requests } from "../../api";
import { ConfirmUser } from "../../types";

interface Props {
  confirmUserParams: ConfirmUserParamsProps;
  onSendTotpCodeViaSmsClick: () => void;
  onFormSubmit: (user: ConfirmUser) => void;
}

interface ConfirmUserParamsProps {
  token: string;
  name: string;
  phone: string;
  requirePassword: boolean;
}

type FormState = "initial" | "sent-totp-code-via-sms";

const ConfirmUserForm = (props: Props) => {
  const [formState, setFormState] = useState<FormState>("initial");
  const [count, setCount] = useState<number>(0);
  const [countIntervalId, setCountIntervalId] = useState<any>();
  const countIntervalIdRef = useRef<any>(null);

  const [form] = Form.useForm<ConfirmUser>();
  useFormErrorHandler(form, "user.attrs", [requests.USER_CONFIRM_USER]);

  useEffect(() => {
    return () => {
      if (countIntervalIdRef.current) {
        clearInterval(countIntervalIdRef.current);
      }
    };
  }, []);

  useEffect(() => {
    countIntervalIdRef.current = countIntervalIdRef;
  }, [countIntervalId]);

  useEffect(() => {
    if (count === 0 && countIntervalId) {
      clearInterval(countIntervalId);
    }
  }, [count, countIntervalId]);

  useRequestFinishedCallback([requests.USER_SEND_TOTP_CODE_VIA_SMS], () => {
    if (formState === "sent-totp-code-via-sms") {
      setCount(30);
      setCountIntervalId(setInterval(() => setCount(prevCount => prevCount - 1), 1000));
    }
  });

  const handleFormFinish = (values: ConfirmUser): void => {
    props.onFormSubmit(Object.assign({}, values, { token: props.confirmUserParams.token }));
  };

  const handleSendTotpCodeViaSmsClick = (): void => {
    setFormState("sent-totp-code-via-sms");
    props.onSendTotpCodeViaSmsClick();
  };

  return (
    <>
      <h2 className="center-align margin-bottom-large">{t("user.titles.confirm")}</h2>

      <Row justify="center">
        <Col className="anonymous-form">
          <Form form={form} layout="vertical" name="confirmUserForm" onFinish={handleFormFinish}>
            <Form.Item
              label={t("user.attrs.name")}
              rules={[validations.notNull, validations.size(1, 255), validations.pattern(regexPatterns.nameRegex)]}
            >
              <Input
                placeholder={t("user.helpers.userNamePlaceholder")}
                defaultValue={props.confirmUserParams.name}
                disabled
              />
            </Form.Item>

            {props.confirmUserParams.requirePassword && (
              <>
                <Form.Item name="password" label={t("user.attrs.password")} rules={validations.sufficientPassword}>
                  <Input.Password prefix={<AntIcon type="lock" className="icon-black-opacity" />} />
                </Form.Item>

                <Form.Item
                  name="passwordRepeat"
                  label={t("user.attrs.passwordRepeat")}
                  rules={[...validations.sufficientPassword, validations.repeatedPassword()]}
                >
                  <Input.Password prefix={<AntIcon type="lock" className="icon-black-opacity" />} />
                </Form.Item>
              </>
            )}

            <Form.Item
              label={t("user.attrs.phone")}
              rules={[validations.notBlank, validations.size(1, 19), validations.mobilePhoneNumber]}
              normalize={phoneNumberNormalizeFunction}
            >
              <Input defaultValue={formatPhoneNumber(props.confirmUserParams.phone)} disabled />
            </Form.Item>

            <Form.Item
              name="smsTotpCode"
              label={t("user.attrs.smsTotpCode")}
              rules={[validations.notBlank, validations.length(6), validations.numeric]}
            >
              <Space.Compact style={{ width: "100%" }}>
                <SecondaryButton
                  onClick={handleSendTotpCodeViaSmsClick}
                  icon={!count && <AntIcon type="send" />}
                  disabled={!!count}
                >
                  {!!count && `(${count}) `}
                  {t("user.actions.sendTotpCodeViaSms")}
                </SecondaryButton>
                <Input prefix={<AntIcon type="lock" className="icon-black-opacity" />} />
              </Space.Compact>
            </Form.Item>

            <Form.Item>
              <Button className="margin-top-medium" type="primary" htmlType="submit" block>
                {t("user.actions.register")}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default ConfirmUserForm;
