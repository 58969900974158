import { Card, Col, Form, Radio, Row } from "antd";
import { FieldData } from "rc-field-form/lib/interface";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { SecondaryButton } from "../../../../../../common/components/buttons/SecondaryButton";
import HiddenInput from "../../../../../../common/components/form/components/HiddenInput";
import AntIcon, { type AntIconType } from "../../../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../../../common/constants";
import { FieldConstraintViolation } from "../../../../../../common/types";
import { resolveFormValidationError, setErrorsToForm } from "../../../../../../common/utils/formUtils";
import { useScrollToTopOnLoad } from "../../../../../../common/utils/hooksUtils";
import messageUtils from "../../../../../../common/utils/messageUtils";
import { validations } from "../../../../../../common/utils/validationUtils";
import { DashboardNoticePosition } from "../../../../../dashboard/enums";
import { CalcType } from "../../../../enums";
import { CALC_ROUTE_PATHS } from "../../../../paths";
import { CalcNavigation } from "../../../components/CalcNavigation";
import CalcNoticesIconView from "../../../components/CalcNoticesIconView";
import { deleteStateTravelCalcResultsAction } from "../../ducks";
import { TravelInsuranceType } from "../../enums";
import { TravelCalc } from "../../types";
import TravelCalcForm from "./TravelCalcForm";

interface Props {
  calcData?: TravelCalc;
  formErrors: FieldConstraintViolation[];
  setIsFormChanged: VoidFunction;
  onCalcResultsDelete: typeof deleteStateTravelCalcResultsAction;
  onCalculationFormSubmit: (calcData: TravelCalc) => void;
  onResetCalculatorClick: () => void;
  goToCalcResults?: VoidFunction;
}

const TravelCalcWrapper = (props: Props) => {
  useScrollToTopOnLoad();

  const { t } = useTranslation();

  const [form] = Form.useForm<TravelCalc>();

  useEffect(() => {
    if (props.calcData) {
      form.setFieldsValue(props.calcData);
    } else {
      form.resetFields();
    }
  }, [props.calcData]);

  useEffect(() => {
    if (props.formErrors.length) {
      setErrorsToForm(form, "calc.travel.attrs", props.formErrors);
    }
  }, [props.formErrors]);

  const handleInsuranceTypeChange = (type: TravelInsuranceType): void => {
    if (type === TravelInsuranceType.YEAR) {
      form.setFieldsValue({ generalData: { effectiveEndDate: undefined } });
    }
  };

  const handleFormFieldsChange = (changedFields?: FieldData[]): void => {
    if (changedFields?.length) {
      props.setIsFormChanged();
    }
  };

  const handleCalculationFormSubmit = (): void => {
    form
      .validateFields()
      .then(props.onCalculationFormSubmit)
      .catch(errorInfo => {
        messageUtils.errorNotification({ message: t("common.error"), description: t("calc.validations.formError") });
        resolveFormValidationError(errorInfo);
      });
  };

  const resolvePageTitle = (type: TravelInsuranceType): string => {
    return type ? t("calc.travel.titles.insuranceType." + type) : t("calc.travel.titles.noSelectedInsurance");
  };

  const resolveInsuranceTypeIcon = (type: TravelInsuranceType): AntIconType => {
    switch (type) {
      case TravelInsuranceType.SHORT_TERM:
        return "schedule";
      case TravelInsuranceType.YEAR:
        return "global";
      case TravelInsuranceType.CANCELLATION:
        return "euro";
      default:
        return "schedule";
    }
  };

  const insuranceType = Form.useWatch(["generalData", "insuranceType"], form);

  return (
    <>
      <Form form={form} layout="vertical" name="travelCalcForm" onFieldsChange={handleFormFieldsChange}>
        <Card
          className="card-box card-box--inner-extra"
          title={<h2>{resolvePageTitle(insuranceType)}</h2>}
          extra={
            <>
              <CalcNoticesIconView position={DashboardNoticePosition.TRAVEL_CALC} />
              <Link to={CALC_ROUTE_PATHS.travelDraft.to}>
                <SecondaryButton icon={<AntIcon type="form" />}>{t("calc.actions.showDrafts")}</SecondaryButton>
              </Link>
            </>
          }
        >
          <Row gutter={rowGutter} justify="center">
            <Col span={24} className="center-align">
              <HiddenInput name="type" initialValue={CalcType.TRAVEL} />
              <Form.Item
                name={["generalData", "insuranceType"]}
                rules={[validations.notNull]}
                className="no-bottom-space"
              >
                <Radio.Group
                  size="large"
                  buttonStyle="solid"
                  className="radio-group-icon"
                  onChange={e => handleInsuranceTypeChange(e.target.value)}
                >
                  {Object.values(TravelInsuranceType).map(type => (
                    <Radio.Button value={type} key={type} style={{ width: 168 }}>
                      <AntIcon type={resolveInsuranceTypeIcon(type)} />
                      <span>{t("calc.travel.enums.insuranceType." + type)}</span>
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Card>

        <TravelCalcForm form={form} />
      </Form>

      <CalcNavigation
        onCalculate={handleCalculationFormSubmit}
        onResetCalculatorClick={props.onResetCalculatorClick}
        goToCalcResults={props.goToCalcResults}
      />
    </>
  );
};

export default TravelCalcWrapper;
