import { ConfigProvider, Modal, StepProps, Steps, Tabs } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import ContentWrapper from "../../../../../common/modules/wrappers/ContentWrapper";
import DisplayWrapper from "../../../../../common/modules/wrappers/DisplayWrapper";
import { FieldConstraintViolation, RootState } from "../../../../../common/types";
import messageUtils from "../../../../../common/utils/messageUtils";
import { QueryKeys } from "../../../../../common/utils/queryUtils";
import { ClientFormType } from "../../../../client/enums";
import { Client } from "../../../../client/types";
import { VehicleInsurance } from "../../../../contract/types";
import { getCalcDraftActions } from "../../../drafts/ducks";
import { CALC_ROUTE_PATHS } from "../../../paths";
import { getCalcRecordDataActions } from "../../../records/ducks";
import CalcLoadingModal from "../../components/CalcLoadingModal";
import { loadContractToCalculatorActions } from "../../ducks";
import { OperationStage } from "../../realty/enums";
import { CalcResult, GenResponse } from "../../types";
import { isApplicableResult } from "../../utils";
import { VehicleCalcWrapper } from "../components/calc/VehicleCalcWrapper";
import VehicleGenWrapper from "../components/gen/VehicleGenWrapper";
import { VehicleCalcResultsView } from "../components/result/VehicleCalcResultsView";
import {
  calculateVehicleActions,
  deleteStateVehicleCalcResultsAction,
  deleteStateVehicleDraftAction,
  deleteStateVehicleGenResultAction,
  deleteStateVehicleInitialCalcGenDataAction,
  generateVehicleActions,
  selectVehicleCalcResults,
  selectVehicleDraft,
  selectVehicleGenResult,
  selectVehicleInitialCalcData,
  selectVehicleInitialGenData
} from "../ducks";
import { VehicleOwnerRelation, VehiclePolicyHolderRelation } from "../enums";
import {
  VehicleCalc,
  VehicleCalcDraft,
  VehicleCalcResultData,
  VehicleFormClients,
  VehicleGen,
  VehicleGenForm
} from "../types";
import {
  createVehicleCalcObjectFromCalcData,
  createVehicleCalcObjectFromGenData,
  createVehicleFormClientsObjectFromCalcData,
  createVehicleFormClientsObjectFromGenData,
  createVehicleGenFormDataObject
} from "../utils";

type StepItem = {
  title: React.ReactNode;
  icon: React.ReactNode;
  content?: React.ReactNode;
};

enum VEHICLE_CALC_STEPS {
  FIRST_STEP_CALC = 0,
  SECOND_STEP_RESULTS = 1,
  THIRD_STEP_GENERATE_CONTRACT = 2
}

export const VehicleCalcContainer = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const recordId = searchParams.get(QueryKeys.RECORD_ID);
  const draftId = searchParams.get(QueryKeys.DRAFT_ID);
  const contractId = searchParams.get(QueryKeys.CONTRACT_ID);
  const [currentStepIndex, setCurrentStepIndex] = useState<VEHICLE_CALC_STEPS>(VEHICLE_CALC_STEPS.FIRST_STEP_CALC);
  const [lastAvailableStepIndex, setLastAvailableStepIndex] = useState<number>(VEHICLE_CALC_STEPS.FIRST_STEP_CALC);
  const [selectedResult, setSelectedResult] = useState<CalcResult<VehicleCalcResultData>>();
  const [selectedVehicleVin, setSelectedVehicleVin] = useState<string | undefined>();
  const [calcData, setCalcData] = useState<VehicleCalc>();
  const [genData, setGenData] = useState<VehicleGenForm>();
  const [clients, setClients] = useState<VehicleFormClients>({
    holder: undefined,
    policyHolder: undefined,
    owner: undefined,
    representative: undefined
  });

  const [isCalcFormSubmit, setIsCalcFormSubmit] = useState<boolean>(false);
  const [calcDataFormErrors, setCalcDataFormErrors] = useState<FieldConstraintViolation[]>([]);
  const [isCalcFormChanged, setIsCalcFormChanged] = useState<boolean>(false);

  const initialCalcData = useSelector<RootState, VehicleCalc | undefined>(selectVehicleInitialCalcData);
  const initialGenData = useSelector<RootState, VehicleGen | undefined>(selectVehicleInitialGenData);
  const draft = useSelector<RootState, VehicleCalcDraft | undefined>(selectVehicleDraft);
  const calcResults = useSelector<RootState, CalcResult<VehicleCalcResultData>[]>(selectVehicleCalcResults);
  const genResult = useSelector<RootState, GenResponse | undefined>(selectVehicleGenResult);

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          calculateVehicle: calculateVehicleActions.request,
          generateVehicle: generateVehicleActions.request,
          deleteStateVehicleCalcResults: deleteStateVehicleCalcResultsAction,
          deleteStateVehicleGenResult: deleteStateVehicleGenResultAction,
          deleteStateVehicleDraft: deleteStateVehicleDraftAction,
          deleteStateVehicleInitialCalcGenData: deleteStateVehicleInitialCalcGenDataAction,
          getCalcRecordData: getCalcRecordDataActions.request,
          getCalcDraft: getCalcDraftActions.request,
          loadContractToCalculator: loadContractToCalculatorActions.request
        },
        dispatch
      ),
    [dispatch]
  );

  const clearData = () => {
    actions.deleteStateVehicleInitialCalcGenData();
    actions.deleteStateVehicleCalcResults();
    actions.deleteStateVehicleGenResult();
    actions.deleteStateVehicleDraft();
  };

  useEffect(() => {
    if (recordId) {
      actions.getCalcRecordData({ id: recordId });
    }

    if (draftId) {
      actions.getCalcDraft({ id: draftId });
    }

    if (contractId) {
      actions.loadContractToCalculator({ id: contractId });
    }

    return () => {
      clearData();
    };
  }, [recordId, draftId, contractId]);

  useEffect(() => {
    if (calcData) {
      return;
    }

    if (initialCalcData || initialGenData) {
      messageUtils.infoNotification({
        message: t("calc.records.helpers.calcDataInitTitle"),
        description: t("calc.records.helpers.calcDataInit"),
        duration: 10,
        key: "vehicleCalcDataInit"
      });
    }

    if (initialCalcData) {
      setCalcData(createVehicleCalcObjectFromCalcData(initialCalcData));
      setClients(createVehicleFormClientsObjectFromCalcData(initialCalcData));
    } else if (initialGenData) {
      setCalcData(createVehicleCalcObjectFromGenData(initialGenData));
      const clients = createVehicleFormClientsObjectFromGenData(initialGenData);
      setClients(clients);
      setGenData(createVehicleGenFormDataObject(initialGenData, clients));
      setSelectedVehicleVin(initialGenData.vehicleData.vin);
    } else if (draft?.draftData) {
      messageUtils.infoNotification({
        message: t("calc.draft.helpers.draftLoaded"),
        description: t("calc.draft.helpers.draftLoadedInfo"),
        duration: 10,
        key: "vehicleDraftLoaded"
      });

      setCalcData(createVehicleCalcObjectFromGenData(draft.draftData));
      const clients = createVehicleFormClientsObjectFromGenData(draft.draftData);
      setClients(clients);

      if (draft.stage === OperationStage.GENERATE) {
        setGenData(createVehicleGenFormDataObject(draft.draftData, clients));
        setSelectedVehicleVin(draft?.draftData.vehicleData.vin);
      }
    }
  }, [initialCalcData, initialGenData, draft?.draftData, calcData]);

  useEffect(() => {
    if (isCalcFormSubmit && calcResults.length && currentStepIndex === VEHICLE_CALC_STEPS.FIRST_STEP_CALC) {
      handleNextStep();
      setIsCalcFormSubmit(false);
    }

    if (currentStepIndex === VEHICLE_CALC_STEPS.SECOND_STEP_RESULTS && calcResults.length === 0) {
      setIsCalcFormSubmit(false);
      setCurrentStepIndex(VEHICLE_CALC_STEPS.FIRST_STEP_CALC);
      setLastAvailableStepIndex(VEHICLE_CALC_STEPS.FIRST_STEP_CALC);
    }
  }, [isCalcFormSubmit, calcResults, currentStepIndex]);

  const handleClientChange = (type: ClientFormType, client?: Client): VehicleFormClients => {
    let updatedClients: VehicleFormClients;
    switch (type) {
      case ClientFormType.HOLDER:
        updatedClients = { ...clients, holder: client };
        break;
      case ClientFormType.POLICY_HOLDER:
        updatedClients = { ...clients, policyHolder: client };
        break;
      case ClientFormType.OWNER:
        updatedClients = { ...clients, owner: client };
        break;
      case ClientFormType.REPRESENTATIVE:
        updatedClients = { ...clients, representative: client };
        break;
      default:
        updatedClients = { ...clients };
        break;
    }

    setClients(updatedClients);
    return updatedClients;
  };

  const handleResetCalculatorClick = (): void => {
    Modal.confirm({
      title: t("calc.helpers.resetCalculatorSubmit"),
      okText: t("common.yes"),
      cancelText: t("common.back"),
      onOk: () => {
        setClients({ holder: undefined, policyHolder: undefined, owner: undefined, representative: undefined });
        setCalcData(undefined);
        setGenData(undefined);
        setSelectedResult(undefined);

        clearData();
        navigate(CALC_ROUTE_PATHS.vehicle.to, { replace: true });
      }
    });
  };

  const handleCalculationFormSubmit = (calcData: VehicleCalc): void => {
    actions.deleteStateVehicleCalcResults();
    setCalcData(calcData);
    actions.calculateVehicle(calcData);
    setIsCalcFormSubmit(true);
    setIsCalcFormChanged(false);
    setCalcDataFormErrors([]);
  };

  const handleReturnToCalculationClick = (genFormData: VehicleGenForm): void => {
    if (genResult?.contract && calcData) {
      const { contract } = genResult;
      const insurance = contract.insurances[0] as VehicleInsurance;
      const { policyHolderRelation, ownerRelation } = calcData.clientsData;

      setSelectedResult(undefined);
      setGenData({
        ...genFormData,
        clientsData: {
          ...genFormData.clientsData,
          representativeIdentifier: undefined,
          representativeFunction: undefined
        }
      });
      setClients({
        holder: contract.clients[insurance.vehicleHolderIndex],
        policyHolder:
          policyHolderRelation === VehiclePolicyHolderRelation.DIFFERENT_FROM_VEHICLE_HOLDER
            ? contract.clients[contract.policyHolderIndex]
            : undefined,
        owner:
          ownerRelation === VehicleOwnerRelation.DIFFERENT_FROM_BOTH
            ? contract.clients[insurance.vehicleOwnerIndex]
            : undefined,
        representative: undefined
      });
    } else {
      setSelectedResult(undefined);
      setGenData(genFormData);
    }

    setCurrentStepIndex(VEHICLE_CALC_STEPS.FIRST_STEP_CALC);
    setLastAvailableStepIndex(VEHICLE_CALC_STEPS.FIRST_STEP_CALC);
    actions.deleteStateVehicleGenResult();
  };

  const handleGenerateContractClick = (selectedResult: CalcResult<VehicleCalcResultData>) => {
    setSelectedResult(selectedResult);
    handleNextStep();
  };

  const handleShowErrors = (errors: FieldConstraintViolation[]) => {
    setCalcDataFormErrors(errors);
    goToPreviousStep();
  };

  const handleNextStep = () => {
    setCurrentStepIndex(currentStepIndex + 1);

    if (lastAvailableStepIndex < currentStepIndex + 1) {
      setLastAvailableStepIndex(lastAvailableStepIndex + 1);
    }
  };

  const goToPreviousStep = () => {
    setCurrentStepIndex(currentStepIndex - 1);
  };

  const goToNextStep = () => {
    setCurrentStepIndex(currentStepIndex + 1);
  };

  const resultItems = useMemo(() => structuredClone(calcResults).filter(isApplicableResult), [calcResults]);

  const stepItems: StepItem[] = [
    {
      title: <div style={{ fontSize: 18 }}>{t("calc.realty.sections.calculation")}</div>,
      icon: <AntIcon type="laptop" />,
      content: (
        <VehicleCalcWrapper
          calcData={calcData}
          formErrors={calcDataFormErrors}
          setIsFormChanged={() => setIsCalcFormChanged(true)}
          holder={clients.holder}
          onCalculationFormSubmit={handleCalculationFormSubmit}
          onClientChange={handleClientChange}
          onResetCalculatorClick={handleResetCalculatorClick}
          goToCalcResults={isCalcFormChanged || resultItems.length === 0 ? undefined : goToNextStep}
        />
      )
    },
    {
      title: <div style={{ fontSize: 18 }}>{t("calc.realty.sections.insuranceSelection")}</div>,
      icon: <AntIcon type="file-search" />,
      content: calcData ? (
        <VehicleCalcResultsView
          calcData={calcData}
          resultItems={resultItems}
          handleGenerateContract={handleGenerateContractClick}
          handleShowErrors={handleShowErrors}
          goToPreviousStep={goToPreviousStep}
          clients={clients}
        />
      ) : undefined
    },
    {
      title: <div style={{ fontSize: 18 }}>{t("calc.realty.sections.contractGeneration")}</div>,
      icon: <AntIcon type="file-done" />,
      content:
        calcData && selectedResult ? (
          <VehicleGenWrapper
            initialData={genData}
            genResult={genResult}
            calcData={calcData}
            clients={clients}
            calcResults={calcResults}
            selectedResult={selectedResult}
            draftId={draft?.id}
            selectedVehicleVin={selectedVehicleVin}
            onGenerateFormSubmit={actions.generateVehicle}
            onGenResultDelete={actions.deleteStateVehicleGenResult}
            onReturnToCalculationClick={handleReturnToCalculationClick}
            onClientChange={handleClientChange}
            onSelectedVehicleVinSet={setSelectedVehicleVin}
            goToPreviousStep={goToPreviousStep}
          />
        ) : undefined
    }
  ];

  const steps: StepProps[] = stepItems.map((item, index) => ({
    key: index,
    title: item.title,
    disabled: index > lastAvailableStepIndex || isCalcFormChanged,
    status: currentStepIndex >= index ? "finish" : "wait",
    icon: item.icon
  }));

  return (
    <DisplayWrapper itemLoaded={recordId || draftId || contractId ? !!calcData : true}>
      <ContentWrapper>
        <div style={{ maxWidth: 800, margin: "10px auto 20px" }}>
          <ConfigProvider theme={{ components: { Steps: { customIconFontSize: 28 } } }}>
            <Steps onChange={setCurrentStepIndex} current={currentStepIndex} items={steps} />
          </ConfigProvider>
        </div>

        <Tabs
          activeKey={currentStepIndex.toString()}
          renderTabBar={() => <></>}
          items={[
            {
              key: VEHICLE_CALC_STEPS.FIRST_STEP_CALC.toString(),
              label: undefined,
              children: stepItems[VEHICLE_CALC_STEPS.FIRST_STEP_CALC]?.content
            },
            {
              key: VEHICLE_CALC_STEPS.SECOND_STEP_RESULTS.toString(),
              label: undefined,
              children: stepItems[VEHICLE_CALC_STEPS.SECOND_STEP_RESULTS]?.content
            },
            {
              key: VEHICLE_CALC_STEPS.THIRD_STEP_GENERATE_CONTRACT.toString(),
              label: undefined,
              children: stepItems[VEHICLE_CALC_STEPS.THIRD_STEP_GENERATE_CONTRACT]?.content
            }
          ]}
        />

        <CalcLoadingModal />
      </ContentWrapper>
    </DisplayWrapper>
  );
};
