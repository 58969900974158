import { Col, Row } from "antd";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { rowGutter } from "../../../common/constants";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import { Permission } from "../../../common/security/authorization/enums";
import { hasAnyPermission, hasPermission } from "../../../common/utils/utils";
import { selectPermissions, selectSelectedAccount } from "../../auth/ducks";
import {
  DashboardAgentCompetencesWidget,
  DashboardContactsWidget,
  DashboardContractsWidget,
  DashboardNoticesWidget,
  DashboardPersonalDatesWidget,
  DashboardStatisticsWidget
} from "../components/widgets";
import { refreshDashboardAction } from "../ducks";

export const DashboardContainer = () => {
  const permissions = useSelector(selectPermissions);
  const selectedAccount = useSelector(selectSelectedAccount);

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          refreshDashboard: refreshDashboardAction
        },
        dispatch
      ),
    [dispatch]
  );

  const { hasContractReadPermission, hasClientOrAgentReadPermission, hasProfileCompetencePermission } = useMemo(() => {
    return {
      hasContractReadPermission: hasAnyPermission(permissions, [
        Permission.INSURANCE_READ,
        Permission.LOAN_READ,
        Permission.INVESTMENT_READ,
        Permission.DEPOSIT_READ,
        Permission.SECOND_PILLAR_READ,
        Permission.THIRD_PILLAR_READ,
        Permission.GENERIC_READ
      ]),
      hasClientOrAgentReadPermission: hasAnyPermission(permissions, [Permission.CLIENT_READ, Permission.AGENT_READ]),
      hasProfileCompetencePermission: hasPermission(permissions, Permission.PROFILE_COMPETENCE)
    };
  }, [permissions]);

  useEffect(() => {
    actions.refreshDashboard("initial");
  }, [selectedAccount.accountId]);

  return (
    <ContentWrapper wide key={selectedAccount.accountId}>
      <Row gutter={rowGutter}>
        <Col span={18}>
          <Row gutter={rowGutter}>
            <Col span={24}>
              <DashboardStatisticsWidget />
            </Col>
          </Row>

          <Row gutter={rowGutter}>
            {hasContractReadPermission && (
              <Col span={24}>
                <DashboardContractsWidget />
              </Col>
            )}
          </Row>

          <Row gutter={rowGutter}>
            {hasClientOrAgentReadPermission && (
              <Col span={14}>
                <DashboardPersonalDatesWidget />
              </Col>
            )}

            {hasProfileCompetencePermission && (
              <Col span={10}>
                <DashboardAgentCompetencesWidget />
              </Col>
            )}
          </Row>

          <Row gutter={rowGutter}>
            <Col span={24}>
              <DashboardContactsWidget />
            </Col>
          </Row>
        </Col>

        <Col span={6}>
          <DashboardNoticesWidget />
        </Col>
      </Row>
    </ContentWrapper>
  );
};
