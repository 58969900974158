import { Select } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../../common/types";
import { formatAgentEducationName } from "../../../../common/utils/formatUtils";
import { selectStandardProps } from "../../../../common/utils/formUtils";
import { UUID } from "../../../../typings/global";
import { AgentEducationBase } from "../../../agent/types";
import { selectAgentEducationsEnums } from "../../ducks";

type Props<T extends boolean> = {
  value?: T extends true ? UUID[] : UUID;
  allowClear?: boolean;
  isMultiple?: T;
  dataSource?: AgentEducationBase[];
  onChange?: (data: T extends true ? UUID[] : UUID) => void;
  style?: React.CSSProperties;
};

export const AgentEducationSelect = <T extends boolean>({
  value,
  allowClear,
  isMultiple,
  dataSource,
  onChange,
  style
}: Props<T>) => {
  const educationEnums = useSelector<RootState, AgentEducationBase[]>(selectAgentEducationsEnums);
  const data = dataSource ?? educationEnums;

  return (
    <Select
      style={style}
      {...selectStandardProps}
      allowClear={allowClear}
      mode={isMultiple ? "multiple" : undefined}
      options={data.map(education => ({
        label: formatAgentEducationName(education),
        value: education.id
      }))}
      onChange={onChange}
      value={value}
    />
  );
};
