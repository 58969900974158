import { Card } from "antd";
import { RcFile } from "antd/lib/upload";
import { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import t from "../../../../app/i18n";
import { EntityIdObject, RootState } from "../../../../common/types";
import messageUtils from "../../../../common/utils/messageUtils";
import { contains } from "../../../../common/utils/utils";
import type { UUID } from "../../../../typings/global";
import { getEnumerationsActions } from "../../../enumerations/ducks";
import VehicleBrandMappingsListForm from "../components/brands/VehicleBrandMappingsListForm";
import VehicleModelMappingsListForm from "../components/models/VehicleModelMappingsListForm";
import VehicleSettingsHeader from "../components/VehicleSettingsHeader";
import {
  createVehicleBrandMappingActions,
  createVehicleModelMappingActions,
  deleteStateVehicleMappingsDataAction,
  exportVehicleMappingsActions,
  filterVehicleBrandMappingsActions,
  filterVehicleModelMappingsActions,
  getVehicleMappingsActions,
  importVehicleMappingsActions,
  mapVehicleMappingsUpdatesActions,
  remapVehicleMappingsActions,
  selectVehicleBrandsCurrentPage,
  selectVehicleMappings,
  selectVehicleModelsCurrentPage,
  updateVehicleBrandMappingsActions,
  updateVehicleModelMappingsActions
} from "../ducks";
import { VehicleMappingType } from "../enums";
import { VEHICLE_BRAND_ROUTE_PATHS } from "../paths";
import { VehicleBrandMappingFilterPageResult, VehicleMappingData, VehicleModelMappingFilterPageResult } from "../types";

interface StateProps {
  vehicleMappingsData: VehicleMappingData[];
  brandsCurrentPage: VehicleBrandMappingFilterPageResult;
  modelsCurrentPage: VehicleModelMappingFilterPageResult;
}

const VehicleSettingsContainer = (props: StateProps) => {
  const { id } = useParams<EntityIdObject>();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedMappingTypes, setSelectedMappingTypes] = useState<VehicleMappingType[]>(
    Object.values(VehicleMappingType)
  );
  const [selectedBrandId, setSelectedBrandId] = useState<UUID | undefined>(id);

  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          importVehicleMappings: importVehicleMappingsActions.request,
          exportVehicleMappings: exportVehicleMappingsActions.request,
          getVehicleMappings: getVehicleMappingsActions.request,
          remapVehicleMappings: remapVehicleMappingsActions.request,
          mapVehicleMappingsUpdates: mapVehicleMappingsUpdatesActions.request,
          filterVehicleBrandMappings: filterVehicleBrandMappingsActions.request,
          createVehicleBrandMapping: createVehicleBrandMappingActions.request,
          updateVehicleBrandMappings: updateVehicleBrandMappingsActions.request,
          filterVehicleModelMappings: filterVehicleModelMappingsActions.request,
          createVehicleModelMapping: createVehicleModelMappingActions.request,
          updateVehicleModelMappings: updateVehicleModelMappingsActions.request,
          getEnumerations: getEnumerationsActions.request,
          deleteStateVehicleMappingsData: deleteStateVehicleMappingsDataAction
        },
        dispatch
      ),
    [dispatch]
  );

  useEffect(() => {
    return () => {
      actions.deleteStateVehicleMappingsData();
      actions.getEnumerations();
    };
  }, []);

  const handleSelectedMappingTypesChange = (checkedValues: any[]): void => {
    setSelectedMappingTypes(checkedValues as VehicleMappingType[]);

    if (props.vehicleMappingsData.length > 0) {
      const fetchedMappings = props.vehicleMappingsData.flatMap(mapping => mapping.type);
      const missingMappings = (checkedValues as VehicleMappingType[]).filter(
        mapping => !contains(fetchedMappings, mapping)
      );
      if (missingMappings.length > 0) {
        actions.getVehicleMappings({ queriedMappings: missingMappings });
      }
    }
  };

  const handleDownloadMappingsClick = (): void => {
    if (checkIfAtLeastMappingTypeIsSelected()) {
      actions.getVehicleMappings({ queriedMappings: selectedMappingTypes });
    }
  };

  const handleRemapMappingsClick = (): void => {
    if (checkIfAtLeastMappingTypeIsSelected()) {
      actions.remapVehicleMappings({ queriedMappings: selectedMappingTypes });
    }
  };

  const handleMapMappingsUpdatesClick = (): void => {
    if (checkIfAtLeastMappingTypeIsSelected()) {
      actions.mapVehicleMappingsUpdates({ queriedMappings: selectedMappingTypes });
    }
  };

  const handleShowModelsClick = (brandMappingId: UUID): void => {
    setSelectedBrandId(brandMappingId);
    navigate(generatePath(VEHICLE_BRAND_ROUTE_PATHS.models.to, { id: brandMappingId }));
  };

  const handleReturnToBrandsClick = (): void => {
    setSelectedBrandId(undefined);
    navigate(VEHICLE_BRAND_ROUTE_PATHS.brands.to);
  };

  const checkIfAtLeastMappingTypeIsSelected = (): boolean => {
    if (selectedMappingTypes.length === 0) {
      messageUtils.warnMessage(t("vehicle.validations.noMappingTypeSelected"));
      return false;
    }
    return true;
  };

  const handleImportMappingsClick = (file: RcFile): boolean => {
    const formData = new FormData();
    formData.append("file", file);
    actions.importVehicleMappings(formData);
    return false;
  };

  return (
    <Card className="card-box" title={<h2>{t("vehicle.titles.pageTitle")}</h2>}>
      <VehicleSettingsHeader
        selectedMappingTypes={selectedMappingTypes}
        showDownloadButton={props.vehicleMappingsData.length === 0}
        onSelectedMappingTypesChange={handleSelectedMappingTypesChange}
        onDownloadMappingsClick={handleDownloadMappingsClick}
        onRemapMappingsClick={handleRemapMappingsClick}
        onMapMappingsUpdatesClick={handleMapMappingsUpdatesClick}
        onImportMappingsClick={handleImportMappingsClick}
        onExportMappingsClick={actions.exportVehicleMappings}
      />

      {props.vehicleMappingsData.length > 0 &&
        (selectedBrandId ? (
          <VehicleModelMappingsListForm
            selectedBrandId={selectedBrandId}
            selectedMappingTypes={selectedMappingTypes}
            mappingData={props.vehicleMappingsData}
            currentPage={props.modelsCurrentPage}
            onFilter={actions.filterVehicleModelMappings}
            onCreate={actions.createVehicleModelMapping}
            onUpdate={actions.updateVehicleModelMappings}
            onReturnToBrandsClick={handleReturnToBrandsClick}
          />
        ) : (
          <VehicleBrandMappingsListForm
            selectedMappingTypes={selectedMappingTypes}
            mappingData={props.vehicleMappingsData}
            currentPage={props.brandsCurrentPage}
            onFilter={actions.filterVehicleBrandMappings}
            onCreate={actions.createVehicleBrandMapping}
            onUpdate={actions.updateVehicleBrandMappings}
            onShowModelsClick={handleShowModelsClick}
          />
        ))}
    </Card>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  vehicleMappingsData: selectVehicleMappings(state),
  brandsCurrentPage: selectVehicleBrandsCurrentPage(state),
  modelsCurrentPage: selectVehicleModelsCurrentPage(state)
});

export default connect<StateProps, undefined, Record<string, any>, RootState>(mapStateToProps)(
  VehicleSettingsContainer
);
