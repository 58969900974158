import { Card, Col, Divider, Form, InputNumber, Row, Select, Space } from "antd";
import t from "../../../../../../../app/i18n";
import ActionButton from "../../../../../../../common/components/buttons/ActionButton";
import InputAddon from "../../../../../../../common/components/form/addons/InputAddon";
import { rowGutter } from "../../../../../../../common/constants";
import { inputNumberIntegerStandardProps, selectStandardProps } from "../../../../../../../common/utils/formUtils";
import { tInterval } from "../../../../../../../common/utils/translationUtils";
import { validations } from "../../../../../../../common/utils/validationUtils";
import { ConstructionMaterial, RoofType } from "../../../enums";

const colSpan = 5;

const RealtyCalcHouseSection = () => (
  <Card type="inner" className="card-box" title={t("calc.realty.sections.insuredObjectAndAreas")}>
    <Row gutter={rowGutter}>
      <Col span={colSpan}>
        <Form.Item
          name={["houseOrRecreationalBuildingData", "constructionMaterial"]}
          label={t("calc.realty.enums.constructionMaterial._label")}
          rules={[validations.notNull]}
        >
          <Select
            {...selectStandardProps}
            options={Object.keys(ConstructionMaterial).map(material => ({
              value: material,
              label: t("calc.realty.enums.constructionMaterial." + material)
            }))}
          />
        </Form.Item>
      </Col>

      <Col span={colSpan}>
        <Form.Item
          name={["houseOrRecreationalBuildingData", "roofType"]}
          label={t("calc.realty.enums.roofType._label")}
          rules={[validations.notNull]}
        >
          <Select
            {...selectStandardProps}
            options={Object.keys(RoofType).map(type => ({
              value: type,
              label: t("calc.realty.enums.roofType." + type)
            }))}
          />
        </Form.Item>
      </Col>

      <Col span={colSpan}>
        <Form.Item
          name={["houseOrRecreationalBuildingData", "buildArea"]}
          label={t("calc.realty.attrs.houseOrRecreationalBuildingData.buildArea")}
          rules={[validations.notNull, validations.minNumber(1)]}
        >
          <InputNumber {...inputNumberIntegerStandardProps} addonAfter={<InputAddon type="area" />} />
        </Form.Item>
      </Col>
    </Row>

    <Divider className="divider-subheader">{t("calc.realty.sections.areas")}</Divider>

    <Form.Item
      noStyle
      shouldUpdate={(prev, next) =>
        prev.houseOrRecreationalBuildingData?.roofType !== next.houseOrRecreationalBuildingData?.roofType ||
        prev.houseOrRecreationalBuildingData?.buildArea !== next.houseOrRecreationalBuildingData?.buildArea
      }
    >
      {({ getFieldValue }) => {
        const roofType = getFieldValue(["houseOrRecreationalBuildingData", "roofType"]);
        const buildArea = getFieldValue(["houseOrRecreationalBuildingData", "buildArea"]);
        return (
          <>
            <Row gutter={rowGutter}>
              <Col span={colSpan}>
                <Form.Item
                  name={["houseOrRecreationalBuildingData", "cellarArea"]}
                  label={t("calc.realty.attrs.houseOrRecreationalBuildingData.cellarArea")}
                  rules={[
                    validations.minNumber(0),
                    validations.maxNumber(buildArea, t("calc.realty.attrs.houseOrRecreationalBuildingData.buildArea"))
                  ]}
                >
                  <InputNumber {...inputNumberIntegerStandardProps} addonAfter={<InputAddon type="area" />} />
                </Form.Item>
              </Col>

              <Col span={colSpan}>
                <Form.Item
                  name={["houseOrRecreationalBuildingData", "residentialCellarArea"]}
                  label={t("calc.realty.attrs.houseOrRecreationalBuildingData.residentialCellarArea")}
                  rules={[
                    validations.minNumber(0),
                    validations.maxNumber(buildArea, t("calc.realty.attrs.houseOrRecreationalBuildingData.buildArea"))
                  ]}
                >
                  <InputNumber {...inputNumberIntegerStandardProps} addonAfter={<InputAddon type="area" />} />
                </Form.Item>
              </Col>

              <Col span={colSpan}>
                <Form.Item
                  name={["houseOrRecreationalBuildingData", "atticArea"]}
                  label={t("calc.realty.attrs.houseOrRecreationalBuildingData.atticArea")}
                  rules={[
                    validations.minNumber(0),
                    validations.maxNumber(buildArea, t("calc.realty.attrs.houseOrRecreationalBuildingData.buildArea"))
                  ]}
                >
                  <InputNumber {...inputNumberIntegerStandardProps} addonAfter={<InputAddon type="area" />} />
                </Form.Item>
              </Col>

              {roofType === RoofType.PITCHED_WITH_ATTIC && (
                <Col span={colSpan}>
                  <Form.Item
                    name={["houseOrRecreationalBuildingData", "residentialAtticArea"]}
                    label={t("calc.realty.attrs.houseOrRecreationalBuildingData.residentialAtticArea")}
                    rules={[
                      validations.minNumber(0),
                      validations.maxNumber(buildArea, t("calc.realty.attrs.houseOrRecreationalBuildingData.buildArea"))
                    ]}
                  >
                    <InputNumber {...inputNumberIntegerStandardProps} addonAfter={<InputAddon type="area" />} />
                  </Form.Item>
                </Col>
              )}

              <Col span={colSpan}>
                <Form.Item
                  name={["houseOrRecreationalBuildingData", "garageArea"]}
                  label={t("calc.realty.attrs.houseOrRecreationalBuildingData.garageArea")}
                  rules={[validations.none, validations.minNumber(0)]}
                >
                  <InputNumber {...inputNumberIntegerStandardProps} addonAfter={<InputAddon type="area" />} />
                </Form.Item>
              </Col>
            </Row>

            <Divider className="divider-subheader">{t("calc.realty.sections.overgroundAreas")}</Divider>

            <Form.List name={["houseOrRecreationalBuildingData", "overgroundFloorAreas"]} initialValue={[null]}>
              {(fields, { add, remove }) => (
                <>
                  <Row gutter={rowGutter}>
                    {fields.map(({ key, ...field }) => (
                      <Col span={colSpan} key={key}>
                        <Form.Item
                          {...field}
                          name={field.name}
                          label={tInterval(
                            "calc.realty.attrs.houseOrRecreationalBuildingData.overgroundFloorAreas_interval",
                            field.name + 1
                          )}
                          rules={[
                            validations.notNull,
                            validations.minNumber(1),
                            validations.maxNumber(
                              buildArea,
                              t("calc.realty.attrs.houseOrRecreationalBuildingData.buildArea")
                            )
                          ]}
                        >
                          <InputNumber {...inputNumberIntegerStandardProps} addonAfter={<InputAddon type="area" />} />
                        </Form.Item>
                      </Col>
                    ))}
                  </Row>

                  <div className="margin-bottom-medium">
                    <Space>
                      <ActionButton icon="plus" disabled={fields.length >= 3} onClick={() => add()}>
                        {t("calc.realty.actions.overgroundAreaAdd")}
                      </ActionButton>

                      <ActionButton
                        icon="minus"
                        disabled={fields.length <= 1}
                        onClick={() => {
                          const name = fields[fields.length - 1]?.name;
                          if (name) {
                            return remove(name);
                          }
                        }}
                      >
                        {t("calc.realty.actions.overgroundAreaDelete")}
                      </ActionButton>
                    </Space>
                  </div>
                </>
              )}
            </Form.List>
          </>
        );
      }}
    </Form.Item>
  </Card>
);

export default RealtyCalcHouseSection;
