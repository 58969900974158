import { Alert, Card, Checkbox, Col, DatePicker, Divider, Form, Input, Row } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { FormInstance, Rule } from "antd/lib/form";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import t from "../../../../../../../app/i18n";
import ContactsEmailAutoComplete from "../../../../../../../common/components/form/components/ContactsEmailAutoComplete";
import ContactsPhoneNumberAutoComplete from "../../../../../../../common/components/form/components/ContactsPhoneNumberAutoComplete";
import HiddenCheckbox from "../../../../../../../common/components/form/components/HiddenCheckbox";
import HiddenDatePicker from "../../../../../../../common/components/form/components/HiddenDatePicker";
import LabelWithPopover from "../../../../../../../common/components/form/labels/LabelWithPopover";
import { rowGutter } from "../../../../../../../common/constants";
import MarketingConsentSelect from "../../../../../../../common/modules/contact/MarketingConsentSelect";
import { FieldConstraintViolation } from "../../../../../../../common/types";
import {
  datePickerFormItemProps,
  datePickerStandardProps,
  disableDatePickerPresentAndFuture,
  phoneNumberNormalizeFunction,
  resolveFormValidationError,
  toDate
} from "../../../../../../../common/utils/formUtils";
import { parseBirthDateFromPin } from "../../../../../../../common/utils/utils";
import { regexPatterns, validationFunctions, validations } from "../../../../../../../common/utils/validationUtils";
import ClientDrawerForm from "../../../../../../client/components/drawers/ClientDrawerForm";
import ClientRepresentativeSearchForm from "../../../../../../client/components/search/ClientRepresentativeSearchForm";
import ClientSearchInput from "../../../../../../client/components/search/ClientSearchInput";
import { ClientFormStage, ClientFormType, ClientSearchActionType, ClientType } from "../../../../../../client/enums";
import { Client, LegalClient, NaturalClient } from "../../../../../../client/types";
import { useClientSearch } from "../../../../../../client/utils";
import AcademicDegreeAfterSelect from "../../../../../../enumerations/components/form/AcademicDegreeAfterSelect";
import AcademicDegreeSelect from "../../../../../../enumerations/components/form/AcademicDegreeSelect";
import { InstitutionEnum } from "../../../../../../institution/enums";
import { resolveClientFormTypeIdentifierName } from "../../../../utils";
import { TravelInsuranceType } from "../../../enums";
import { TravelCalc, TravelFormClients, TravelGenForm, TravelGenFormInsuredClient } from "../../../types";

interface Props {
  form: FormInstance<TravelGenForm>;
  calcData: TravelCalc;
  clients: TravelFormClients;
  clientsViolationErrors: Map<ClientFormType, FieldConstraintViolation[]>;
  selectedInstitutionEnum?: InstitutionEnum;
  onPolicyHolderIsAlsoInsuredChange: (event: CheckboxChangeEvent) => void;
  onClientChange: (type: ClientFormType, client?: Client) => void;
  onClientViolationErrorsChange: (type: ClientFormType, violations?: FieldConstraintViolation[]) => void;
}

interface ClientsStagesState {
  readonly policyHolder?: ClientFormStage;
  readonly representative?: ClientFormStage;
}

const policyHolderIsAlsoInsuredBirthDateRule = (
  insuredClientBirthDate?: string,
  policyHolderBirthDate?: string
): Rule => ({
  validator: (_, value) =>
    value &&
    policyHolderBirthDate &&
    dayjs.isDayjs(toDate(insuredClientBirthDate)) &&
    !toDate(policyHolderBirthDate)?.isSame(insuredClientBirthDate, "day")
      ? Promise.reject(t("calc.travel.validations.incorrectBirtDateFromPolicyHolder"))
      : Promise.resolve()
});

const insuredClientPinForBirthDateRule = (birthDate?: string): Rule => ({
  validator: (_, value) =>
    value &&
    validationFunctions.validatePin(value) &&
    dayjs.isDayjs(toDate(birthDate)) &&
    !toDate(parseBirthDateFromPin(value))?.isSame(birthDate, "day")
      ? Promise.reject(t("calc.travel.validations.incorrectPinForBirthDate"))
      : Promise.resolve()
});

const insuredClientNoDuplicatePinRule =
  (index: number): Rule =>
  ({ getFieldValue }) => ({
    validator: (_, value) => {
      if (value) {
        const insuredClients = [
          ...(getFieldValue(["clientsData", "insuredClients"]) || [])
        ] as TravelGenFormInsuredClient[];

        return insuredClients.some(
          (insuredClient, i) => index !== i && insuredClient?.personalIdentificationNumber === value
        )
          ? Promise.reject(t("calc.travel.validations.duplicatePin"))
          : Promise.resolve();
      }
      return Promise.resolve();
    }
  });

const TravelGenClientsDataSection = ({ form, calcData, clients, selectedInstitutionEnum, ...props }: Props) => {
  const clientSearch = useClientSearch();

  const [processedClientFormType, setProcessedClientFormType] = useState<ClientFormType>();
  const [clientFormOpen, setClientFormOpen] = useState<boolean>(false);
  const [clientStages, setClientStages] = useState<ClientsStagesState>({
    policyHolder: clients.policyHolder ? ClientFormStage.EXISTING : undefined,
    representative:
      clients.representative &&
      clients.policyHolder?.type === ClientType.LEGAL &&
      ((clients.policyHolder as LegalClient)?.representatives || []).some(
        r => r.representative.identifier === clients.representative?.identifier
      )
        ? ClientFormStage.EXISTING
        : undefined
  });

  useEffect(() => {
    return () => {
      clientSearch.onResultDelete();
    };
  }, []);

  useEffect(() => {
    if (processedClientFormType && clientSearch.result.keyword === getClientFormIdentifier(processedClientFormType)) {
      if (clientSearch.result.data) {
        setClientFormOpen(true);
        setClientFormStage(processedClientFormType, ClientFormStage.EXISTING);

        if (
          clientSearch.result.data.type === ClientType.NATURAL ||
          clientSearch.result.data.type === ClientType.SELF_EMPLOYED
        ) {
          props.onClientChange(processedClientFormType, {
            ...clientSearch.result.data,
            identityCardNumber: undefined,
            previousIdentityCardNumber: (clientSearch.result.data as NaturalClient).identityCardNumber
          } as NaturalClient);
        } else {
          props.onClientChange(processedClientFormType, clientSearch.result.data);
        }
      } else {
        setClientFormStage(processedClientFormType, ClientFormStage.NEW);
      }
    }
  }, [clientSearch.result]);

  const handleInsuredClientPinChange = (pin: string, index: number): void => {
    if (calcData.generalData.insuranceType === TravelInsuranceType.CANCELLATION) {
      const insuredClients = [...form.getFieldValue(["clientsData", "insuredClients"])];

      insuredClients[index] = {
        ...insuredClients[index],
        birthDate: validationFunctions.validatePin(pin) ? parseBirthDateFromPin(pin) : ""
      } as TravelGenFormInsuredClient;

      form.setFieldsValue({ clientsData: { insuredClients } });
    }
  };

  const handleClientSearchSubmit = (value: string, type: ClientFormType): void => {
    form
      .validateFields([["clientsData", resolveClientFormTypeIdentifierName(type)]])
      .then(() => clientSearch.onSearch({ keyword: value, clientType: resolveClientTypeByClientFormType(type) }))
      .catch(resolveFormValidationError);
  };

  const handleClientSearchChange = (value: string, type: ClientFormType): void => {
    if (getClientFormStage(type)) {
      setClientFormStage(type);
    }
    if (getClientFromProps(type)) {
      props.onClientChange(type);
    }
    if (props.clientsViolationErrors.has(type)) {
      props.onClientViolationErrorsChange(type);
    }
    handleClientSearchSubmit(value, type);
  };

  const handleClientSearchActionClick = (type: ClientFormType, actionType: ClientSearchActionType): void => {
    switch (actionType) {
      case ClientSearchActionType.CREATE:
      case ClientSearchActionType.UPDATE:
        setProcessedClientFormType(type);
        setClientFormOpen(true);
        break;
      case ClientSearchActionType.DELETE:
        form.setFieldsValue({ clientsData: { [resolveClientFormTypeIdentifierName(type)]: null } });
        setClientFormStage(type);
        props.onClientViolationErrorsChange(type);
        props.onClientChange(type);
        break;
    }
  };

  const handleClientFormSubmit = (client: Client, clientFormType: ClientFormType): void => {
    setClientFormOpen(false);
    setProcessedClientFormType(undefined);
    setClientFormStage(clientFormType, ClientFormStage.SELECTED);
    props.onClientViolationErrorsChange(clientFormType);
    props.onClientChange(clientFormType, client);
  };

  const getClientFormIdentifier = (type: ClientFormType): string => {
    switch (type) {
      case ClientFormType.POLICY_HOLDER:
        return form.getFieldValue(["clientsData", "policyHolderIdentifier"]);
      case ClientFormType.REPRESENTATIVE:
        return form.getFieldValue(["clientsData", "representativeIdentifier"]);
      default:
        return "";
    }
  };

  const getClientFormStage = (type: ClientFormType): ClientFormStage | undefined => {
    switch (type) {
      case ClientFormType.POLICY_HOLDER:
        return clientStages.policyHolder;
      case ClientFormType.REPRESENTATIVE:
        return clientStages.representative;
      default:
        return undefined;
    }
  };

  const getClientFromProps = (type: ClientFormType): Client | undefined => {
    switch (type) {
      case ClientFormType.POLICY_HOLDER:
        return clients.policyHolder;
      case ClientFormType.REPRESENTATIVE:
        return clients.representative;
      default:
        return undefined;
    }
  };

  const setClientFormStage = (type: ClientFormType, stage?: ClientFormStage): void => {
    switch (type) {
      case ClientFormType.POLICY_HOLDER:
        setClientStages({ ...clientStages, policyHolder: stage });
        break;
      case ClientFormType.REPRESENTATIVE:
        setClientStages({ ...clientStages, representative: stage });
        break;
    }
  };

  const resolveClientTypeByClientFormType = (type: ClientFormType): ClientType | undefined => {
    switch (type) {
      case ClientFormType.POLICY_HOLDER:
        return undefined;
      case ClientFormType.REPRESENTATIVE:
        return ClientType.NATURAL;
      default:
        return undefined;
    }
  };

  const colSpan = 4;
  const { insuredClients } = calcData.clientsData;

  const axaAssistanceYearFamilyInsurance =
    selectedInstitutionEnum === InstitutionEnum.AXA_ASSISTANCE &&
    calcData.generalData.insuranceType === TravelInsuranceType.YEAR &&
    calcData.discountsData?.familyInsurance;

  const clientSearchProps = {
    processedType: processedClientFormType,
    violationErrors: props.clientsViolationErrors,
    inProgress: clientSearch.inProgress,
    inputColSpan: 5,
    clientNameColSpan: 6,
    onActionClick: handleClientSearchActionClick,
    onFocus: setProcessedClientFormType,
    onSearch: handleClientSearchSubmit,
    onChange: handleClientSearchChange
  };

  return (
    <>
      <Card type="inner" className="card-box" title={t("calc.travel.sections.policyHolder")}>
        <Row gutter={rowGutter}>
          <ClientSearchInput<ClientFormType>
            {...clientSearchProps}
            formItemProps={{
              name: ["clientsData", "policyHolderIdentifier"],
              label: t("calc.travel.attrs.clientsData.policyHolderPinCrn"),
              rules: [validations.notBlank, validations.pinOrCrn],
              validateFirst: true
            }}
            formStage={clientStages.policyHolder}
            formType={ClientFormType.POLICY_HOLDER}
            client={clients.policyHolder}
          />

          {axaAssistanceYearFamilyInsurance ? (
            <HiddenCheckbox name={["clientsData", "policyHolderIsAlsoInsured"]} initialValue={false} />
          ) : (
            <Col span={colSpan * 2}>
              <Form.Item
                name={["clientsData", "policyHolderIsAlsoInsured"]}
                className="form-item-without-label"
                valuePropName="checked"
                rules={[
                  policyHolderIsAlsoInsuredBirthDateRule(
                    insuredClients?.[0]?.birthDate,
                    clients.policyHolder?.type === ClientType.NATURAL
                      ? (clients.policyHolder as NaturalClient).birthDate
                      : undefined
                  )
                ]}
                initialValue={false}
              >
                <Checkbox
                  disabled={!clients.policyHolder || clients.policyHolder.type !== ClientType.NATURAL}
                  onChange={props.onPolicyHolderIsAlsoInsuredChange}
                >
                  {t("calc.travel.attrs.clientsData.policyHolderIsAlsoInsured")}
                </Checkbox>
              </Form.Item>
            </Col>
          )}
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <ContactsEmailAutoComplete
              formItemProps={{
                name: ["clientsData", "policyHolderEmail"],
                label: t("calc.travel.attrs.clientsData.policyHolderEmail"),
                rules: [validations.notBlank, validations.size(1, 254), validations.email]
              }}
              contacts={clients.policyHolder?.contacts}
            />
          </Col>

          <Col span={colSpan}>
            <ContactsPhoneNumberAutoComplete
              formItemProps={{
                name: ["clientsData", "policyHolderPhone"],
                label: t("calc.travel.attrs.clientsData.policyHolderPhone"),
                rules: [validations.notBlank, validations.size(1, 19), validations.mobilePhoneNumber],
                normalize: phoneNumberNormalizeFunction
              }}
              contacts={clients.policyHolder?.contacts}
            />
          </Col>

          <Col span={colSpan}>
            <MarketingConsentSelect
              formItemProps={{
                name: ["clientsData", "policyHolderMarketingConsent"],
                label: t("contact.enums.marketingConsent._label"),
                rules: [validations.notNull]
              }}
            />
          </Col>
        </Row>

        <ClientRepresentativeSearchForm
          form={form}
          policyHolder={clients.policyHolder}
          searchInputProps={{
            ...clientSearchProps,
            formStage: clientStages.representative,
            formType: ClientFormType.REPRESENTATIVE,
            client: clients.representative
          }}
          colSpan={colSpan}
        />

        <Divider className="divider-subheader">{t("calc.travel.sections.insuredClients")}</Divider>

        {axaAssistanceYearFamilyInsurance ? (
          !clients.policyHolder || clients.policyHolder.type === ClientType.NATURAL ? (
            <>
              <Row gutter={rowGutter}>
                <Col span={16}>
                  <Alert type="info" showIcon message={t("calc.travel.helpers.axaAssistanceYearFamilyInsuranceDesc")} />
                </Col>
              </Row>

              {[...Array(calcData.clientsData.insuredClientsCount)].map((_, index) => (
                <HiddenDatePicker key={index} name={["clientsData", "insuredClients", index, "birthDate"]} />
              ))}
            </>
          ) : (
            <>
              <Row gutter={rowGutter}>
                <Col span={colSpan}>
                  <Form.Item
                    name={["clientsData", "insuredClientForFamilyInsurance", "birthDate"]}
                    label={t("calc.travel.attrs.clientsData.insuredClientForFamilyInsurance.birthDate")}
                    rules={[validations.notNull, validations.notPresentAndFuture]}
                    {...datePickerFormItemProps}
                  >
                    <DatePicker
                      {...datePickerStandardProps}
                      showNow={false}
                      disabledDate={disableDatePickerPresentAndFuture}
                    />
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  <Form.Item
                    name={["clientsData", "insuredClientForFamilyInsurance", "firstName"]}
                    label={t("calc.travel.attrs.clientsData.insuredClientForFamilyInsurance.firstName")}
                    rules={[
                      validations.notBlank,
                      validations.size(1, 255),
                      validations.pattern(regexPatterns.uppercaseWordRegex)
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  <Form.Item
                    name={["clientsData", "insuredClientForFamilyInsurance", "lastName"]}
                    label={t("calc.travel.attrs.clientsData.insuredClientForFamilyInsurance.lastName")}
                    rules={[
                      validations.notBlank,
                      validations.size(1, 255),
                      validations.pattern(regexPatterns.uppercaseWordRegex)
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={rowGutter}>
                <Col span={16}>
                  <Alert
                    type="info"
                    showIcon
                    message={t("calc.travel.helpers.axaAssistanceYearFamilyInsuranceLegalDesc")}
                  />
                </Col>
              </Row>

              {[...Array(calcData.clientsData.insuredClientsCount)].map((_, index) => (
                <HiddenDatePicker key={index} name={["clientsData", "insuredClients", index, "birthDate"]} />
              ))}
            </>
          )
        ) : (
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.clientsData?.policyHolderIsAlsoInsured !== next.clientsData?.policyHolderIsAlsoInsured
            }
          >
            {({ getFieldValue }) => {
              const policyHolderIsAlsoInsured = getFieldValue(["clientsData", "policyHolderIsAlsoInsured"]);
              return [...Array(calcData.clientsData.insuredClientsCount)].map((_, index) => (
                <React.Fragment key={index}>
                  <Row gutter={rowGutter}>
                    <Col className="bold-text margin-bottom-tiny">
                      {t("calc.travel.sections.insuredClient", { number: index + 1 })}
                    </Col>
                  </Row>

                  <Row gutter={rowGutter}>
                    <Col span={colSpan}>
                      <Form.Item
                        name={["clientsData", "insuredClients", index, "birthDate"]}
                        label={t("calc.travel.attrs.clientsData.insuredClients.birthDate")}
                        rules={[validations.notNull, validations.notPresentAndFuture]}
                        {...datePickerFormItemProps}
                      >
                        <DatePicker
                          {...datePickerStandardProps}
                          showNow={false}
                          disabled={calcData.generalData.insuranceType !== TravelInsuranceType.CANCELLATION}
                          disabledDate={disableDatePickerPresentAndFuture}
                        />
                      </Form.Item>
                    </Col>

                    {selectedInstitutionEnum === InstitutionEnum.GENERALI ||
                    (selectedInstitutionEnum === InstitutionEnum.UNION &&
                      calcData.generalData.insuranceType === TravelInsuranceType.YEAR &&
                      calcData.discountsData?.unionHealthInsuranceClient) ? (
                      <Col span={colSpan}>
                        <Form.Item
                          name={["clientsData", "insuredClients", index, "personalIdentificationNumber"]}
                          label={t("calc.travel.attrs.clientsData.insuredClients.personalIdentificationNumber")}
                          rules={[
                            validations.notBlank,
                            validations.pin,
                            insuredClientPinForBirthDateRule(insuredClients?.[index]?.birthDate),
                            insuredClientNoDuplicatePinRule(index)
                          ]}
                          validateFirst
                        >
                          <Input
                            disabled={index === 0 && policyHolderIsAlsoInsured}
                            onChange={e => handleInsuredClientPinChange(e.target.value, index)}
                          />
                        </Form.Item>
                      </Col>
                    ) : null}

                    {selectedInstitutionEnum === InstitutionEnum.GENERALI && (
                      <Col span={colSpan}>
                        <AcademicDegreeSelect
                          formItemProps={{
                            name: ["clientsData", "insuredClients", index, "academicDegree"],
                            label: t("calc.travel.attrs.clientsData.insuredClients.academicDegree")
                          }}
                          selectProps={{ allowClear: true, disabled: index === 0 && policyHolderIsAlsoInsured }}
                        />
                      </Col>
                    )}

                    <Col span={colSpan}>
                      <Form.Item
                        name={["clientsData", "insuredClients", index, "firstName"]}
                        label={t("calc.travel.attrs.clientsData.insuredClients.firstName")}
                        rules={[
                          validations.notBlank,
                          validations.size(1, 255),
                          validations.pattern(regexPatterns.uppercaseWordRegex)
                        ]}
                      >
                        <Input disabled={index === 0 && policyHolderIsAlsoInsured} />
                      </Form.Item>
                    </Col>

                    <Col span={colSpan}>
                      <Form.Item
                        name={["clientsData", "insuredClients", index, "lastName"]}
                        label={t("calc.travel.attrs.clientsData.insuredClients.lastName")}
                        rules={[
                          validations.notBlank,
                          validations.size(1, 255),
                          validations.pattern(regexPatterns.uppercaseWordRegex)
                        ]}
                      >
                        <Input disabled={index === 0 && policyHolderIsAlsoInsured} />
                      </Form.Item>
                    </Col>

                    {selectedInstitutionEnum === InstitutionEnum.GENERALI && (
                      <Col span={colSpan}>
                        <AcademicDegreeAfterSelect
                          formItemProps={{
                            name: ["clientsData", "insuredClients", index, "academicDegreeAfter"],
                            label: t("calc.travel.attrs.clientsData.insuredClients.academicDegreeAfter")
                          }}
                          selectProps={{ allowClear: true, disabled: index === 0 && policyHolderIsAlsoInsured }}
                        />
                      </Col>
                    )}

                    {selectedInstitutionEnum === InstitutionEnum.UNION &&
                      calcData.generalData.insuranceType === TravelInsuranceType.SHORT_TERM &&
                      insuredClients?.[index]?.discountType && (
                        <Col span={colSpan}>
                          <Form.Item
                            name={["clientsData", "insuredClients", index, "discountIdentifier"]}
                            label={
                              <LabelWithPopover
                                label={t("calc.travel.attrs.clientsData.insuredClients.discountIdentifier")}
                                popoverTitle={t("calc.travel.helpers.discount.title", {
                                  type: t("calc.travel.enums.clientDiscountType." + insuredClients[index]?.discountType)
                                })}
                                popoverContent={
                                  <p style={{ maxWidth: "300px" }}>
                                    {t("calc.travel.helpers.discount.type." + insuredClients[index]?.discountType)}
                                  </p>
                                }
                              />
                            }
                            rules={[validations.notBlank, validations.size(1, 255)]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      )}
                  </Row>
                </React.Fragment>
              ));
            }}
          </Form.Item>
        )}
      </Card>

      <ClientDrawerForm<ClientFormType>
        open={clientFormOpen}
        client={processedClientFormType ? getClientFromProps(processedClientFormType) : undefined}
        initialClientType={
          processedClientFormType ? resolveClientTypeByClientFormType(processedClientFormType) : undefined
        }
        initialIdentifier={processedClientFormType ? getClientFormIdentifier(processedClientFormType) : undefined}
        requireIdCardNumber
        formType={processedClientFormType}
        violationErrors={props.clientsViolationErrors}
        onFormSubmit={handleClientFormSubmit}
      />
    </>
  );
};

export default TravelGenClientsDataSection;
