import { Select } from "antd";
import { selectStandardProps, selectTagsStandardProps } from "../../../../common/utils/formUtils";
import { UUID } from "../../../../typings/global";
import { CommissionsSettingsLevelType, commissionsSettingsLevelTypeTMap } from "../enums";
import CommissionsLevelTypeTag from "./CommissionsLevelTypeTag";

type Props<T extends boolean> = {
  value?: T extends true ? UUID[] : UUID;
  allowClear?: boolean;
  isMultiple?: T;
  dataSource?: CommissionsSettingsLevelType[];
  onChange?: (data: T extends true ? UUID[] : UUID) => void;
  style?: React.CSSProperties;
};

export const SelectCommissionsSettingsLevelType = <T extends boolean>({
  value,
  style,
  allowClear,
  isMultiple,
  dataSource,
  onChange
}: Props<T>) => {
  const data = dataSource ?? Object.values(CommissionsSettingsLevelType);

  return (
    <Select
      {...selectTagsStandardProps(commissionsSettingsLevelTypeTMap)}
      style={style}
      {...selectStandardProps}
      allowClear={allowClear}
      mode={isMultiple ? "multiple" : undefined}
      tagRender={props => (
        <CommissionsLevelTypeTag
          type={CommissionsSettingsLevelType[props.value as keyof typeof CommissionsSettingsLevelType]}
          closable={props.closable}
          onClose={props.onClose}
        />
      )}
      options={data.map(type => ({
        value: type,
        label: <CommissionsLevelTypeTag type={type} />
      }))}
      onChange={onChange}
      value={value}
    />
  );
};
