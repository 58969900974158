import { Tabs } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import AntIcon from "../../../common/components/icons/AntIcon";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import { TabKeyRequest } from "../../../common/types";
import { useFilterSearchParams } from "../../../common/utils/hooksUtils";
import { QueryKeys, URLQuery } from "../../../common/utils/queryUtils";
import { cssVariables } from "../../../common/utils/utils";
import { getEnumerationsActions } from "../../enumerations/ducks";
import ProductForm from "../components/forms/ProductForm";
import ProductGroupForm from "../components/forms/ProductGroupForm";
import { ProductFilterView } from "../components/views/ProductFilterView";
import { ProductGroupFilterView } from "../components/views/ProductGroupFilterView";
import { ProductGroupTableView } from "../components/views/ProductGroupTableView";
import { ProductTableView } from "../components/views/ProductTableView";
import { Product, ProductGroup } from "../types";

export const PRODUCT_PAGE_TABS = {
  PRODUCT_GROUPS: "product-groups",
  PRODUCTS: "products"
};

export const ProductsContainer = () => {
  const { t } = useTranslation();

  const [productGroupFormOpen, setProductGroupFormOpen] = useState<boolean>(false);
  const [productGroupToUpdate, setProductGroupToUpdate] = useState<ProductGroup>();

  const [productFormOpen, setProductFormOpen] = useState<boolean>(false);
  const [productToUpdate, setProductToUpdate] = useState<Product>();

  const navigate = useNavigate();
  const searchTabParam = useFilterSearchParams<TabKeyRequest>({ params: [QueryKeys.TAB] }).tab;

  const activeTab =
    searchTabParam && [PRODUCT_PAGE_TABS.PRODUCT_GROUPS, PRODUCT_PAGE_TABS.PRODUCTS].includes(searchTabParam)
      ? searchTabParam
      : PRODUCT_PAGE_TABS.PRODUCTS;

  const handleTabKeyChange = (tab: string) => {
    navigate({ search: URLQuery.searchParamsToString({ searchParams: { [QueryKeys.TAB]: tab } }) }, { replace: true });
  };

  const dispatch = useDispatch();
  const actions = useMemo(
    () => bindActionCreators({ getEnumerations: getEnumerationsActions.request }, dispatch),
    [dispatch]
  );

  useEffect(() => {
    if (!searchTabParam || activeTab !== searchTabParam) {
      navigate(
        { search: URLQuery.searchParamsToString({ searchParams: { [QueryKeys.TAB]: PRODUCT_PAGE_TABS.PRODUCTS } }) },
        { replace: true }
      );
    }
  }, [searchTabParam]);

  const handleProductGroupCreateClick = () => {
    setProductGroupToUpdate(undefined);
    setProductGroupFormOpen(true);
  };

  const handleProductGroupUpdateClick = (group: ProductGroup) => {
    setProductGroupToUpdate(group);
    setProductGroupFormOpen(true);
  };

  const handleProductGroupFormCancel = (): void => {
    setProductGroupFormOpen(false);
    setProductGroupToUpdate(undefined);
    actions.getEnumerations();
  };

  const handleProductCreateClick = () => {
    setProductToUpdate(undefined);
    setProductFormOpen(true);
  };

  const handleProductUpdateClick = (product: Product) => {
    setProductToUpdate(product);
    setProductFormOpen(true);
  };

  const handleProductFormCancel = (): void => {
    setProductFormOpen(false);
    setProductToUpdate(undefined);
    actions.getEnumerations();
  };

  return (
    <ContentWrapper>
      <Tabs
        tabBarStyle={{
          backgroundColor: "white",
          padding: "0 24px",
          marginBottom: 24,
          borderRadius: 8,
          boxShadow: `0 1px 3px 0 ${cssVariables.colorShadow}, 0 1px 2px 0 ${cssVariables.colorShadow}`,
          border: `1px solid ${cssVariables.colorHover}`,
          overflow: "hidden"
        }}
        activeKey={activeTab}
        onChange={handleTabKeyChange}
        items={[
          {
            key: PRODUCT_PAGE_TABS.PRODUCT_GROUPS,
            icon: <AntIcon type="group" />,
            label: t("product.titles.productGroups"),
            children: (
              <div>
                <ProductGroupFilterView onCreateClick={handleProductGroupCreateClick} />

                <ProductGroupTableView onUpdateClick={handleProductGroupUpdateClick} />

                <ProductGroupForm
                  open={productGroupFormOpen}
                  onFormCancel={handleProductGroupFormCancel}
                  productGroup={productGroupToUpdate}
                />
              </div>
            )
          },
          {
            key: PRODUCT_PAGE_TABS.PRODUCTS,
            icon: <AntIcon type="unordered-list" />,
            label: t("product.titles.products"),
            children: (
              <div>
                <ProductFilterView onCreateClick={handleProductCreateClick} />

                <ProductTableView onUpdateClick={handleProductUpdateClick} />

                <ProductForm open={productFormOpen} onFormCancel={handleProductFormCancel} product={productToUpdate} />
              </div>
            )
          }
        ]}
      />
    </ContentWrapper>
  );
};
