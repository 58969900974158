import { AutoComplete, Button, Card, Checkbox, Flex, Form, Input, Modal } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import ActionButton from "../../../../common/components/buttons/ActionButton";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import AntIcon from "../../../../common/components/icons/AntIcon";
import { ContactType } from "../../../../common/modules/enums";
import { Contact } from "../../../../common/modules/types";
import { resolveFormValidationError, selectFilterFunction } from "../../../../common/utils/formUtils";
import { isDefined } from "../../../../common/utils/utils";
import { validations } from "../../../../common/utils/validationUtils";
import { createCalcDraftActions, updateCalcDraftActions } from "../../drafts/ducks";
import { CreateUpdateCalcDraft } from "../../drafts/types";
import { OfferType } from "../enums";
import { generateRealtyOfferActions, sendRealtyOfferActions } from "../realty/ducks";
import { OperationStage } from "../realty/enums";
import type { RealtyCalc, RealtyCalcDraft, RealtyFormClients } from "../realty/types";
import { generateTravelOfferActions, sendTravelOfferActions } from "../travel/ducks";
import type { TravelCalc, TravelCalcDraft } from "../travel/types";
import type { CalcResult, Offer } from "../types";
import {
  isRealtyCalcData,
  isRealtyOffer,
  isTravelCalcData,
  isTravelOffer,
  isVehicleCalcData,
  isVehicleOffer
} from "../utils";
import { generateVehicleOfferActions, sendVehicleOfferActions } from "../vehicle/ducks";
import type { VehicleCalc, VehicleCalcDraft, VehicleFormClients } from "../vehicle/types";
import { resolveVehicleOfferType, resolveVehiclePolicyHolder } from "../vehicle/utils";

type Props = {
  isUpdate: boolean;
  formType?: "createUpdateDraft" | "createOffer" | "sendOffer";
  calcData: TravelCalc | RealtyCalc | VehicleCalc;
  calcResults: CalcResult[];
  operationStage: OperationStage;
  draft?: TravelCalcDraft | VehicleCalcDraft | RealtyCalcDraft;
  onFinish: VoidFunction;
  clients?: RealtyFormClients | VehicleFormClients;
};

export const DraftOfferForm = ({
  isUpdate,
  formType,
  draft,
  onFinish,
  calcData,
  calcResults,
  operationStage,
  clients
}: Props) => {
  const { t } = useTranslation();

  const [form] = Form.useForm<Offer<TravelCalc | RealtyCalc | VehicleCalc>>();

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          generateTravelOffer: generateTravelOfferActions.request,
          sendTravelOffer: sendTravelOfferActions.request,
          generateRealtyOffer: generateRealtyOfferActions.request,
          sendRealtyOffer: sendRealtyOfferActions.request,
          generateVehicleOffer: generateVehicleOfferActions.request,
          sendVehicleOffer: sendVehicleOfferActions.request,
          createCalcDraft: createCalcDraftActions.request,
          updateCalcDraft: updateCalcDraftActions.request
        },
        dispatch
      ),
    [dispatch]
  );

  useEffect(() => {
    if (formType) {
      form.setFieldsValue({
        clientName: getInitialClientName(),
        calcRequest: calcData,
        calcResponse: { results: calcResults },
        type: getDefaultOfferType()
      });
    }
  }, [formType, isUpdate]);

  const processDraftCreateOrUpdate = (values: Offer<TravelCalc | RealtyCalc | VehicleCalc>): void => {
    const newDraft: CreateUpdateCalcDraft = {
      draftData: calcData,
      calcResponse: { results: calcResults },
      clientName: values.clientName ?? "",
      calcType: calcData.type,
      stage: operationStage
    };

    if (draft && isUpdate) {
      actions.updateCalcDraft({
        id: draft.id,
        object: { ...newDraft, optimisticLockVersion: draft.optimisticLockVersion }
      });
    } else {
      actions.createCalcDraft(newDraft);
    }
  };

  const processCreateOffer = (values: Offer<TravelCalc | RealtyCalc | VehicleCalc>) => {
    if (isTravelOffer(values)) {
      actions.generateTravelOffer(values);
    } else if (isRealtyOffer(values)) {
      actions.generateRealtyOffer(values);
    } else if (isVehicleOffer(values)) {
      actions.generateVehicleOffer(values);
    }
  };

  const processSendOffer = (values: Offer<TravelCalc | RealtyCalc | VehicleCalc>) => {
    if (isTravelOffer(values)) {
      actions.sendTravelOffer(values);
    } else if (isRealtyOffer(values)) {
      actions.sendRealtyOffer(values);
    } else if (isVehicleOffer(values)) {
      actions.sendVehicleOffer(values);
    }
  };

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        if (formType === "createOffer") {
          processCreateOffer(values);
        } else if (formType === "createUpdateDraft") {
          processDraftCreateOrUpdate(values);
        } else {
          processSendOffer(values);
        }

        onFinish();
      })
      .catch(resolveFormValidationError);
  };

  const getTitle = () => {
    if (formType === "createOffer") {
      return t("calc.titles.offerCreate");
    } else if (formType === "createUpdateDraft") {
      return t("calc.titles.draftCreate");
    } else {
      return t("calc.titles.offerSend");
    }
  };

  const getOkText = () => {
    if (formType === "createOffer") {
      return t("calc.actions.generateOffer");
    } else if (formType === "createUpdateDraft") {
      return t("calc.actions.saveDraft");
    } else {
      return t("calc.actions.sendOffer");
    }
  };

  const handleGenerateRiskOffer = (event: CheckboxChangeEvent) => {
    if (event.target.checked) {
      form.setFieldValue("type", OfferType.REALTY_RISKS);
    } else {
      form.setFieldValue("type", OfferType.REALTY);
    }
  };

  const getDefaultOfferType = () => {
    if (isTravelCalcData(calcData)) {
      return OfferType.TRAVEL;
    } else if (isRealtyCalcData(calcData)) {
      return OfferType.REALTY;
    } else {
      return resolveVehicleOfferType(calcData.type);
    }
  };

  const getInitialClientName = () => {
    if (formType === "createUpdateDraft" && isUpdate) {
      return draft?.clientName;
    }

    return isRealtyCalcData(calcData)
      ? clients?.policyHolder?.aggregatedName
      : (clients as VehicleFormClients)?.holder?.aggregatedName;
  };

  const getAutocompleteEmailOptions = () => {
    let contacts: Contact[] = [];

    if (isRealtyCalcData(calcData) && clients?.policyHolder?.contacts) {
      contacts = clients.policyHolder.contacts;
    } else if (isVehicleCalcData(calcData) && clients) {
      contacts = resolveVehiclePolicyHolder(clients, calcData.clientsData.policyHolderRelation)?.contacts ?? [];
    }

    return [...new Set(contacts.filter(c => c.type === ContactType.EMAIL && !!c.value?.trim()).map(c => c.value))].map(
      email => ({ value: email, label: email })
    );
  };

  return (
    <Modal
      title={getTitle()}
      open={isDefined(formType)}
      okText={getOkText()}
      cancelText={t("common.close")}
      centered
      onOk={handleFormSubmit}
      onCancel={onFinish}
      afterClose={() => form.resetFields()}
    >
      <Form form={form} layout="vertical" name="draftOfferForm">
        <HiddenInput name="calcRequest" />
        <HiddenInput name="calcResponse" />
        <HiddenInput name="type" />

        <Form.Item
          name="clientName"
          label={t("calc.helpers.clientName")}
          rules={[validations.notBlank, validations.size(1, 255)]}
          initialValue={getInitialClientName()}
        >
          <Input />
        </Form.Item>

        {formType === "sendOffer" ? (
          <Form.List name="emails" initialValue={[""]}>
            {(fields, { add, remove }) => (
              <Card
                style={{ marginBottom: 16 }}
                size="small"
                title={t("common.toWho")}
                extra={
                  <ActionButton type="text" icon="plus" size="small" variant="outlined" onClick={() => add("")}>
                    {t("calc.actions.addEmail")}
                  </ActionButton>
                }
              >
                {fields.map(({ key, ...field }) => (
                  <Flex key={field.name}>
                    <Form.Item
                      {...field}
                      style={{ flex: 1 }}
                      rules={[
                        validations.notBlank,
                        validations.size(1, 254),
                        validations.email,
                        validations.noRepeatedValue("emails")
                      ]}
                    >
                      {field.name === 0 && clients ? (
                        <AutoComplete<string>
                          popupMatchSelectWidth={false}
                          placeholder="email@email.com"
                          options={getAutocompleteEmailOptions()}
                          filterOption={selectFilterFunction}
                        />
                      ) : (
                        <Input placeholder="email@email.com" />
                      )}
                    </Form.Item>

                    {field.name !== 0 ? (
                      <Button
                        style={{ marginLeft: 8 }}
                        type="text"
                        onClick={() => remove(field.name)}
                        icon={<AntIcon type="close" />}
                      />
                    ) : undefined}
                  </Flex>
                ))}
              </Card>
            )}
          </Form.List>
        ) : undefined}

        {formType === "sendOffer" ? (
          <Form.Item name="sendToLoggedUser" initialValue={false} valuePropName="checked" noStyle>
            <Checkbox style={{ marginBottom: 16 }}>{t("calc.actions.sendToMe")}</Checkbox>
          </Form.Item>
        ) : undefined}

        {formType !== "createUpdateDraft" && isRealtyCalcData(calcData) ? (
          <div>
            <Checkbox onChange={handleGenerateRiskOffer}>
              {formType === "sendOffer"
                ? t("calc.realty.actions.sendRisksOffer")
                : t("calc.realty.actions.saveRisksOffer")}
            </Checkbox>
          </div>
        ) : undefined}
      </Form>
    </Modal>
  );
};
