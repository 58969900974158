import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { PageSizes } from "../../../../common/constants";
import ContentWrapper from "../../../../common/modules/wrappers/ContentWrapper";
import { appendSearchParamsToURL, numberOrZero } from "../../../../common/utils/utils";
import { CommissionsLevelForm } from "../components/forms/CommissionsLevelForm";
import CommissionsLevelFilterView from "../components/views/list/CommissionsLevelFilterView";
import CommissionsLevelTableView from "../components/views/list/CommissionsLevelTableView";
import {
  createCommissionsLevelActions,
  deleteStateCommissionsLevelsPageAction,
  filterCommissionsLevelsActions,
  selectCommissionsLevelsCurrentPage
} from "../ducks";
import { CommissionsSettingsLevelType } from "../enums";
import { CommissionsSettingsLevelFilterPageRequest } from "../types";

export const CommissionsLevelListContainer = () => {
  const navigate = useNavigate();
  const levelsPage = useSelector(selectCommissionsLevelsCurrentPage);
  const [urlParams] = useSearchParams();

  const [levelFormOpen, setLevelFormOpen] = useState<boolean>(false);

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          filterCommissionsLevels: filterCommissionsLevelsActions.request,
          createCommissionsLevel: createCommissionsLevelActions.request,
          deleteStateCommissionsLevelsPage: deleteStateCommissionsLevelsPageAction
        },
        dispatch
      ),
    [dispatch]
  );

  useEffect(() => {
    actions.filterCommissionsLevels({
      pageIndex: numberOrZero(urlParams.get("pageIndex")),
      pageSize: PageSizes.LARGE,
      keyword: urlParams.get("keyword") ?? undefined,
      type: urlParams.get("type") as CommissionsSettingsLevelType
    });
    return () => {
      actions.deleteStateCommissionsLevelsPage();
    };
  }, []);

  const handleFilterSubmit = (filter: CommissionsSettingsLevelFilterPageRequest): void => {
    navigate(appendSearchParamsToURL({ ...filter, pageIndex: undefined, keyword: filter.keyword }), {
      replace: true
    });
    actions.filterCommissionsLevels({ pageIndex: 0, pageSize: levelsPage.pageSize, ...filter });
  };

  const handleCreateClick = (): void => {
    setLevelFormOpen(true);
  };

  const handleTablePageChange = (pageNumber: number): void => {
    const { pageSize, keyword, type } = levelsPage;
    navigate(appendSearchParamsToURL({ pageIndex: pageNumber - 1 }), { replace: true });
    actions.filterCommissionsLevels({ pageIndex: pageNumber - 1, pageSize, keyword, type });
  };

  const handleFormCancel = (): void => {
    setLevelFormOpen(false);
  };

  return (
    <ContentWrapper>
      <CommissionsLevelFilterView
        currentFilter={levelsPage}
        onFilterSubmit={handleFilterSubmit}
        onCreateClick={handleCreateClick}
      />

      <CommissionsLevelTableView levelsPage={levelsPage} onPageChange={handleTablePageChange} />

      <CommissionsLevelForm
        open={levelFormOpen}
        onCreate={actions.createCommissionsLevel}
        onFormCancel={handleFormCancel}
      />
    </ContentWrapper>
  );
};
