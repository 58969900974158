import { Card } from "antd";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import t from "../../../../app/i18n";
import BackNavigationArrow from "../../../../common/components/views/BackNavigationArrow";
import ContentWrapper from "../../../../common/modules/wrappers/ContentWrapper";
import DisplayWrapper from "../../../../common/modules/wrappers/DisplayWrapper";
import { ActionProps, EntityIdObject, RootState } from "../../../../common/types";
import { requests } from "../api";
import CalcSettingsForm from "../components/forms/CalcSettingsForm";
import {
  deleteCalcSettingsActions,
  deleteCalcSettingsAttachmentConfActions,
  deleteStateCalcSettingsDetailAction,
  getCalcSettingsActions,
  selectCalcSettingsDetail,
  updateCalcSettingsActions,
  uploadCalcSettingsAttachmentConfsActions
} from "../ducks";
import { CalcSettings } from "../types";

interface StateProps {
  calcSettings?: CalcSettings;
}

interface ActionsMap {
  getCalcSettings: typeof getCalcSettingsActions.request;
  updateCalcSettings: typeof updateCalcSettingsActions.request;
  deleteCalcSettings: typeof deleteCalcSettingsActions.request;
  uploadAttachmentConf: typeof uploadCalcSettingsAttachmentConfsActions.request;
  deleteAttachmentConf: typeof deleteCalcSettingsAttachmentConfActions.request;
  deleteStateCalcSettingsDetail: typeof deleteStateCalcSettingsDetailAction;
}

type Props = StateProps & ActionProps<ActionsMap>;

const CalcSettingsDetailContainer = ({ calcSettings, ...props }: Props) => {
  const { id } = useParams<EntityIdObject>() as { id: string };

  useEffect(() => {
    if (!calcSettings) {
      props.actions.getCalcSettings({ id });
    }

    return () => {
      props.actions.deleteStateCalcSettingsDetail();
    };
  }, []);

  return (
    <ContentWrapper>
      <DisplayWrapper itemLoaded={!!calcSettings} notFoundCheckRequest={requests.GET_CALC_SETTINGS}>
        {calcSettings ? (
          <Card
            className="card-box"
            title={
              <BackNavigationArrow>
                <h2>
                  {t("calc.settings.titles.settings", {
                    calcType: t("calc.enums.calcType." + calcSettings.type),
                    institution: calcSettings.institution.name
                  })}
                </h2>
              </BackNavigationArrow>
            }
          >
            <CalcSettingsForm
              calcType={calcSettings.type}
              calcSettings={calcSettings}
              onUpdateCalcSettings={props.actions.updateCalcSettings}
              onDeleteCalcSettings={props.actions.deleteCalcSettings}
              onUploadAttachmentConf={props.actions.uploadAttachmentConf}
              onDeleteAttachmentConf={props.actions.deleteAttachmentConf}
            />
          </Card>
        ) : undefined}
      </DisplayWrapper>
    </ContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  calcSettings: selectCalcSettingsDetail(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      getCalcSettings: getCalcSettingsActions.request,
      updateCalcSettings: updateCalcSettingsActions.request,
      deleteCalcSettings: deleteCalcSettingsActions.request,
      uploadAttachmentConf: uploadCalcSettingsAttachmentConfsActions.request,
      deleteAttachmentConf: deleteCalcSettingsAttachmentConfActions.request,
      deleteStateCalcSettingsDetail: deleteStateCalcSettingsDetailAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, Record<string, any>, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(CalcSettingsDetailContainer);
