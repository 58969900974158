import { Button, Card, Col, Form, Input, Row } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AntIcon from "../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../common/constants";
import { SearchPageRequest } from "../../../../common/types";
import { useFilterSearchParams } from "../../../../common/utils/hooksUtils";
import { URLQuery } from "../../../../common/utils/queryUtils";
import { validationConstants, validations } from "../../../../common/utils/validationUtils";

type Props = {
  onCreateClick: VoidFunction;
};

export const ProductGroupFilterView = ({ onCreateClick }: Props) => {
  const { t } = useTranslation();

  const [form] = Form.useForm<SearchPageRequest>();

  const navigate = useNavigate();
  const keywordSearchParam = useFilterSearchParams().keyword;

  const colSpan = 6;

  const handleSearchSubmit = (value: SearchPageRequest) => {
    navigate(
      {
        search: URLQuery.searchParamsToString({
          searchParams: {
            keyword: value.keyword,
            pageIndex: undefined
          },
          append: true
        })
      },
      { replace: true }
    );
  };

  useEffect(() => {
    if (keywordSearchParam) {
      form.setFieldValue("keyword", keywordSearchParam);
    }
  }, [keywordSearchParam]);

  return (
    <Card
      className="card-box"
      title={<h2>{t("product.titles.productGroupsAdmin")}</h2>}
      extra={
        <Button type="primary" icon={<AntIcon type="plus" />} onClick={onCreateClick}>
          {t("product.actions.createProductGroup")}
        </Button>
      }
    >
      <Form form={form} onFinish={handleSearchSubmit} layout="vertical" name="productGroupSearchForm">
        <Row gutter={rowGutter} justify="center">
          <Col span={colSpan}>
            <Form.Item
              name="keyword"
              label={t("common.searchKeyword")}
              rules={[
                validations.size(
                  validationConstants.SEARCH_KEYWORD_MIN_LENGTH,
                  validationConstants.SEARCH_KEYWORD_MAX_LENGTH
                )
              ]}
            >
              <Input allowClear placeholder={t("product.helpers.productGroupSearchHint")} />
            </Form.Item>
          </Col>

          <Col className="no-title-space">
            <Form.Item>
              <Button type="primary" htmlType="submit" icon={<AntIcon type="search" />}>
                {t("common.filter")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
