import { Card, Col, Form, Radio, Row } from "antd";
import { FieldData } from "rc-field-form/lib/interface";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { SecondaryButton } from "../../../../../../common/components/buttons/SecondaryButton";
import AntIcon, { type AntIconType } from "../../../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../../../common/constants";
import type { FieldConstraintViolation } from "../../../../../../common/types";
import { resolveFormValidationError, setErrorsToForm } from "../../../../../../common/utils/formUtils";
import { useScrollToTopOnLoad } from "../../../../../../common/utils/hooksUtils";
import messageUtils from "../../../../../../common/utils/messageUtils";
import { isDeepEqual, isDefined } from "../../../../../../common/utils/utils";
import { validations } from "../../../../../../common/utils/validationUtils";
import { CalcValidationGroup, ClientFormType, ClientType } from "../../../../../client/enums";
import {
  CreateUpdateContractClient,
  LegalClient,
  NaturalClient,
  SelfEmployedClient
} from "../../../../../client/types";
import {
  clientToCreateUpdateContractClient,
  createUpdateContractClientToClient,
  processClientsDataViolations,
  resolveClientIdentifier,
  useClientValidation
} from "../../../../../client/utils";
import { DashboardNoticePosition } from "../../../../../dashboard/enums";
import { CalcType } from "../../../../enums";
import { CALC_ROUTE_PATHS } from "../../../../paths";
import { CalcNavigation } from "../../../components/CalcNavigation";
import CalcNoticesIconView from "../../../components/CalcNoticesIconView";
import { BuildingType, NearbyBuildingType } from "../../enums";
import { RealtyCalc, RealtyFormClients } from "../../types";
import { realtyCalcPageTitle } from "../../utils";
import RealtyCalcApartmentSection from "./sections/RealtyCalcApartmentSection";
import RealtyCalcGeneralSection from "./sections/RealtyCalcGeneralSection";
import RealtyCalcHouseholdRealtySection from "./sections/RealtyCalcHouseholdRealtySection";
import RealtyCalcHouseSection from "./sections/RealtyCalcHouseSection";
import RealtyCalcInsuranceSection from "./sections/RealtyCalcInsuranceSection";

interface Props {
  calcData?: RealtyCalc;
  formErrors: FieldConstraintViolation[];
  setIsFormChanged: VoidFunction;
  policyHolder?: NaturalClient | SelfEmployedClient | LegalClient;
  onCalculationFormSubmit: (calcData: RealtyCalc) => void;
  onClientChange: (
    type: ClientFormType,
    client?: NaturalClient | SelfEmployedClient | LegalClient
  ) => RealtyFormClients;
  onResetCalculatorClick: () => void;
  goToCalcResults?: VoidFunction;
}

const POLICY_HOLDER_INDEX_MAP = new Map<ClientFormType, number>([[ClientFormType.POLICY_HOLDER, 0]]);

export const RealtyCalcWrapper = ({
  calcData,
  formErrors,
  setIsFormChanged,
  policyHolder,
  onCalculationFormSubmit,
  onClientChange,
  onResetCalculatorClick,
  goToCalcResults
}: Props) => {
  useScrollToTopOnLoad();
  const { t } = useTranslation();

  const [form] = Form.useForm<RealtyCalc>();
  const buildingType = Form.useWatch(["generalBuildingData", "type"], form);
  const realtyInsuranceEnabled = Form.useWatch(["realtyInsuranceEnabled"], form);
  const householdInsuranceEnabled = Form.useWatch(["householdInsuranceEnabled"], form);

  const clientValidation = useClientValidation();

  const [clientsViolationErrors, setClientsViolationErrors] = useState<Map<ClientFormType, FieldConstraintViolation[]>>(
    new Map()
  );

  useEffect(() => {
    if (calcData) {
      const { realtyInsuranceData, householdInsuranceData, clientsData } = calcData;

      const policyHolder = isDefined<number>(clientsData.policyHolderIndex)
        ? createUpdateContractClientToClient(
            clientsData.clients?.[clientsData.policyHolderIndex] as CreateUpdateContractClient
          )
        : undefined;

      form.setFieldsValue({
        ...calcData,
        realtyInsuranceEnabled: !!realtyInsuranceData,
        householdInsuranceEnabled: !!householdInsuranceData,
        realtyInsuranceData: realtyInsuranceData
          ? {
              ...realtyInsuranceData,
              nearbyBuildingReinsurances: realtyInsuranceData.nearbyBuildingReinsurances.map(r => ({
                ...r,
                placeOfGarageSameAsBuilding:
                  r.type === NearbyBuildingType.DETACHED_GARAGE && !isDefined(r.placeOfGarageSameAsBuilding)
                    ? true
                    : r.placeOfGarageSameAsBuilding
              }))
            }
          : undefined,
        clientsData: {
          ...calcData.clientsData,
          clients: undefined,
          policyHolderIndex: undefined,
          policyHolderIdentifier:
            calcData.clientsData.policyHolderIdentifier ||
            (policyHolder ? resolveClientIdentifier(policyHolder) : undefined)
        }
      });
    } else {
      form.resetFields();
    }
  }, [calcData]);

  useEffect(() => {
    if (!formErrors.length) {
      return;
    }

    if (formErrors.some(formError => formError.fieldPath.startsWith("clientsData.clients"))) {
      setClientsViolationErrors(
        processClientsDataViolations(POLICY_HOLDER_INDEX_MAP, "clientsData.clients", formErrors)
      );
    }

    setErrorsToForm(form, "calc.realty.attrs", formErrors);
  }, [formErrors]);

  useEffect(() => {
    if (clientValidation.errorResponse?.violations?.length) {
      setClientsViolationErrors(
        processClientsDataViolations(
          POLICY_HOLDER_INDEX_MAP,
          "clientsData.clients",
          clientValidation.errorResponse?.violations
        )
      );
    }
  }, [clientValidation.errorResponse]);

  useEffect(() => {
    return () => {
      clientValidation.onErrorResponseDelete();
    };
  }, []);

  const handleFormFieldsChange = (changedFields?: FieldData[]): void => {
    if (changedFields?.length) {
      setIsFormChanged();
    }
  };

  const handleCalculationFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        if (!policyHolder || clientsViolationErrors.has(ClientFormType.POLICY_HOLDER)) {
          messageUtils.errorNotification({ message: t("common.error"), description: t("calc.validations.formError") });
        } else {
          onCalculationFormSubmit(processAndGetCalcFormData(values));
        }
      })
      .catch(errorInfo => {
        messageUtils.errorNotification({ message: t("common.error"), description: t("calc.validations.formError") });
        resolveFormValidationError(errorInfo);
      });
  };

  const handlePolicyHolderChange = (client?: NaturalClient | SelfEmployedClient | LegalClient): void => {
    if (!isDeepEqual(policyHolder, client)) {
      setIsFormChanged();
    }

    onClientChange(ClientFormType.POLICY_HOLDER, client);

    if (client) {
      clientValidation.onValidate({
        prefix: `clientsData.clients[0]`,
        client: clientToCreateUpdateContractClient(client),
        validationGroup: CalcValidationGroup.CALCULATE
      });

      if (client.type !== ClientType.NATURAL) {
        form.setFieldsValue({
          householdInsuranceEnabled: false,
          generalInsuranceData: {
            crossSelling: {
              unionHealthContract: false
            }
          }
        });
      }
    }
  };

  const handlePolicyHolderViolationErrorsDelete = (): void => {
    setClientsViolationErrors(new Map<ClientFormType, FieldConstraintViolation[]>());
  };

  const processAndGetCalcFormData = (calcData?: RealtyCalc): RealtyCalc => {
    const processedValues = calcData ? { ...calcData } : (form.getFieldsValue() as RealtyCalc);
    processedValues.type = CalcType.REALTY;

    delete processedValues.realtyInsuranceEnabled;
    if (processedValues.realtyInsuranceData && !processedValues.realtyInsuranceData.nearbyBuildingReinsurances) {
      processedValues.realtyInsuranceData.nearbyBuildingReinsurances = [];
    }

    delete processedValues.householdInsuranceEnabled;
    if (processedValues.householdInsuranceData && !processedValues.householdInsuranceData.equipmentReinsurances) {
      processedValues.householdInsuranceData.equipmentReinsurances = [];
    }

    processedValues.clientsData = {
      ...processedValues.clientsData,
      clients: policyHolder ? [clientToCreateUpdateContractClient(policyHolder)] : [],
      policyHolderIndex: POLICY_HOLDER_INDEX_MAP.get(ClientFormType.POLICY_HOLDER) as number,
      policyHolderIdentifier: undefined
    };

    return processedValues;
  };

  const resolveBuildingTypeIcon = (buildingType: BuildingType): AntIconType => {
    switch (buildingType) {
      case BuildingType.HOUSE:
        return "home";
      case BuildingType.APARTMENT:
        return "hdd";
      case BuildingType.RECREATIONAL_BUILDING:
        return "shop";
    }
  };

  const renderBuildingTypeSection = () => {
    if (buildingType === BuildingType.APARTMENT) {
      return <RealtyCalcApartmentSection form={form} />;
    }

    return <RealtyCalcHouseSection />;
  };

  return (
    <>
      <Form form={form} layout="vertical" name="realtyCalcForm" onFieldsChange={handleFormFieldsChange}>
        <Card
          className="card-box card-box--inner-extra"
          title={<h2>{realtyCalcPageTitle(buildingType, realtyInsuranceEnabled, householdInsuranceEnabled)}</h2>}
          extra={
            <>
              <CalcNoticesIconView position={DashboardNoticePosition.REALTY_CALC} />
              <Link to={CALC_ROUTE_PATHS.realtyDraft.to}>
                <SecondaryButton icon={<AntIcon type="form" />}>{t("calc.actions.showDrafts")}</SecondaryButton>
              </Link>
            </>
          }
        >
          <Row gutter={rowGutter} justify="center">
            <Col span={24} className="center-align">
              <Form.Item
                name={["generalBuildingData", "type"]}
                rules={[validations.notNull]}
                className="no-bottom-space"
              >
                <Radio.Group size="large" buttonStyle="solid" className="radio-group-icon">
                  {Object.values(BuildingType).map(type => (
                    <Radio.Button value={type} key={type} style={{ width: 185 }}>
                      <AntIcon type={resolveBuildingTypeIcon(type)} />
                      <span>{t("calc.realty.enums.buildingType." + type)}</span>
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Card>

        <RealtyCalcGeneralSection
          form={form}
          policyHolder={policyHolder}
          clientsViolationErrors={clientsViolationErrors}
          onPolicyHolderChange={handlePolicyHolderChange}
          onPolicyHolderViolationErrorsDelete={handlePolicyHolderViolationErrorsDelete}
        />

        {renderBuildingTypeSection()}

        <RealtyCalcHouseholdRealtySection form={form} policyHolderType={policyHolder?.type} />

        <RealtyCalcInsuranceSection policyHolderType={policyHolder?.type} />
      </Form>

      <CalcNavigation
        onCalculate={handleCalculationFormSubmit}
        onResetCalculatorClick={onResetCalculatorClick}
        goToCalcResults={goToCalcResults}
      />
    </>
  );
};
