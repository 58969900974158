import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { PageSizes } from "../../../common/constants";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import { ActionProps, RootState } from "../../../common/types";
import { appendSearchParamsToURL, numberOrZero } from "../../../common/utils/utils";
import { selectRouterLocationSearch } from "../../ducks";
import CoverageLimitForm from "../components/forms/CoverageLimitForm";
import CoverageLimitFilterView from "../components/views/CoverageLimitFilterView";
import CoverageLimitTableView from "../components/views/CoverageLimitTableView";
import {
  createCoverageLimitActions,
  deleteCoverageLimitActions,
  deleteStateCoverageLimitsPageAction,
  filterCoverageLimitsActions,
  selectCoverageLimitsCurrentPage,
  updateCoverageLimitActions
} from "../ducks";
import { CoverageLimit, CoverageLimitFilterPageRequest, CoverageLimitFilterPageResult } from "../types";

interface StateProps {
  coverageLimitsCurrentPage: CoverageLimitFilterPageResult;
  urlSearchQuery: string;
}

interface ActionsMap {
  filterCoverageLimits: typeof filterCoverageLimitsActions.request;
  createCoverageLimit: typeof createCoverageLimitActions.request;
  updateCoverageLimit: typeof updateCoverageLimitActions.request;
  deleteCoverageLimit: typeof deleteCoverageLimitActions.request;
  deleteStateCoverageLimitsPage: typeof deleteStateCoverageLimitsPageAction;
}

const CoverageLimitListContainer = ({
  coverageLimitsCurrentPage,
  urlSearchQuery,
  actions
}: StateProps & ActionProps<ActionsMap>) => {
  const navigate = useNavigate();

  const [limitFormOpen, setLimitFormOpen] = useState<boolean>(false);
  const [limitToUpdate, setLimitToUpdate] = useState<CoverageLimit>();

  useEffect(() => {
    const urlParams = new URLSearchParams(urlSearchQuery);
    actions.filterCoverageLimits({
      pageIndex: numberOrZero(urlParams.get("pageIndex")),
      pageSize: PageSizes.LARGE,
      institutionIds: urlParams.getAll("institutionIds")
    });
    return () => {
      actions.deleteStateCoverageLimitsPage();
    };
  }, []);

  const handleFilterSubmit = (filter: CoverageLimitFilterPageRequest): void => {
    navigate(
      appendSearchParamsToURL({
        ...filter,
        pageIndex: undefined
      }),
      { replace: true }
    );
    actions.filterCoverageLimits({
      pageIndex: 0,
      pageSize: coverageLimitsCurrentPage.pageSize,
      institutionIds: filter.institutionIds
    });
  };

  const handlePageChange = (pageNumber: number): void => {
    const { pageSize, institutionIds } = coverageLimitsCurrentPage;
    navigate(appendSearchParamsToURL({ pageIndex: pageNumber - 1 }), { replace: true });
    actions.filterCoverageLimits({
      pageIndex: pageNumber - 1,
      pageSize,
      institutionIds
    });
  };

  const handleCreateClick = (): void => {
    setLimitFormOpen(true);
  };

  const handleUpdateClick = (limit: CoverageLimit): void => {
    setLimitFormOpen(true);
    setLimitToUpdate(limit);
  };

  const handleFormCancel = (): void => {
    setLimitFormOpen(false);
    setLimitToUpdate(undefined);
  };

  return (
    <ContentWrapper>
      <CoverageLimitFilterView
        initialFilter={coverageLimitsCurrentPage}
        onFilterSubmit={handleFilterSubmit}
        onCreateClick={handleCreateClick}
      />

      <CoverageLimitTableView
        limitsPage={coverageLimitsCurrentPage}
        onPageChange={handlePageChange}
        onUpdateClick={handleUpdateClick}
        onDelete={actions.deleteCoverageLimit}
      />

      <CoverageLimitForm
        coverageLimit={limitToUpdate}
        open={limitFormOpen}
        onCreate={actions.createCoverageLimit}
        onUpdate={actions.updateCoverageLimit}
        onFormCancel={handleFormCancel}
      />
    </ContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  coverageLimitsCurrentPage: selectCoverageLimitsCurrentPage(state),
  urlSearchQuery: selectRouterLocationSearch(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      filterCoverageLimits: filterCoverageLimitsActions.request,
      createCoverageLimit: createCoverageLimitActions.request,
      updateCoverageLimit: updateCoverageLimitActions.request,
      deleteCoverageLimit: deleteCoverageLimitActions.request,
      deleteStateCoverageLimitsPage: deleteStateCoverageLimitsPageAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, Record<string, any>, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(CoverageLimitListContainer);
