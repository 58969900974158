import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../../common/api/ApiRequestAdapter";
import { apiService } from "../../../common/api/apiService";
import { HttpMethod } from "../../../common/api/http";
import { EntityObject } from "../../../common/types";
import { ProductCommissionsSettings, UpdateProductCommissionsSettings } from "./types";

const UPDATE_PRODUCT_COMMISSIONS_SETTINGS = ApiRequestAdapter.create(
  "/products/{0}/commissions-settings",
  HttpMethod.PUT
);

export const productCommissionsSettingsApi = {
  updateProductCommissionsSettings: (
    request: EntityObject<UpdateProductCommissionsSettings>
  ): AxiosPromise<ProductCommissionsSettings> => {
    return apiService.put(UPDATE_PRODUCT_COMMISSIONS_SETTINGS.getParametrizedUrl(request.id), request.object);
  }
};
