import { Alert, Col, DatePicker, Form, Modal, Row } from "antd";
import { useTranslation } from "react-i18next";
import LabelWithTooltip from "../../../../../common/components/form/labels/LabelWithTooltip";
import { ModalSizes, rowGutter } from "../../../../../common/constants";
import {
  datePickerFormItemProps,
  datePickerStandardProps,
  resolveFormValidationError,
  useFormErrorHandler
} from "../../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import { TopAgentType } from "../../../../agent/enums";
import { Agent } from "../../../../agent/types";
import AgentSelect from "../../../../enumerations/components/form/AgentSelect";
import CommissionsLevelSelect from "../../../../enumerations/components/form/CommissionsLevelSelect";
import { requests } from "../../api";
import { updateCommissionsSettingsActions } from "../../ducks";
import { CommissionsSettings, UpdateCommissionsSettings } from "../../types";

interface Props {
  agent: Agent;
  open: boolean;
  settings: CommissionsSettings;
  onUpdate: typeof updateCommissionsSettingsActions.request;
  onFormCancel: () => void;
}

export const CommissionsSettingsAssignLevelForm = ({ agent, open, settings, onUpdate, onFormCancel }: Props) => {
  const { t } = useTranslation();

  const isTopAgentTypePlus = agent?.topAgentType === TopAgentType.PLUS;

  const [form] = Form.useForm<UpdateCommissionsSettings>();
  useFormErrorHandler(form, "commissions.settings.attrs", [requests.UPDATE_COMMISSIONS_SETTINGS]);

  const inProgress = useRequestFinishedCallback([requests.UPDATE_COMMISSIONS_SETTINGS], onFormCancel);

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values =>
        onUpdate({
          id: settings.agent.id,
          object: {
            ...values,
            optimisticLockVersion: settings.optimisticLockVersion,
            includeInBulkPayment: settings.includeInBulkPayment,
            useAsHead: settings.useAsHead,
            unifyPointsCalculation: settings.unifyPointsCalculation
          }
        })
      )
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      width={ModalSizes.MEDIUM}
      open={open}
      title={t("commissions.settings.titles.agentLevelAssign")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <Form form={form} layout="vertical" name="commissionsSettingsAssignLevelForm">
        <Row gutter={rowGutter}>
          <Col span={10}>
            <CommissionsLevelSelect
              formItemProps={{
                name: "commissionsSettingsLevelId",
                label: t("commissions.settings.attrs.commissionsSettingsLevelId"),
                rules: [validations.notNull]
              }}
            />
          </Col>

          {isTopAgentTypePlus ? undefined : (
            <Col span={7}>
              <AgentSelect
                formItemProps={{
                  name: "levelSuperiorAgentId",
                  label: (
                    <LabelWithTooltip
                      label={t("commissions.settings.attrs.levelSuperiorAgentId")}
                      tooltip={t("commissions.settings.helpers.assignLevelSuperiorAgentDesc")}
                    />
                  ),
                  rules: [validations.notNull]
                }}
                optionsProps={{
                  filter: a =>
                    !!(
                      a.accessTreePath &&
                      settings.agent.accessTreePath?.startsWith(a.accessTreePath) &&
                      settings.agent.id !== a.id
                    )
                }}
              />
            </Col>
          )}

          <Col span={7}>
            <Form.Item
              name="levelChangeDate"
              label={t("commissions.settings.attrs.levelChangeDate")}
              rules={[validations.notNull]}
              {...datePickerFormItemProps}
            >
              <DatePicker {...datePickerStandardProps} />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Alert type="info" message={t("commissions.settings.helpers.assignLevelDesc")} showIcon />
    </Modal>
  );
};
