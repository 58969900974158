import { Button, Card, Col, Row } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import t from "../../../../app/i18n";
import AntIcon from "../../../../common/components/icons/AntIcon";
import BackNavigationArrow from "../../../../common/components/views/BackNavigationArrow";
import { PageSizes } from "../../../../common/constants";
import DisplayWrapper from "../../../../common/modules/wrappers/DisplayWrapper";
import ComponentWithPermission from "../../../../common/security/authorization/ComponentWithPermission";
import { Permission } from "../../../../common/security/authorization/enums";
import { ActionProps, RootState, TwoLevelEntityIdObject } from "../../../../common/types";
import { appendSearchParamsToURL, numberOrZero } from "../../../../common/utils/utils";
import { selectRouterLocationSearch } from "../../../ducks";
import { selectProductsClassificationEnums } from "../../../enumerations/ducks";
import { InstitutionWithProducts } from "../../../enumerations/types";
import CommissionForm from "../components/forms/CommissionForm";
import CommissionPostponeForm from "../components/forms/CommissionPostponeForm";
import BatchCommissionTableView from "../components/views/BatchCommissionTableView";
import BatchCommissionFilterView from "../components/views/filter/BatchCommissionFilterView";
import {
  createCommissionActions,
  deleteCommissionActions,
  deleteStateCommissionsPageAction,
  filterCommissionsActions,
  postponeCommissionActions,
  selectCommissionsPage,
  tryToIncludeCommissionActions,
  updateCommissionActions
} from "../ducks";
import { CommissionOrigin, CommissionProcessingResult, CommissionsBatchStep, PostponementReason } from "../enums";
import { Commission, CommissionsFilterPageRequest, CommissionsFilterPageResult } from "../types";

interface StateProps {
  commissionsPage: CommissionsFilterPageResult;
  institutionEnums: InstitutionWithProducts[];
  urlSearchQuery: string;
}

interface ActionsMap {
  filterCommissions: typeof filterCommissionsActions.request;
  createCommission: typeof createCommissionActions.request;
  updateCommission: typeof updateCommissionActions.request;
  postponeCommission: typeof postponeCommissionActions.request;
  tryToIncludeCommission: typeof tryToIncludeCommissionActions.request;
  deleteCommission: typeof deleteCommissionActions.request;
  deleteStateCommissionsPage: typeof deleteStateCommissionsPageAction;
}

const CommissionsBatchManualImportsContainer = (props: StateProps & ActionProps<ActionsMap>) => {
  const { id1, id2 } = useParams<TwoLevelEntityIdObject>() as { id1: string; id2: string };
  const navigate = useNavigate();

  const [commissionFormOpen, setCommissionFormOpen] = useState<boolean>(false);
  const [commissionToUpdate, setCommissionToUpdate] = useState<Commission>();
  const [commissionToPostpone, setCommissionToPostpone] = useState<Commission>();

  useEffect(() => {
    const urlParams = new URLSearchParams(props.urlSearchQuery);
    props.actions.filterCommissions({
      id: id1,
      object: {
        pageIndex: numberOrZero(urlParams.get("pageIndex")),
        pageSize: PageSizes.HUGE,
        keyword: urlParams.get("keyword") ?? undefined,
        origin: CommissionOrigin.MANUALLY_ADDED,
        processingResult:
          CommissionProcessingResult[urlParams.get("processingResult") as keyof typeof CommissionProcessingResult],
        postponementReasons: urlParams
          .getAll("postponementReasons")
          .map(reason => PostponementReason[reason as keyof typeof PostponementReason]),
        institutionIds: [id2]
      }
    });
    return () => {
      props.actions.deleteStateCommissionsPage();
    };
  }, []);

  const handleFilterSubmit = (filter: CommissionsFilterPageRequest): void => {
    navigate(appendSearchParamsToURL({ ...filter, pageIndex: undefined, keyword: filter.keyword || undefined }), {
      replace: true
    });
    props.actions.filterCommissions({
      id: id1,
      object: {
        ...filter,
        pageIndex: 0,
        pageSize: props.commissionsPage.pageSize,
        origin: CommissionOrigin.MANUALLY_ADDED,
        institutionIds: [id2]
      }
    });
  };

  const handleTablePageChange = (pageNumber: number): void => {
    const { pageSize, keyword, origin, processingResult, postponementReasons, institutionIds } = props.commissionsPage;

    navigate(appendSearchParamsToURL({ pageIndex: pageNumber - 1 }), { replace: true });
    props.actions.filterCommissions({
      id: id1,
      object: {
        pageIndex: pageNumber - 1,
        pageSize,
        keyword,
        origin,
        processingResult,
        postponementReasons,
        institutionIds
      }
    });
  };

  const handleCommissionCreateClick = (): void => {
    setCommissionFormOpen(true);
  };

  const handleCommissionUpdateClick = (commission: Commission): void => {
    setCommissionFormOpen(true);
    setCommissionToUpdate(commission);
  };

  const handleCommissionFormCancel = (): void => {
    setCommissionFormOpen(false);
    setCommissionToUpdate(undefined);
  };

  const handleCommissionPostponeClick = (commission: Commission): void => {
    setCommissionToPostpone(commission);
  };

  const handleCommissionPostponeFormCancel = (): void => {
    setCommissionToPostpone(undefined);
  };

  return (
    <DisplayWrapper itemLoaded={!!props.commissionsPage.batch}>
      <Card
        className="card-box"
        title={
          <BackNavigationArrow>
            <h2>
              {t("commissions.batch.titles.manageManualImports", {
                institutionName: props.institutionEnums.find(i => i.id === id2)?.name || ""
              })}
            </h2>
            <span className="sub-header-info normal-font-size">
              {props.commissionsPage.batch &&
                t("commissions.batch.titles.batchDetail", { name: props.commissionsPage.batch.name })}
            </span>
          </BackNavigationArrow>
        }
        extra={
          <ComponentWithPermission permissions={[Permission.COMMISSIONS_MANAGE]}>
            {props.commissionsPage.batch?.step !== CommissionsBatchStep.FINISH && (
              <Button icon={<AntIcon type="plus" />} type="primary" onClick={handleCommissionCreateClick}>
                {t("commissions.batch.actions.createCommission")}
              </Button>
            )}
          </ComponentWithPermission>
        }
      >
        <Row justify="center">
          <Col flex="1000px">
            <BatchCommissionFilterView filter={props.commissionsPage} onFilterSubmit={handleFilterSubmit} />
          </Col>
        </Row>
      </Card>

      <Card className="card-box">
        <BatchCommissionTableView
          commissionsPage={props.commissionsPage}
          batch={props.commissionsPage.batch}
          onPageChange={handleTablePageChange}
          onUpdateClick={handleCommissionUpdateClick}
          onPostponeClick={handleCommissionPostponeClick}
          onTryToInclude={props.actions.tryToIncludeCommission}
          onDelete={props.actions.deleteCommission}
        />
      </Card>

      <CommissionForm
        open={commissionFormOpen}
        formType="batchCommission"
        commission={commissionToUpdate}
        batchId={id1}
        institutionId={id2}
        onCreate={props.actions.createCommission}
        onUpdate={props.actions.updateCommission}
        onFormCancel={handleCommissionFormCancel}
      />

      <CommissionPostponeForm
        commission={commissionToPostpone}
        batchId={id1}
        open={!!commissionToPostpone}
        onPostpone={props.actions.postponeCommission}
        onFormCancel={handleCommissionPostponeFormCancel}
      />
    </DisplayWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  commissionsPage: selectCommissionsPage(state),
  institutionEnums: selectProductsClassificationEnums(state),
  urlSearchQuery: selectRouterLocationSearch(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      filterCommissions: filterCommissionsActions.request,
      createCommission: createCommissionActions.request,
      updateCommission: updateCommissionActions.request,
      postponeCommission: postponeCommissionActions.request,
      tryToIncludeCommission: tryToIncludeCommissionActions.request,
      deleteCommission: deleteCommissionActions.request,
      deleteStateCommissionsPage: deleteStateCommissionsPageAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, Record<string, any>, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(CommissionsBatchManualImportsContainer);
