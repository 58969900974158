import { Form, Select } from "antd";
import { FormItemProps } from "antd/lib/form";
import { SelectProps } from "antd/lib/select";
import groupBy from "lodash/groupBy";
import { useSelector } from "react-redux";
import { DEFAULT_LOCALE } from "../../../../app/i18n";
import { RootState } from "../../../../common/types";
import { formatProductName } from "../../../../common/utils/formatUtils";
import { selectStandardProps } from "../../../../common/utils/formUtils";
import type { UUID } from "../../../../typings/global";
import { ProductFinancialSector } from "../../../product/enums";
import { ProductBase } from "../../../product/types";
import { selectProductGroupsEnums, selectProductsClassificationEnums } from "../../ducks";
import { FilterFunction, InstitutionWithProducts, ProductGroupWithProducts } from "../../types";

interface Props {
  formItemProps: FormItemProps;
  selectProps?: SelectProps<string>;
  optionsProps?: ProductOptionsProps;
}

interface ProductOptionsProps {
  selected?: ProductBase;
  selectedInTree?: ProductOptionsSelectedInTreeProps;
  institutionId?: UUID;
  productGroupId?: UUID;
  filterSectors?: ProductFinancialSector[];
  groupByProductGroup?: boolean;
  hideAll?: boolean;
  filter?: FilterFunction<ProductBase>;
  onChange?(product: ProductBase): void;
}

interface ProductOptionsSelectedInTreeProps {
  selected?: ProductBase;
  condition: boolean;
}

const ProductSelect = ({ formItemProps, selectProps, optionsProps }: Props) => {
  const productsClassification = useSelector<RootState, InstitutionWithProducts[]>(selectProductsClassificationEnums);
  const productGroupsClassification = useSelector<RootState, ProductGroupWithProducts[]>(selectProductGroupsEnums);

  const resolveProductSelectOptions = (): ProductBase[] => {
    if (optionsProps?.hideAll) {
      return [];
    }

    const institutions = optionsProps?.institutionId
      ? productsClassification.filter(institution => institution.id === optionsProps.institutionId)
      : productsClassification;

    let groups = institutions.flatMap(institution => institution.productGroups);
    if (optionsProps?.productGroupId) {
      groups = groups.filter(group => group.id === optionsProps.productGroupId);
    }

    let products: (ProductBase & { coveredByNbs?: boolean })[] = groups.flatMap(group => group.products);
    if (optionsProps?.filterSectors?.length) {
      products = products.filter(product => product.sector && optionsProps?.filterSectors?.includes(product.sector));
    }

    if (optionsProps?.filter) {
      products = products.filter(optionsProps.filter);
    }

    if (optionsProps?.selected && !products.some(product => product.id === optionsProps?.selected?.id)) {
      products.unshift(optionsProps.selected);
    }

    if (
      optionsProps?.selectedInTree?.condition &&
      optionsProps.selectedInTree.selected &&
      !products.some(product => product.id === optionsProps?.selectedInTree?.selected?.id)
    ) {
      products.unshift(optionsProps.selectedInTree.selected);
    }

    const distinctProducts: ProductBase[] = [];
    products.forEach(product => {
      if (distinctProducts.every(distinctProduct => distinctProduct.id !== product.id)) {
        distinctProducts.push(product);
      }
    });

    return distinctProducts.sort((a, b) => a.name.localeCompare(b.name, DEFAULT_LOCALE, { sensitivity: "accent" }));
  };

  const onChange = (productId: UUID, option: any): void => {
    selectProps?.onChange?.(productId, option);
    const product = productGroupsClassification
      .flatMap(group => group.products)
      .find(product => product.id === productId);

    if (product) {
      optionsProps?.onChange?.(product);
    }
  };

  if (optionsProps?.groupByProductGroup) {
    const groupsMap = groupBy(
      resolveProductSelectOptions(),
      product => productGroupsClassification.find(group => group.products.some(p => p.id === product.id))?.id
    );
    const groups = Object.keys(groupsMap)
      .map(groupId => ({
        ...productGroupsClassification.find(group => group.id === groupId),
        products: groupsMap[groupId] as ProductBase[]
      }))
      .sort((a, b) => {
        const nameA = a.name ?? "";
        const nameB = b.name ?? "";

        return nameA.localeCompare(nameB, DEFAULT_LOCALE, { sensitivity: "accent" });
      });

    return (
      <Form.Item {...formItemProps}>
        <Select {...selectStandardProps} {...selectProps} onChange={onChange}>
          {groups.map((group, index) => (
            <Select.OptGroup key={index} label={group.name}>
              {group.products.map(p => (
                <Select.Option key={p.id} value={p.id}>
                  {formatProductName(p)}
                </Select.Option>
              ))}
            </Select.OptGroup>
          ))}
        </Select>
      </Form.Item>
    );
  }

  return (
    <Form.Item {...formItemProps}>
      <Select {...selectStandardProps} {...selectProps} onChange={onChange}>
        {resolveProductSelectOptions().map(p => (
          <Select.Option key={p.id} value={p.id}>
            {formatProductName(p)}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default ProductSelect;
