import { Calendar, Card, Col, Empty, Row, Select, Space, Spin, Tag, Tooltip } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import { rowGutter } from "../../../../../common/constants";
import { RootState } from "../../../../../common/types";
import { formatAggregatedName } from "../../../../../common/utils/formatUtils";
import { dateToIsoDateString, toDate } from "../../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import { AGENT_ROUTE_PATHS } from "../../../../agent/paths";
import { CLIENT_ROUTE_PATHS } from "../../../../client/paths";
import { requests } from "../../../api";
import { getDashboardPersonalDatesActions, selectDashboardPersonalDates } from "../../../ducks";
import { PersonalDatesDashboard } from "../../../types";
import styles from "./DashboardPersonalDatesWidget.module.scss";

const renderCalendarHeader = (value: Dayjs, onChange: (date: Dayjs) => void) => {
  const start = 0;
  const end = 12;
  const monthOptions = [];

  let current = value.clone();
  const localeData = value.localeData();
  const months = [];

  for (let i = start; i < end; i++) {
    current = current.month(i);
    months.push(localeData.monthsShort(current));
  }

  for (let i = start; i < end; i++) {
    monthOptions.push(
      <Select.Option key={i} value={i}>
        {months[i]}
      </Select.Option>
    );
  }

  const year = value.year();
  const month = value.month();
  const options = [];

  for (let i = year - 10; i < year + 10; i += 1) {
    options.push(
      <Select.Option key={i} value={i}>
        {i}
      </Select.Option>
    );
  }
  return (
    <div style={{ padding: 8 }}>
      <Row gutter={8} justify="end">
        <Col>
          <Select
            size="small"
            popupMatchSelectWidth={false}
            value={year}
            onChange={newYear => {
              const now = value.clone().year(newYear);
              onChange(now);
            }}
          >
            {options}
          </Select>
        </Col>
        <Col>
          <Select
            size="small"
            popupMatchSelectWidth={false}
            value={month}
            onChange={newMonth => {
              const now = value.clone().month(newMonth);
              onChange(now);
            }}
          >
            {monthOptions}
          </Select>
        </Col>
      </Row>
    </div>
  );
};

export const DashboardPersonalDatesWidget = () => {
  const { t } = useTranslation();

  const personalDates = useSelector<RootState, PersonalDatesDashboard | undefined>(selectDashboardPersonalDates);

  const inProgress = useRequestFinishedCallback([requests.GET_DASHBOARD_PERSONAL_DATES]);

  const dispatch = useDispatch();
  const actions = useMemo(
    () => bindActionCreators({ onDatesGet: getDashboardPersonalDatesActions.request }, dispatch),
    [dispatch]
  );

  const [calendarDate, setCalendarDate] = useState<Dayjs>(dayjs());

  useEffect(() => {
    if (personalDates?.personalDates) {
      const allDates = Object.keys(personalDates.personalDates).sort();
      const searchedDate = allDates[0]?.substring(0, 8) + dayjs().date().toString().padStart(2, "0");
      setCalendarDate(toDate(allDates.includes(searchedDate) ? searchedDate : allDates[allDates.length - 1]) as Dayjs);
    }
  }, []);

  const handlePanelChange = (value: Dayjs): void => {
    actions.onDatesGet({
      minDate: dateToIsoDateString(dayjs(value).startOf("month")),
      maxDate: dateToIsoDateString(dayjs(value).endOf("month"))
    });
  };

  const persons = personalDates?.personalDates[dateToIsoDateString(calendarDate)] || [];

  return (
    <Card
      className="card-box"
      style={{ height: 380 }}
      size="small"
      loading={!personalDates && inProgress}
      title={t("dashboard.personalDates.card")}
      extra={
        <span className="sub-header-info">
          {t("dashboard.personalDates.todayNameDays")}:{" "}
          {personalDates?.todayNameDays.length ? personalDates?.todayNameDays.join(", ") : "-"}
        </span>
      }
    >
      <Spin spinning={personalDates && inProgress}>
        {personalDates ? (
          <Row gutter={rowGutter}>
            <Col span={13}>
              <Calendar
                className={styles.calendar}
                fullscreen={false}
                mode="month"
                value={calendarDate}
                cellRender={date =>
                  personalDates.personalDates[dateToIsoDateString(date)]?.length ? (
                    <div className={styles.calendarDateHighlight} />
                  ) : undefined
                }
                headerRender={({ value, onChange }) => renderCalendarHeader(value, onChange)}
                onChange={setCalendarDate}
                onPanelChange={handlePanelChange}
              />
            </Col>

            <Col span={11} className={styles.list}>
              {persons.length > 0 ? (
                persons.map((person, index) => (
                  <Row key={index} className={styles.listRow}>
                    <Col span={4}>
                      {person.clientType ? (
                        <Tooltip title={t("dashboard.personalDates.client")}>
                          <Tag className={styles.listTypeTag} color="blue" icon={<AntIcon type="team" />} />
                        </Tooltip>
                      ) : (
                        <Tooltip title={t("dashboard.personalDates.agent")}>
                          <Tag className={styles.listTypeTag} color="purple" icon={<AntIcon type="user-switch" />} />
                        </Tooltip>
                      )}
                    </Col>

                    <Col span={16}>
                      <Ellipsis>
                        <Link
                          className="no-link-color"
                          to={generatePath(
                            person.clientType ? CLIENT_ROUTE_PATHS.detail.to : AGENT_ROUTE_PATHS.detail.to,
                            {
                              id: person.id
                            }
                          )}
                          target="_blank"
                        >
                          {formatAggregatedName(person)}
                        </Link>
                      </Ellipsis>
                    </Col>

                    <Col span={4} className="right-align">
                      <Space size="small">
                        {person.hasNameDay && (
                          <Tooltip title={t("dashboard.personalDates.nameDay")}>
                            <span>
                              <AntIcon className={styles.listIcon} color="green" type="contact" />
                            </span>
                          </Tooltip>
                        )}

                        {person.hasBirthday && (
                          <Tooltip title={t("dashboard.personalDates.birthday")}>
                            <span>
                              <AntIcon className={styles.listIcon} color="red" type="gift" />
                            </span>
                          </Tooltip>
                        )}
                      </Space>
                    </Col>
                  </Row>
                ))
              ) : (
                <Empty
                  style={{ marginTop: 80 }}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={t("dashboard.personalDates.noDates")}
                />
              )}
            </Col>
          </Row>
        ) : (
          <Empty style={{ marginTop: 80 }} image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Spin>
    </Card>
  );
};
