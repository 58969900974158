import { Checkbox, Col, Form, Input, Modal, Row, Select } from "antd";
import React, { useEffect } from "react";
import t from "../../../../app/i18n";
import ActionButton from "../../../../common/components/buttons/ActionButton";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import DeleteIcon from "../../../../common/components/icons/DeleteIcon";
import { ModalSizes, rowGutter } from "../../../../common/constants";
import { Feature } from "../../../../common/security/authorization/enums";
import {
  resolveFormValidationError,
  selectStandardProps,
  useFormErrorHandler
} from "../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import { validations } from "../../../../common/utils/validationUtils";
import TopAgentSelect from "../../../enumerations/components/form/TopAgentSelect";
import { requests } from "../../api";
import { createConfigPropertyActions, updateConfigPropertyActions } from "../../ducks";
import { CreateUpdateDynamicConfigProperty, DynamicConfigProperty } from "../../types";
import { CONFIG_PROPERTY_EMAIL_KEYS, CONFIG_PROPERTY_FEATURE_KEYS, CONFIG_PROPERTY_PHONE_KEYS } from "../../utils";

interface Props {
  open: boolean;
  property?: DynamicConfigProperty;
  onCreate?: typeof createConfigPropertyActions.request;
  onUpdate?: typeof updateConfigPropertyActions.request;
  onFormCancel: () => void;
}

const ConfigPropertyForm = ({ open, property, onCreate, onUpdate, onFormCancel }: Props) => {
  const [form] = Form.useForm<CreateUpdateDynamicConfigProperty>();
  useFormErrorHandler(form, "configProperty.attrs", [requests.CREATE_CONFIG_PROPERTY, requests.UPDATE_CONFIG_PROPERTY]);

  useEffect(() => {
    if (open && property) {
      form.setFieldsValue({
        ...property,
        ownedById: property.ownedBy?.id,
        value:
          CONFIG_PROPERTY_EMAIL_KEYS.includes(property.key) ||
          CONFIG_PROPERTY_PHONE_KEYS.includes(property.key) ||
          CONFIG_PROPERTY_FEATURE_KEYS.includes(property.key)
            ? property.value.split(",").map(value => value?.trim())
            : property.value
      });
    }
  }, [property, open, form]);

  const inProgress = useRequestFinishedCallback(
    [requests.CREATE_CONFIG_PROPERTY, requests.UPDATE_CONFIG_PROPERTY],
    onFormCancel
  );

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        const processesValues: CreateUpdateDynamicConfigProperty = {
          ...values,
          value: Array.isArray(values.value) ? (values.value as string[]).map(v => v.trim()).join(",") : values.value,
          encrypted: Array.isArray(values.value) ? false : values.encrypted
        };

        if (property) {
          onUpdate?.({ id: property.id, object: processesValues });
        } else {
          onCreate?.(processesValues);
        }
      })
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      width={ModalSizes.MEDIUM}
      open={open}
      title={property ? t("configProperty.titles.updateProperty") : t("configProperty.titles.createProperty")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <Form form={form} layout="vertical" name="configPropertyForm">
        <HiddenInput name="optimisticLockVersion" />
        <Row gutter={rowGutter}>
          <Col span={16}>
            <Form.Item
              name="key"
              label={t("configProperty.attrs.key")}
              rules={[validations.notBlank, validations.size(1, 255)]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <TopAgentSelect
              formItemProps={{
                name: "ownedById",
                label: t("configProperty.attrs.ownedById"),
                rules: [validations.none]
              }}
              selectProps={{ allowClear: true }}
            />
          </Col>
        </Row>

        <Form.Item noStyle shouldUpdate={(prev, next) => prev.encrypted !== next.encrypted || prev.key !== next.key}>
          {({ getFieldValue }) => {
            const encrypted = getFieldValue("encrypted");
            const key = getFieldValue("key");

            if (CONFIG_PROPERTY_EMAIL_KEYS.includes(key)) {
              return (
                <Form.List name="value" initialValue={[""]}>
                  {(fields, { add, remove }) => (
                    <>
                      <Row gutter={rowGutter}>
                        {fields.map(({ key, ...field }) => (
                          <React.Fragment key={key}>
                            <Col span={10}>
                              <Form.Item
                                {...field}
                                name={field.name}
                                label={t("configProperty.attrs.value")}
                                rules={[validations.notBlank, validations.size(1, 254), validations.email]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col span={2}>{fields.length > 1 && <DeleteIcon onClick={() => remove(field.name)} />}</Col>
                          </React.Fragment>
                        ))}
                      </Row>
                      <ActionButton icon="plus" onClick={() => add()}>
                        {t("common.add")}
                      </ActionButton>
                    </>
                  )}
                </Form.List>
              );
            }

            if (CONFIG_PROPERTY_PHONE_KEYS.includes(key)) {
              return (
                <Form.List name="value" initialValue={[""]}>
                  {(fields, { add, remove }) => (
                    <>
                      <Row gutter={rowGutter}>
                        {fields.map(({ key, ...field }) => (
                          <React.Fragment key={key}>
                            <Col span={10}>
                              <Form.Item
                                {...field}
                                name={field.name}
                                label={t("configProperty.attrs.value")}
                                rules={[validations.notBlank, validations.size(1, 19), validations.mobilePhoneNumber]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col span={2}>{fields.length > 1 && <DeleteIcon onClick={() => remove(field.name)} />}</Col>
                          </React.Fragment>
                        ))}
                      </Row>
                      <ActionButton icon="plus" onClick={() => add()}>
                        {t("common.add")}
                      </ActionButton>
                    </>
                  )}
                </Form.List>
              );
            }

            if (CONFIG_PROPERTY_FEATURE_KEYS.includes(key)) {
              return (
                <Row gutter={rowGutter}>
                  <Col span={24}>
                    <Form.Item name="value" label={t("configProperty.attrs.value")} rules={[validations.notNull]}>
                      <Select
                        {...selectStandardProps}
                        mode="multiple"
                        maxTagCount="responsive"
                        options={Object.keys(Feature).map(feature => ({
                          value: feature,
                          label: t("agent.enums.feature." + feature)
                        }))}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              );
            }

            return (
              <Row gutter={rowGutter}>
                <Col span={16}>
                  {encrypted ? (
                    <Form.Item name="value" label={t("configProperty.attrs.value")} rules={[validations.size(1, 4096)]}>
                      <Input.Password />
                    </Form.Item>
                  ) : (
                    <Form.Item name="value" label={t("configProperty.attrs.value")} rules={[validations.size(1, 4096)]}>
                      <Input.TextArea rows={3} />
                    </Form.Item>
                  )}
                </Col>

                <Col span={8}>
                  <Form.Item
                    name="encrypted"
                    className="form-item-without-label"
                    valuePropName="checked"
                    initialValue={false}
                    rules={[validations.none]}
                  >
                    <Checkbox>{t("configProperty.attrs.encrypted")}</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            );
          }}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ConfigPropertyForm;
