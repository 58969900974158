import { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { PageSizes } from "../../../common/constants";
import { ExportFileType } from "../../../common/enums";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import { ActionProps, RootState } from "../../../common/types";
import { appendSearchParamsToURL, numberOrZero } from "../../../common/utils/utils";
import { selectRouterLocationSearch } from "../../ducks";
import ClientFilterView from "../components/views/list/ClientFilterView";
import ClientListTableView from "../components/views/list/ClientListTableView";
import {
  deleteStateClientsPageAction,
  downloadClientsExportActions,
  filterClientsActions,
  selectClientsCurrentPage
} from "../ducks";
import { ClientType } from "../enums";
import { ClientFilterPageRequest, ClientFilterPageResult } from "../types";

interface StateProps {
  clientsCurrentPage: ClientFilterPageResult;
  urlSearchQuery: string;
}

interface ActionsMap {
  filterClients: typeof filterClientsActions.request;
  downloadClientsExport: typeof downloadClientsExportActions.request;
  deleteStateClientsPage: typeof deleteStateClientsPageAction;
}

const ClientListContainer = ({ clientsCurrentPage, urlSearchQuery, actions }: StateProps & ActionProps<ActionsMap>) => {
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(urlSearchQuery);
    actions.filterClients({
      pageIndex: numberOrZero(urlParams.get("pageIndex")),
      pageSize: PageSizes.HUGE,
      keyword: urlParams.get("keyword") ?? undefined,
      types: urlParams.getAll("types") as ClientType[]
    });
    return () => {
      actions.deleteStateClientsPage();
    };
  }, []);

  const handleTablePageChange = (pageNumber: number): void => {
    navigate(appendSearchParamsToURL({ pageIndex: pageNumber - 1 }), { replace: true });
    actions.filterClients({
      pageIndex: pageNumber - 1,
      pageSize: PageSizes.HUGE,
      keyword: clientsCurrentPage.keyword,
      types: clientsCurrentPage.types
    });
  };

  const handleFilterSubmit = (filter: ClientFilterPageRequest): void => {
    navigate(appendSearchParamsToURL({ ...filter, pageIndex: undefined, keyword: filter.keyword || undefined }), {
      replace: true
    });
    actions.filterClients({ pageIndex: 0, pageSize: clientsCurrentPage.pageSize, ...filter });
  };

  const handleExportClick = (exportFileType: ExportFileType): void => {
    actions.downloadClientsExport({
      keyword: clientsCurrentPage.keyword,
      types: clientsCurrentPage.types,
      exportFileType
    });
  };

  return (
    <ContentWrapper>
      <ClientFilterView filter={clientsCurrentPage} onFilterSubmit={handleFilterSubmit} />

      <ClientListTableView
        clientsPage={clientsCurrentPage}
        onPageChange={handleTablePageChange}
        onExportClick={handleExportClick}
      />
    </ContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  clientsCurrentPage: selectClientsCurrentPage(state),
  urlSearchQuery: selectRouterLocationSearch(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      filterClients: filterClientsActions.request,
      downloadClientsExport: downloadClientsExportActions.request,
      deleteStateClientsPage: deleteStateClientsPageAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, Record<string, any>, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(ClientListContainer);
