import { Select } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../../common/types";
import { formatAgentDirectorateName } from "../../../../common/utils/formatUtils";
import { selectStandardProps } from "../../../../common/utils/formUtils";
import { UUID } from "../../../../typings/global";
import { AgentDirectorateBase } from "../../../agent/types";
import { selectAgentDirectoratesEnums } from "../../ducks";

type Props<T extends boolean> = {
  value?: T extends true ? UUID[] : UUID;
  allowClear?: boolean;
  isMultiple?: T;
  dataSource?: AgentDirectorateBase[];
  onChange?: (data: T extends true ? UUID[] : UUID) => void;
  style?: React.CSSProperties;
};

export const AgentDirectoratesSelect = <T extends boolean>({
  value,
  allowClear,
  isMultiple,
  dataSource,
  onChange,
  style
}: Props<T>) => {
  const directoratesEnums = useSelector<RootState, AgentDirectorateBase[]>(selectAgentDirectoratesEnums);
  const data = dataSource ?? directoratesEnums;

  return (
    <Select
      style={style}
      {...selectStandardProps}
      allowClear={allowClear}
      mode={isMultiple ? "multiple" : undefined}
      options={data.map(directorate => ({
        label: formatAgentDirectorateName(directorate),
        value: directorate.id
      }))}
      onChange={onChange}
      value={value}
    />
  );
};
