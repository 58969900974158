import { Card, Divider, Popconfirm, Table, TablePaginationConfig } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { useDeleteProductGroup, useFilterProductGroups } from "../../../../common/api/queries/product";
import ActionTextIcon from "../../../../common/components/icons/ActionTextIcon";
import PopconfirmDeleteIcon from "../../../../common/components/icons/PopconfirmDeleteIcon";
import Ellipsis from "../../../../common/components/views/Ellipsis";
import { TableSizes } from "../../../../common/constants";
import { RootState, TabKeyRequest } from "../../../../common/types";
import { useFilterSearchParams } from "../../../../common/utils/hooksUtils";
import { QueryKeys, URLQuery } from "../../../../common/utils/queryUtils";
import { numberOrZero, paginationTableProps, tableStandardProps } from "../../../../common/utils/utils";
import { UUID } from "../../../../typings/global";
import { TopAgentType } from "../../../agent/enums";
import { selectUserAccount } from "../../../auth/ducks";
import { getEnumerationsActions } from "../../../enumerations/ducks";
import { UserAccount } from "../../../user/types";
import { PRODUCT_PAGE_TABS } from "../../containers/ProductsContainer";
import { ProductGroup } from "../../types";

type Props = {
  onUpdateClick: (group: ProductGroup) => void;
};

export const ProductGroupTableView = ({ onUpdateClick }: Props) => {
  const { t } = useTranslation();

  const userAccount = useSelector<RootState, UserAccount | undefined>(selectUserAccount);

  const navigate = useNavigate();
  const filterSearchParams = useFilterSearchParams<TabKeyRequest>({ params: [QueryKeys.TAB] });

  const { data, isLoading } = useFilterProductGroups(
    filterSearchParams,
    filterSearchParams.tab === PRODUCT_PAGE_TABS.PRODUCT_GROUPS
  );
  const deleteProductGroup = useDeleteProductGroup();

  const dispatch = useDispatch();
  const actions = useMemo(
    () => bindActionCreators({ getEnumerations: getEnumerationsActions.request }, dispatch),
    [dispatch]
  );

  const handleDeleteProductGroup = async (id: UUID) => {
    await deleteProductGroup.mutateAsync(id);
    actions.getEnumerations();
  };

  const handleTableChange = (pagination: TablePaginationConfig) => {
    navigate(
      {
        search: URLQuery.searchParamsToString({
          searchParams: {
            pageIndex: pagination.current ? pagination.current - 1 : undefined
          },
          append: true
        })
      },
      { replace: true }
    );
  };

  const getColumns = () => {
    const columns: ColumnsType<ProductGroup> = [
      {
        key: "name",
        title: t("product.attrs.name"),
        ellipsis: { showTitle: false },
        render: (_, record) => <Ellipsis>{record.name}</Ellipsis>
      }
    ];

    if (userAccount?.agent?.topAgentType === TopAgentType.PLUS) {
      columns.push({
        key: "name",
        title: t("product.enums.productGroupCategory._label"),
        render: (_, record) =>
          record.category ? (
            <Ellipsis>{t(`product.enums.productGroupCategory.${record.category}`)}</Ellipsis>
          ) : undefined
      });
    }

    columns.push({
      key: "actions",
      align: "right",
      fixed: "right",
      width: 180,
      render: (_, record) => (
        <>
          <ActionTextIcon icon="edit" color="blue" text={t("common.edit")} onClick={() => onUpdateClick(record)} />

          <Divider type="vertical" />

          <Popconfirm
            title={t("product.helpers.productGroupDeleteConfirm")}
            icon={<PopconfirmDeleteIcon />}
            cancelText={t("common.no")}
            okText={t("common.yes")}
            okType="danger"
            onConfirm={() => handleDeleteProductGroup(record.id)}
          >
            <span>
              <ActionTextIcon icon="delete" color="red" text={t("common.delete")} />
            </span>
          </Popconfirm>
        </>
      )
    });

    return columns;
  };

  return (
    <Card className="card-box">
      <Table<ProductGroup>
        {...tableStandardProps()}
        loading={isLoading}
        columns={getColumns()}
        scroll={{ x: TableSizes.SMALL }}
        dataSource={data?.pageData}
        pagination={{
          ...paginationTableProps,
          current: numberOrZero(data?.pageIndex) + 1,
          pageSize: data?.pageSize,
          total: data?.totalElementsCount
        }}
        onChange={handleTableChange}
      />
    </Card>
  );
};
