import { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { PageSizes } from "../../../../common/constants";
import ContentWrapper from "../../../../common/modules/wrappers/ContentWrapper";
import { ActionProps, RootState } from "../../../../common/types";
import { appendSearchParamsToURL, numberOrZero } from "../../../../common/utils/utils";
import type { UUID } from "../../../../typings/global";
import { selectRouterLocationSearch } from "../../../ducks";
import BailAccountsReportFilterView from "../components/views/BailAccountsReportFilterView";
import BailAccountsReportTableView from "../components/views/BailAccountsReportTableView";
import { deleteStateBailAccountsReportAction, getBailAccountsReportActions, selectBailAccountsReport } from "../ducks";
import { BailAccountsReportFilterPageRequest, BailAccountsReportFilterPageResult } from "../types";

interface StateProps {
  urlSearchQuery: string;
  reportPage: BailAccountsReportFilterPageResult;
}

interface ActionsMap {
  getBailAccountsReport: typeof getBailAccountsReportActions.request;
  deleteStateBailAccountsReport: typeof deleteStateBailAccountsReportAction;
}

const BailAccountsReportContainer = (props: StateProps & ActionProps<ActionsMap>) => {
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(props.urlSearchQuery);
    props.actions.getBailAccountsReport({
      pageIndex: numberOrZero(urlParams.get("pageIndex")),
      pageSize: PageSizes.LARGE,
      balanceAsOfDate: urlParams.get("balanceAsOfDate") ?? undefined,
      commissionsSettingsLevelIds: urlParams.getAll("commissionsSettingsLevelIds") as UUID[],
      agentCreatedAtDateMin: urlParams.get("agentCreatedAtDateMin") ?? undefined,
      includeZeroBalance: urlParams.get("includeZeroBalance") === "true" || undefined,
      rootAgentId: urlParams.get("rootAgentId") as UUID,
      onlyDirectSubordinates: urlParams.get("onlyDirectSubordinates") === "true" || undefined,
      includeDeactivated: urlParams.get("includeDeactivated") === "true" || undefined,
      includeRepresentatives: urlParams.get("includeRepresentatives") === "true" || undefined,
      includeNonGainers: urlParams.get("includeNonGainers") === "true" || undefined
    });
    return () => {
      props.actions.deleteStateBailAccountsReport();
    };
  }, []);

  const handleFilterSubmit = (filter: BailAccountsReportFilterPageRequest): void => {
    const urlParams = {
      ...filter,
      includeZeroBalance: filter.includeZeroBalance || undefined,
      onlyDirectSubordinates: filter.onlyDirectSubordinates || undefined,
      includeDeactivated: filter.includeDeactivated || undefined,
      includeRepresentatives: filter.includeRepresentatives || undefined,
      includeNonGainers: filter.includeNonGainers || undefined
    } as BailAccountsReportFilterPageRequest;

    navigate(appendSearchParamsToURL({ ...urlParams, pageIndex: undefined } as Record<string, any>), { replace: true });
    props.actions.getBailAccountsReport({
      ...urlParams,
      pageIndex: 0,
      pageSize: props.reportPage.pageSize
    });
  };

  const handleTablePageChange = (pageNumber: number): void => {
    const {
      pageSize,
      balanceAsOfDate,
      commissionsSettingsLevelIds,
      agentCreatedAtDateMin,
      includeZeroBalance,
      rootAgentId,
      onlyDirectSubordinates,
      includeDeactivated,
      includeRepresentatives,
      includeNonGainers
    } = props.reportPage;

    navigate(appendSearchParamsToURL({ pageIndex: pageNumber - 1 }), { replace: true });
    props.actions.getBailAccountsReport({
      pageIndex: pageNumber - 1,
      pageSize,
      balanceAsOfDate,
      commissionsSettingsLevelIds,
      agentCreatedAtDateMin,
      includeZeroBalance,
      rootAgentId,
      onlyDirectSubordinates,
      includeDeactivated,
      includeRepresentatives,
      includeNonGainers
    });
  };

  return (
    <ContentWrapper>
      <BailAccountsReportFilterView filter={props.reportPage} onFilterSubmit={handleFilterSubmit} />
      <BailAccountsReportTableView reportPage={props.reportPage} onPageChange={handleTablePageChange} />
    </ContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  urlSearchQuery: selectRouterLocationSearch(state),
  reportPage: selectBailAccountsReport(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      getBailAccountsReport: getBailAccountsReportActions.request,
      deleteStateBailAccountsReport: deleteStateBailAccountsReportAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, Record<string, any>, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(BailAccountsReportContainer);
