import { Route, Routes } from "react-router";
import { WILDCARD_ROUTE } from "../../../common/constants";
import NotFound from "../../../common/pages/NotFound/NotFound";
import { Permission } from "../../../common/security/authorization/enums";
import PageWithPermission from "../../../common/security/authorization/PageWithPermission";
import { CommissionsLevelDetailContainer } from "./containers/CommissionsLevelDetailContainer";
import { CommissionsLevelListContainer } from "./containers/CommissionsLevelListContainer";
import { COMMISSIONS_LEVEL_ROUTE_PATHS } from "./paths";

const routes = () => (
  <Routes>
    <Route
      index
      element={
        <PageWithPermission
          component={<CommissionsLevelListContainer />}
          permissions={[Permission.TOP_AGENT_COMMISSIONS]}
        />
      }
    />

    <Route
      path={COMMISSIONS_LEVEL_ROUTE_PATHS.detail.path}
      element={
        <PageWithPermission
          component={<CommissionsLevelDetailContainer />}
          permissions={[Permission.TOP_AGENT_COMMISSIONS]}
        />
      }
    />

    <Route path={WILDCARD_ROUTE} element={<NotFound />} />
  </Routes>
);

export default routes;
