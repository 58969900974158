import { Card, Input } from "antd";
import { Pathname } from "history";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import t from "../../../../app/i18n";
import BackNavigationArrow from "../../../../common/components/views/BackNavigationArrow";
import { PageSizes } from "../../../../common/constants";
import ContentWrapper from "../../../../common/modules/wrappers/ContentWrapper";
import { Permission } from "../../../../common/security/authorization/enums";
import { ActionProps, RootState } from "../../../../common/types";
import { initSearchPageResult } from "../../../../common/utils/apiUtils";
import {
  appendSearchParamsToURL,
  hasAllPermissions,
  hasPermission,
  numberOrZero
} from "../../../../common/utils/utils";
import { selectPermissions } from "../../../auth/ducks";
import { selectRouterLocationPathname, selectRouterLocationSearch } from "../../../ducks";
import { CalcType } from "../../enums";
import { CALC_ROUTE_PATHS } from "../../paths";
import RealtyCalcDraftTableView from "../components/RealtyCalcDraftTableView";
import TravelCalcDraftTableView from "../components/TravelCalcDraftTableView";
import VehicleCalcDraftTableView from "../components/VehicleCalcDraftTableView";
import {
  deleteCalcDraftActions,
  deleteCalcDraftAttachmentActions,
  deleteStateCalcDraftsPageAction,
  filterCalcDraftsActions,
  selectCalcDraftsCurrentPage,
  uploadCalcDraftAttachmentsActions
} from "../ducks";
import { CalcDraftFilterPageResult, CalcDraftList, CalcDraftTableViewProps } from "../types";

interface StateProps {
  calcDraftsCurrentPage: CalcDraftFilterPageResult;
  accountPermissions: Permission[];
  urlSearchQuery: string;
  locationPathname: Pathname;
}

interface ActionsMap {
  filterCalcDrafts: typeof filterCalcDraftsActions.request;
  deleteCalcDraft: typeof deleteCalcDraftActions.request;
  uploadCalcDraftAttachments: typeof uploadCalcDraftAttachmentsActions.request;
  deleteCalcDraftAttachment: typeof deleteCalcDraftAttachmentActions.request;
  deleteStateCalcDraftsPage: typeof deleteStateCalcDraftsPageAction;
}

const CalcDraftListContainer = ({
  calcDraftsCurrentPage,
  accountPermissions,
  urlSearchQuery,
  locationPathname,
  actions
}: StateProps & ActionProps<ActionsMap>) => {
  const navigate = useNavigate();
  const [viewType, setViewType] = useState<"vehicle" | "realty" | "travel">();

  useEffect(() => {
    const calcTypes: CalcType[] = [];

    switch (locationPathname) {
      case CALC_ROUTE_PATHS.vehicleDraft.to:
        setViewType("vehicle");
        if (hasPermission(accountPermissions, Permission.MTPL_CALCULATE)) {
          calcTypes.push(CalcType.MTPL);
        }
        if (hasPermission(accountPermissions, Permission.CRASH_CALCULATE)) {
          calcTypes.push(CalcType.CRASH, CalcType.GAP, CalcType.PAS);
        }
        if (hasAllPermissions(accountPermissions, [Permission.MTPL_CALCULATE, Permission.CRASH_CALCULATE])) {
          calcTypes.push(CalcType.MTPL_CRASH);
        }
        break;
      case CALC_ROUTE_PATHS.realtyDraft.to:
        setViewType("realty");
        calcTypes.push(CalcType.REALTY);
        break;
      case CALC_ROUTE_PATHS.travelDraft.to:
        setViewType("travel");
        calcTypes.push(CalcType.TRAVEL);
        break;
    }

    const urlParams = new URLSearchParams(urlSearchQuery);
    actions.filterCalcDrafts({
      pageIndex: numberOrZero(urlParams.get("pageIndex")),
      pageSize: PageSizes.LARGE,
      keyword: urlParams.get("keyword") ?? undefined,
      calcTypes
    });
    return () => {
      actions.deleteStateCalcDraftsPage();
    };
  }, []);

  const handleTablePageChange = (pageNumber: number): void => {
    const { keyword, pageSize, calcTypes } = calcDraftsCurrentPage;
    navigate(appendSearchParamsToURL({ pageIndex: pageNumber - 1 }), { replace: true });
    actions.filterCalcDrafts({ pageIndex: pageNumber - 1, pageSize, keyword, calcTypes });
  };

  const handleSearchSubmit = (keyword: string): void => {
    const { pageSize, calcTypes } = calcDraftsCurrentPage;

    navigate(appendSearchParamsToURL({ pageIndex: undefined, keyword: keyword || undefined }), { replace: true });
    actions.filterCalcDrafts({
      pageIndex: 0,
      pageSize,
      keyword,
      calcTypes
    });
  };

  const tableViewProps: CalcDraftTableViewProps = {
    draftsPage: { ...initSearchPageResult<CalcDraftList>(), calcTypes: [] },
    onDelete: actions.deleteCalcDraft,
    onUploadCalcDraftAttachments: actions.uploadCalcDraftAttachments,
    onDeleteCalcDraftAttachment: actions.deleteCalcDraftAttachment,
    onPageChange: handleTablePageChange
  };

  let tableContent = null;
  let pageTitle = null;

  switch (viewType) {
    case "vehicle":
      tableContent = (
        <VehicleCalcDraftTableView
          {...tableViewProps}
          draftsPage={
            calcDraftsCurrentPage.pageData[0]?.calcType === CalcType.REALTY ||
            calcDraftsCurrentPage.pageData[0]?.calcType === CalcType.TRAVEL
              ? tableViewProps.draftsPage
              : calcDraftsCurrentPage
          }
        />
      );
      pageTitle = t("calc.draft.titles.vehicleList");
      break;
    case "realty":
      tableContent = (
        <RealtyCalcDraftTableView
          {...tableViewProps}
          draftsPage={
            calcDraftsCurrentPage.pageData[0]?.calcType !== CalcType.REALTY
              ? tableViewProps.draftsPage
              : calcDraftsCurrentPage
          }
        />
      );
      pageTitle = t("calc.draft.titles.realtyList");
      break;
    case "travel":
      tableContent = (
        <TravelCalcDraftTableView
          {...tableViewProps}
          draftsPage={
            calcDraftsCurrentPage.pageData[0]?.calcType !== CalcType.TRAVEL
              ? tableViewProps.draftsPage
              : calcDraftsCurrentPage
          }
        />
      );
      pageTitle = t("calc.draft.titles.travelList");
      break;
  }

  return (
    <ContentWrapper>
      <Card
        className="card-box"
        title={
          <BackNavigationArrow>
            <h2>{pageTitle}</h2>
          </BackNavigationArrow>
        }
        extra={
          <Input.Search
            style={{ width: "350px" }}
            enterButton
            allowClear
            placeholder={t("calc.draft.helpers.searchHint")}
            defaultValue={new URLSearchParams(urlSearchQuery).get("keyword") ?? undefined}
            onSearch={handleSearchSubmit}
          />
        }
      >
        {tableContent}
      </Card>
    </ContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  calcDraftsCurrentPage: selectCalcDraftsCurrentPage(state),
  accountPermissions: selectPermissions(state),
  urlSearchQuery: selectRouterLocationSearch(state),
  locationPathname: selectRouterLocationPathname(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      filterCalcDrafts: filterCalcDraftsActions.request,
      deleteCalcDraft: deleteCalcDraftActions.request,
      uploadCalcDraftAttachments: uploadCalcDraftAttachmentsActions.request,
      deleteCalcDraftAttachment: deleteCalcDraftAttachmentActions.request,
      deleteStateCalcDraftsPage: deleteStateCalcDraftsPageAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, Record<string, any>, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(CalcDraftListContainer);
