import { Spin } from "antd";
import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { PayloadActionCreator } from "typesafe-actions";
import { requests as agentRequests } from "../../../modules/agent/api";
import { downloadAgentAttachmentActions } from "../../../modules/agent/ducks";
import { requests as bugReportRequests } from "../../../modules/bugreport/api";
import { downloadBugReportAttachmentActions } from "../../../modules/bugreport/ducks";
import { downloadCalcResultAttachmentActions } from "../../../modules/calculator/calcs/ducks";
import { requests as calcDraftRequests } from "../../../modules/calculator/drafts/api";
import { downloadCalcDraftAttachmentActions } from "../../../modules/calculator/drafts/ducks";
import { requests as calcSettingsRequests } from "../../../modules/calculator/settings/api";
import { downloadCalcSettingsAttachmentConfActions } from "../../../modules/calculator/settings/ducks";
import { requests as clientRequests } from "../../../modules/client/api";
import { downloadClientAttachmentActions, downloadClientsImportResultActions } from "../../../modules/client/ducks";
import { requests as commissionsBatchRequests } from "../../../modules/commissions/batch/api";
import { downloadCommissionsBatchAttachmentActions } from "../../../modules/commissions/batch/ducks";
import { requests as commissionsLevelRequests } from "../../../modules/commissions/level/api";
import { downloadCommissionsLevelAttachmentActions } from "../../../modules/commissions/level/ducks";
import { requests as commissionsOutputRequests } from "../../../modules/commissions/outputs/api";
import { downloadCommissionsOutputActions } from "../../../modules/commissions/outputs/ducks";
import { requests as commissionsSettingsRequests } from "../../../modules/commissions/settings/api";
import { downloadCommissionsSettingsAttachmentActions } from "../../../modules/commissions/settings/ducks";
import { requests as contractRequests } from "../../../modules/contract/api";
import {
  downloadContractAttachmentActions,
  downloadContractsImportResultActions
} from "../../../modules/contract/ducks";
import { requests as dashboardRequests } from "../../../modules/dashboard/api";
import { downloadDashboardNoticeAttachmentActions } from "../../../modules/dashboard/ducks";
import { requests as documentNodeRequests } from "../../../modules/documents/api";
import { openDocumentNodeActions } from "../../../modules/documents/ducks";
import { generateFinancialMediationActions } from "../../../modules/financialmediation/ducks";
import { FinancialMediationVersion } from "../../../modules/financialmediation/enums";
import NotFound from "../../pages/NotFound/NotFound";
import { EntityIdObject, TwoLevelEntityIdObject } from "../../types";
import { useAreParamsIdsValid, useRequestFinishedCallback } from "../../utils/hooksUtils";
import { ATTACHMENT_VIEWER_ID } from "../../utils/utils";
import { regexPatterns } from "../../utils/validationUtils";
import { ATTACHMENT_BOUNDARY_TYPE } from "./enums";

interface AttachmentBoundaryProps {
  type: ATTACHMENT_BOUNDARY_TYPE;
}

const AttachmentBoundary = (props: AttachmentBoundaryProps) => {
  const { pathname, search } = useLocation();
  const areParamsIdsValid = useAreParamsIdsValid();

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          [ATTACHMENT_BOUNDARY_TYPE.AGENT]: downloadAgentAttachmentActions.request,
          [ATTACHMENT_BOUNDARY_TYPE.BUG_REPORT]: downloadBugReportAttachmentActions.request,
          [ATTACHMENT_BOUNDARY_TYPE.CALCULATOR_DRAFT]: downloadCalcDraftAttachmentActions.request,
          [ATTACHMENT_BOUNDARY_TYPE.CALCULATOR_RESULT]: downloadCalcResultAttachmentActions.request,
          [ATTACHMENT_BOUNDARY_TYPE.CALCULATOR_SETTING]: downloadCalcSettingsAttachmentConfActions.request,
          [ATTACHMENT_BOUNDARY_TYPE.CLIENT]: downloadClientAttachmentActions.request,
          [ATTACHMENT_BOUNDARY_TYPE.CLIENT_IMPORT_RESULT]: downloadClientsImportResultActions.request,
          [ATTACHMENT_BOUNDARY_TYPE.COMMISSIONS_BATCH]: downloadCommissionsBatchAttachmentActions.request,
          [ATTACHMENT_BOUNDARY_TYPE.COMMISSIONS_LEVEL]: downloadCommissionsLevelAttachmentActions.request,
          [ATTACHMENT_BOUNDARY_TYPE.COMMISSIONS_SETTINGS]: downloadCommissionsSettingsAttachmentActions.request,
          [ATTACHMENT_BOUNDARY_TYPE.COMMISSIONS_OUTPUT]: downloadCommissionsOutputActions.request,
          [ATTACHMENT_BOUNDARY_TYPE.CONTRACT]: downloadContractAttachmentActions.request,
          [ATTACHMENT_BOUNDARY_TYPE.CONTRACT_IMPORT_RESULT]: downloadContractsImportResultActions.request,
          [ATTACHMENT_BOUNDARY_TYPE.DASHBOARD_NOTICE]: downloadDashboardNoticeAttachmentActions.request,
          [ATTACHMENT_BOUNDARY_TYPE.DOCUMENT_NODE]: openDocumentNodeActions.request,
          [ATTACHMENT_BOUNDARY_TYPE.FINANCIAL_MEDIATION]: generateFinancialMediationActions.request
        },
        dispatch
      ),
    [dispatch]
  );

  useEffect(() => {
    processDownload(props.type);
  }, [props.type]);

  const processTwoIdsDownload = (
    downloadAttachmentAction: PayloadActionCreator<string, TwoLevelEntityIdObject>
  ): void => {
    const ids = pathname.match(new RegExp(regexPatterns.uuidRegex, "g"));
    if (ids && ids[0] && ids[1]) {
      downloadAttachmentAction({ id1: ids[0], id2: ids[1] });
    }
  };

  const processOneIdDownload = (downloadAttachmentAction: PayloadActionCreator<string, EntityIdObject>): void => {
    const ids = pathname.match(new RegExp(regexPatterns.uuidRegex, "g"));
    if (ids) {
      downloadAttachmentAction({ id: ids[0] });
    }
  };

  const processFinancialMediationDownload = (): void => {
    const ids = pathname.match(new RegExp(regexPatterns.uuidRegex, "g"));
    if (ids) {
      actions[ATTACHMENT_BOUNDARY_TYPE.FINANCIAL_MEDIATION]({
        id: ids[0],
        object: { version: new URLSearchParams(search).get("version") as FinancialMediationVersion }
      });
    }
  };

  const processDownload = (type: ATTACHMENT_BOUNDARY_TYPE): void => {
    switch (type) {
      case ATTACHMENT_BOUNDARY_TYPE.AGENT:
      case ATTACHMENT_BOUNDARY_TYPE.BUG_REPORT:
      case ATTACHMENT_BOUNDARY_TYPE.CALCULATOR_DRAFT:
      case ATTACHMENT_BOUNDARY_TYPE.CALCULATOR_SETTING:
      case ATTACHMENT_BOUNDARY_TYPE.CLIENT:
      case ATTACHMENT_BOUNDARY_TYPE.COMMISSIONS_BATCH:
      case ATTACHMENT_BOUNDARY_TYPE.COMMISSIONS_LEVEL:
      case ATTACHMENT_BOUNDARY_TYPE.COMMISSIONS_SETTINGS:
      case ATTACHMENT_BOUNDARY_TYPE.COMMISSIONS_OUTPUT:
      case ATTACHMENT_BOUNDARY_TYPE.CONTRACT:
      case ATTACHMENT_BOUNDARY_TYPE.DASHBOARD_NOTICE:
        return processTwoIdsDownload(actions[type]);
      case ATTACHMENT_BOUNDARY_TYPE.DOCUMENT_NODE:
      case ATTACHMENT_BOUNDARY_TYPE.CLIENT_IMPORT_RESULT:
      case ATTACHMENT_BOUNDARY_TYPE.CONTRACT_IMPORT_RESULT:
      case ATTACHMENT_BOUNDARY_TYPE.CALCULATOR_RESULT:
        return processOneIdDownload(actions[type]);
      case ATTACHMENT_BOUNDARY_TYPE.FINANCIAL_MEDIATION:
        return processFinancialMediationDownload();
      default:
        return;
    }
  };

  const downloadAttachmentInProgress = useRequestFinishedCallback([
    agentRequests.DOWNLOAD_AGENT_ATTACHMENT,
    bugReportRequests.DOWNLOAD_BUG_REPORT_ATTACHMENT,
    calcDraftRequests.DOWNLOAD_CALC_DRAFT_ATTACHMENT,
    calcSettingsRequests.DOWNLOAD_ATTACHMENT_CONF,
    clientRequests.DOWNLOAD_CLIENT_ATTACHMENT,
    commissionsBatchRequests.DOWNLOAD_COMMISSIONS_BATCH_ATTACHMENT,
    commissionsLevelRequests.DOWNLOAD_COMMISSIONS_LEVEL_ATTACHMENT,
    commissionsSettingsRequests.DOWNLOAD_COMMISSIONS_SETTINGS_ATTACHMENT,
    commissionsOutputRequests.DOWNLOAD_COMMISSIONS_OUTPUT,
    contractRequests.DOWNLOAD_CONTRACT_ATTACHMENT,
    dashboardRequests.DOWNLOAD_DASHBOARD_NOTICE_ATACHMENT,
    documentNodeRequests.DOWNLOAD_DOCUMENT_NODE
  ]);

  return areParamsIdsValid ? (
    <Spin style={{ paddingTop: 150 }} spinning={downloadAttachmentInProgress} size="large">
      <iframe
        id={ATTACHMENT_VIEWER_ID}
        title={ATTACHMENT_VIEWER_ID}
        style={{
          position: "absolute",
          border: 0,
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          width: "100%",
          height: "100vh"
        }}
        allowFullScreen
      />
    </Spin>
  ) : (
    <NotFound />
  );
};

export default AttachmentBoundary;
