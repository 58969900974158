import {
  Button,
  Card,
  Cascader,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space
} from "antd";
import { FormInstance, Rule } from "antd/lib/form";
import dayjs from "dayjs";
import debounce from "lodash/debounce";
import { DefaultOptionType } from "rc-select/lib/Select";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import t from "../../../../app/i18n";
import { useGenerateContractTermination, useSearchContract } from "../../../../common/api/queries";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import LabelWithTooltip from "../../../../common/components/form/labels/LabelWithTooltip";
import AntIcon from "../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../common/constants";
import { AddressForm } from "../../../../common/modules/address/AddressForm";
import { formatClientName } from "../../../../common/utils/formatUtils";
import {
  datePickerFormItemProps,
  datePickerStandardProps,
  dateToIsoDateString,
  licensePlateNormalizeFunction,
  resolveFormValidationError,
  selectStandardProps,
  selectTagsStandardProps,
  setErrorsToForm
} from "../../../../common/utils/formUtils";
import { useFilterSearchParams } from "../../../../common/utils/hooksUtils";
import { regexPatterns, validations } from "../../../../common/utils/validationUtils";
import type { UUID } from "../../../../typings/global";
import ClientTypeTag from "../../../client/components/tags/ClientTypeTag";
import { ClientType, clientTypeTMap } from "../../../client/enums";
import { Client, ClientRepresentative, LegalClient } from "../../../client/types";
import { useClientSearch } from "../../../client/utils";
import { VehicleInsurance } from "../../../contract/types";
import { isVehicleInsurance } from "../../../contract/utils";
import AcademicDegreeAfterSelect from "../../../enumerations/components/form/AcademicDegreeAfterSelect";
import AcademicDegreeSelect from "../../../enumerations/components/form/AcademicDegreeSelect";
import CityAutoComplete from "../../../enumerations/components/form/CityAutoComplete";
import InstitutionSelect from "../../../enumerations/components/form/InstitutionSelect";
import { InstitutionType } from "../../../institution/enums";
import { ContractTerminationCategory, ContractTerminationReason } from "../../enums";
import { ContractFormClient, ContractFormSearchContract, GenerateContractTermination } from "../../types";
import { CONTRACT_TERMINATION_REASONS_MAP, INSURANCE_TYPE_TO_TERMINATION_CATEGORY_MAP } from "../../utils";

interface TerminationClientSectionProps {
  form: FormInstance<GenerateContractTermination>;
  kind: ClientKind;
  sectionLabel: string;
  clientType?: ClientType;
  representatives?: ClientRepresentative[];
  currentFocusedKind?: ClientKind;
  searchInProgress: boolean;
  onFocus: (kind: ClientKind) => void;
  onSearch: (pinOrCrn: string, kind: ClientKind) => void;
}

type ClientKind = "policyHolder" | "representative";

const reasonCorrectlySelectedRule: Rule = {
  validator: (_, value) =>
    value && value.length !== 2
      ? Promise.reject(t("contractTermination.validations.reasonCorrectlySelected"))
      : Promise.resolve()
};

const TerminationClientSection = ({
  form,
  kind,
  sectionLabel,
  clientType,
  representatives,
  currentFocusedKind,
  searchInProgress,
  onFocus,
  onSearch
}: TerminationClientSectionProps) => {
  const colSpan = 4;

  const handleRepresentativeChange = (id: UUID): void => {
    form.setFieldsValue({ representative: representatives?.find(r => r.id === id)?.representative });
  };

  return (
    <>
      <Divider orientation="left">{sectionLabel}</Divider>

      {representatives && representatives?.length > 1 && (
        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              label={
                <LabelWithTooltip
                  label={t("contractTermination.attrs.representative._label")}
                  tooltip={t("contractTermination.helpers.representativeDesc")}
                />
              }
            >
              <Select
                {...selectStandardProps}
                allowClear
                options={representatives.map(r => ({
                  value: r.id,
                  label: `${formatClientName(r.representative)} | ${t(
                    "client.enums.representativeFunction." + r.function
                  )}`
                }))}
                onChange={handleRepresentativeChange}
              />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row gutter={rowGutter}>
        {clientType ? (
          <HiddenInput name={[kind, "type"]} initialValue={clientType} />
        ) : (
          <Col span={colSpan}>
            <Form.Item name={[kind, "type"]} label={t("client.enums.type._label")} rules={[validations.notNull]}>
              <Select
                {...selectTagsStandardProps(clientTypeTMap)}
                options={Object.values(ClientType).map(type => ({
                  value: type,
                  label: <ClientTypeTag type={ClientType[type]} />
                }))}
              />
            </Form.Item>
          </Col>
        )}

        <Form.Item noStyle shouldUpdate={(prev, next) => prev[kind]?.type !== next[kind]?.type}>
          {({ getFieldValue }) => {
            switch (getFieldValue([kind, "type"]) as ClientType) {
              case ClientType.NATURAL:
                return (
                  <>
                    <Col span={colSpan}>
                      <Form.Item
                        name={[kind, "personalIdentificationNumber"]}
                        label={t(`contractTermination.attrs.${kind}.personalIdentificationNumber`)}
                        rules={[validations.pin]}
                      >
                        <Input.Search
                          loading={kind === currentFocusedKind && searchInProgress}
                          onFocus={() => onFocus(kind)}
                          onSearch={value => onSearch(value, kind)}
                          onChange={e => onSearch(e.target.value, kind)}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={colSpan}>
                      <AcademicDegreeSelect
                        formItemProps={{
                          name: [kind, "academicDegree"],
                          label: t(`contractTermination.attrs.${kind}.academicDegree`)
                        }}
                        selectProps={{ allowClear: true }}
                      />
                    </Col>

                    <Col span={colSpan}>
                      <Form.Item
                        name={[kind, "firstName"]}
                        label={t(`contractTermination.attrs.${kind}.firstName`)}
                        rules={[
                          validations.notBlank,
                          validations.size(1, 255),
                          validations.pattern(regexPatterns.wordRegex)
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col span={colSpan}>
                      <Form.Item
                        name={[kind, "lastName"]}
                        label={t(`contractTermination.attrs.${kind}.lastName`)}
                        rules={[
                          validations.notBlank,
                          validations.size(1, 255),
                          validations.pattern(regexPatterns.wordRegex)
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col span={colSpan}>
                      <AcademicDegreeAfterSelect
                        formItemProps={{
                          name: [kind, "academicDegreeAfter"],
                          label: t(`contractTermination.attrs.${kind}.academicDegreeAfter`)
                        }}
                        selectProps={{ allowClear: true }}
                      />
                    </Col>
                  </>
                );
              case ClientType.SELF_EMPLOYED:
              case ClientType.LEGAL:
                return (
                  <>
                    <Col span={colSpan}>
                      <Form.Item
                        name={[kind, "companyRegistrationNumber"]}
                        label={t(`contractTermination.attrs.${kind}.companyRegistrationNumber`)}
                        rules={[validations.notBlank, validations.crn]}
                      >
                        <Input.Search
                          loading={kind === currentFocusedKind && searchInProgress}
                          onFocus={() => onFocus(kind)}
                          onSearch={value => onSearch(value, kind)}
                          onChange={e => onSearch(e.target.value, kind)}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={colSpan}>
                      <Form.Item
                        name={[kind, "companyName"]}
                        label={t(`contractTermination.attrs.${kind}.companyName`)}
                        rules={[validations.notBlank, validations.size(1, 255)]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </>
                );
            }
          }}
        </Form.Item>
      </Row>

      <AddressForm rootNamePath={[kind, "address"]} label={t(`contractTermination.attrs.${kind}.address`)} required />
    </>
  );
};

export const ContractTerminationForm = () => {
  const { t } = useTranslation();

  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm<GenerateContractTermination>();
  const policyHolderType = Form.useWatch(["policyHolder", "type"], form);

  const searchParams = useFilterSearchParams<{ contractNumber: string }>({ params: ["contractNumber"] });
  const [searchKeyword, setSearchKeyword] = useState(searchParams.contractNumber);

  const { data, isLoading, refetch } = useSearchContract({ keyword: searchKeyword });
  const generateContractTermination = useGenerateContractTermination();
  const clientSearch = useClientSearch();

  const [focusedClientKind, setFocusedClientKind] = useState<ClientKind>();
  const [representatives, setRepresentatives] = useState<ClientRepresentative[]>([]);
  const [reasonSelectOpen, setReasonSelectOpen] = useState<boolean>(false);

  const setFormData = useCallback(
    (contract: ContractFormSearchContract) => {
      form.setFieldsValue({
        contractNumber: contract.identifier,
        institutionId: contract.institution.id,
        date: dateToIsoDateString(dayjs()),
        text: undefined,
        reason: contract.insurances[0]?.type
          ? [INSURANCE_TYPE_TO_TERMINATION_CATEGORY_MAP.get(contract.insurances[0].type) as ContractTerminationCategory]
          : [],
        city: undefined
      });

      setClientToForm("policyHolder", contract.policyHolder);
      setRepresentativesOfClientToForm(contract.policyHolder);
      setReasonSelectOpen(true);

      messageApi.open({
        key: "loadContractInfo",
        type: "success",
        content: t("contractTermination.helpers.loadContractInfo")
      });
    },
    [form]
  );

  useEffect(() => {
    return () => {
      clientSearch.onResultDelete();
    };
  }, []);

  useEffect(() => {
    const contract = data?.contract;

    if (contract) {
      setFormData(contract);
    }
  }, [data, setFormData]);

  useEffect(() => {
    if (clientSearch.result?.data && focusedClientKind) {
      const { keyword, clientType, data } = clientSearch.result;
      if (
        (clientType === ClientType.NATURAL &&
          keyword === form.getFieldValue([focusedClientKind, "personalIdentificationNumber"])) ||
        (clientType !== ClientType.NATURAL &&
          keyword === form.getFieldValue([focusedClientKind, "companyRegistrationNumber"]))
      ) {
        setClientToForm(focusedClientKind, data);

        if (focusedClientKind === "policyHolder") {
          setRepresentativesOfClientToForm(data);
        }
        setFocusedClientKind(undefined);
      }
    }
  }, [clientSearch.result]);

  const handleContractNumberSearchOrChangeDebounced = useMemo(() => debounce(setSearchKeyword, 500), []);

  const handleContractNumberSearchOrChange = useCallback(
    (value: string) => {
      handleContractNumberSearchOrChangeDebounced(value);
    },
    [handleContractNumberSearchOrChangeDebounced]
  );

  const setRepresentativesOfClientToForm = (client: Client) => {
    setClientToForm("representative");
    setRepresentatives([]);

    if (client.type === ClientType.LEGAL && (client as LegalClient).representatives?.length) {
      const representatives = (client as LegalClient).representatives;
      if (representatives?.length === 1) {
        setClientToForm("representative", representatives[0]?.representative);
      } else {
        setRepresentatives(representatives ?? []);
      }
    }
  };

  const setClientToForm = (kind: ClientKind, client?: Client) => {
    if (!client && kind === "representative") {
      form.setFieldsValue({ representative: { type: ClientType.NATURAL } as ContractFormClient });
    } else {
      form.setFieldsValue({ [kind]: client });
    }
  };

  const handleClientSearch = (pinOrCrn: string, kind: ClientKind) => {
    if (pinOrCrn) {
      const type = form.getFieldValue([kind, "type"]) as ClientType;
      form
        .validateFields([
          [kind, type === ClientType.NATURAL ? "personalIdentificationNumber" : "companyRegistrationNumber"]
        ])
        .then(() => clientSearch.onSearch({ keyword: pinOrCrn, clientType: type }))
        .catch(errorInfo => {
          if (focusedClientKind === "policyHolder" && representatives?.length > 0) {
            setRepresentatives([]);
          }
          resolveFormValidationError(errorInfo);
        });
    }
  };

  const handleReasonChange = (reason: ContractTerminationReason) => {
    if (reason) {
      let text = t(`contractTermination.helpers.terminationText.${reason}`);

      if (CONTRACT_TERMINATION_REASONS_MAP.get(ContractTerminationCategory.VEHICLE)?.includes(reason)) {
        const insurances = data?.contract?.insurances;
        const insurance = insurances?.[0];

        if (insurances?.length === 1 && insurance && isVehicleInsurance(insurance)) {
          const vehicleInsurance = insurances[0] as VehicleInsurance;
          text +=
            `\n\nEČV: ${licensePlateNormalizeFunction(vehicleInsurance.licensePlate)}\n` +
            `VIN: ${vehicleInsurance.vehicle.vin}\n` +
            `Značka: ${vehicleInsurance.vehicle.model.brand.name}\n` +
            `Model: ${vehicleInsurance.vehicle.model.name}`;
        }
      }

      text += `\n\n${t("contractTermination.helpers.terminationTextSuffix")}: `;

      form.setFieldsValue({ text });
    }
  };

  const handleContractSearch = async (value: string) => {
    setSearchKeyword(value);

    const { data } = await refetch();

    if (data?.contract) {
      setFormData(data?.contract);
    }
  };

  const handleFormSubmit = () => {
    form.validateFields().then(values =>
      generateContractTermination.mutate(
        {
          ...values,
          reason: undefined,
          policyHolder: { ...values.policyHolder, personalIdentificationNumber: undefined },
          representative: values.representative
            ? { ...values.representative, personalIdentificationNumber: undefined }
            : undefined
        },
        {
          onError: error => {
            setErrorsToForm(form, "contractTermination.attr", error.violations);
          }
        }
      )
    );
  };

  const handleFormReset = (): void => {
    Modal.confirm({
      title: t("contractTermination.helpers.resetFormConfirm"),
      okText: t("common.yes"),
      cancelText: t("common.back"),
      onOk: () => {
        form.resetFields();
        clientSearch.onResultDelete();
      }
    });
  };

  return (
    <Card className="card-box">
      {contextHolder}

      <Form form={form} layout="vertical" name="contractTerminationForm">
        <Row gutter={rowGutter}>
          <Col span={8}>
            <Form.Item
              name="contractNumber"
              label={t("contractTermination.attrs.contractNumber")}
              rules={[validations.notNull, validations.size(1, 64)]}
            >
              <Input.Search
                loading={isLoading}
                onSearch={handleContractSearch}
                onChange={e => handleContractNumberSearchOrChange(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>

        <TerminationClientSection
          form={form}
          kind="policyHolder"
          sectionLabel={t("contractTermination.sections.clientData")}
          currentFocusedKind={focusedClientKind}
          searchInProgress={clientSearch.inProgress}
          onFocus={setFocusedClientKind}
          onSearch={handleClientSearch}
        />

        {policyHolderType === ClientType.LEGAL ? (
          <TerminationClientSection
            form={form}
            kind="representative"
            sectionLabel={t("contractTermination.sections.representativeData")}
            clientType={ClientType.NATURAL}
            representatives={representatives}
            currentFocusedKind={focusedClientKind}
            searchInProgress={clientSearch.inProgress}
            onFocus={setFocusedClientKind}
            onSearch={handleClientSearch}
          />
        ) : undefined}

        <Divider orientation="left">{t("contractTermination.sections.generalData")}</Divider>

        <Row gutter={rowGutter}>
          <Col span={8}>
            <InstitutionSelect
              formItemProps={{
                name: "institutionId",
                label: t("contractTermination.attrs.institutionId"),
                rules: [validations.notNull]
              }}
              optionsProps={{ filterType: InstitutionType.INSURANCE_COMPANY }}
            />
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={8}>
            <Form.Item
              name="reason"
              label={
                <LabelWithTooltip
                  label={t("contractTermination.enums.terminationReason._label")}
                  tooltip={t("contractTermination.helpers.reasonDesc")}
                />
              }
              rules={[reasonCorrectlySelectedRule]}
            >
              <Cascader<DefaultOptionType>
                allowClear
                expandTrigger="hover"
                open={reasonSelectOpen}
                options={Object.values(ContractTerminationCategory).map<DefaultOptionType>(category => ({
                  label: t("contractTermination.enums.terminationCategory." + category),
                  value: category,
                  children: CONTRACT_TERMINATION_REASONS_MAP.get(category)?.map<DefaultOptionType>(reason => ({
                    label: t("contractTermination.enums.terminationReason." + reason),
                    value: reason
                  }))
                }))}
                onChange={(value: (string | number | null)[]) =>
                  handleReasonChange(value?.[1] as ContractTerminationReason)
                }
                onDropdownVisibleChange={setReasonSelectOpen}
              />
            </Form.Item>
          </Col>

          <Col span={16}>
            <Form.Item
              name="text"
              label={t("contractTermination.attrs.text")}
              rules={[validations.notBlank, validations.size(1, 4096)]}
            >
              <Input.TextArea rows={10} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={8}>
            <Form.Item
              name="date"
              label={t("contractTermination.attrs.date")}
              rules={[validations.notNull]}
              initialValue={dayjs()}
              {...datePickerFormItemProps}
            >
              <DatePicker {...datePickerStandardProps} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <CityAutoComplete
              formItemProps={{
                name: "city",
                label: t("contractTermination.attrs.city"),
                rules: [validations.notBlank, validations.size(1, 64), validations.pattern(regexPatterns.wordRegex)]
              }}
            />
          </Col>
        </Row>
      </Form>

      <Space className="margin-top-medium">
        <Button type="primary" icon={<AntIcon type="download" />} onClick={handleFormSubmit}>
          {t("common.download")}
        </Button>

        <Button icon={<AntIcon type="reload" />} onClick={handleFormReset}>
          {t("contractTermination.actions.resetForm")}
        </Button>
      </Space>
    </Card>
  );
};
