import { Form, Input, Modal } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCreateAgentDirectorate, useUpdateAgentDirectorate } from "../../../../common/api/queries";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import { ModalSizes } from "../../../../common/constants";
import { setErrorsToForm } from "../../../../common/utils/formUtils";
import { validations } from "../../../../common/utils/validationUtils";
import { AgentDirectorate, CreateUpdateAgentDirectorate } from "../../types";
import { convertAgentDirectorateToCreateUpdateObject } from "../../utils";

type Props = {
  directorate?: AgentDirectorate;
  isOpen: boolean;
  onCancel: VoidFunction;
};

export const AgentDirectorateModalForm = ({ isOpen, directorate, onCancel }: Props) => {
  const { t } = useTranslation();

  const [form] = Form.useForm<CreateUpdateAgentDirectorate>();

  const createAgentDirectorate = useCreateAgentDirectorate();
  const updateAgentDirectorate = useUpdateAgentDirectorate();

  useEffect(() => {
    if (isOpen && directorate) {
      const formData = convertAgentDirectorateToCreateUpdateObject(directorate);

      form.setFieldsValue(formData);
    }
  }, [isOpen, directorate, form]);

  const handleFormSubmit = () => {
    form.validateFields().then(values => {
      if (directorate) {
        updateAgentDirectorate.mutate(
          { id: directorate.id, object: values },
          {
            onSuccess: onCancel,
            onError: error => {
              setErrorsToForm(form, "agent.directorates.attrs", error.violations);
            }
          }
        );
      } else {
        createAgentDirectorate.mutate(values, {
          onSuccess: onCancel,
          onError: error => {
            setErrorsToForm(form, "agent.directorates.attrs", error.violations);
          }
        });
      }
    });
  };

  return (
    <Modal
      width={ModalSizes.SMALL}
      open={isOpen}
      title={
        directorate
          ? t("agent.directorates.titles.updateDirectorate")
          : t("agent.directorates.titles.createDirectorate")
      }
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      confirmLoading={createAgentDirectorate.isPending || updateAgentDirectorate.isPending}
      onCancel={onCancel}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
    >
      <Form form={form} layout="vertical" name="agentDirectorateForm">
        <HiddenInput name="optimisticLockVersion" />

        <Form.Item
          name="code"
          label={t("agent.directorates.attrs.code")}
          rules={[validations.notBlank, validations.size(1, 64)]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="name"
          label={t("agent.directorates.attrs.name")}
          rules={[validations.notBlank, validations.size(1, 64)]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
