import { Button, Col, Collapse, Divider, Popconfirm, Row, Space, Tag, Tree } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import t from "../../../../../app/i18n";
import ActionButton from "../../../../../common/components/buttons/ActionButton";
import { SecondaryButton } from "../../../../../common/components/buttons/SecondaryButton";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import PopconfirmDeleteIcon from "../../../../../common/components/icons/PopconfirmDeleteIcon";
import ItemCreatedUpdatedInfoView from "../../../../../common/components/views/ItemCreatedUpdatedInfoView";
import { Feature } from "../../../../../common/security/authorization/enums";
import { RootState } from "../../../../../common/types";
import { formatAgentName, formatPhoneNumber } from "../../../../../common/utils/formatUtils";
import { selectIsSystemAdmin } from "../../../../auth/ducks";
import { selectTopAgentAllowedFeaturesEnums } from "../../../../enumerations/ducks";
import AdminUpdateUserAccountForm from "../../../../user/components/forms/AdminUpdateUserAccountForm";
import AdminUpdateUserForm from "../../../../user/components/forms/AdminUpdateUserForm";
import {
  adminCreateUserWithSingleAgentUserAccountActions,
  adminDeleteAgentUserAccountActions,
  adminGetUsersByAgentActions,
  adminResendUserConfirmLinkActions,
  adminUpdateAgentUserAccountActions,
  adminUpdateUserActions,
  deleteStateAdminUsersByAgentAction
} from "../../../../user/ducks";
import { UserAccount, UserAdminView } from "../../../../user/types";
import { buildPermissionTreeNodesView } from "../../../../user/utils";
import { Agent } from "../../../types";
import AdminCreateUserForAgentForm from "../../forms/AdminCreateUserForAgentForm";

interface Props {
  agent: Agent;
  users: UserAdminView[];
  onGetUsers: typeof adminGetUsersByAgentActions.request;
  onCreateUser: typeof adminCreateUserWithSingleAgentUserAccountActions.request;
  onUpdateUser: typeof adminUpdateUserActions.request;
  onResendUserConfirmLink: typeof adminResendUserConfirmLinkActions.request;
  onUpdateUserAccount: typeof adminUpdateAgentUserAccountActions.request;
  onDeleteUserAccount: typeof adminDeleteAgentUserAccountActions.request;
  onDeleteStateAdminUsersByAgent: typeof deleteStateAdminUsersByAgentAction;
}

interface UserAccountToUpdateState {
  user: UserAdminView;
  account: UserAccount;
}

const AgentUserAccountsView = ({ agent, users, ...props }: Props) => {
  const isCurrentUserSystemAdmin = useSelector<RootState, boolean | undefined>(selectIsSystemAdmin);
  const topAgentAllowedFeatures = useSelector<RootState, Feature[]>(selectTopAgentAllowedFeaturesEnums);

  const [createUserFormOpen, setCreateUserFormOpen] = useState<boolean>(false);
  const [userToUpdate, setUserToUpdate] = useState<UserAdminView>();
  const [userAccountToUpdate, setUserAccountToUpdate] = useState<UserAccountToUpdateState>();

  useEffect(() => {
    props.onGetUsers({ agentId: agent.id });

    return () => {
      props.onDeleteStateAdminUsersByAgent();
    };
  }, []);

  return (
    <>
      {users.length > 0 ? (
        <Collapse
          accordion
          items={users.flatMap(
            user =>
              user.agentUserRole?.userAccounts
                .filter(account => account.agent?.id === agent.id)
                .map(account => ({
                  key: account.id,
                  label: account.representingAgent
                    ? `${user.name} | ${formatAgentName(account.representingAgent)}`
                    : user.name,
                  extra: (
                    <Space>
                      {user.locked && <Tag color="red">{t("user.helpers.lockedUser")}</Tag>}
                      {account.disabled && <Tag color="purple">{t("user.helpers.disabledAccount")}</Tag>}
                      {!account.confirmed && <Tag color="orange">{t("user.helpers.unconfirmedUser")}</Tag>}
                      {user.agentUserRole?.systemAdmin && <Tag color="cyan">{t("user.attrs.systemAdmin")}</Tag>}
                    </Space>
                  ),
                  children: (
                    <>
                      <Divider orientation="left" style={{ marginTop: 0 }}>
                        {t("user.titles.basicData")}
                      </Divider>

                      <ItemCreatedUpdatedInfoView item={user} className="clear-both margin-bottom-medium" />

                      <table className="data-table-view margin-top-medium margin-bottom-small">
                        <tbody>
                          <tr>
                            <td>{t("user.attrs.name")}:</td>
                            <td>{user.name}</td>
                            <td>
                              {t("user.attrs.wrongLoginAttempts")}: {user.wrongLoginAttempts}
                            </td>
                          </tr>
                          <tr>
                            <td>{t("user.attrs.email")}:</td>
                            <td>{user.email || user.emailToConfirm}</td>
                            <td>
                              {!account.confirmed && (
                                <Popconfirm
                                  title={t("user.titles.resendConfirmLinkConfirm")}
                                  icon={<PopconfirmDeleteIcon />}
                                  okText={t("common.yes")}
                                  cancelText={t("common.no")}
                                  okType="danger"
                                  onConfirm={() => props.onResendUserConfirmLink({ id: user.id })}
                                >
                                  <SecondaryButton size="small" type="primary" icon={<AntIcon type="mail" />}>
                                    {t("user.actions.resendConfirmLink")}
                                  </SecondaryButton>
                                </Popconfirm>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>{t("user.attrs.phone")}:</td>
                            <td>{formatPhoneNumber(user.phone || user.phoneToConfirm)}</td>
                          </tr>
                        </tbody>
                      </table>

                      <Button
                        className="margin-bottom-tiny"
                        size="small"
                        type="primary"
                        icon={<AntIcon type="edit" />}
                        onClick={() => setUserToUpdate(user)}
                      >
                        {t("user.actions.updateBasicUserData")}
                      </Button>

                      <Divider orientation="left">{t("user.titles.account")}</Divider>

                      <ItemCreatedUpdatedInfoView item={account} className="clear-both margin-bottom-medium" />

                      {account.representingAgent && (
                        <p className="margin-bottom-medium">
                          <b>{t("user.attrs.accounts.representingAgentId")}:</b>{" "}
                          {formatAgentName(account.representingAgent)}
                        </p>
                      )}

                      <h4>{t("user.attrs.permissionsLabel")}</h4>
                      <Tree
                        showLine={{ showLeafIcon: false }}
                        treeData={buildPermissionTreeNodesView(
                          topAgentAllowedFeatures,
                          account.permissions,
                          isCurrentUserSystemAdmin
                        )}
                      />

                      <Space className="margin-top-medium">
                        <Button
                          type="primary"
                          size="small"
                          icon={<AntIcon type="edit" />}
                          onClick={() => setUserAccountToUpdate({ user, account })}
                        >
                          {t("user.actions.updateAccount")}
                        </Button>

                        <Popconfirm
                          title={
                            <div style={{ maxWidth: 450 }}>
                              <Row>
                                <Col>
                                  {t("user.titles.deleteAgentAccountConfirm", {
                                    aggregatedName: account.agent?.aggregatedName
                                  })}
                                </Col>
                              </Row>
                              <Row>
                                <Col>{t("user.helpers.updateInfo")}</Col>
                              </Row>
                            </div>
                          }
                          icon={<PopconfirmDeleteIcon />}
                          okText={t("common.yes")}
                          cancelText={t("common.no")}
                          okType="danger"
                          onConfirm={() => props.onDeleteUserAccount({ id1: user.id, id2: account.id })}
                        >
                          <ActionButton icon="minus">{t("user.actions.deleteAccount")}</ActionButton>
                        </Popconfirm>
                      </Space>
                    </>
                  )
                })) || []
          )}
        />
      ) : (
        <div className="center-align sub-header-info dashed">{t("user.helpers.noUserForAgent")}</div>
      )}

      <ActionButton icon="plus" className="margin-top-small" onClick={() => setCreateUserFormOpen(true)}>
        {t("user.actions.createUser")}
      </ActionButton>

      <AdminCreateUserForAgentForm
        open={createUserFormOpen}
        agent={agent}
        onFormSubmit={props.onCreateUser}
        onFormCancel={() => setCreateUserFormOpen(false)}
      />

      <AdminUpdateUserForm
        open={!!userToUpdate}
        user={userToUpdate}
        onFormSubmit={props.onUpdateUser}
        onFormCancel={() => setUserToUpdate(undefined)}
      />

      <AdminUpdateUserAccountForm
        open={!!userAccountToUpdate}
        user={userAccountToUpdate?.user}
        account={userAccountToUpdate?.account}
        onFormSubmit={props.onUpdateUserAccount}
        onFormCancel={() => setUserAccountToUpdate(undefined)}
      />
    </>
  );
};

export default AgentUserAccountsView;
