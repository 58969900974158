import { Form, Input, Modal, Select } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCreateAgentEducation, useUpdateAgentEducation } from "../../../../common/api/queries";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import { ModalSizes } from "../../../../common/constants";
import { selectStandardProps, setErrorsToForm } from "../../../../common/utils/formUtils";
import { validations } from "../../../../common/utils/validationUtils";
import { AgentEducationType } from "../../enums";
import { AgentEducation, CreateUpdateAgentEducation } from "../../types";
import { convertAgentEducationToCreateUpdateObject } from "../../utils";

type Props = {
  isOpen: boolean;
  onCancel: VoidFunction;
  education?: AgentEducation;
};

export const AgentEducationModalForm = ({ isOpen, onCancel, education }: Props) => {
  const { t } = useTranslation();

  const createAgentEducation = useCreateAgentEducation();
  const updateAgentEducation = useUpdateAgentEducation();

  const [form] = Form.useForm<CreateUpdateAgentEducation>();

  useEffect(() => {
    if (isOpen && education) {
      const formData = convertAgentEducationToCreateUpdateObject(education);

      form.setFieldsValue(formData);
    }
  }, [isOpen, education, form]);

  const handleFormSubmit = () => {
    form.validateFields().then(async values => {
      if (education) {
        updateAgentEducation.mutate(
          { id: education.id, object: values },
          {
            onSuccess: onCancel,
            onError: error => {
              setErrorsToForm(form, "agent.education.attrs", error.violations);
            }
          }
        );
      } else {
        createAgentEducation.mutate(values, {
          onSuccess: onCancel,
          onError: error => {
            setErrorsToForm(form, "agent.education.attrs", error.violations);
          }
        });
      }
    });
  };

  return (
    <Modal
      width={ModalSizes.SMALL}
      title={education ? t("agent.education.titles.updateEducation") : t("agent.education.titles.createEducation")}
      open={isOpen}
      onOk={handleFormSubmit}
      confirmLoading={createAgentEducation.isPending || updateAgentEducation.isPending}
      onCancel={onCancel}
      afterClose={() => form.resetFields()}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
    >
      <Form form={form} layout="vertical" name="agentEducationForm">
        <HiddenInput name="optimisticLockVersion" />

        <Form.Item
          name="code"
          label={t("agent.education.attrs.code")}
          rules={[validations.notBlank, validations.size(1, 64)]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="name"
          label={t("agent.education.attrs.name")}
          rules={[validations.notBlank, validations.size(1, 255)]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="type"
          label={t("agent.education.enums.agentEducationType._label")}
          rules={[validations.notNull]}
        >
          <Select
            {...selectStandardProps}
            options={Object.values(AgentEducationType)
              .filter(type => type !== AgentEducationType.MANAGERIAL_ACADEMY_COMPLETION)
              .map(type => ({
                value: type,
                label: t(`agent.education.enums.agentEducationType.${type}`)
              }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
