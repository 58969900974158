import { Loader } from "@googlemaps/js-api-loader";
import { createContext, ReactNode, useEffect, useState } from "react";
import { getGoogleMapsApiKey } from "../../common/utils/utils";
import { DEFAULT_LOCALE } from "../i18n";

export type GoogleMapsAPIContextType = {
  googleApi?: google.maps.PlacesLibrary;
};

export const GoogleMapsAPIContext = createContext<GoogleMapsAPIContextType>({
  googleApi: undefined
});

type Props = {
  children: ReactNode;
};

export const GoogleMapsAPIProvider = ({ children }: Props) => {
  const [googleApi, setGoogleApi] = useState<google.maps.PlacesLibrary>();

  useEffect(() => {
    const googleMapsApiKey = getGoogleMapsApiKey();

    if (googleMapsApiKey) {
      new Loader({
        apiKey: googleMapsApiKey,
        language: DEFAULT_LOCALE,
        region: DEFAULT_LOCALE
      })
        .importLibrary("places")
        .then(value => {
          setGoogleApi(value);
        })
        .catch(error => {
          console.error("Error occurred while loading Google Maps Places API.", error);
        });
    }
  }, []);

  return <GoogleMapsAPIContext.Provider value={{ googleApi }}>{children}</GoogleMapsAPIContext.Provider>;
};
