export enum ContractTerminationReason {
  INSURANCE_PERIOD_END = "INSURANCE_PERIOD_END",
  VEHICLE_INSURANCE_PERIOD_END = "VEHICLE_INSURANCE_PERIOD_END",
  VEHICLE_DECOMMISSION = "VEHICLE_DECOMMISSION",
  WITHIN_TWO_MONTHS_OF_CONCLUSION = "WITHIN_TWO_MONTHS_OF_CONCLUSION",
  VEHICLE_DESTRUCTION = "VEHICLE_DESTRUCTION",
  HOLDER_CHANGE = "HOLDER_CHANGE",
  VEHICLE_THEFT = "VEHICLE_THEFT",
  DISSATISFACTION_WITH_PAYMENT = "DISSATISFACTION_WITH_PAYMENT",
  DENIAL_OF_PAYMENT = "DENIAL_OF_PAYMENT",
  INSURANCE_SUBJECT_END = "INSURANCE_SUBJECT_END",
  OWNER_CHANGE = "OWNER_CHANGE",
  DEATH = "DEATH",
  LIVING_TO_THE_END_OF_INSURANCE = "LIVING_TO_THE_END_OF_INSURANCE",
  INSURANCE_CANCELLATION_WITH_PAYMENT = "INSURANCE_CANCELLATION_WITH_PAYMENT",
  REFUSAL_TO_FULFILL = "REFUSAL_TO_FULFILL",
  TERMINATION_BY_AGREEMENT = "TERMINATION_BY_AGREEMENT"
}

export enum ContractTerminationCategory {
  VEHICLE = "VEHICLE",
  PROPERTY = "PROPERTY",
  LIFE = "LIFE",
  TRAVEL = "TRAVEL",
  GENERIC = "GENERIC"
}
