import { Card, Descriptions, DescriptionsProps, Divider, Popconfirm, Table, TablePaginationConfig } from "antd";
import { ColumnsType } from "antd/lib/table";
import { TFunction } from "i18next";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { useDeleteProduct, useDownloadProductsExport, useFilterProducts } from "../../../../common/api/queries/product";
import LabelWithTooltip from "../../../../common/components/form/labels/LabelWithTooltip";
import ActionTextIcon from "../../../../common/components/icons/ActionTextIcon";
import PopconfirmDeleteIcon from "../../../../common/components/icons/PopconfirmDeleteIcon";
import DeactivatedTag from "../../../../common/components/tags/DeactivatedTag";
import Ellipsis from "../../../../common/components/views/Ellipsis";
import { TableSizes } from "../../../../common/constants";
import { ExportFileType } from "../../../../common/enums";
import { TabKeyRequest } from "../../../../common/types";
import {
  formatLocaleCurrency,
  formatLocaleDate,
  formatLocaleNumber,
  formatLocalePercentageNumber
} from "../../../../common/utils/formatUtils";
import { useFilterSearchParams } from "../../../../common/utils/hooksUtils";
import { QueryKeys, URLQuery } from "../../../../common/utils/queryUtils";
import { tBoolean } from "../../../../common/utils/translationUtils";
import { numberOrZero, paginationTableProps, tableStandardProps } from "../../../../common/utils/utils";
import { UUID } from "../../../../typings/global";
import { selectIsCurrentAgentTypePlus } from "../../../auth/ducks";
import { CommissionDivisionType } from "../../../commissions/productcommissionssettings/enum";
import { getEnumerationsActions } from "../../../enumerations/ducks";
import { PRODUCT_PAGE_TABS } from "../../containers/ProductsContainer";
import { Product, ProductFilterPageRequest } from "../../types";

const getLabelForValueCoveredByNbs = ({ value, isSFAPlus }: { value: boolean; isSFAPlus: boolean }, t: TFunction) => {
  if (isSFAPlus) {
    return value ? t("product.helpers.productProfiPlus") : t("product.helpers.productProjectsPlus");
  }

  return value ? t("product.helpers.productCoveredByNbs") : t("product.helpers.productNoCoveredByNbs");
};

type Props = {
  onUpdateClick: (product: Product) => void;
};

export const ProductTableView = ({ onUpdateClick }: Props) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const filterSearchParams = useFilterSearchParams<ProductFilterPageRequest & TabKeyRequest>({
    params: ["sectors", "groupIds", QueryKeys.TAB]
  });

  const isCurrentAgentTypePlus = useSelector(selectIsCurrentAgentTypePlus);

  const { data, isLoading } = useFilterProducts(
    {
      ...filterSearchParams,
      groupIds:
        filterSearchParams.groupIds && !Array.isArray(filterSearchParams.groupIds)
          ? [filterSearchParams.groupIds]
          : filterSearchParams.groupIds,
      sectors:
        filterSearchParams.sectors && !Array.isArray(filterSearchParams.sectors)
          ? [filterSearchParams.sectors]
          : filterSearchParams.sectors
    },
    filterSearchParams.tab === PRODUCT_PAGE_TABS.PRODUCTS
  );
  const deleteProduct = useDeleteProduct();
  const downloadProductsExport = useDownloadProductsExport();

  const dispatch = useDispatch();
  const actions = useMemo(
    () => bindActionCreators({ getEnumerations: getEnumerationsActions.request }, dispatch),
    [dispatch]
  );

  const columns: ColumnsType<Product> = [
    {
      key: "group",
      title: t("product.attrs.productGroupId"),
      width: 160,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.productGroup.name}</Ellipsis>
    },
    {
      key: "name",
      title: t("product.helpers.productName"),
      width: 190,
      ellipsis: { showTitle: false },
      render: (_, record) => (
        <>
          <Ellipsis>{record.name}</Ellipsis>
          {record.deactivated && (
            <>
              <br />
              <DeactivatedTag style={{ marginRight: 0 }} />
            </>
          )}
        </>
      )
    },
    {
      key: "sector",
      title: t("product.enums.financialSector._label"),
      width: 150,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{t("product.enums.financialSector." + record.sector)}</Ellipsis>
    },
    {
      key: "insuranceType",
      title: (
        <>
          <span>{t("product.helpers.insuranceTypeLabel")}</span>
          <br />
          <i>{t("product.attrs.tariffGroupId")}</i>
        </>
      ),
      width: 130,
      ellipsis: { showTitle: false },
      render: (_, record) =>
        record.insuranceType && (
          <>
            <Ellipsis>{t("contract.enums.insuranceType." + record.insuranceType)}</Ellipsis>
            {record.tariffGroup && (
              <>
                <br />
                <Ellipsis style={{ fontStyle: "italic" }}>{record.tariffGroup.name}</Ellipsis>
              </>
            )}
          </>
        )
    },
    {
      key: "institutions",
      title: t("common.institutions"),
      width: 290,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.institutions.map(institution => institution.name).join(", ")}</Ellipsis>
    },
    {
      key: "actions",
      align: "right",
      fixed: "right",
      width: 180,
      render: (_, record) => (
        <div className="nowrap">
          <ActionTextIcon icon="edit" color="blue" text={t("common.edit")} onClick={() => onUpdateClick(record)} />

          <Divider type="vertical" />

          <Popconfirm
            title={t("product.helpers.productDeleteConfirm")}
            icon={<PopconfirmDeleteIcon />}
            cancelText={t("common.no")}
            okText={t("common.yes")}
            okType="danger"
            onConfirm={() => handleDeleteProduct(record.id)}
          >
            <span>
              <ActionTextIcon icon="delete" color="red" text={t("common.delete")} />
            </span>
          </Popconfirm>
        </div>
      )
    }
  ];

  const handleTableChange = (pagination: TablePaginationConfig) => {
    navigate(
      {
        search: URLQuery.searchParamsToString({
          searchParams: {
            pageIndex: pagination.current ? pagination.current - 1 : undefined
          },
          append: true
        })
      },
      { replace: true }
    );
  };

  const handleDeleteProduct = async (id: UUID) => {
    await deleteProduct.mutateAsync(id);
    actions.getEnumerations();
  };

  const renderExpandedRow = (record: Product) => {
    let settingsDescription;

    const items: DescriptionsProps["items"] = [
      {
        key: "coveredByNbs",
        label: t("product.attrs.coveredByNbs"),
        children: getLabelForValueCoveredByNbs({ value: record.coveredByNbs, isSFAPlus: isCurrentAgentTypePlus }, t)
      },
      {
        key: "subjectToVat",
        label: t("product.attrs.subjectToVat"),
        children: tBoolean(record.subjectToVat)
      }
    ];

    if (record.commissionsSettings) {
      const {
        amountAdjustmentRatio,
        divisionType,
        year1PaymentRatio,
        year2PaymentRatio,
        year3PaymentRatio,
        pointsAmount,
        paymentAmount,
        omitManagerialCommissions,
        intervals
      } = record.commissionsSettings;

      const intervalsItems: DescriptionsProps["items"] = intervals.reduce<DescriptionsProps["items"]>(
        (acc, value, intervalIndex) => {
          acc?.push({
            key: `startDate${intervalIndex}`,
            label: t(`commissions.productCommissionsSettings.attrs.intervals.startDate`),
            children: formatLocaleDate(value.startDate),
            span: value.endDate ? 1 : 2
          });

          if (value.endDate) {
            acc?.push({
              key: `endDate${intervalIndex}`,
              label: t(`commissions.productCommissionsSettings.attrs.intervals.endDate`),
              children: formatLocaleDate(value.endDate)
            });
          }

          acc?.push({
            key: `pointsCoefficient${intervalIndex}`,
            label: t(`commissions.productCommissionsSettings.attrs.intervals.pointsCoefficient`),
            children: (
              <span style={{ textAlign: "right", width: 60 }}>{formatLocaleNumber(value.pointsCoefficient, 2)}</span>
            )
          });

          return acc;
        },
        []
      );

      const settingsItems: DescriptionsProps["items"] = [
        {
          key: "amountAdjustmentRatio",
          label: (
            <LabelWithTooltip
              tooltip={t("commissions.productCommissionsSettings.helpers.amountAdjustmentRatioHint")}
              label={t("commissions.productCommissionsSettings.attrs.amountAdjustmentRatio")}
            />
          ),
          children: formatLocalePercentageNumber(amountAdjustmentRatio),
          span: 4
        },
        {
          key: "divisionType",
          label: t("commissions.productCommissionsSettings.enums.commissionDivisionType._label"),
          children: t(`commissions.productCommissionsSettings.enums.commissionDivisionType.${divisionType}`),
          span: divisionType === CommissionDivisionType.NONE ? 4 : 1
        }
      ];

      if (divisionType !== CommissionDivisionType.NONE) {
        settingsItems.push(
          {
            key: "year1PaymentRatio",
            label: t("commissions.productCommissionsSettings.attrs.year1PaymentRatio"),
            children: formatLocalePercentageNumber(year1PaymentRatio)
          },
          {
            key: "year2PaymentRatio",
            label: t("commissions.productCommissionsSettings.attrs.year2PaymentRatio"),
            children: formatLocalePercentageNumber(year2PaymentRatio),
            span: divisionType === CommissionDivisionType.THREE_YEARS ? 1 : 2
          }
        );
      }

      if (divisionType === CommissionDivisionType.THREE_YEARS) {
        settingsItems.push({
          key: "year3PaymentRatio",
          label: t("commissions.productCommissionsSettings.attrs.year3PaymentRatio"),
          children: formatLocalePercentageNumber(year3PaymentRatio)
        });
      }

      settingsItems.push(
        {
          key: "pointsAmount",
          label: (
            <LabelWithTooltip
              tooltip={t("commissions.productCommissionsSettings.helpers.pointsAmountHint")}
              label={t("commissions.productCommissionsSettings.attrs.pointsAmount")}
            />
          ),
          children: formatLocaleNumber(pointsAmount, 2) ?? "-"
        },
        {
          key: "paymentAmount",
          label: (
            <LabelWithTooltip
              tooltip={t("commissions.productCommissionsSettings.helpers.paymentAmountHint")}
              label={t("commissions.productCommissionsSettings.attrs.paymentAmount")}
            />
          ),
          children: formatLocaleCurrency(paymentAmount) ?? "-"
        },
        {
          key: "omitManagerialCommissions",
          label: t("commissions.productCommissionsSettings.attrs.omitManagerialCommissions"),
          children: tBoolean(omitManagerialCommissions),
          span: 2
        },
        {
          key: "intervals",
          label: t("commissions.productCommissionsSettings.attrs.intervals._label"),
          children: <Descriptions style={{ maxHeight: 130, overflow: "auto" }} size="small" items={intervalsItems} />
        }
      );

      settingsDescription = (
        <Descriptions
          title={t("commissions.productCommissionsSettings.sections.commissionSettings")}
          styles={{
            title: { fontSize: 14 },
            header: { marginBottom: 8 },
            label: { whiteSpace: "nowrap" },
            content: { whiteSpace: "nowrap" }
          }}
          size="small"
          bordered
          items={settingsItems}
          column={4}
        />
      );
    }

    return (
      <>
        <Descriptions size="small" title={t("product.titles.additionalInfo")} items={items} bordered column={2} />
        {record.commissionsSettings ? <div className="margin-top-small">{settingsDescription}</div> : undefined}
      </>
    );
  };

  return (
    <Card className="card-box">
      <div className={data?.totalElementsCount ? "table-header-export-actions" : "margin-bottom-small"}>
        <ActionTextIcon
          icon="download"
          color="blue"
          text={t("common.exportXlsx")}
          onClick={() =>
            downloadProductsExport.mutate({
              exportFileType: ExportFileType.XLSX,
              ...filterSearchParams
            })
          }
        />

        <Divider type="vertical" />

        <ActionTextIcon
          icon="download"
          color="green"
          text={t("common.exportCsv")}
          onClick={() =>
            downloadProductsExport.mutate({
              exportFileType: ExportFileType.CSV,
              ...filterSearchParams
            })
          }
        />
      </div>

      <Table<Product>
        {...tableStandardProps()}
        loading={isLoading}
        columns={columns}
        scroll={{ x: TableSizes.LARGE }}
        dataSource={data?.pageData}
        pagination={{
          ...paginationTableProps,
          ...data,
          current: numberOrZero(data?.pageIndex) + 1,
          pageSize: data?.pageSize,
          total: data?.totalElementsCount
        }}
        onChange={handleTableChange}
        expandable={{ expandedRowRender: renderExpandedRow }}
      />
    </Card>
  );
};
