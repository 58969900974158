import { InsuranceType } from "../contract/enums";
import { ContractTerminationCategory, ContractTerminationReason } from "./enums";

export const INSURANCE_TYPE_TO_TERMINATION_CATEGORY_MAP = new Map<InsuranceType, ContractTerminationCategory>([
  [InsuranceType.GENERIC, ContractTerminationCategory.GENERIC],
  [InsuranceType.MTPL, ContractTerminationCategory.VEHICLE],
  [InsuranceType.CRASH, ContractTerminationCategory.VEHICLE],
  [InsuranceType.GAP, ContractTerminationCategory.VEHICLE],
  [InsuranceType.PAS, ContractTerminationCategory.VEHICLE],
  [InsuranceType.REALTY, ContractTerminationCategory.PROPERTY],
  [InsuranceType.TRAVEL, ContractTerminationCategory.TRAVEL],
  [InsuranceType.LIFE, ContractTerminationCategory.LIFE]
]);

export const CONTRACT_TERMINATION_REASONS_MAP = new Map<ContractTerminationCategory, ContractTerminationReason[]>([
  [
    ContractTerminationCategory.VEHICLE,
    [
      ContractTerminationReason.VEHICLE_INSURANCE_PERIOD_END,
      ContractTerminationReason.VEHICLE_DECOMMISSION,
      ContractTerminationReason.WITHIN_TWO_MONTHS_OF_CONCLUSION,
      ContractTerminationReason.VEHICLE_DESTRUCTION,
      ContractTerminationReason.HOLDER_CHANGE,
      ContractTerminationReason.VEHICLE_THEFT,
      ContractTerminationReason.DISSATISFACTION_WITH_PAYMENT,
      ContractTerminationReason.DENIAL_OF_PAYMENT,
      ContractTerminationReason.TERMINATION_BY_AGREEMENT
    ]
  ],
  [
    ContractTerminationCategory.PROPERTY,
    [
      ContractTerminationReason.INSURANCE_PERIOD_END,
      ContractTerminationReason.INSURANCE_SUBJECT_END,
      ContractTerminationReason.OWNER_CHANGE,
      ContractTerminationReason.WITHIN_TWO_MONTHS_OF_CONCLUSION,
      ContractTerminationReason.DISSATISFACTION_WITH_PAYMENT,
      ContractTerminationReason.DENIAL_OF_PAYMENT,
      ContractTerminationReason.TERMINATION_BY_AGREEMENT
    ]
  ],
  [
    ContractTerminationCategory.LIFE,
    [
      ContractTerminationReason.DEATH,
      ContractTerminationReason.WITHIN_TWO_MONTHS_OF_CONCLUSION,
      ContractTerminationReason.LIVING_TO_THE_END_OF_INSURANCE,
      ContractTerminationReason.INSURANCE_CANCELLATION_WITH_PAYMENT,
      ContractTerminationReason.REFUSAL_TO_FULFILL,
      ContractTerminationReason.TERMINATION_BY_AGREEMENT
    ]
  ],
  [
    ContractTerminationCategory.TRAVEL,
    [
      ContractTerminationReason.INSURANCE_PERIOD_END,
      ContractTerminationReason.WITHIN_TWO_MONTHS_OF_CONCLUSION,
      ContractTerminationReason.DISSATISFACTION_WITH_PAYMENT,
      ContractTerminationReason.DENIAL_OF_PAYMENT,
      ContractTerminationReason.TERMINATION_BY_AGREEMENT
    ]
  ],
  [
    ContractTerminationCategory.GENERIC,
    [
      ContractTerminationReason.INSURANCE_PERIOD_END,
      ContractTerminationReason.INSURANCE_SUBJECT_END,
      ContractTerminationReason.OWNER_CHANGE,
      ContractTerminationReason.WITHIN_TWO_MONTHS_OF_CONCLUSION,
      ContractTerminationReason.DISSATISFACTION_WITH_PAYMENT,
      ContractTerminationReason.DENIAL_OF_PAYMENT,
      ContractTerminationReason.TERMINATION_BY_AGREEMENT
    ]
  ]
]);
