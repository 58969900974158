import { Button, Card, Input } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import t from "../../../app/i18n";
import AntIcon from "../../../common/components/icons/AntIcon";
import { PageSizes } from "../../../common/constants";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import { ActionProps, RootState, SearchPageResult } from "../../../common/types";
import messageUtils from "../../../common/utils/messageUtils";
import { appendSearchParamsToURL, numberOrZero } from "../../../common/utils/utils";
import { validationConstants, validationFunctions } from "../../../common/utils/validationUtils";
import { selectRouterLocationSearch } from "../../ducks";
import AffiliatePartnerForm from "../components/forms/AffiliatePartnerForm";
import AffiliatePartnerTableView from "../components/views/AffiliatePartnerTableView";
import {
  createAffiliatePartnerActions,
  deleteAffiliatePartnerActions,
  deleteStateAffiliatePartnersPageAction,
  filterAffiliatePartnersActions,
  selectAffiliatePartnersCurrentPage,
  updateAffiliatePartnerActions
} from "../ducks";
import { AffiliatePartner } from "../types";

interface StateProps {
  affiliatePartnersCurrentPage: SearchPageResult<AffiliatePartner>;
  urlSearchQuery: string;
}

interface ActionsMap {
  filterAffiliatePartners: typeof filterAffiliatePartnersActions.request;
  createAffiliatePartner: typeof createAffiliatePartnerActions.request;
  updateAffiliatePartner: typeof updateAffiliatePartnerActions.request;
  deleteAffiliatePartner: typeof deleteAffiliatePartnerActions.request;
  deleteStateAffiliatePartnersPage: typeof deleteStateAffiliatePartnersPageAction;
}

const AffiliatePartnerListContainer = ({
  affiliatePartnersCurrentPage,
  urlSearchQuery,
  actions
}: StateProps & ActionProps<ActionsMap>) => {
  const navigate = useNavigate();

  const [partnerFormOpen, setPartnerFormOpen] = useState<boolean>(false);
  const [partnerToUpdate, setPartnerToUpdate] = useState<AffiliatePartner>();

  useEffect(() => {
    const urlParams = new URLSearchParams(urlSearchQuery);

    actions.filterAffiliatePartners({
      pageIndex: numberOrZero(urlParams.get("pageIndex")),
      pageSize: PageSizes.LARGE,
      keyword: urlParams.get("keyword") ?? undefined
    });
    return () => {
      actions.deleteStateAffiliatePartnersPage();
    };
  }, []);

  const handleSearchSubmit = (keyword: string): void => {
    if (validationFunctions.validateSearchKeyword(keyword)) {
      navigate(appendSearchParamsToURL({ pageIndex: undefined, keyword: keyword || undefined }), { replace: true });
      actions.filterAffiliatePartners({ pageIndex: 0, pageSize: affiliatePartnersCurrentPage.pageSize, keyword });
    } else {
      messageUtils.errorMessage(
        t("validation.size", {
          min: validationConstants.SEARCH_KEYWORD_MIN_LENGTH,
          max: validationConstants.SEARCH_KEYWORD_MAX_LENGTH
        })
      );
    }
  };

  const handlePageChange = (pageNumber: number): void => {
    const { pageSize, keyword } = affiliatePartnersCurrentPage;
    navigate(appendSearchParamsToURL({ pageIndex: pageNumber - 1 }), { replace: true });
    actions.filterAffiliatePartners({ pageIndex: pageNumber - 1, pageSize, keyword });
  };

  const handleCreateClick = (): void => {
    setPartnerFormOpen(true);
  };

  const handleUpdateClick = (affiliatePartner: AffiliatePartner): void => {
    setPartnerFormOpen(true);
    setPartnerToUpdate(affiliatePartner);
  };

  const handleFormCancel = (): void => {
    setPartnerFormOpen(false);
    setPartnerToUpdate(undefined);
  };

  const defaultKeyword = new URLSearchParams(urlSearchQuery).get("keyword");

  return (
    <ContentWrapper>
      <Card
        className="card-box"
        title={<h2>{t("affiliatePartner.titles.list")}</h2>}
        extra={
          <>
            <Button
              className="margin-right-small"
              type="primary"
              icon={<AntIcon type="plus" />}
              onClick={handleCreateClick}
            >
              {t("affiliatePartner.actions.createPartner")}
            </Button>
            <Input.Search
              style={{ width: 350 }}
              enterButton
              allowClear
              placeholder={t("affiliatePartner.helpers.searchHint")}
              defaultValue={defaultKeyword ?? undefined}
              onSearch={handleSearchSubmit}
            />
          </>
        }
      >
        <AffiliatePartnerTableView
          affiliatePartnerPage={affiliatePartnersCurrentPage}
          onPageChange={handlePageChange}
          onUpdateClick={handleUpdateClick}
          onDelete={actions.deleteAffiliatePartner}
        />
      </Card>

      <AffiliatePartnerForm
        affiliatePartner={partnerToUpdate}
        open={partnerFormOpen}
        onCreate={actions.createAffiliatePartner}
        onUpdate={actions.updateAffiliatePartner}
        onFormCancel={handleFormCancel}
      />
    </ContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  affiliatePartnersCurrentPage: selectAffiliatePartnersCurrentPage(state),
  urlSearchQuery: selectRouterLocationSearch(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      filterAffiliatePartners: filterAffiliatePartnersActions.request,
      createAffiliatePartner: createAffiliatePartnerActions.request,
      updateAffiliatePartner: updateAffiliatePartnerActions.request,
      deleteAffiliatePartner: deleteAffiliatePartnerActions.request,
      deleteStateAffiliatePartnersPage: deleteStateAffiliatePartnersPageAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, Record<string, any>, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(AffiliatePartnerListContainer);
