import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import ContentWrapper from "../../../../common/modules/wrappers/ContentWrapper";
import DisplayWrapper from "../../../../common/modules/wrappers/DisplayWrapper";
import { EntityIdObject } from "../../../../common/types";
import { requests } from "../api";
import { CommissionsLevelDetailView } from "../components/views/detail/CommissionsLevelDetailView";
import {
  deleteStateCommissionsLevelDetailAction,
  getCommissionsLevelActions,
  selectCommissionsLevelDetail
} from "../ducks";

export const CommissionsLevelDetailContainer = () => {
  const { id } = useParams<EntityIdObject>() as { id: string };

  const level = useSelector(selectCommissionsLevelDetail);

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          getCommissionsLevel: getCommissionsLevelActions.request,
          deleteStateCommissionsLevelDetail: deleteStateCommissionsLevelDetailAction
        },
        dispatch
      ),
    [dispatch]
  );

  useEffect(() => {
    actions.getCommissionsLevel({ id });

    return () => {
      actions.deleteStateCommissionsLevelDetail();
    };
  }, []);

  return (
    <DisplayWrapper itemLoaded={!!level} notFoundCheckRequest={requests.GET_COMMISSIONS_LEVEL}>
      <ContentWrapper>{level ? <CommissionsLevelDetailView level={level} /> : undefined}</ContentWrapper>
    </DisplayWrapper>
  );
};
