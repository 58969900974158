import { Button, Checkbox, Col, Divider, Form, Input, List, Modal, Popconfirm, Row, Select, Space } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import React, { useEffect, useState } from "react";
import t from "../../../../../app/i18n";
import { SecondaryButton } from "../../../../../common/components/buttons/SecondaryButton";
import HiddenInput from "../../../../../common/components/form/components/HiddenInput";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import PopconfirmDeleteIcon from "../../../../../common/components/icons/PopconfirmDeleteIcon";
import { PageSizes, rowGutter } from "../../../../../common/constants";
import { RootArrayFormObject } from "../../../../../common/types";
import { selectStandardProps, useFormErrorHandler } from "../../../../../common/utils/formUtils";
import messageUtils from "../../../../../common/utils/messageUtils";
import { contains, paginationStandardProps, removeFromArray, replaceInArray } from "../../../../../common/utils/utils";
import { validationConstants, validationFunctions, validations } from "../../../../../common/utils/validationUtils";
import type { UUID } from "../../../../../typings/global";
import { requests } from "../../api";
import {
  createVehicleModelMappingActions,
  filterVehicleModelMappingsActions,
  updateVehicleModelMappingsActions
} from "../../ducks";
import { VehicleMappingType } from "../../enums";
import {
  UpdateVehicleMapping,
  UpdateVehicleMappingFormItem,
  VehicleMapping,
  VehicleMappingData,
  VehicleMappingFilterPageRequest,
  VehicleModelMappingFilterPageResult
} from "../../types";
import {
  parseVehicleMappingsFromIdentifiers,
  parseVehicleMappingsToUpdateFormItems,
  VEHICLE_IDENTIFIER_DELIMITER,
  VEHICLE_TYPES_PATH
} from "../../utils";
import VehicleModelCreateMappingModal from "./VehicleModelCreateMappingModal";

interface Props {
  selectedBrandId: UUID;
  selectedMappingTypes: VehicleMappingType[];
  mappingData: VehicleMappingData[];
  currentPage: VehicleModelMappingFilterPageResult;
  onFilter: typeof filterVehicleModelMappingsActions.request;
  onCreate: typeof createVehicleModelMappingActions.request;
  onUpdate: typeof updateVehicleModelMappingsActions.request;
  onReturnToBrandsClick: () => void;
}

const VehicleModelMappingsListForm = ({ selectedMappingTypes, mappingData, currentPage, ...props }: Props) => {
  const [form] = Form.useForm<RootArrayFormObject<UpdateVehicleMappingFormItem>>();
  useFormErrorHandler(form, "vehicle.attrs", [requests.UPDATE_VEHICLE_MODEL_MAPPINGS]);

  const [createMappingFormOpen, setCreateMappingFormOpen] = useState<boolean>(false);
  const [updatedRowsIds, setUpdatedRowsIds] = useState<UUID[]>([]);

  useEffect(() => {
    props.onFilter({
      id: props.selectedBrandId,
      object: {
        keyword: currentPage.keyword,
        pageIndex: 0,
        pageSize: PageSizes.SMALL,
        queriedMappings: selectedMappingTypes,
        excludeMapped: currentPage.excludeMapped
      }
    });
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      items: parseVehicleMappingsToUpdateFormItems(...currentPage.pageData)
    });
    setUpdatedRowsIds([]);
  }, [currentPage, form]);

  const handleFormFinish = (values: RootArrayFormObject<UpdateVehicleMappingFormItem>): void => {
    if (updatedRowsIds.length > 0) {
      props.onUpdate({
        id: props.selectedBrandId,
        object: {
          mappings: values.items
            .filter(mapping => contains(updatedRowsIds, mapping.id))
            .map<UpdateVehicleMapping>(mapping => parseVehicleMappingsFromIdentifiers(mapping) as UpdateVehicleMapping)
        }
      });
    } else {
      messageUtils.warnMessage(t("vehicle.validations.noChanges"));
    }
  };

  const handleSearchSubmit = (keyword: string): void => {
    if (validationFunctions.validateSearchKeyword(keyword)) {
      checkUnsavedChangesBeforePageChange({
        keyword,
        pageIndex: 0,
        pageSize: currentPage.pageSize,
        queriedMappings: selectedMappingTypes,
        excludeMapped: currentPage.excludeMapped
      });
    } else {
      messageUtils.errorMessage(
        t("validation.size", {
          min: validationConstants.SEARCH_KEYWORD_MIN_LENGTH,
          max: validationConstants.SEARCH_KEYWORD_MAX_LENGTH
        })
      );
    }
  };

  const handleExcludeMappedChange = (e: CheckboxChangeEvent): void => {
    checkUnsavedChangesBeforePageChange({
      keyword: currentPage.keyword,
      pageIndex: 0,
      pageSize: currentPage.pageSize,
      queriedMappings: selectedMappingTypes,
      excludeMapped: e.target.checked
    });
  };

  const handleListPageChange = (pageNumber: number): void => {
    checkUnsavedChangesBeforePageChange({
      keyword: currentPage.keyword,
      pageIndex: pageNumber - 1,
      pageSize: currentPage.pageSize,
      queriedMappings: selectedMappingTypes,
      excludeMapped: currentPage.excludeMapped
    });
  };

  const handleMappingRowChange = (id: UUID): void => {
    if (!contains(updatedRowsIds, id)) {
      setUpdatedRowsIds([...updatedRowsIds, id]);
    }
  };

  const handleRowResetClick = (rowId: UUID): void => {
    const row = currentPage.pageData.find(row => row.id === rowId);
    if (!row) {
      return;
    }
    const allRows = form.getFieldsValue().items;
    const newValue = parseVehicleMappingsToUpdateFormItems(row)[0];
    if (newValue) {
      form.setFieldsValue({
        items: replaceInArray(
          allRows,
          item => item?.id === rowId,
          () => newValue
        )
      });
    }

    setUpdatedRowsIds(removeFromArray(updatedRowsIds, stateRowId => stateRowId === rowId));
  };

  const checkUnsavedChangesBeforePageChange = (request: VehicleMappingFilterPageRequest): void => {
    if (updatedRowsIds.length > 0) {
      Modal.confirm({
        title: t("common.unsavedChanges"),
        okText: t("common.continue"),
        cancelText: t("common.back"),
        onOk: () => {
          props.onFilter({ id: props.selectedBrandId, object: request });
        }
      });
    } else {
      props.onFilter({ id: props.selectedBrandId, object: request });
    }
  };

  return (
    <>
      <Divider orientation="left">
        {currentPage.brand
          ? t("vehicle.titles.modelsMappingForBrand", { brandName: currentPage.brand.name })
          : t("vehicle.titles.modelsMapping")}
      </Divider>

      <Row gutter={rowGutter}>
        <Col span={16}>
          <Input.Search
            style={{ width: 350 }}
            enterButton
            allowClear
            defaultValue={currentPage.keyword}
            placeholder={t("vehicle.helpers.searchModelHint")}
            onSearch={handleSearchSubmit}
          />

          <span className="margin-left-small">
            <Checkbox style={{ marginTop: 4 }} checked={currentPage.excludeMapped} onChange={handleExcludeMappedChange}>
              {t("vehicle.actions.excludeMapped")}
            </Checkbox>
          </span>
        </Col>

        <Col span={8} className="right-align">
          <Space>
            <Button icon={<AntIcon type="double-left" />} onClick={props.onReturnToBrandsClick}>
              {t("vehicle.actions.backToBrands")}
            </Button>

            <SecondaryButton icon={<AntIcon type="plus" />} onClick={() => setCreateMappingFormOpen(true)}>
              {t("vehicle.actions.createModel")}
            </SecondaryButton>
          </Space>
        </Col>
      </Row>

      <Form form={form} layout="vertical" onFinish={handleFormFinish} name="vehicleModelMappingsListForm">
        <List<VehicleMapping>
          itemLayout="vertical"
          dataSource={currentPage.pageData}
          pagination={{
            ...paginationStandardProps,
            current: currentPage.pageIndex + 1,
            pageSize: currentPage.pageSize,
            total: currentPage.totalElementsCount,
            onChange: handleListPageChange
          }}
          renderItem={(item, rowIndex) => (
            <List.Item key={rowIndex}>
              <HiddenInput name={["items", rowIndex, "id"]} />
              <HiddenInput name={["items", rowIndex, "optimisticLockVersion"]} />

              <Row
                gutter={rowGutter}
                className={"vehicle-mappings-row" + (contains(updatedRowsIds, item.id) ? " edited-row" : "")}
              >
                <Col span={4}>
                  <Form.Item
                    name={["items", rowIndex, "name"]}
                    label={t("vehicle.attrs.name")}
                    rules={[validations.notBlank]}
                  >
                    <Input size="small" onChange={() => handleMappingRowChange(item.id)} />
                  </Form.Item>
                </Col>

                <Col span={16}>
                  <Row gutter={rowGutter}>
                    {Object.values(VehicleMappingType).map((type, index) => {
                      const typePath = VEHICLE_TYPES_PATH[type];
                      const mapping = mappingData.find(mapping => mapping.type === type);
                      const brandMapping = mapping
                        ? mapping.brands.find(
                            brand => currentPage.brand && currentPage.brand[typePath.keyId] === brand.id
                          )
                        : undefined;

                      return (
                        <React.Fragment key={index}>
                          <HiddenInput name={["items", rowIndex, typePath.keyId]} />
                          <HiddenInput name={["items", rowIndex, typePath.keyName]} />

                          {contains(selectedMappingTypes, type) ? (
                            <Col span={6} key={index}>
                              <Form.Item
                                name={["items", rowIndex, typePath.keyIdentifier]}
                                label={t(`vehicle.attrs.${typePath.keyIdentifier}`)}
                                rules={[validations.none]}
                              >
                                <Select
                                  {...selectStandardProps}
                                  allowClear
                                  size="small"
                                  options={(brandMapping && brandMapping.models ? brandMapping.models : []).map(
                                    item => ({
                                      value: item.id + VEHICLE_IDENTIFIER_DELIMITER + item.name,
                                      label: `${item.name} (${item.id})`
                                    })
                                  )}
                                  onChange={() => handleMappingRowChange(item.id)}
                                />
                              </Form.Item>
                            </Col>
                          ) : (
                            <HiddenInput name={["items", rowIndex, typePath.keyIdentifier]} />
                          )}
                        </React.Fragment>
                      );
                    })}
                  </Row>
                </Col>

                <Col span={4} className="right-align">
                  <Button
                    size="small"
                    icon={<AntIcon type="reload" />}
                    className="margin-bottom-tiny"
                    style={{ width: 100 }}
                    onClick={() => handleRowResetClick(item.id)}
                  >
                    {t("common.reset")}
                  </Button>
                  <br />

                  <Popconfirm
                    title={t("vehicle.titles.modelDelete")}
                    icon={<PopconfirmDeleteIcon />}
                    okText={t("common.yes")}
                    cancelText={t("common.no")}
                    okType="danger"
                    disabled
                  >
                    <Button
                      size="small"
                      icon={<AntIcon type="delete" />}
                      disabled
                      danger
                      className="margin-bottom-tiny"
                      style={{ width: 100 }}
                    >
                      {t("common.delete")}
                    </Button>
                  </Popconfirm>
                </Col>
              </Row>
            </List.Item>
          )}
        />

        <Button className="margin-top-medium" type="primary" htmlType="submit" icon={<AntIcon type="save" />}>
          {t("common.save")}
        </Button>
      </Form>

      <VehicleModelCreateMappingModal
        open={createMappingFormOpen}
        selectedBrand={currentPage.brand}
        selectedMappingTypes={selectedMappingTypes}
        mappingData={mappingData}
        onCreate={props.onCreate}
        onFormCancel={() => setCreateMappingFormOpen(false)}
      />
    </>
  );
};

export default VehicleModelMappingsListForm;
