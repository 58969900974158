import { DatePicker, Form, Modal } from "antd";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useCreateAgentCompletedEducation, useUpdateAgentCompletedEducation } from "../../../../common/api/queries";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import { ModalSizes } from "../../../../common/constants";
import { datePickerFormItemProps, datePickerStandardProps, setErrorsToForm } from "../../../../common/utils/formUtils";
import { validations } from "../../../../common/utils/validationUtils";
import { UUID } from "../../../../typings/global";
import { AgentEducationSelect } from "../../../enumerations/components/form/AgentEducationSelect";
import { selectAgentEducationsEnums } from "../../../enumerations/ducks";
import { AgentEducationType } from "../../enums";
import { AgentCompletedEducation, CreateUpdateAgentCompletedEducation } from "../../types";
import { convertAgentCompletedEducationToCreateUpdateObject } from "../../utils";

type Props = {
  isOpen: boolean;
  education?: AgentCompletedEducation;
  managerialCompletion?: { minDate: string };
  agentId: UUID;
  onCancel: VoidFunction;
  isFirstEducation?: boolean;
};

export const AgentCompletedEducationModalForm = ({
  isOpen,
  agentId,
  education,
  onCancel,
  managerialCompletion,
  isFirstEducation
}: Props) => {
  const { t } = useTranslation();

  const webinarEducationId = useSelector(selectAgentEducationsEnums).find(
    education => education.type === AgentEducationType.WEBINAR
  )?.id;

  const createAgentCompletedEducation = useCreateAgentCompletedEducation();
  const updateAgentCompletedEducation = useUpdateAgentCompletedEducation();

  const [form] = useForm<CreateUpdateAgentCompletedEducation>();

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    if (education) {
      const formData = convertAgentCompletedEducationToCreateUpdateObject(education);

      form.setFieldsValue(formData);
    }

    if (managerialCompletion) {
      form.setFieldValue("type", AgentEducationType.MANAGERIAL_ACADEMY_COMPLETION);
    }

    if (isFirstEducation) {
      form.setFieldValue("educationId", webinarEducationId);
    }
  }, [isOpen, education, form, managerialCompletion, isFirstEducation]);

  const managerialAcademyCompletionType = Form.useWatch("type", form);

  const handleFormSubmit = () => {
    form.validateFields().then(async values => {
      if (education) {
        updateAgentCompletedEducation.mutate(
          { id1: agentId, id2: education.id, object: values },
          {
            onSuccess: onCancel,
            onError: error => {
              setErrorsToForm(form, "agent.completedEducation.attrs", error.violations);
            }
          }
        );
      } else {
        createAgentCompletedEducation.mutate(
          { id: agentId, object: values },
          {
            onSuccess: onCancel,
            onError: error => {
              setErrorsToForm(form, "agent.completedEducation.attrs", error.violations);
            }
          }
        );
      }
    });
  };

  return (
    <Modal
      width={ModalSizes.SMALL}
      open={isOpen}
      title={education ? t("agent.education.titles.updateEducation") : t("agent.education.titles.createEducation")}
      cancelText={t("common.cancel")}
      okText={t("common.save")}
      confirmLoading={createAgentCompletedEducation.isPending || updateAgentCompletedEducation.isPending}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onCancel}
    >
      <Form form={form} layout="vertical" name="agentCompletedEducationForm">
        <HiddenInput name="optimisticLockVersion" />

        <Form.Item
          name="completedDate"
          label={t("agent.completedEducation.attrs.completedDate")}
          rules={[validations.notNull, validations.notFuture]}
          {...datePickerFormItemProps}
        >
          <DatePicker
            {...datePickerStandardProps}
            maxDate={dayjs()}
            minDate={managerialCompletion?.minDate ? dayjs(managerialCompletion?.minDate) : undefined}
          />
        </Form.Item>

        {managerialAcademyCompletionType ? undefined : (
          <Form.Item
            name="educationId"
            label={t("agent.completedEducation.attrs.education")}
            rules={[validations.notNull]}
          >
            <AgentEducationSelect />
          </Form.Item>
        )}

        <HiddenInput name="type" />
      </Form>
    </Modal>
  );
};
