import { Card } from "antd";
import { useEffect, useState } from "react";
import { PageSizes } from "../../../../../../../common/constants";
import { CommissionOrigin, CommissionProcessingResult, CommissionsBatchStep } from "../../../../enums";
import {
  Commission,
  CommissionsActions,
  CommissionsBatch,
  CommissionsFilterPageRequest,
  CommissionsFilterPageResult
} from "../../../../types";
import CalculatedCommissionsForm from "../../../forms/CalculatedCommissionsForm";
import CommissionForm from "../../../forms/CommissionForm";
import CommissionPostponeForm from "../../../forms/CommissionPostponeForm";
import BatchCommissionTableView from "../../BatchCommissionTableView";
import BatchCalculatedCommissionFilterView from "../../filter/BatchCalculatedCommissionFilterView";

interface Props {
  batch: CommissionsBatch;
  commissionsPage: CommissionsFilterPageResult;
  commissionsActions: CommissionsActions;
}

const CommissionsBatchCalculationStep = ({ batch, commissionsPage, commissionsActions }: Props) => {
  const [commissionToUpdate, setCommissionToUpdate] = useState<Commission>();
  const [commissionToPostpone, setCommissionToPostpone] = useState<Commission>();
  const [calculationsToUpdate, setCalculationsToUpdate] = useState<Commission>();

  useEffect(() => {
    if (batch.step === CommissionsBatchStep.CALCULATION) {
      commissionsActions.onFilter({
        id: batch.id,
        object: {
          pageIndex: 0,
          pageSize: PageSizes.HUGE,
          keyword: undefined,
          origin: CommissionOrigin.ALL,
          processingResult: CommissionProcessingResult.ALL,
          postponementReasons: [],
          institutionIds: []
        }
      });
    }
  }, [batch.step]);

  const handleFilterSubmit = (filter: CommissionsFilterPageRequest): void => {
    commissionsActions.onFilter({
      id: batch.id,
      object: { ...filter, pageIndex: 0, pageSize: commissionsPage.pageSize }
    });
  };

  const handleTablePageChange = (pageNumber: number): void => {
    const { pageSize, keyword, origin, processingResult, postponementReasons, institutionIds } = commissionsPage;

    commissionsActions.onFilter({
      id: batch.id,
      object: {
        pageIndex: pageNumber - 1,
        pageSize,
        keyword,
        origin,
        processingResult,
        postponementReasons,
        institutionIds
      }
    });
  };

  const handleCommissionUpdateClick = (commission: Commission): void => {
    setCommissionToUpdate(commission);
  };

  const handleCommissionFormCancel = (): void => {
    setCommissionToUpdate(undefined);
  };

  const handleCommissionPostponeClick = (commission: Commission): void => {
    setCommissionToPostpone(commission);
  };

  const handleCommissionPostponeFormCancel = (): void => {
    setCommissionToPostpone(undefined);
  };

  const handleCalculationsUpdateClick = (commission: Commission): void => {
    setCalculationsToUpdate(commission);
  };

  const handleCalculatedCommissionsFormCancel = (): void => {
    setCalculationsToUpdate(undefined);
  };

  return (
    <Card className="card-box">
      <BatchCalculatedCommissionFilterView showCalculationFilterOptions onFilterSubmit={handleFilterSubmit} />

      <BatchCommissionTableView
        commissionsPage={commissionsPage}
        batch={batch}
        viewType="calculated"
        onPageChange={handleTablePageChange}
        onUpdateClick={handleCommissionUpdateClick}
        onPostponeClick={handleCommissionPostponeClick}
        onTryToInclude={commissionsActions.onTryToInclude}
        onRecalculateForContract={commissionsActions.onRecalculateForContract}
        onCalculationsUpdateClick={handleCalculationsUpdateClick}
        onDelete={commissionsActions.onDelete}
      />

      <CommissionForm
        open={!!commissionToUpdate}
        formType="batchCommission"
        commission={commissionToUpdate}
        batchId={batch.id}
        onUpdate={commissionsActions.onUpdate}
        onFormCancel={handleCommissionFormCancel}
      />

      <CommissionPostponeForm
        commission={commissionToPostpone}
        batchId={batch.id}
        open={!!commissionToPostpone}
        onPostpone={commissionsActions.onPostpone}
        onFormCancel={handleCommissionPostponeFormCancel}
      />

      {calculationsToUpdate ? (
        <CalculatedCommissionsForm
          commission={calculationsToUpdate}
          batchId={batch.id}
          open={!!calculationsToUpdate}
          onUpdate={commissionsActions.onCalculationsUpdate}
          onFormCancel={handleCalculatedCommissionsFormCancel}
        />
      ) : undefined}
    </Card>
  );
};

export default CommissionsBatchCalculationStep;
