import { Alert, Button, Checkbox, Col, Divider, Form, Input, Row, Select, Space } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import HiddenInput from "../../../../../common/components/form/components/HiddenInput";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../../common/constants";
import ContactsForm from "../../../../../common/modules/contact/ContactsForm";
import { MarketingConsent } from "../../../../../common/modules/enums";
import { RootState } from "../../../../../common/types";
import { formatAgentShortSystemIdNumber } from "../../../../../common/utils/formatUtils";
import {
  getAllFieldsNames,
  resolveFormValidationError,
  selectTagsStandardProps,
  useFormErrorHandler
} from "../../../../../common/utils/formUtils";
import { contains, removeStringWhiteSpaces } from "../../../../../common/utils/utils";
import { validations } from "../../../../../common/utils/validationUtils";
import { selectUserAccount } from "../../../../auth/ducks";
import { AgentDirectoratesSelect } from "../../../../enumerations/components/form/AgentDirectoratesSelect";
import AgentSelect from "../../../../enumerations/components/form/AgentSelect";
import { selectAllAgentsEnums } from "../../../../enumerations/ducks";
import { AgentEnumeration } from "../../../../enumerations/types";
import { UserAccount } from "../../../../user/types";
import { requests } from "../../../api";
import { createAgentActions, updateAgentActions } from "../../../ducks";
import { AgentType, agentTypeTMap, TopAgentType } from "../../../enums";
import { Agent, AgentSearchProps, CreateUpdateAgent } from "../../../types";
import { convertAgentToCreateUpdateAgent, createPartialCreateUpdateAgent } from "../../../utils";
import { AgentPartnerIdNumber } from "../../fields/AgentPartnerIdNumber";
import { AgentTypeLongNameTag } from "../../tags/AgenTypeLongNameTag";
import AgentAddressesFormPart from "./AgentAddressesFormPart";
import LegalAgentDataFormPart from "./LegalAgentDataFormPart";
import NaturalAgentDataFormPart from "./NaturalAgentDataFormPart";
import SelfEmployedAgentDataFormPart from "./SelfEmployedAgentDataFormPart";

interface Props {
  agent?: Agent;
  agentSearch?: AgentSearchProps;
  onCreate?: typeof createAgentActions.request;
  onUpdate?: typeof updateAgentActions.request;
  onCancelClick?: () => void;
}

export const AgentForm = ({ agent, agentSearch, ...props }: Props) => {
  const { t } = useTranslation();

  const [form] = Form.useForm<CreateUpdateAgent>();
  useFormErrorHandler(form, "agent.attrs", [requests.CREATE_AGENT, requests.UPDATE_AGENT]);

  const userAccount = useSelector<RootState, UserAccount | undefined>(selectUserAccount);

  const [showDirectorateAlert, setShowDirectorateAlert] = useState(false);
  const [parent, setParent] = useState<AgentEnumeration>();
  const [serviceAddressEnabled, setServiceAddressEnabled] = useState<boolean>(!!agent?.serviceAddress);
  const [correspondenceAddressEnabled, setCorrespondenceAddressEnabled] = useState<boolean>(
    !!agent?.correspondenceAddress
  );

  const agentsEnums = useSelector<RootState, AgentEnumeration[]>(selectAllAgentsEnums);
  const [subordinateAgentsCountWithSameDirectorate, setSubordinateAgentsCountWithSameDirectorate] = useState(0);

  const directorateId = Form.useWatch("directorateId", form);

  useEffect(() => {
    if (agent) {
      form.setFieldsValue(convertAgentToCreateUpdateAgent(agent));
    }
  }, [agent, form]);

  useEffect(() => {
    if (agent && agent?.directorate?.id !== directorateId && subordinateAgentsCountWithSameDirectorate) {
      setShowDirectorateAlert(true);
    }

    if (showDirectorateAlert && agent?.directorate?.id === directorateId) {
      setShowDirectorateAlert(false);
    }
  }, [agent, directorateId, showDirectorateAlert, subordinateAgentsCountWithSameDirectorate]);

  useEffect(() => {
    if (agent) {
      setSubordinateAgentsCountWithSameDirectorate(
        agentsEnums.filter(
          agentEnum =>
            agent.accessTreePath &&
            agentEnum.accessTreePath?.includes(agent.accessTreePath) &&
            agent.accessTreePath !== agentEnum.accessTreePath &&
            agent.directorate?.id === agentEnum.directorate?.id
        ).length
      );
    }
  }, [agent, agentsEnums]);

  useEffect(() => {
    if (agentSearch?.result?.data) {
      const { keyword, agentType, data } = agentSearch.result;
      if (
        (agentType === AgentType.NATURAL && keyword === form.getFieldValue("personalIdentificationNumber")) ||
        (agentType !== AgentType.NATURAL && keyword === form.getFieldValue("companyRegistrationNumber"))
      ) {
        const formData = form.getFieldsValue();
        form.setFieldsValue({
          ...createPartialCreateUpdateAgent(data),
          parentId: formData.parentId,
          structureIdNumber: formData.structureIdNumber,
          partnerIdNumber: formData.partnerIdNumber,
          canBeGainer: formData.canBeGainer,
          deactivated: formData.deactivated
        });

        setServiceAddressEnabled(!!data.serviceAddress);
        setCorrespondenceAddressEnabled(!!data.correspondenceAddress);
      }
    }
  }, [agentSearch?.result]);

  useEffect(() => {
    if (!agent) {
      form.setFieldValue("directorateId", parent?.directorate?.id);
    }
  }, [parent, agent]);

  const handleAgentTypeChange = (type: AgentType): void => {
    form.setFieldsValue({ canBeGainer: type !== AgentType.NATURAL });
  };

  const handleTipperChange = (event: CheckboxChangeEvent): void => {
    if (event.target.checked) {
      form.setFieldsValue({ nbsRegistrationNumber: undefined });
    }
  };

  const handlePinOrCrnSearchOrChange = (pinOrCrn: string): void => {
    if (agentSearch) {
      const type = form.getFieldValue("type") as AgentType;
      form
        .validateFields(type === AgentType.NATURAL ? ["personalIdentificationNumber"] : ["companyRegistrationNumber"])
        .then(() => agentSearch.onSearch({ keyword: pinOrCrn, agentType: type }))
        .catch(resolveFormValidationError);
    }
  };

  const handleFormSubmit = (): void => {
    let fieldsToValidate = getAllFieldsNames(form);
    fieldsToValidate = serviceAddressEnabled
      ? fieldsToValidate
      : fieldsToValidate.filter(fieldPath => !contains(fieldPath, "serviceAddress"));
    fieldsToValidate = correspondenceAddressEnabled
      ? fieldsToValidate
      : fieldsToValidate.filter(fieldPath => !contains(fieldPath, "correspondenceAddress"));

    form
      .validateFields(fieldsToValidate)
      .then(values => {
        const processedValues = { ...values };

        processedValues.structureIdNumber = processedValues.structureIdNumber
          ? parseInt(processedValues.structureIdNumber.toString())
          : undefined;
        processedValues.bankAccountNumber = removeStringWhiteSpaces(processedValues.bankAccountNumber);
        processedValues.sectors = processedValues.sectors ? processedValues.sectors : [];
        processedValues.contacts =
          processedValues.contacts?.map(contact => ({ ...contact, marketingConsent: MarketingConsent.NOT_GRANTED })) ||
          [];
        processedValues.serviceAddress = serviceAddressEnabled ? processedValues.serviceAddress : undefined;
        processedValues.correspondenceAddress = correspondenceAddressEnabled
          ? processedValues.correspondenceAddress
          : undefined;

        if (agent) {
          props.onUpdate?.({ id: agent.id, object: processedValues });
        } else {
          props.onCreate?.(processedValues);
        }
      })
      .catch(resolveFormValidationError);
  };

  const colSpan = 4;

  return (
    <Form form={form} layout="vertical" name="agentUpdateForm">
      <HiddenInput name="optimisticLockVersion" />
      {agent ? (
        <HiddenInput name="type" />
      ) : (
        <Row gutter={rowGutter}>
          <Col span={6}>
            <Form.Item
              name="type"
              label={t("agent.enums.type._label")}
              rules={[validations.notNull]}
              initialValue={AgentType.NATURAL}
            >
              <Select<AgentType>
                {...selectTagsStandardProps(agentTypeTMap)}
                options={Object.keys(AgentType).map(type => ({
                  value: type,
                  label: <AgentTypeLongNameTag type={AgentType[type as keyof typeof AgentType]} />
                }))}
                onChange={handleAgentTypeChange}
              />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row gutter={rowGutter}>
        {!agent ? (
          <Col span={colSpan}>
            <Form.Item noStyle shouldUpdate={(prev, next) => prev.deactivated !== next.deactivated}>
              {({ getFieldValue }) => (
                <AgentSelect
                  formItemProps={{
                    name: "parentId",
                    label: t("agent.attrs.parentId"),
                    rules: [validations.notNull]
                  }}
                  optionsProps={{
                    filter: agent => getFieldValue("deactivated") || !agent.deactivated,
                    onChange: setParent
                  }}
                />
              )}
            </Form.Item>
          </Col>
        ) : (
          <HiddenInput name={"parentId"} />
        )}

        <Col span={colSpan}>
          <Form.Item
            name="directorateId"
            label={t("agent.attrs.directorate")}
            rules={[agent?.parent?.directorate || parent?.directorate ? validations.notNull : validations.none]}
          >
            <AgentDirectoratesSelect allowClear={!agent?.parent?.directorate && !parent?.directorate} />
          </Form.Item>
        </Col>

        {userAccount?.agent?.topAgentType !== TopAgentType.PLUS && (
          <Col span={colSpan}>
            <Form.Item
              name="structureIdNumber"
              label={t("agent.attrs.structureIdNumber")}
              rules={[validations.size(1, 3), validations.numeric]}
            >
              <Input addonAfter={formatAgentShortSystemIdNumber(agent)} />
            </Form.Item>
          </Col>
        )}

        <Col span={colSpan}>
          <AgentPartnerIdNumber shouldAutoLoad={!agent} />
        </Col>

        {showDirectorateAlert ? (
          <Col span={24}>
            <Alert
              message={t("agent.helpers.agentDirectorateChangeWarning", {
                count: subordinateAgentsCountWithSameDirectorate
              })}
              type="info"
              showIcon
            />
          </Col>
        ) : undefined}
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name="canBeGainer"
            valuePropName="checked"
            className={showDirectorateAlert ? "form-item-without-label" : undefined}
            rules={[validations.none]}
            initialValue={false}
          >
            <Checkbox>{t("agent.attrs.canBeGainer")}</Checkbox>
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="deactivated"
            valuePropName="checked"
            className={showDirectorateAlert ? "form-item-without-label" : undefined}
            rules={[validations.none]}
            initialValue={false}
          >
            <Checkbox>{t("agent.attrs.deactivated")}</Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Divider orientation="left">{t("agent.titles.basicData")}</Divider>

      <Form.Item noStyle shouldUpdate={(prev, next) => prev.type !== next.type}>
        {({ getFieldValue }) => {
          switch (getFieldValue("type")) {
            case AgentType.NATURAL:
              return (
                <NaturalAgentDataFormPart
                  form={form}
                  searchInProgress={agentSearch?.inProgress}
                  onTipperChange={handleTipperChange}
                  onPinSearchOrChange={agentSearch ? handlePinOrCrnSearchOrChange : undefined}
                />
              );
            case AgentType.SELF_EMPLOYED:
              return (
                <SelfEmployedAgentDataFormPart
                  form={form}
                  searchInProgress={agentSearch?.inProgress}
                  onTipperChange={handleTipperChange}
                  onCrnSearchOrChange={agentSearch ? handlePinOrCrnSearchOrChange : undefined}
                />
              );
            case AgentType.LEGAL:
              return (
                <LegalAgentDataFormPart
                  agent={agent}
                  searchInProgress={agentSearch?.inProgress}
                  onTipperChange={handleTipperChange}
                  onCrnSearchOrChange={agentSearch ? handlePinOrCrnSearchOrChange : undefined}
                />
              );
            default:
              return null;
          }
        }}
      </Form.Item>

      <Divider orientation="left">{t("agent.titles.addresses")}</Divider>

      <Form.Item noStyle shouldUpdate={(prev, next) => prev.type !== next.type}>
        {({ getFieldValue }) => (
          <div style={{ maxWidth: 1100 }}>
            <AgentAddressesFormPart
              form={form}
              agentType={getFieldValue("type")}
              serviceAddressEnabled={serviceAddressEnabled}
              correspondenceAddressEnabled={correspondenceAddressEnabled}
              onServiceAddressEnabledChange={setServiceAddressEnabled}
              onCorrespondenceAddressEnabledChange={setCorrespondenceAddressEnabled}
            />
          </div>
        )}
      </Form.Item>

      <Divider orientation="left">{t("agent.titles.contacts")}</Divider>

      <ContactsForm />

      <div className="margin-top-large">
        <Space>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.parentId !== next.parentId}>
            <Button type="primary" icon={<AntIcon type="save" />} onClick={handleFormSubmit}>
              {t("common.save")}
            </Button>
          </Form.Item>
          {props.onCancelClick && (
            <Button onClick={props.onCancelClick} icon={<AntIcon type="close" />}>
              {t("common.cancel")}
            </Button>
          )}
        </Space>
      </div>
    </Form>
  );
};
