import { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import AnonymousContentWrapper from "../../../common/modules/wrappers/AnonymousContentWrapper";
import { ActionProps, EntityIdObject, RootState } from "../../../common/types";
import { DASHBOARD_ROUTE_PATHS } from "../../dashboard/paths";
import { selectRouterLocationSearchParam } from "../../ducks";
import ResetPasswordForm from "../components/forms/ResetPasswordForm";
import { resetPasswordActions } from "../ducks";
import { PasswordReset } from "../types";

interface StateProps {
  token: string;
}

interface ActionsMap {
  resetPassword: typeof resetPasswordActions.request;
}

type Props = StateProps & ActionProps<ActionsMap>;

const ResetPasswordContainer = (props: Props) => {
  const { id } = useParams<EntityIdObject>() as { id: string };
  const navigate = useNavigate();

  useEffect(() => {
    const { token } = props;
    if (!token) {
      navigate(DASHBOARD_ROUTE_PATHS.homepage.to, { replace: true });
    }
  }, []);

  const handleResetPasswordFormSubmit = (passwordReset: PasswordReset): void => {
    props.actions.resetPassword({ id, object: passwordReset });
  };

  return (
    <AnonymousContentWrapper>
      <ResetPasswordForm resetPasswordParams={{ token: props.token }} onFormSubmit={handleResetPasswordFormSubmit} />
    </AnonymousContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  token: selectRouterLocationSearchParam(state, "token") ?? ""
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      resetPassword: resetPasswordActions.request
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, Record<string, any>, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordContainer);
