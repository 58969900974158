import { Alert, Col, Divider, Form, Input, InputNumber, Modal, Row } from "antd";
import Big from "big.js";
import React, { useEffect, useState } from "react";
import t from "../../../../../app/i18n";
import InputAddon from "../../../../../common/components/form/addons/InputAddon";
import HiddenCheckbox from "../../../../../common/components/form/components/HiddenCheckbox";
import HiddenInput from "../../../../../common/components/form/components/HiddenInput";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import { ModalSizes, rowGutter } from "../../../../../common/constants";
import { formatAgentName, formatLocaleCurrency } from "../../../../../common/utils/formatUtils";
import {
  inputNumberDecimalStandardProps,
  resolveFormValidationError,
  useFormErrorHandler
} from "../../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import messageUtils from "../../../../../common/utils/messageUtils";
import { bigToFloat, isNumber } from "../../../../../common/utils/utils";
import { validations } from "../../../../../common/utils/validationUtils";
import type { UUID } from "../../../../../typings/global";
import { requests } from "../../api";
import { updateCalculatedCommissionsActions } from "../../ducks";
import {
  CalculatedCommission,
  Commission,
  UpdateCalculatedCommission,
  UpdateCalculatedCommissionList
} from "../../types";

interface Props {
  commission: Commission;
  batchId: UUID;
  open: boolean;
  onUpdate: typeof updateCalculatedCommissionsActions.request;
  onFormCancel: () => void;
}

const CalculatedCommissionsForm = ({ commission, batchId, open, onUpdate, onFormCancel }: Props) => {
  const [form] = Form.useForm<UpdateCalculatedCommissionList>();
  useFormErrorHandler(form, "commissions.batch.attrs.commission.calculatedCommissions", [
    requests.UPDATE_CALCULATED_COMMISSIONS
  ]);

  const [hasAmountsSumError, setHasAmountsSumError] = useState<boolean>(false);

  const topAgentId = commission.calculatedCommissions[0]?.gainer.id;
  const dividerPositions: number[] = [];
  for (let i = 0; i < commission.calculatedCommissions.length; i++) {
    if (commission.calculatedCommissions[i]?.gainer.id === topAgentId) {
      dividerPositions.push(i);
    }
  }

  const colSpan = 6;

  useEffect(() => {
    if (open && commission) {
      form.setFieldsValue({
        calculatedCommissions: commission.calculatedCommissions.map<UpdateCalculatedCommission>(commission => ({
          id: commission.id,
          optimisticLockVersion: commission.optimisticLockVersion,
          index: commission.index,
          commissionAmount: commission.commissionAmount,
          modified: commission.modified || commission.errors.length > 0,
          modificationReason: commission.modificationReason
        }))
      });
      checkAmountsSum(commission.calculatedCommissions);
    }
  }, [open, commission, form]);

  const inProgress = useRequestFinishedCallback([requests.UPDATE_CALCULATED_COMMISSIONS], onFormCancel);

  const handleCommissionAmountChange = (commissionAmount: string | number, index: number): void => {
    const calculatedCommission = commission.calculatedCommissions[index];
    const modified =
      calculatedCommission?.modified ||
      calculatedCommission?.commissionAmount !== commissionAmount ||
      calculatedCommission?.errors.length > 0;

    const values = [...form.getFieldsValue().calculatedCommissions];
    if (values[index]?.modified !== modified) {
      values[index] = {
        ...(values[index] as UpdateCalculatedCommission),
        modified,
        modificationReason: modified ? (values[index]?.modificationReason as string) : ""
      };
      form.setFieldsValue({ calculatedCommissions: values as UpdateCalculatedCommission[] });
    }
    checkAmountsSum(values);
  };

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        if (hasAmountsSumError) {
          messageUtils.errorNotification({
            message: t("common.error"),
            description: t("commissions.batch.validations.commissionAmountsSum", {
              commissionAmount: formatLocaleCurrency(commission.commissionAmount)
            })
          });
        } else {
          onUpdate({ id1: batchId, id2: commission.id, object: values });
        }
      })
      .catch(resolveFormValidationError);
  };

  const checkAmountsSum = (calculatedCommissions: CalculatedCommission[] | UpdateCalculatedCommission[]): void => {
    let sum = new Big(0);
    calculatedCommissions.forEach(calculatedCommission => {
      sum = sum.plus(isNumber(calculatedCommission?.commissionAmount) ? calculatedCommission.commissionAmount : 0);
    });
    setHasAmountsSumError(commission.commissionAmount !== bigToFloat(sum));
  };

  return (
    <Modal
      width={ModalSizes.LARGE}
      open={open}
      title={t("commissions.batch.titles.updateCalculatedCommissions")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields(["calculatedCommissions"])}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <Form form={form} layout="vertical" name="calculatedCommissionsForm">
        <Form.List name={["calculatedCommissions"]}>
          {fields => (
            <>
              {fields.map(({ key, ...field }) => (
                <React.Fragment key={key}>
                  <HiddenInput name={[field.name, "id"]} />
                  <HiddenInput name={[field.name, "optimisticLockVersion"]} />
                  <HiddenInput name={[field.name, "index"]} />
                  <HiddenCheckbox name={[field.name, "modified"]} />

                  {dividerPositions.indexOf(field.name) >= 0 && (
                    <Row gutter={rowGutter}>
                      <Col span={24}>
                        <Divider orientation="center" style={{ marginTop: 12, marginBottom: 8 }}>
                          {t(`contract.attrs.gainerRecords.gainer${dividerPositions.indexOf(field.name) + 1}Id`)}
                        </Divider>
                      </Col>
                    </Row>
                  )}

                  <Row gutter={rowGutter}>
                    <Col span={colSpan} style={{ marginTop: 36 }}>
                      <b>
                        <Ellipsis>{formatAgentName(commission?.calculatedCommissions[field.name]?.gainer)}</Ellipsis>
                      </b>
                    </Col>

                    <Col span={colSpan}>
                      <Form.Item
                        {...field}
                        name={[field.name, "commissionAmount"]}
                        label={t("commissions.batch.attrs.commission.calculatedCommissions.commissionAmount")}
                        rules={[validations.notNull]}
                      >
                        <InputNumber
                          {...inputNumberDecimalStandardProps}
                          addonAfter={<InputAddon type="euro" />}
                          onChange={value => (value ? handleCommissionAmountChange(value, field.name) : undefined)}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={colSpan * 2}>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prev, next) =>
                          prev.calculatedCommissions[field.name].modified !==
                          next.calculatedCommissions[field.name].modified
                        }
                      >
                        {({ getFieldValue }) =>
                          getFieldValue(["calculatedCommissions", field.name, "modified"]) && (
                            <Form.Item
                              {...field}
                              name={[field.name, "modificationReason"]}
                              label={t("commissions.batch.attrs.commission.calculatedCommissions.modificationReason")}
                              rules={[validations.notBlank, validations.size(1, 255)]}
                            >
                              <Input />
                            </Form.Item>
                          )
                        }
                      </Form.Item>
                    </Col>
                  </Row>
                </React.Fragment>
              ))}
            </>
          )}
        </Form.List>
      </Form>

      {hasAmountsSumError && (
        <Row gutter={rowGutter}>
          <Col span={24}>
            <Alert
              type="error"
              showIcon
              message={t("commissions.batch.validations.commissionAmountsSum", {
                commissionAmount: formatLocaleCurrency(commission?.commissionAmount)
              })}
            />
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default CalculatedCommissionsForm;
