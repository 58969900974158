import { Card, Flex, Form, Modal, Tabs, TabsProps } from "antd";
import { TablePaginationConfig } from "antd/lib/table";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import t from "../../../app/i18n";
import AntIcon from "../../../common/components/icons/AntIcon";
import BackNavigationArrow from "../../../common/components/views/BackNavigationArrow";
import ItemCreatedUpdatedInfoView from "../../../common/components/views/ItemCreatedUpdatedInfoView";
import { PageSizes } from "../../../common/constants";
import HistoryView from "../../../common/modules/history/HistoryView";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import DisplayWrapper from "../../../common/modules/wrappers/DisplayWrapper";
import { Permission } from "../../../common/security/authorization/enums";
import { EntityIdObject, RootState } from "../../../common/types";
import { useFormErrorHandler } from "../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../common/utils/hooksUtils";
import { appendSearchParamsToURL, numberOrZero } from "../../../common/utils/utils";
import type { UUID } from "../../../typings/global";
import { TopAgentType } from "../../agent/enums";
import { selectHasPermissions, selectUserAccount } from "../../auth/ducks";
import { deleteStateContractPageAction } from "../../contract/ducks";
import { ContractStatus, ContractView } from "../../contract/enums";
import { ContractFilterPageResult } from "../../contract/types";
import { ProductGroupCategory } from "../../product/enums";
import { ClientUserAccountWithUserAgentView, UserAccount } from "../../user/types";
import { requests } from "../api";
import ClientFormBody from "../components/forms/ClientFormBody";
import ClientTypeTag from "../components/tags/ClientTypeTag";
import ClientAttachmentsTableView from "../components/views/detail/attachments/ClientAttachmentsTableView";
import { ClientDetailView } from "../components/views/detail/ClientDetailView";
import {
  ClientPortfolioContracts,
  ClientPortfolioView
} from "../components/views/detail/portfolio/ClientPortfolioView";
import ClientUserAccountsView from "../components/views/list/ClientUserAccountsView";
import {
  createUserWithClientUserAccountActions,
  deleteClientActions,
  deleteClientAttachmentActions,
  deleteClientUserAccountActions,
  deleteStateClientDetailAction,
  deleteStateClientUserAccountsAction,
  downloadClientAttachmentsAsZipActions,
  filterClientActiveContractsActions,
  filterClientDetailContractsActions,
  getClientActions,
  getClientUserAccountsActions,
  resendUserConfirmLinkActions,
  selectClientActiveContractsPage,
  selectClientDetail,
  selectClientDetailContractsPage,
  selectClientUserAccounts,
  updateClientActions,
  updateClientUserAccountActions,
  uploadClientAttachmentsActions
} from "../ducks";
import { Client, CreateUpdateClient } from "../types";

const TAB = {
  CLIENT: "client",
  PORTFOLIO: "portfolio",
  HISTORY: "history",
  ACCOUNTS: "accounts",
  ATTACHMENTS: "attachments"
};

export const ClientDetailContainer = () => {
  const { id } = useParams<EntityIdObject>() as { id: string };
  const [activeContracts, setActiveContracts] = useState<ClientPortfolioContracts>({
    riskContracts: [],
    propertyContracts: [],
    reserveContracts: [],
    futureContracts: []
  });
  const navigate = useNavigate();
  const [urlParams] = useSearchParams();

  const client = useSelector<RootState, Client | undefined>(selectClientDetail);
  const accounts = useSelector<RootState, ClientUserAccountWithUserAgentView[]>(selectClientUserAccounts);
  const hasClientUpdatePermission = useSelector<RootState, boolean>(selectHasPermissions(Permission.CLIENT_UPDATE));
  const hasClientAccountsPermission = useSelector<RootState, boolean>(selectHasPermissions(Permission.CLIENT_ACCOUNTS));
  const userAccount = useSelector<RootState, UserAccount | undefined>(selectUserAccount);
  const clientDetailContractsPage = useSelector<RootState, ContractFilterPageResult>(selectClientDetailContractsPage);
  const clientActiveContractsPage = useSelector<RootState, ContractFilterPageResult>(selectClientActiveContractsPage);

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          getClient: getClientActions.request,
          updateClient: updateClientActions.request,
          deleteClient: deleteClientActions.request,
          deleteStateClientDetail: deleteStateClientDetailAction,
          getClientUserAccounts: getClientUserAccountsActions.request,
          createUserWithClientUserAccount: createUserWithClientUserAccountActions.request,
          resendUserConfirmLink: resendUserConfirmLinkActions.request,
          updateClientUserAccount: updateClientUserAccountActions.request,
          deleteClientUserAccount: deleteClientUserAccountActions.request,
          deleteStateClientUserAccounts: deleteStateClientUserAccountsAction,
          filterClientDetailContracts: filterClientDetailContractsActions.request,
          filterClientActiveContracts: filterClientActiveContractsActions.request,
          deleteStateContractPage: deleteStateContractPageAction,
          downloadClientAttachmentsAsZip: downloadClientAttachmentsAsZipActions.request,
          uploadClientAttachments: uploadClientAttachmentsActions.request,
          deleteClientAttachment: deleteClientAttachmentActions.request
        },
        dispatch
      ),
    [dispatch]
  );

  const [form] = Form.useForm<CreateUpdateClient>();
  useFormErrorHandler(form, "client.attrs", [requests.UPDATE_CLIENT]);

  const [tabKey, setTabKey] = useState<string>(TAB.CLIENT);
  const [updateMode, setUpdateMode] = useState<boolean>(false);

  const isClientPortfolioFullCovered =
    !!activeContracts.riskContracts.length &&
    !!activeContracts.reserveContracts.length &&
    !!activeContracts.propertyContracts.length &&
    !!activeContracts.futureContracts.length;

  useRequestFinishedCallback([requests.UPDATE_CLIENT], () => setUpdateMode(false));

  useEffect(() => {
    actions.getClient({ id });

    actions.filterClientDetailContracts({
      pageIndex: numberOrZero(urlParams.get("pageIndex")),
      pageSize: PageSizes.SMALL,
      report: ContractView.INTERNAL_GENERIC_REPORT,
      clientId: id
    });

    actions.filterClientActiveContracts({
      pageIndex: 1,
      pageSize: PageSizes.HUGE,
      report: ContractView.INTERNAL_GENERIC_REPORT,
      clientId: id,
      statuses: [ContractStatus.ACTIVE, ContractStatus.UNSTARTED]
    });

    const urlTabKey = urlParams.get("tab");
    switch (urlTabKey) {
      case TAB.CLIENT:
      case TAB.PORTFOLIO:
      case TAB.HISTORY:
      case TAB.ATTACHMENTS:
        setTabKey(urlTabKey);
        break;
      case TAB.ACCOUNTS:
        setTabKey(hasClientAccountsPermission ? urlTabKey : TAB.CLIENT);
        break;
      default:
        setTabKey(TAB.CLIENT);
        break;
    }

    return () => {
      actions.deleteStateClientDetail();
      actions.deleteStateContractPage();
    };
  }, [id]);

  useEffect(() => {
    const risksContracts = clientActiveContractsPage.pageData.filter(
      contract => contract.product.productGroup.category === ProductGroupCategory.RISK
    );

    const reserveContracts = clientActiveContractsPage.pageData.filter(
      contract => contract.product.productGroup.category === ProductGroupCategory.RESERVE
    );

    const propertyContracts = clientActiveContractsPage.pageData.filter(
      contract => contract.product.productGroup.category === ProductGroupCategory.PROPERTY
    );

    const futureContracts = clientActiveContractsPage.pageData.filter(
      contract => contract.product.productGroup.category === ProductGroupCategory.FUTURE
    );

    setActiveContracts({
      riskContracts: risksContracts,
      reserveContracts: reserveContracts,
      propertyContracts: propertyContracts,
      futureContracts: futureContracts
    });
  }, [clientActiveContractsPage]);

  const handleTabKeyChange = (key: string): void => {
    if (tabKey === TAB.CLIENT && updateMode) {
      Modal.confirm({
        title: t("client.helpers.unsavedChanges"),
        okText: t("client.helpers.unsavedChangesConfirm"),
        cancelText: t("common.back"),
        onOk: () => moveViewToTab(key)
      });
    } else {
      moveViewToTab(key);
    }
  };

  const handleContractsTablePageChange = (pagination: TablePaginationConfig) => {
    const { report, clientId } = clientDetailContractsPage;
    navigate(appendSearchParamsToURL({ pageIndex: (pagination.current as number) - 1 }), { replace: true });
    actions.filterClientDetailContracts({
      pageIndex: (pagination.current as number) - 1,
      pageSize: pagination.pageSize,
      report,
      clientId
    });
  };

  const handleUpdateModeToggle = (): void => {
    setUpdateMode(!updateMode);
  };

  const handleUpdateFormSubmit = (clientData: CreateUpdateClient, clientId: UUID): void => {
    actions.updateClient({ id: clientId, object: clientData });
  };

  const moveViewToTab = (tabKey: string): void => {
    setTabKey(tabKey);
    navigate(appendSearchParamsToURL({ pageIndex: undefined, tab: tabKey }), { replace: true });
  };

  const getItems = (client: Client, updateMode: boolean, accounts: ClientUserAccountWithUserAgentView[]) => {
    const items: TabsProps["items"] = [];

    items.push({
      key: TAB.CLIENT,
      label: (
        <span>
          <AntIcon type="solution" className="margin-right-tiny" />
          {t("client.titles.data")}
        </span>
      ),
      children: updateMode ? (
        <Form form={form} layout="vertical" name="updateClientForm">
          <ClientFormBody
            form={form}
            client={client}
            viewType="form"
            onFormSubmit={clientCreateUpdate => handleUpdateFormSubmit(clientCreateUpdate, client.id)}
            onCancelClick={handleUpdateModeToggle}
          />
        </Form>
      ) : (
        <ClientDetailView
          client={client}
          contractsPage={clientDetailContractsPage}
          onUpdateClick={handleUpdateModeToggle}
          onDelete={actions.deleteClient}
          onContractsTablePageChange={handleContractsTablePageChange}
        />
      )
    });

    if (userAccount?.agent?.topAgentType === TopAgentType.PLUS) {
      items.push({
        key: TAB.PORTFOLIO,
        label: (
          <span>
            <AntIcon type="appstore" className="margin-right-tiny" />
            {t("client.titles.portfolio")}

            {isClientPortfolioFullCovered ? (
              <AntIcon type="check" color="green" style={{ marginLeft: 4 }} />
            ) : undefined}
          </span>
        ),
        children: (
          <Flex style={{ paddingTop: 16 }} justify="center">
            <ClientPortfolioView contracts={activeContracts} />
          </Flex>
        )
      });
    }

    items.push({
      key: TAB.HISTORY,
      label: (
        <span>
          <AntIcon type="history" className="margin-right-tiny" />
          {t("client.titles.history")}
        </span>
      ),
      children: <HistoryView item={client} translationRootPath="client.attrs" type="client" />
    });

    items.push({
      key: TAB.ATTACHMENTS,
      label: (
        <span>
          <AntIcon type="paper-clip" className="margin-right-tiny" />
          {t("client.titles.attachments")}
        </span>
      ),
      children: (
        <ClientAttachmentsTableView
          client={client}
          onDownloadAsZip={actions.downloadClientAttachmentsAsZip}
          onUpload={hasClientUpdatePermission ? actions.uploadClientAttachments : undefined}
          onDelete={hasClientUpdatePermission ? actions.deleteClientAttachment : undefined}
        />
      )
    });

    if (hasClientAccountsPermission) {
      items.push({
        key: TAB.ACCOUNTS,
        label: (
          <span>
            <AntIcon type="key" className="margin-right-tiny" />
            {t("client.titles.accounts")}
          </span>
        ),
        children: (
          <ClientUserAccountsView
            client={client}
            accounts={accounts}
            onGet={actions.getClientUserAccounts}
            onCreate={actions.createUserWithClientUserAccount}
            onResendConfirmLink={actions.resendUserConfirmLink}
            onUpdate={actions.updateClientUserAccount}
            onDelete={actions.deleteClientUserAccount}
            onDeleteStateClientUserAccounts={actions.deleteStateClientUserAccounts}
          />
        )
      });
    }

    return items;
  };

  return (
    <ContentWrapper>
      <DisplayWrapper itemLoaded={!!client} notFoundCheckRequest={requests.GET_CLIENT}>
        {client && (
          <Card
            className="card-box"
            title={
              <>
                <BackNavigationArrow>
                  <h2 className="left-float">{client.aggregatedName}</h2>
                </BackNavigationArrow>
                <ClientTypeTag style={{ margin: "6px 0 0 8px" }} type={client.type} />
              </>
            }
          >
            <ItemCreatedUpdatedInfoView item={client} className="clear-both margin-bottom-medium" />

            <Tabs activeKey={tabKey} onChange={handleTabKeyChange} items={getItems(client, updateMode, accounts)} />
          </Card>
        )}
      </DisplayWrapper>
    </ContentWrapper>
  );
};
