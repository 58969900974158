import { Card, Result } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import t from "../../../app/i18n";
import AntIcon from "../../../common/components/icons/AntIcon";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import DisplayWrapper from "../../../common/modules/wrappers/DisplayWrapper";
import NotFound from "../../../common/pages/NotFound/NotFound";
import { ActionProps, RootState } from "../../../common/types";
import { selectPartnerSsoTempData, selectRouterLocationSearch } from "../../ducks";
import { deleteStatePartnerSsoTempDataAction, monlyAuthorizationCodeGrantActions } from "../ducks";
import { PartnerSsoType } from "../enums";
import { PartnerSsoTempData } from "../types";

interface StateProps {
  urlSearchQuery: string;
  partnerSsoTempData?: PartnerSsoTempData;
}

interface ActionsMap {
  monlyAuthorizationCodeGrant: typeof monlyAuthorizationCodeGrantActions.request;
  deleteStatePartnerSsoTempData: typeof deleteStatePartnerSsoTempDataAction;
}

const PartnerSsoOAuthClientContainer = ({
  urlSearchQuery,
  partnerSsoTempData,
  actions
}: StateProps & ActionProps<ActionsMap>) => {
  const [ssoParamsState, setSsoParamsState] = useState<"found" | "notFound" | "notInitialized">("notInitialized");

  useEffect(() => {
    const urlParams = new URLSearchParams(urlSearchQuery);

    const code = urlParams.get("code");
    const state = urlParams.get("state");

    if (code && state) {
      if (partnerSsoTempData?.token === state) {
        actions.deleteStatePartnerSsoTempData();
        const [statePrefix] = state.split("_");
        switch (statePrefix) {
          case PartnerSsoType.MONLY:
            setSsoParamsState("found");
            actions.monlyAuthorizationCodeGrant(code);
            break;
          default:
            setSsoParamsState("notFound");
            break;
        }
      } else {
        setSsoParamsState("notFound");
      }
    } else {
      setSsoParamsState("notFound");
    }
  }, []);

  return (
    <DisplayWrapper itemLoaded={ssoParamsState !== "notInitialized"}>
      {ssoParamsState === "notFound" ? (
        <NotFound />
      ) : (
        <ContentWrapper>
          <Card className="card-box" title={<h2>{t("partnerSso.titles.ssoPage")}</h2>}>
            <Result
              status="info"
              icon={<AntIcon type="loading" />}
              title={t("partnerSso.titles.automaticAuthentication")}
            />
          </Card>
        </ContentWrapper>
      )}
    </DisplayWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  urlSearchQuery: selectRouterLocationSearch(state),
  partnerSsoTempData: selectPartnerSsoTempData(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      monlyAuthorizationCodeGrant: monlyAuthorizationCodeGrantActions.request,
      deleteStatePartnerSsoTempData: deleteStatePartnerSsoTempDataAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, Record<string, any>, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(PartnerSsoOAuthClientContainer);
