import { Button, Checkbox, Col, Form, Popconfirm, Row } from "antd";
import { useEffect } from "react";
import t from "../../../../../app/i18n";
import HiddenInput from "../../../../../common/components/form/components/HiddenInput";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import PopconfirmDeleteIcon from "../../../../../common/components/icons/PopconfirmDeleteIcon";
import ItemCreatedUpdatedInfoView from "../../../../../common/components/views/ItemCreatedUpdatedInfoView";
import { rowGutter } from "../../../../../common/constants";
import { formatIban } from "../../../../../common/utils/formatUtils";
import { useFormErrorHandler } from "../../../../../common/utils/formUtils";
import { removeStringWhiteSpaces } from "../../../../../common/utils/utils";
import { validations } from "../../../../../common/utils/validationUtils";
import InstitutionSelect from "../../../../enumerations/components/form/InstitutionSelect";
import { InstitutionType } from "../../../../institution/enums";
import { CalcType } from "../../../enums";
import { requests } from "../../api";
import {
  createCalcSettingsActions,
  deleteCalcSettingsActions,
  deleteCalcSettingsAttachmentConfActions,
  updateCalcSettingsActions,
  uploadCalcSettingsAttachmentConfsActions
} from "../../ducks";
import { CalcSettings, CreateUpdateCalcSettings } from "../../types";
import { mapToUpdateAttachmentConf } from "../../utils";
import CalcSettingsAttachmentConfsPart from "./parts/CalcSettingsAttachmentConfsPart";
import CalcSettingsPaymentDetailsPart from "./parts/CalcSettingsPaymentDetailsPart";
import CalcSettingsProductsPart from "./parts/CalcSettingsProductsPart";

interface Props {
  calcType: CalcType;
  calcSettings?: CalcSettings;
  onCreateCalcSettings?: typeof createCalcSettingsActions.request;
  onUpdateCalcSettings?: typeof updateCalcSettingsActions.request;
  onDeleteCalcSettings?: typeof deleteCalcSettingsActions.request;
  onUploadAttachmentConf?: typeof uploadCalcSettingsAttachmentConfsActions.request;
  onDeleteAttachmentConf?: typeof deleteCalcSettingsAttachmentConfActions.request;
}

const CalcSettingsForm = ({ calcSettings, ...props }: Props) => {
  const [form] = Form.useForm<CreateUpdateCalcSettings>();

  useFormErrorHandler(form, "calc.settings.attrs", [
    calcSettings ? requests.UPDATE_CALC_SETTINGS : requests.CREATE_CALC_SETTINGS
  ]);

  useEffect(() => {
    if (calcSettings) {
      const formData: CreateUpdateCalcSettings = {
        type: calcSettings.type,
        optimisticLockVersion: calcSettings.optimisticLockVersion,
        enabled: calcSettings.enabled,
        paymentDetails: {
          ...calcSettings.paymentDetails,
          bankAccounts: calcSettings.paymentDetails.bankAccounts.map(iban => formatIban(iban))
        },
        institutionId: calcSettings.institution.id,
        attachmentConfs: calcSettings.attachmentConfs.map(mapToUpdateAttachmentConf),
        mtplProductId: calcSettings.mtplProduct?.id,
        crashProductId: calcSettings.crashProduct?.id,
        mtplCrashProductId: calcSettings.mtplCrashProduct?.id,
        gapProductId: calcSettings.gapProduct?.id,
        pasProductId: calcSettings.pasProduct?.id,
        realtyProductId: calcSettings.realtyProduct?.id,
        shortTermInsuranceProductId: calcSettings.shortTermInsuranceProduct?.id,
        yearInsuranceProductId: calcSettings.yearInsuranceProduct?.id,
        cancellationInsuranceProductId: calcSettings.cancellationInsuranceProduct?.id
      };

      form.setFieldsValue(formData);
    }
  }, [calcSettings]);

  const handleFormFinish = (values: CreateUpdateCalcSettings): void => {
    const formData: CreateUpdateCalcSettings = {
      ...values,
      paymentDetails: {
        ...values.paymentDetails,
        bankAccounts: values.paymentDetails.bankAccounts.map(account => removeStringWhiteSpaces(account) ?? "")
      },
      attachmentConfs: values.attachmentConfs || []
    };

    if (calcSettings) {
      props.onUpdateCalcSettings?.({ id: calcSettings.id, object: formData });
    } else {
      props.onCreateCalcSettings?.(formData);
    }
  };

  const colSpan = 5;

  return (
    <Form form={form} layout="vertical" onFinish={handleFormFinish} name="calcSettingsForm">
      <HiddenInput name="type" initialValue={props.calcType} />

      {calcSettings && (
        <>
          <HiddenInput name="optimisticLockVersion" />
          <Row>
            <Col span={24}>
              <ItemCreatedUpdatedInfoView className="margin-bottom-small" item={calcSettings} />
            </Col>
          </Row>
        </>
      )}

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <InstitutionSelect
            formItemProps={{
              name: "institutionId",
              label: t("common.insuranceInstitution"),
              rules: [validations.notNull]
            }}
            optionsProps={{
              selected: calcSettings ? [calcSettings?.institution] : undefined,
              filterType: InstitutionType.INSURANCE_COMPANY
            }}
          />
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="enabled"
            className="form-item-without-label"
            valuePropName="checked"
            rules={[validations.none]}
            initialValue={false}
          >
            <Checkbox>{t("calc.settings.attrs.enabled")}</Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <CalcSettingsProductsPart calcType={props.calcType} />

      <CalcSettingsPaymentDetailsPart />

      {calcSettings && props.onUploadAttachmentConf && props.onDeleteAttachmentConf ? (
        <CalcSettingsAttachmentConfsPart
          calcSettings={calcSettings}
          onUploadAttachmentConf={props.onUploadAttachmentConf}
          onDeleteAttachmentConf={props.onDeleteAttachmentConf}
        />
      ) : undefined}

      <div className="margin-top-medium">
        <Button type="primary" htmlType="submit" className="margin-right-tiny" icon={<AntIcon type="save" />}>
          {t("common.save")}
        </Button>

        {calcSettings && (
          <Popconfirm
            title={t("calc.settings.titles.deleteConfirm")}
            icon={<PopconfirmDeleteIcon />}
            okText={t("common.yes")}
            cancelText={t("common.no")}
            okType="danger"
            onConfirm={() =>
              props.onDeleteCalcSettings ? props.onDeleteCalcSettings({ id: calcSettings.id }) : undefined
            }
          >
            <Button icon={<AntIcon type="delete" />} danger>
              {t("common.delete")}
            </Button>
          </Popconfirm>
        )}
      </div>
    </Form>
  );
};

export default CalcSettingsForm;
