import { useQueryClient } from "@tanstack/react-query";
import { Checkbox, Col, Flex, Form, Input, InputNumber, Modal, Row, Segmented, Select } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { PRODUCT_QUERY_KEYS, useCreateProduct, useUpdateProduct } from "../../../../common/api/queries/product";
import { useUpdateProductCommissionsSettings } from "../../../../common/api/queries/productCommissionsSettings";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import ItemCreatedUpdatedInfoView from "../../../../common/components/views/ItemCreatedUpdatedInfoView";
import { ModalSizes, rowGutter } from "../../../../common/constants";
import ComponentWithPermission from "../../../../common/security/authorization/ComponentWithPermission";
import { Permission } from "../../../../common/security/authorization/enums";
import { selectStandardProps, setErrorsToForm } from "../../../../common/utils/formUtils";
import { validations } from "../../../../common/utils/validationUtils";
import { selectIsCurrentAgentTypePlus } from "../../../auth/ducks";
import { ProductCommissionsSettingsForm } from "../../../commissions/productcommissionssettings/components/form/ProductCommissionsSettingsForm";
import { UpdateProductCommissionsSettings } from "../../../commissions/productcommissionssettings/types";
import { InsuranceType } from "../../../contract/enums";
import InstitutionSelect from "../../../enumerations/components/form/InstitutionSelect";
import LifeInsuranceTariffGroupSelect from "../../../enumerations/components/form/LifeInsuranceTariffGroupSelect";
import ProductGroupSelect from "../../../enumerations/components/form/ProductGroupSelect";
import { ProductFinancialSector } from "../../enums";
import { CreateUpdateProduct, Product } from "../../types";
import {
  convertProductCommissionsSettingsToUpdateObject,
  convertProductToCreateUpdateObject,
  PRODUCT_SECTOR_TO_INSTITUTION_TYPE_MAP
} from "../../utils";

interface Props {
  open: boolean;
  product?: Product;
  onFormCancel: VoidFunction;
}

const ProductForm = ({ open, product, onFormCancel }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const isTopAgentPlus = useSelector(selectIsCurrentAgentTypePlus);

  const [form] = Form.useForm<CreateUpdateProduct>();
  const sector = Form.useWatch("sector", form);
  const insuranceType = Form.useWatch("insuranceType", form);

  const [productCommissionsSettingsForm] = Form.useForm<UpdateProductCommissionsSettings>();

  const createProduct = useCreateProduct({ invalidateFilter: false });
  const updateProduct = useUpdateProduct({ invalidateFilter: false });

  const updateProductCommissionsSettings = useUpdateProductCommissionsSettings();

  useEffect(() => {
    if (open && product) {
      const formData = convertProductToCreateUpdateObject(product);

      form.setFieldsValue(formData);
    }

    if (open && product?.commissionsSettings) {
      const formData = convertProductCommissionsSettingsToUpdateObject(product.commissionsSettings);

      productCommissionsSettingsForm.setFieldsValue(formData);
    }
  }, [open, product, form, productCommissionsSettingsForm]);

  const handleSectorChange = () => {
    form.setFieldsValue({ institutionIds: [] });
  };

  const handleFormSubmit = async () => {
    const productFormsValidationPromises: (Promise<CreateUpdateProduct> | Promise<UpdateProductCommissionsSettings>)[] =
      [form.validateFields()];

    if (isTopAgentPlus) {
      productFormsValidationPromises.push(productCommissionsSettingsForm.validateFields());
    }

    const promisesResults = await Promise.all(productFormsValidationPromises);

    let createdUpdatedProduct: Product;
    const productFormValues = promisesResults[0] as CreateUpdateProduct;
    const productCommissionsSettingsFormValues = promisesResults[1] as UpdateProductCommissionsSettings;

    if (product) {
      createdUpdatedProduct = await updateProduct.mutateAsync(
        { id: product.id, object: productFormValues },
        {
          onSuccess: isTopAgentPlus ? undefined : onFormCancel,
          onError: error => setErrorsToForm(form, "product.attrs", error.violations)
        }
      );
    } else {
      createdUpdatedProduct = await createProduct.mutateAsync(productFormValues, {
        onSuccess: isTopAgentPlus ? undefined : onFormCancel,
        onError: error => setErrorsToForm(form, "product.attrs", error.violations)
      });

      if (createdUpdatedProduct.commissionsSettings) {
        productCommissionsSettingsFormValues["optimisticLockVersion"] =
          createdUpdatedProduct.commissionsSettings.optimisticLockVersion;
      }
    }

    form.setFieldValue("optimisticLockVersion", createdUpdatedProduct.optimisticLockVersion);

    if (isTopAgentPlus) {
      await updateProductCommissionsSettings.mutateAsync(
        {
          id: createdUpdatedProduct.id,
          object: productCommissionsSettingsFormValues
        },
        {
          onSuccess: onFormCancel,
          onError: error => setErrorsToForm(form, "commissions.productCommissionsSettings.attrs", error.violations)
        }
      );
    }

    queryClient.invalidateQueries({ queryKey: PRODUCT_QUERY_KEYS.filterProducts() });
  };

  const colSpan = 8;

  return (
    <Modal
      width={ModalSizes.LARGE}
      open={open}
      title={t(product ? "product.titles.updateProduct" : "product.titles.createProduct")}
      cancelText={t("common.cancel")}
      okText={t("common.save")}
      maskClosable={false}
      confirmLoading={createProduct.isPending || updateProduct.isPending}
      afterClose={() => {
        form.resetFields();
        productCommissionsSettingsForm.resetFields();
      }}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <ItemCreatedUpdatedInfoView className="margin-bottom-small" item={product} />

      <Form form={form} layout="vertical" name="productForm">
        <HiddenInput name="optimisticLockVersion" />

        <Row gutter={rowGutter}>
          <Col span={24}>
            <Form.Item
              name="name"
              label={t("product.helpers.productName")}
              rules={[validations.notBlank, validations.size(1, 1024)]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <ProductGroupSelect
              formItemProps={{
                name: "productGroupId",
                label: t("product.attrs.productGroupId"),
                rules: [validations.notNull]
              }}
              selectProps={{ disabled: !!product }}
            />
          </Col>

          <Col span={colSpan}>
            <Form.Item name="sector" label={t("product.enums.financialSector._label")} rules={[validations.notNull]}>
              <Select
                {...selectStandardProps}
                disabled={!!product}
                options={Object.keys(ProductFinancialSector)
                  .filter(sector => sector !== ProductFinancialSector.ALL)
                  .map(sector => ({
                    value: sector,
                    label: t("product.enums.financialSector." + sector)
                  }))}
                onChange={handleSectorChange}
              />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="deactivated"
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={false}
              className="form-item-without-label"
            >
              <Checkbox>{t("product.attrs.deactivated")}</Checkbox>
            </Form.Item>
          </Col>
        </Row>

        {sector === ProductFinancialSector.NON_LIFE_INSURANCES || sector === ProductFinancialSector.LIFE_INSURANCES ? (
          <Row gutter={rowGutter}>
            <Col span={colSpan}>
              <Form.Item
                name="insuranceType"
                label={t("product.helpers.insuranceTypeLabel")}
                rules={[validations.notNull]}
              >
                <Select
                  {...selectStandardProps}
                  disabled={product?.insuranceType === InsuranceType.LIFE}
                  options={Object.keys(InsuranceType).map(type => ({
                    value: type,
                    label: t("contract.enums.insuranceType." + type)
                  }))}
                />
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <Form.Item
                name="externalPartnerCode"
                label={t("product.attrs.externalPartnerCode")}
                rules={[validations.size(1, 64)]}
              >
                <Input />
              </Form.Item>
            </Col>

            {insuranceType === InsuranceType.LIFE ? (
              <Col span={colSpan}>
                <LifeInsuranceTariffGroupSelect
                  formItemProps={{
                    name: "tariffGroupId",
                    label: t("product.attrs.tariffGroupId"),
                    rules: [validations.notNull]
                  }}
                  selectProps={{ disabled: !!product?.tariffGroup }}
                />
              </Col>
            ) : undefined}
          </Row>
        ) : undefined}

        {sector === ProductFinancialSector.CAPITAL_MARKET ? (
          <Row gutter={rowGutter}>
            <Col span={colSpan}>
              <Form.Item
                name="eicContractNumberFirstDigit"
                label={t("product.attrs.eicContractNumberFirstDigit")}
                rules={[validations.minNumber(1), validations.maxNumber(9)]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <Form.Item
                name="wemContractNumberLastTwoDigits"
                label={t("product.attrs.wemContractNumberLastTwoDigits")}
                rules={[validations.size(2, 2), validations.numeric]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <Form.Item
                name="iadOneTimeInvestmentContract"
                valuePropName="checked"
                rules={[validations.none]}
                initialValue={false}
                className="form-item-without-label"
              >
                <Checkbox>{t("product.attrs.iadOneTimeInvestmentContract")}</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        ) : undefined}

        <Row gutter={rowGutter}>
          <Col span={16}>
            <Flex style={{ height: 32 }} align="center">
              <label style={{ marginRight: 8 }} className="form-item-required">
                {t("product.attrs.coveredByNbs")}:
              </label>
              <Form.Item name="coveredByNbs" noStyle rules={[validations.notNull]} initialValue={true}>
                <Segmented<boolean>
                  size="small"
                  options={[
                    {
                      value: true,
                      label: isTopAgentPlus
                        ? t("product.helpers.productProfiPlus")
                        : t("product.helpers.productCoveredByNbs")
                    },
                    {
                      value: false,
                      label: isTopAgentPlus
                        ? t("product.helpers.productProjectsPlus")
                        : t("product.helpers.productNoCoveredByNbs")
                    }
                  ]}
                />
              </Form.Item>
            </Flex>
          </Col>
          <Col span={colSpan}>
            <Form.Item name="subjectToVat" valuePropName="checked" rules={[validations.notNull]} initialValue={false}>
              <Checkbox>{t("product.attrs.subjectToVat")}</Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan * 3}>
            <InstitutionSelect
              formItemProps={{
                name: "institutionIds",
                label: t("product.attrs.institutionIds"),
                rules: [validations.notNull]
              }}
              selectProps={{ mode: "multiple", maxTagCount: "responsive" }}
              optionsProps={{
                selected: product?.institutions,
                filterType: PRODUCT_SECTOR_TO_INSTITUTION_TYPE_MAP.get(sector)
              }}
            />
          </Col>
        </Row>
      </Form>

      <ComponentWithPermission permissions={[Permission.COMMISSIONS_MANAGE]}>
        {isTopAgentPlus ? (
          <>
            <ProductCommissionsSettingsForm form={productCommissionsSettingsForm} />
          </>
        ) : undefined}
      </ComponentWithPermission>
    </Modal>
  );
};

export default ProductForm;
