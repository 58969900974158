import { Card, Col, Row } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import t from "../../../../app/i18n";
import BackNavigationArrow from "../../../../common/components/views/BackNavigationArrow";
import { PageSizes } from "../../../../common/constants";
import DisplayWrapper from "../../../../common/modules/wrappers/DisplayWrapper";
import { ActionProps, EntityIdObject, RootState } from "../../../../common/types";
import { appendSearchParamsToURL, numberOrZero } from "../../../../common/utils/utils";
import { selectRouterLocationSearch } from "../../../ducks";
import {
  createDuplicatedCommissionActions,
  deleteDuplicatedCommissionActions,
  deleteStatePostponedCommissionsPageAction,
  filterPostponedCommissionsActions,
  selectPostponedCommissionsPage,
  updateDuplicatedCommissionActions
} from "../../postponed/ducks";
import { PostponedCommissionsReport } from "../../postponed/enums";
import { PostponedCommissionsFilterPageRequest, PostponedCommissionsFilterPageResult } from "../../postponed/types";
import CommissionForm from "../components/forms/CommissionForm";
import BatchCommissionTableView from "../components/views/BatchCommissionTableView";
import BatchPostponedCommissionFilterView from "../components/views/filter/BatchPostponedCommissionFilterView";
import { deleteStateCommissionsBatchDetailAction, getCommissionsBatchActions, selectBatchDetail } from "../ducks";
import { PostponementReason } from "../enums";
import { Commission, CommissionsBatchBase } from "../types";

interface StateProps {
  postponedCommissionsPage: PostponedCommissionsFilterPageResult;
  batch?: CommissionsBatchBase;
  urlSearchQuery: string;
}

interface ActionsMap {
  filterPostponedCommissions: typeof filterPostponedCommissionsActions.request;
  getCommissionsBatch: typeof getCommissionsBatchActions.request;
  createDuplicatedCommission: typeof createDuplicatedCommissionActions.request;
  updateDuplicatedCommission: typeof updateDuplicatedCommissionActions.request;
  deleteDuplicatedCommission: typeof deleteDuplicatedCommissionActions.request;
  deleteStatePostponedCommissionsPage: typeof deleteStatePostponedCommissionsPageAction;
  deleteStateCommissionsBatchDetail: typeof deleteStateCommissionsBatchDetailAction;
}

const CommissionsBatchPostponedCommissionsContainer = (props: StateProps & ActionProps<ActionsMap>) => {
  const { id } = useParams<EntityIdObject>() as { id: string };
  const navigate = useNavigate();

  const [commissionToUpdate, setCommissionToUpdate] = useState<Commission>();

  useEffect(() => {
    props.actions.getCommissionsBatch({ id });

    const urlParams = new URLSearchParams(props.urlSearchQuery);
    const report =
      PostponedCommissionsReport[urlParams.get("report") as keyof typeof PostponedCommissionsReport] ||
      PostponedCommissionsReport.UNRESOLVED_INCLUDED_IN_BATCH;
    props.actions.filterPostponedCommissions({
      pageIndex: numberOrZero(urlParams.get("pageIndex")),
      pageSize: PageSizes.LARGE,
      keyword: urlParams.get("keyword") ?? undefined,
      report: report,
      batchId: report === PostponedCommissionsReport.UNRESOLVED_INCLUDED_IN_BATCH ? id : undefined,
      postponementReasons: urlParams
        .getAll("postponementReasons")
        .map(reason => PostponementReason[reason as keyof typeof PostponementReason] || ("" as PostponementReason)),
      institutionIds: urlParams.getAll("institutionIds")
    });
    return () => {
      props.actions.deleteStateCommissionsBatchDetail();
      props.actions.deleteStatePostponedCommissionsPage();
    };
  }, []);

  const handleReportSwitch = (filter: PostponedCommissionsFilterPageRequest): void => {
    const params = {
      keyword: filter.keyword || null,
      report: filter.report !== PostponedCommissionsReport.UNRESOLVED_INCLUDED_IN_BATCH ? filter.report : undefined,
      postponementReasons: filter.postponementReasons,
      institutionIds: filter.institutionIds
    } as PostponedCommissionsFilterPageRequest;

    navigate(appendSearchParamsToURL({ ...params, pageIndex: undefined }), { replace: true });
    props.actions.filterPostponedCommissions({
      ...params,
      report: filter.report,
      batchId: filter.report === PostponedCommissionsReport.UNRESOLVED_INCLUDED_IN_BATCH ? props.batch?.id : undefined,
      pageIndex: 0,
      pageSize: props.postponedCommissionsPage.pageSize
    });
  };

  const handleFilterSubmit = (filter: PostponedCommissionsFilterPageRequest): void => {
    const params = {
      keyword: filter.keyword || undefined,
      report:
        props.postponedCommissionsPage.report !== PostponedCommissionsReport.UNRESOLVED_INCLUDED_IN_BATCH
          ? props.postponedCommissionsPage.report
          : undefined,
      postponementReasons: filter.postponementReasons,
      institutionIds: filter.institutionIds
    } as PostponedCommissionsFilterPageRequest;

    navigate(appendSearchParamsToURL({ ...params, pageIndex: undefined }), { replace: true });
    props.actions.filterPostponedCommissions({
      ...params,
      report: props.postponedCommissionsPage.report,
      batchId:
        props.postponedCommissionsPage.report === PostponedCommissionsReport.UNRESOLVED_INCLUDED_IN_BATCH
          ? props.batch?.id
          : undefined,
      pageIndex: 0,
      pageSize: props.postponedCommissionsPage.pageSize
    });
  };

  const handleTablePageChange = (pageNumber: number): void => {
    const { pageSize, keyword, report, batchId, postponementReasons, institutionIds } = props.postponedCommissionsPage;
    navigate(appendSearchParamsToURL({ pageIndex: pageNumber - 1 }), { replace: true });
    props.actions.filterPostponedCommissions({
      pageIndex: pageNumber - 1,
      pageSize,
      keyword,
      report,
      batchId,
      postponementReasons,
      institutionIds
    });
  };

  return (
    <DisplayWrapper itemLoaded={!!props.batch && !!props.postponedCommissionsPage.report}>
      <Card
        className="card-box"
        title={
          <BackNavigationArrow>
            <h2>{t("commissions.batch.titles.managePreviousBatchesCommissions")}</h2>
            <span className="sub-header-info normal-font-size">
              {props.batch && t("commissions.batch.titles.batchDetail", { name: props.batch.name })}
            </span>
          </BackNavigationArrow>
        }
      >
        <Row justify="center">
          <Col flex="1000px">
            <BatchPostponedCommissionFilterView
              filter={props.postponedCommissionsPage}
              batch={props.batch}
              onReportSwitch={handleReportSwitch}
              onFilterSubmit={handleFilterSubmit}
            />
          </Col>
        </Row>
      </Card>

      <Card className="card-box">
        <BatchCommissionTableView
          commissionsPage={props.postponedCommissionsPage}
          batch={props.batch}
          viewType="postponed"
          onPageChange={handleTablePageChange}
          onUpdateClick={commission => setCommissionToUpdate(commission)}
          onCreateDuplicate={props.actions.createDuplicatedCommission}
          onDeleteDuplicate={props.actions.deleteDuplicatedCommission}
        />
      </Card>

      <CommissionForm
        open={!!commissionToUpdate}
        formType="duplicatedCommission"
        commission={commissionToUpdate}
        batchId={id}
        onUpdateDuplicated={props.actions.updateDuplicatedCommission}
        onFormCancel={() => setCommissionToUpdate(undefined)}
      />
    </DisplayWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  urlSearchQuery: selectRouterLocationSearch(state),
  batch: selectBatchDetail(state),
  postponedCommissionsPage: selectPostponedCommissionsPage(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      filterPostponedCommissions: filterPostponedCommissionsActions.request,
      getCommissionsBatch: getCommissionsBatchActions.request,
      createDuplicatedCommission: createDuplicatedCommissionActions.request,
      updateDuplicatedCommission: updateDuplicatedCommissionActions.request,
      deleteDuplicatedCommission: deleteDuplicatedCommissionActions.request,
      deleteStatePostponedCommissionsPage: deleteStatePostponedCommissionsPageAction,
      deleteStateCommissionsBatchDetail: deleteStateCommissionsBatchDetailAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, Record<string, any>, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(CommissionsBatchPostponedCommissionsContainer);
