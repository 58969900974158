import { Divider, Popconfirm, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import ActionTextIcon from "../../../../../../common/components/icons/ActionTextIcon";
import PopconfirmDeleteIcon from "../../../../../../common/components/icons/PopconfirmDeleteIcon";
import { PageSizes, TableSizes } from "../../../../../../common/constants";
import { formatLocaleCurrency, formatLocalePercentageNumber } from "../../../../../../common/utils/formatUtils";
import { paginationTableProps, tableStandardProps } from "../../../../../../common/utils/utils";
import CommissionSourceTag from "../../../../settings/components/CommissionSourceTag";
import CommissionsSettingsRuleView from "../../../../settings/components/views/CommissionsSettingsRuleView";
import { deleteCommissionsLevelRuleActions } from "../../../ducks";
import { CommissionsSettingsLevelType } from "../../../enums";
import { CommissionsSettingsLevel, CommissionsSettingsLevelRule } from "../../../types";

interface Props {
  level: CommissionsSettingsLevel;
  showActions: boolean;
  onUpdateClick: (rule: CommissionsSettingsLevelRule) => void;
  onDelete: typeof deleteCommissionsLevelRuleActions.request;
}

export const CommissionsLevelRuleTableView = ({ level, showActions, onUpdateClick, onDelete }: Props) => {
  const { t } = useTranslation();

  const getColumns = () => {
    const columns: ColumnsType<CommissionsSettingsLevelRule> = [
      {
        key: "rule",
        title: t("commissions.level.sections.rule"),
        render: (_, record) => <CommissionsSettingsRuleView rule={record} />
      }
    ];

    if (level.type === CommissionsSettingsLevelType.CAREER) {
      columns.push({
        key: "commissionAmountPerPoint",
        title: t("commissions.level.attrs.rule.commissionAmountPerPoint"),
        width: 200,
        align: "right",
        render: (_, record) => formatLocaleCurrency(record.commissionAmountPerPoint)
      });
    } else {
      columns.push(
        {
          key: "source",
          title: t("commissions.settings.enums.commissionSource._label"),
          width: 115,
          render: (_, record) =>
            record.source ? <CommissionSourceTag source={record.source} style={{ marginRight: 0 }} /> : undefined
        },
        {
          key: "initialCommissionRate",
          title: t("commissions.level.attrs.rule.initialCommissionRate"),
          width: 120,
          align: "right",
          render: (_, record) => formatLocalePercentageNumber(record.initialCommissionRate)
        },
        {
          key: "subsequentCommissionRate",
          title: t("commissions.level.attrs.rule.subsequentCommissionRate"),
          width: 110,
          align: "right",
          render: (_, record) => formatLocalePercentageNumber(record.subsequentCommissionRate)
        }
      );
    }

    if (showActions) {
      columns.push({
        key: "actions",
        align: "right",
        fixed: "right",
        width: 180,
        render: (_, record) => (
          <div className="nowrap">
            <ActionTextIcon icon="edit" color="blue" text={t("common.edit")} onClick={() => onUpdateClick(record)} />

            <Divider type="vertical" />

            <Popconfirm
              title={t("commissions.level.helpers.deleteRuleConfirm")}
              icon={<PopconfirmDeleteIcon />}
              okText={t("common.yes")}
              cancelText={t("common.no")}
              okType="danger"
              onConfirm={() => onDelete?.({ id1: level.id, id2: record.id })}
            >
              <span>
                <ActionTextIcon icon="delete" color="red" text={t("common.delete")} />
              </span>
            </Popconfirm>
          </div>
        )
      });
    }

    return columns;
  };

  return (
    <Table<CommissionsSettingsLevelRule>
      {...tableStandardProps()}
      columns={getColumns()}
      scroll={{ x: TableSizes.MEDIUM }}
      dataSource={level.rules}
      pagination={{
        ...paginationTableProps,
        pageSize: PageSizes.SMALL,
        total: level.rules?.length
      }}
    />
  );
};
