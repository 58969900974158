import { Button, Card, Input } from "antd";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import t from "../../../app/i18n";
import AntIcon from "../../../common/components/icons/AntIcon";
import { PageSizes } from "../../../common/constants";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import { ActionProps, RootState, SearchPageResult } from "../../../common/types";
import messageUtils from "../../../common/utils/messageUtils";
import { appendSearchParamsToURL, numberOrZero } from "../../../common/utils/utils";
import { validationConstants, validationFunctions } from "../../../common/utils/validationUtils";
import { selectRouterLocationSearch } from "../../ducks";
import AdminUserListTableView from "../components/views/list/AdminUserListTableView";
import { adminFilterUsersActions, deleteStateAdminUsersPageAction, selectAdminUsersCurrentPage } from "../ducks";
import { ADMIN_USER_ROUTE_PATHS } from "../paths";
import { UserAdminView } from "../types";

interface StateProps {
  usersCurrentPage: SearchPageResult<UserAdminView>;
  urlSearchQuery: string;
}

interface ActionsMap {
  adminFilterUsers: typeof adminFilterUsersActions.request;
  deleteStateAdminUsersPage: typeof deleteStateAdminUsersPageAction;
}

type Props = StateProps & ActionProps<ActionsMap>;

const AdminUserListContainer = (props: Props) => {
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(props.urlSearchQuery);
    props.actions.adminFilterUsers({
      pageIndex: numberOrZero(urlParams.get("pageIndex")),
      pageSize: PageSizes.LARGE,
      keyword: urlParams.get("keyword") ?? undefined
    });
    return () => {
      props.actions.deleteStateAdminUsersPage();
    };
  }, []);

  const handleTablePageChange = (pageNumber: number): void => {
    navigate(appendSearchParamsToURL({ pageIndex: pageNumber - 1 }), { replace: true });
    props.actions.adminFilterUsers({
      pageIndex: pageNumber - 1,
      pageSize: PageSizes.LARGE,
      keyword: props.usersCurrentPage.keyword
    });
  };

  const handleSearchSubmit = (keyword: string): void => {
    if (validationFunctions.validateSearchKeyword(keyword)) {
      navigate(appendSearchParamsToURL({ pageIndex: undefined, keyword: keyword !== "" ? keyword : undefined }), {
        replace: true
      });
      props.actions.adminFilterUsers({ pageIndex: 0, pageSize: PageSizes.LARGE, keyword });
    } else {
      messageUtils.errorMessage(
        t("validation.size", {
          min: validationConstants.SEARCH_KEYWORD_MIN_LENGTH,
          max: validationConstants.SEARCH_KEYWORD_MAX_LENGTH
        })
      );
    }
  };

  const handleCreateClick = (): void => {
    navigate(ADMIN_USER_ROUTE_PATHS.create.to);
  };

  return (
    <ContentWrapper>
      <Card
        className="card-box"
        title={<h2>{t("user.titles.list")}</h2>}
        extra={
          <>
            <Button
              className="margin-right-small"
              type="primary"
              icon={<AntIcon type="plus" />}
              onClick={handleCreateClick}
            >
              {t("user.actions.createUser")}
            </Button>
            <Input.Search
              style={{ width: 350 }}
              enterButton
              allowClear
              placeholder={t("user.helpers.userSearchHint")}
              defaultValue={new URLSearchParams(props.urlSearchQuery).get("keyword") ?? undefined}
              onSearch={handleSearchSubmit}
            />
          </>
        }
      >
        <AdminUserListTableView usersCurrentPage={props.usersCurrentPage} onPageChange={handleTablePageChange} />
      </Card>
    </ContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  usersCurrentPage: selectAdminUsersCurrentPage(state),
  urlSearchQuery: selectRouterLocationSearch(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      adminFilterUsers: adminFilterUsersActions.request,
      deleteStateAdminUsersPage: deleteStateAdminUsersPageAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, Record<string, any>, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(AdminUserListContainer);
