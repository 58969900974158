import { Flex, Form, Input, Modal, Select } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useCreateProductGroup, useUpdateProductGroup } from "../../../../common/api/queries/product";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import ItemCreatedUpdatedInfoView from "../../../../common/components/views/ItemCreatedUpdatedInfoView";
import { ModalSizes, rowGutter } from "../../../../common/constants";
import { RootState } from "../../../../common/types";
import { selectStandardProps, setErrorsToForm } from "../../../../common/utils/formUtils";
import { validations } from "../../../../common/utils/validationUtils";
import { TopAgentType } from "../../../agent/enums";
import { selectUserAccount } from "../../../auth/ducks";
import { UserAccount } from "../../../user/types";
import { ProductGroupCategory } from "../../enums";
import { CreateUpdateProductGroup, ProductGroup } from "../../types";
import { convertProductGroupToCreateUpdateObject } from "../../utils";

interface Props {
  open: boolean;
  onFormCancel: VoidFunction;
  productGroup?: ProductGroup;
}

const ProductGroupForm = ({ open, onFormCancel, productGroup }: Props) => {
  const { t } = useTranslation();

  const [form] = Form.useForm<CreateUpdateProductGroup>();

  const userAccount = useSelector<RootState, UserAccount | undefined>(selectUserAccount);

  const createProductGroup = useCreateProductGroup();
  const updateProductGroup = useUpdateProductGroup();

  useEffect(() => {
    if (open && productGroup) {
      const formData = convertProductGroupToCreateUpdateObject(productGroup);

      form.setFieldsValue(formData);
    }
  }, [open, productGroup, form]);

  const handleFormSubmit = (): void => {
    form.validateFields().then(values => {
      if (productGroup) {
        updateProductGroup.mutate(
          { id: productGroup.id, object: values },
          { onSuccess: onFormCancel, onError: error => setErrorsToForm(form, "product.attrs", error.violations) }
        );
      } else {
        createProductGroup.mutate(values, {
          onSuccess: onFormCancel,
          onError: error => setErrorsToForm(form, "product.attrs", error.violations)
        });
      }
    });
  };

  return (
    <Modal
      width={ModalSizes.MEDIUM}
      open={open}
      title={t(productGroup ? "product.titles.updateProductGroup" : "product.titles.createProductGroup")}
      cancelText={t("common.cancel")}
      okText={t("common.save")}
      maskClosable={false}
      confirmLoading={createProductGroup.isPending || updateProductGroup.isPending}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <ItemCreatedUpdatedInfoView className="margin-bottom-small" item={productGroup} />

      <Form form={form} layout="vertical" name="productGroupForm">
        <HiddenInput name="optimisticLockVersion" />

        <Flex gap={rowGutter}>
          <Form.Item
            style={{ flex: 1 }}
            name="name"
            label={t("product.attrs.name")}
            rules={[validations.notBlank, validations.size(1, 64)]}
          >
            <Input />
          </Form.Item>

          {userAccount?.agent?.topAgentType === TopAgentType.PLUS ? (
            <Form.Item style={{ flex: 1 }} name="category" label={t("product.enums.productGroupCategory._label")}>
              <Select
                {...selectStandardProps}
                allowClear
                options={Object.keys(ProductGroupCategory).map(category => ({
                  value: category,
                  label: t("product.enums.productGroupCategory." + category)
                }))}
              />
            </Form.Item>
          ) : undefined}
        </Flex>
      </Form>
    </Modal>
  );
};

export default ProductGroupForm;
