import { ConfigProvider, Modal, StepProps, Steps, Tabs } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import ContentWrapper from "../../../../../common/modules/wrappers/ContentWrapper";
import DisplayWrapper from "../../../../../common/modules/wrappers/DisplayWrapper";
import { FieldConstraintViolation, RootState } from "../../../../../common/types";
import messageUtils from "../../../../../common/utils/messageUtils";
import { QueryKeys } from "../../../../../common/utils/queryUtils";
import { ClientFormType } from "../../../../client/enums";
import { Client } from "../../../../client/types";
import { getCalcDraftActions } from "../../../drafts/ducks";
import { CALC_ROUTE_PATHS } from "../../../paths";
import { getCalcRecordDataActions } from "../../../records/ducks";
import CalcLoadingModal from "../../components/CalcLoadingModal";
import { loadContractToCalculatorActions } from "../../ducks";
import { OperationStage } from "../../realty/enums";
import { CalcResult, GenResponse } from "../../types";
import { isApplicableResult, sortAndGroupCalcResults } from "../../utils";
import TravelCalcWrapper from "../components/calc/TravelCalcWrapper";
import TravelGenWrapper from "../components/gen/TravelGenWrapper";
import { TravelCalcResultsView } from "../components/result/TravelCalcResultsView";
import {
  calculateTravelActions,
  deleteStateTravelCalcResultsAction,
  deleteStateTravelDraftAction,
  deleteStateTravelGenResultAction,
  deleteStateTravelInitialCalcGenDataAction,
  generateTravelActions,
  selectTravelCalcResults,
  selectTravelDraft,
  selectTravelGenResult,
  selectTravelInitialCalcData,
  selectTravelInitialGenData,
  setTravelCalcResultsAction
} from "../ducks";
import {
  TravelCalc,
  TravelCalcDraft,
  TravelCalcResultData,
  TravelFormClients,
  TravelGen,
  TravelGenForm
} from "../types";
import {
  createTravelCalcObjectFromCalcData,
  createTravelCalcObjectFromGenData,
  createTravelFormClientsObject,
  createTravelGenFormDataObject
} from "../utils";

type StepItem = {
  title: React.ReactNode;
  icon: React.ReactNode;
  content?: React.ReactNode;
};

enum TRAVEL_CALC_STEPS {
  FIRST_STEP_CALC = 0,
  SECOND_STEP_RESULTS = 1,
  THIRD_STEP_GENERATE_CONTRACT = 2
}

export const TravelCalcContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const recordId = searchParams.get(QueryKeys.RECORD_ID);
  const draftId = searchParams.get(QueryKeys.DRAFT_ID);
  const contractId = searchParams.get(QueryKeys.CONTRACT_ID);
  const [currentStepIndex, setCurrentStepIndex] = useState<TRAVEL_CALC_STEPS>(TRAVEL_CALC_STEPS.FIRST_STEP_CALC);
  const [lastAvailableStepIndex, setLastAvailableStepIndex] = useState<number>(TRAVEL_CALC_STEPS.FIRST_STEP_CALC);
  const [selectedResult, setSelectedResult] = useState<CalcResult<TravelCalcResultData>>();
  const [calcData, setCalcData] = useState<TravelCalc>();
  const [genData, setGenData] = useState<TravelGenForm>();
  const [clients, setClients] = useState<TravelFormClients>({
    policyHolder: undefined,
    representative: undefined
  });

  const [isCalcFormSubmit, setIsCalcFormSubmit] = useState<boolean>(false);
  const [calcDataFormErrors, setCalcDataFormErrors] = useState<FieldConstraintViolation[]>([]);
  const [isCalcFormChanged, setIsCalcFormChanged] = useState<boolean>(false);

  const initialCalcData = useSelector<RootState, TravelCalc | undefined>(selectTravelInitialCalcData);
  const initialGenData = useSelector<RootState, TravelGen | undefined>(selectTravelInitialGenData);
  const draft = useSelector<RootState, TravelCalcDraft | undefined>(selectTravelDraft);
  const calcResults = useSelector<RootState, CalcResult<TravelCalcResultData>[]>(selectTravelCalcResults);
  const genResult = useSelector<RootState, GenResponse | undefined>(selectTravelGenResult);

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          calculateTravel: calculateTravelActions.request,
          generateTravel: generateTravelActions.request,
          deleteStateTravelCalcResults: deleteStateTravelCalcResultsAction,
          deleteStateTravelGenResult: deleteStateTravelGenResultAction,
          deleteStateTravelDraft: deleteStateTravelDraftAction,
          deleteStateTravelInitialCalcGenData: deleteStateTravelInitialCalcGenDataAction,
          getCalcRecordData: getCalcRecordDataActions.request,
          getCalcDraft: getCalcDraftActions.request,
          loadContractToCalculator: loadContractToCalculatorActions.request,
          setTravelCalcResultsAction: setTravelCalcResultsAction
        },
        dispatch
      ),
    [dispatch]
  );

  const clearData = () => {
    actions.deleteStateTravelInitialCalcGenData();
    actions.deleteStateTravelCalcResults();
    actions.deleteStateTravelGenResult();
    actions.deleteStateTravelDraft();
  };

  useEffect(() => {
    if (recordId) {
      actions.getCalcRecordData({ id: recordId });
    }

    if (draftId) {
      actions.getCalcDraft({ id: draftId });
    }

    if (contractId) {
      actions.loadContractToCalculator({ id: contractId });
    }

    return () => {
      clearData();
    };
  }, [recordId, draftId, contractId]);

  useEffect(() => {
    if (calcData) {
      return;
    }

    if (initialCalcData || initialGenData) {
      messageUtils.infoNotification({
        message: t("calc.records.helpers.calcDataInitTitle"),
        description: t("calc.records.helpers.calcDataInit"),
        duration: 10,
        key: "travelCalcDataInit"
      });
    }

    if (initialCalcData) {
      setCalcData(createTravelCalcObjectFromCalcData(initialCalcData));
    } else if (initialGenData) {
      const clients = createTravelFormClientsObject(initialGenData);

      setCalcData(createTravelCalcObjectFromGenData(initialGenData));
      setClients(clients);
      setGenData(createTravelGenFormDataObject(initialGenData, clients));
    } else if (draft?.draftData) {
      messageUtils.infoNotification({
        message: t("calc.draft.helpers.draftLoaded"),
        description: t("calc.draft.helpers.draftLoadedInfo"),
        duration: 10
      });

      setCalcData(createTravelCalcObjectFromGenData(draft.draftData));

      if (draft.stage === OperationStage.GENERATE) {
        const clients = createTravelFormClientsObject(draft.draftData);
        setClients(clients);
        setGenData(createTravelGenFormDataObject(draft.draftData, clients));
      }
    }
  }, [initialCalcData, initialGenData, draft?.draftData, calcData]);

  useEffect(() => {
    if (isCalcFormSubmit && calcResults.length && currentStepIndex === TRAVEL_CALC_STEPS.FIRST_STEP_CALC) {
      handleNextStep();
      setIsCalcFormSubmit(false);
    }

    if (currentStepIndex === TRAVEL_CALC_STEPS.SECOND_STEP_RESULTS && calcResults.length === 0) {
      setIsCalcFormSubmit(false);
      setCurrentStepIndex(TRAVEL_CALC_STEPS.FIRST_STEP_CALC);
      setLastAvailableStepIndex(TRAVEL_CALC_STEPS.FIRST_STEP_CALC);
    }
  }, [isCalcFormSubmit, calcResults, currentStepIndex]);

  const handleCalculationFormSubmit = (calcData: TravelCalc): void => {
    actions.setTravelCalcResultsAction([]);
    setCalcData(calcData);
    actions.calculateTravel(calcData);
    setIsCalcFormSubmit(true);
    setIsCalcFormChanged(false);
    setCalcDataFormErrors([]);
  };

  const handleClientChange = (type: ClientFormType, client?: Client): TravelFormClients => {
    let updatedClients: TravelFormClients;
    switch (type) {
      case ClientFormType.POLICY_HOLDER:
        updatedClients = { ...clients, policyHolder: client };
        break;
      case ClientFormType.REPRESENTATIVE:
        updatedClients = { ...clients, representative: client };
        break;
      default:
        updatedClients = { ...clients };
        break;
    }
    setClients(updatedClients);
    return updatedClients;
  };

  const handleResetCalculatorClick = (): void => {
    Modal.confirm({
      title: t("calc.helpers.resetCalculatorSubmit"),
      okText: t("common.yes"),
      cancelText: t("common.back"),
      onOk: () => {
        setClients({ policyHolder: undefined, representative: undefined });
        setCalcData(undefined);
        setGenData(undefined);
        setSelectedResult(undefined);

        clearData();
        navigate(CALC_ROUTE_PATHS.travel.to, { replace: true });
      }
    });
  };

  const handleGenerateContractClick = (selectedResult: CalcResult<TravelCalcResultData>): void => {
    setSelectedResult(selectedResult);
    handleNextStep();
  };

  const handleShowErrors = (errors: FieldConstraintViolation[]) => {
    setCalcDataFormErrors(errors);
    goToPreviousStep();
  };

  const handleNextStep = () => {
    setCurrentStepIndex(currentStepIndex + 1);

    if (lastAvailableStepIndex < currentStepIndex + 1) {
      setLastAvailableStepIndex(lastAvailableStepIndex + 1);
    }
  };

  const goToPreviousStep = () => {
    setCurrentStepIndex(currentStepIndex - 1);
  };

  const goToNextStep = () => {
    setCurrentStepIndex(currentStepIndex + 1);
  };

  const resultItems = useMemo(() => structuredClone(calcResults).filter(isApplicableResult), [calcResults]);

  const stepItems: StepItem[] = [
    {
      title: <div style={{ fontSize: 18 }}>{t("calc.travel.sections.calculation")}</div>,
      icon: <AntIcon type="laptop" />,
      content: (
        <TravelCalcWrapper
          calcData={calcData}
          setIsFormChanged={() => setIsCalcFormChanged(true)}
          formErrors={calcDataFormErrors}
          onCalcResultsDelete={actions.deleteStateTravelCalcResults}
          onCalculationFormSubmit={handleCalculationFormSubmit}
          onResetCalculatorClick={handleResetCalculatorClick}
          goToCalcResults={isCalcFormChanged || resultItems.length === 0 ? undefined : goToNextStep}
        />
      )
    },
    {
      title: <div style={{ fontSize: 18 }}>{t("calc.travel.sections.insuranceSelection")}</div>,
      icon: <AntIcon type="file-search" />,
      content: calcData ? (
        <TravelCalcResultsView
          calcData={calcData}
          resultItems={resultItems}
          handleGenerateContract={handleGenerateContractClick}
          handleShowErrors={handleShowErrors}
          handleGoToPreviousStep={goToPreviousStep}
        />
      ) : undefined
    },
    {
      title: <div style={{ fontSize: 18 }}>{t("calc.travel.sections.contractGeneration")}</div>,
      icon: <AntIcon type="file-done" />,
      content:
        calcData && selectedResult ? (
          <TravelGenWrapper
            initialData={genData}
            genResult={genResult}
            calcData={calcData}
            clients={clients}
            calcResults={sortAndGroupCalcResults(calcResults)}
            selectedResult={selectedResult}
            draftId={draft?.id}
            onGenerateFormSubmit={actions.generateTravel}
            onGenResultDelete={actions.deleteStateTravelGenResult}
            onClientChange={handleClientChange}
            handleGoToPreviousStep={goToPreviousStep}
          />
        ) : undefined
    }
  ];

  const steps: StepProps[] = stepItems.map((item, index) => ({
    key: index,
    title: item.title,
    disabled: index > lastAvailableStepIndex || isCalcFormChanged,
    status: currentStepIndex >= index ? "finish" : "wait",
    icon: item.icon
  }));

  return (
    <DisplayWrapper itemLoaded={recordId || draftId || contractId ? !!calcData : true}>
      <ContentWrapper>
        <div style={{ maxWidth: 800, margin: "10px auto 20px" }}>
          <ConfigProvider theme={{ components: { Steps: { customIconFontSize: 28 } } }}>
            <Steps onChange={setCurrentStepIndex} current={currentStepIndex} items={steps} />
          </ConfigProvider>
        </div>

        <Tabs
          activeKey={currentStepIndex.toString()}
          renderTabBar={() => <></>}
          animated
          items={[
            {
              key: TRAVEL_CALC_STEPS.FIRST_STEP_CALC.toString(),
              label: undefined,
              children: stepItems[TRAVEL_CALC_STEPS.FIRST_STEP_CALC]?.content
            },
            {
              key: TRAVEL_CALC_STEPS.SECOND_STEP_RESULTS.toString(),
              label: undefined,
              children: stepItems[TRAVEL_CALC_STEPS.SECOND_STEP_RESULTS]?.content
            },
            {
              key: TRAVEL_CALC_STEPS.THIRD_STEP_GENERATE_CONTRACT.toString(),
              label: undefined,
              children: stepItems[TRAVEL_CALC_STEPS.THIRD_STEP_GENERATE_CONTRACT]?.content
            }
          ]}
        />

        <CalcLoadingModal />
      </ContentWrapper>
    </DisplayWrapper>
  );
};
