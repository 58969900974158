import { Form } from "antd";
import Big from "big.js";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import t from "../../../../../app/i18n";
import HiddenInput from "../../../../../common/components/form/components/HiddenInput";
import { Permission } from "../../../../../common/security/authorization/enums";
import { FieldConstraintViolation, RootState } from "../../../../../common/types";
import {
  licensePlateNormalizeFunction,
  resolveFormValidationError,
  useFormErrorHandler
} from "../../../../../common/utils/formUtils";
import messageUtils from "../../../../../common/utils/messageUtils";
import {
  bigToFloat,
  distinctArray,
  isNotEmptyArray,
  isNumber,
  removeStringWhiteSpaces
} from "../../../../../common/utils/utils";
import { ClientType } from "../../../../client/enums";
import { Client, NaturalClient } from "../../../../client/types";
import { clientToCreateUpdateContractClient, useClientValidation } from "../../../../client/utils";
import { selectProductGroupsEnums } from "../../../../enumerations/ducks";
import { ProductGroupWithProducts } from "../../../../enumerations/types";
import { requests } from "../../../api";
import { ContractType, InsuranceType } from "../../../enums";
import {
  ContractFormProps,
  CrashInsuranceData,
  CreateUpdateGenericInsurance,
  CreateUpdateInsurance,
  CreateUpdateInsuranceContract,
  CreateUpdateLifeInsurance,
  CreateUpdateRealtyInsurance,
  CreateUpdateTravelInsurance,
  CreateUpdateVehicleInsurance,
  Insurance,
  InsuranceContract,
  InsuredClient,
  LifeInsurance,
  RealtyInsurance,
  VehicleInsurance
} from "../../../types";
import { calculateGainerRecordRatesSum } from "../../../utils";
import ContractFormAgentsSection from "../ContractFormAgentsSection";
import InsuranceContractFormDataSection from "./sections/InsuranceContractFormDataSection";
import InsuranceContractFormHeaderSection from "./sections/InsuranceContractFormHeaderSection";
import InsuranceContractFormInsurancesSection from "./sections/InsuranceContractFormInsurancesSection";

const InsuranceContractForm = ({
  initialContract,
  onCreateFormSubmit,
  onUpdateFormSubmit,
  onCancelClick
}: ContractFormProps<InsuranceContract>) => {
  const [form] = Form.useForm<CreateUpdateInsuranceContract>();

  const errorResponse = useFormErrorHandler(form, "contract.attrs", [
    initialContract ? requests.UPDATE_CONTRACT : requests.CREATE_CONTRACT
  ]);

  const productGroups = useSelector<RootState, ProductGroupWithProducts[]>(selectProductGroupsEnums);
  const clientValidation = useClientValidation();

  const [clients, setClients] = useState<(Client | undefined)[]>(initialContract?.clients || []);
  const [clientsCount, setClientsCount] = useState<number>(initialContract?.clients.length || 1);
  const [clientsViolationErrors, setClientsViolationErrors] = useState<Map<number, FieldConstraintViolation[]>>(
    new Map()
  );
  const [insurancesCount, setInsurancesCount] = useState<number>(initialContract?.insurances.length || 1);

  const [insurancesAnnualPremiumsSum, setInsurancesAnnualPremiumsSum] = useState<number>(0);
  const [insurancesPartialPremiumsSum, setInsurancesPartialPremiumsSum] = useState<number>(0);

  useEffect(() => {
    if (initialContract) {
      const initialContractCopy = structuredClone(initialContract);
      const formData = {
        ...initialContractCopy,
        historyRecords: undefined,
        clients: undefined,
        clientIds: undefined,
        institution: undefined,
        product: undefined,
        signer: undefined,
        manager: undefined,
        affiliatePartner: undefined,
        attachments: undefined,
        institutionId: initialContractCopy.institution.id,
        productGroupId: productGroups.find(group =>
          group.products.some(product => product.id === initialContractCopy.product.id)
        )?.id,
        productId: initialContractCopy.product.id,
        clientIdentifiers: initialContractCopy.clients.map(client => client.identifier),
        signerId: initialContractCopy.signer?.id,
        managerId: initialContractCopy.manager?.id,
        affiliatePartnerId: initialContractCopy.affiliatePartner?.id,
        gainerRecords: initialContractCopy.gainerRecords?.map(record => ({
          id: record.id,
          optimisticLockVersion: record.optimisticLockVersion,
          startDate: record.startDate,
          endDate: record.endDate,
          gainer1Id: record.gainer1.id,
          gainer1Rate: record.gainer1Rate,
          gainer2Id: record.gainer2?.id,
          gainer2Rate: record.gainer2Rate,
          gainer3Id: record.gainer3?.id,
          gainer3Rate: record.gainer3Rate,
          gainer4Id: record.gainer4?.id,
          gainer4Rate: record.gainer4Rate,
          gainer5Id: record.gainer5?.id,
          gainer5Rate: record.gainer5Rate
        })),
        insurances: (initialContractCopy.insurances as Insurance[]).map(initialInsurance => {
          const insurance = structuredClone(initialInsurance);

          if ("insuredClientIndex" in initialInsurance) {
            (
              insurance as CreateUpdateGenericInsurance | CreateUpdateRealtyInsurance | CreateUpdateLifeInsurance
            ).insuredClientIdentifier = initialContractCopy.clients[initialInsurance.insuredClientIndex]?.identifier;
          }

          switch (insurance.type) {
            case InsuranceType.MTPL:
            case InsuranceType.CRASH:
            case InsuranceType.GAP:
            case InsuranceType.PAS:
              const vehicleInsurance = { ...insurance } as Partial<CreateUpdateVehicleInsurance> & VehicleInsurance;
              delete vehicleInsurance["coverageLimit"];
              delete vehicleInsurance["complicity"];
              // @ts-ignore ts-problem
              delete vehicleInsurance.vehicle["model"];
              // @ts-ignore ts-problem
              delete vehicleInsurance.vehicle["color"];
              // @ts-ignore ts-problem
              delete vehicleInsurance.vehicle["certificateCategory"];

              const resultVehicleInsurance: CreateUpdateVehicleInsurance = {
                ...vehicleInsurance,
                licensePlate: licensePlateNormalizeFunction(vehicleInsurance.licensePlate),
                coverageLimitId: (initialInsurance as VehicleInsurance).coverageLimit?.id,
                complicityId: (initialInsurance as VehicleInsurance).complicity?.id,
                vehicleOwnerIdentifier:
                  initialContractCopy.clients[(initialInsurance as VehicleInsurance)["vehicleOwnerIndex"]]?.identifier,
                vehicleHolderIdentifier:
                  initialContractCopy.clients[(initialInsurance as VehicleInsurance)["vehicleHolderIndex"]]?.identifier
              };

              if (vehicleInsurance.type === InsuranceType.CRASH) {
                const crashInsuranceData = vehicleInsurance.insuranceData as CrashInsuranceData;
                crashInsuranceData.coverages.gapComplicityReinsurance =
                  !!crashInsuranceData.coverages.gapComplicityReinsurance;
              }

              const initialVehicle = (initialInsurance as VehicleInsurance).vehicle;

              resultVehicleInsurance.vehicle = {
                ...resultVehicleInsurance.vehicle,
                firstRegistrationDate: initialVehicle.firstRegistrationDate,
                colorId: initialVehicle.color.id,
                brandId: initialVehicle.model.brand.id,
                modelId: initialVehicle.model.id
              };

              return resultVehicleInsurance;
            case InsuranceType.REALTY:
              const realtyInsurance = insurance as CreateUpdateRealtyInsurance;
              realtyInsurance.insuranceData.realtyEnabled = !!realtyInsurance.insuranceData.realty;
              realtyInsurance.insuranceData.householdEnabled = !!realtyInsurance.insuranceData.household;
              realtyInsurance.vinculationClientIdentifier =
                initialContractCopy.clients[
                  (initialInsurance as RealtyInsurance)["vinculationClientIndex"]
                ]?.identifier;
              return realtyInsurance;
            case InsuranceType.TRAVEL:
              const travelInsurance = insurance as CreateUpdateTravelInsurance;
              travelInsurance.insuranceData.insuredClients = travelInsurance.insuranceData.insuredClients.map(
                client => ({
                  ...client
                })
              );
              return travelInsurance;
            case InsuranceType.LIFE:
              const lifeInsurance = { ...insurance } as CreateUpdateLifeInsurance & LifeInsurance;
              lifeInsurance.tariffId = (initialInsurance as LifeInsurance).tariff.id;
              // @ts-ignore ts-problem
              delete lifeInsurance["tariff"];
              return lifeInsurance as CreateUpdateInsurance;
            default:
              return insurance;
          }
        })
      } as Record<string, any>;

      calculateAndSetInsurancesAnnualPremiumsSum(formData.insurances);
      calculateAndSetInsurancesPartialPremiumsSum(formData.insurances);

      if (formData.insurances.length === 1) {
        formData.insurances[0] = {
          ...formData.insurances[0],
          contractEntryDate: undefined,
          contractWithdrawalDate: undefined,
          annualPremium: undefined,
          partialPremium: undefined
        };
      }

      form.setFieldsValue(formData);
    }
  }, []);

  useEffect(() => {
    if (errorResponse?.violations) {
      const parsedViolationErrors = new Map<number, FieldConstraintViolation[]>();
      errorResponse.violations
        .filter(violation => violation.fieldPath.startsWith("clients"))
        .forEach(violation => {
          const index = parseInt(violation.fieldPath.charAt(8));
          const parsedViolation = { ...violation, fieldPath: violation.fieldPath.substring(18) };
          parsedViolationErrors.set(
            index,
            parsedViolationErrors.has(index)
              ? [parsedViolation, ...(parsedViolationErrors.get(index) as FieldConstraintViolation[])]
              : [parsedViolation]
          );
        });
      setClientsViolationErrors(parsedViolationErrors);
    }
  }, [errorResponse]);

  useEffect(() => {
    if (clientValidation.errorResponse?.violations?.length) {
      const violations = clientValidation.errorResponse.violations;
      const indexString = violations[0]?.fieldPath.charAt(8);

      if (!indexString) {
        return;
      }

      const index = parseInt(indexString);
      const updatedViolationsErrors = new Map<number, FieldConstraintViolation[]>([...clientsViolationErrors]);
      updatedViolationsErrors.set(
        index,
        violations.map(v => ({ ...v, fieldPath: v.fieldPath.substring(18) }))
      );
      setClientsViolationErrors(updatedViolationsErrors);
    }
  }, [clientValidation.errorResponse]);

  const handleFormFinish = (): void => {
    form
      .validateFields()
      .then(values => {
        const usedIdentifiers = new Set<string>();
        usedIdentifiers.add(clients[0]?.identifier as string);
        (values.insurances as CreateUpdateInsurance[]).forEach(insurance => {
          const insuredClientIdentifier = (
            insurance as CreateUpdateGenericInsurance | CreateUpdateRealtyInsurance | CreateUpdateLifeInsurance
          ).insuredClientIdentifier;

          if (insuredClientIdentifier) {
            usedIdentifiers.add(insuredClientIdentifier);
          }

          const vehicleHolderIdentifier = (insurance as CreateUpdateVehicleInsurance)["vehicleHolderIdentifier"];
          if (vehicleHolderIdentifier) {
            usedIdentifiers.add(vehicleHolderIdentifier);
          }

          const vehicleOwnerIdentifier = (insurance as CreateUpdateVehicleInsurance)["vehicleOwnerIdentifier"];
          if (vehicleOwnerIdentifier) {
            usedIdentifiers.add(vehicleOwnerIdentifier);
          }

          const vinculationClientIdentifier = (insurance as CreateUpdateRealtyInsurance)["vinculationClientIdentifier"];
          if (vinculationClientIdentifier) {
            usedIdentifiers.add(vinculationClientIdentifier);
          }
        });

        const unusedClients = clients.filter(client => client?.identifier && !usedIdentifiers.has(client.identifier));

        if (
          clientsViolationErrors.size > 0 ||
          values.gainerRecords.some(record => !calculateGainerRecordRatesSum(record).eq(new Big(100))) ||
          clients.filter(client => !!client).length !== clientsCount
        ) {
          messageUtils.errorNotification({
            message: t("common.error"),
            description: t("contract.validations.formError")
          });
        } else if (unusedClients.length > 0) {
          messageUtils.errorNotification({
            message: t("common.error"),
            description: t("contract.validations.formErrorUnusedClient", {
              clientIdentifiers: unusedClients.flatMap(c => c?.identifier).join(", ")
            })
          });
        } else {
          const processedValues = { ...values };

          processedValues.insurances = processedValues.insurances.map(insuranceData => {
            const insurance = { ...insuranceData } as CreateUpdateInsurance;

            if ("insuredClientIdentifier" in insurance) {
              insurance.insuredClientIndex = findClientIndex(insurance.insuredClientIdentifier);
              delete insurance.insuredClientIdentifier;
            }

            switch (insurance.type) {
              case InsuranceType.MTPL:
              case InsuranceType.CRASH:
              case InsuranceType.GAP:
              case InsuranceType.PAS:
                const vehicleInsurance = insurance as CreateUpdateVehicleInsurance;

                vehicleInsurance.vehicleHolderIndex = findClientIndex(vehicleInsurance.vehicleHolderIdentifier);
                vehicleInsurance.vehicleOwnerIndex = findClientIndex(vehicleInsurance.vehicleOwnerIdentifier);
                delete vehicleInsurance.vehicleHolderIdentifier;
                delete vehicleInsurance.vehicleOwnerIdentifier;

                vehicleInsurance.licensePlate = removeStringWhiteSpaces(vehicleInsurance.licensePlate);
                vehicleInsurance.vehicle.licensePlate = vehicleInsurance.licensePlate;
                vehicleInsurance.vehicle.registrationCertificateNumber =
                  vehicleInsurance.insuranceData.registrationCertificateNumber;
                delete vehicleInsurance.vehicle.brandId;

                return vehicleInsurance;
              case InsuranceType.REALTY:
                const realtyInsurance = insurance as CreateUpdateRealtyInsurance;

                realtyInsurance.vinculationClientIndex = findClientIndex(realtyInsurance.vinculationClientIdentifier);
                delete realtyInsurance.vinculationClientIdentifier;
                delete realtyInsurance.insuranceData.realtyEnabled;
                delete realtyInsurance.insuranceData.householdEnabled;

                return realtyInsurance;
              default:
                return insurance;
            }
          });

          if (processedValues.insurances.length === 1) {
            processedValues.insurances[0] = {
              ...(processedValues.insurances[0] as CreateUpdateInsurance),
              annualPremium: processedValues.annualPremium,
              partialPremium: processedValues.partialPremium,
              contractEntryDate: processedValues.effectiveBeginningDate
            };
          }

          processedValues.clients = clients
            .filter((client): client is Client => !!client)
            .map(client => clientToCreateUpdateContractClient(client));
          processedValues.clientIds = [];
          processedValues.policyHolderIndex = 0;

          delete processedValues.productGroupId;
          delete processedValues.clientIdentifiers;

          if (initialContract) {
            onUpdateFormSubmit?.({
              id: initialContract.id,
              object: {
                ...processedValues,
                paidUntilDate: initialContract.paidUntilDate,
                amountOwed: initialContract.amountOwed
              } as CreateUpdateInsuranceContract
            });
          } else {
            onCreateFormSubmit?.(processedValues);
          }
        }
      })
      .catch((errors: ValidateErrorEntity) => {
        const errorInsurancesNumbers = distinctArray(
          errors.errorFields
            .filter(field => field.name[0] === "insurances" && typeof field.name[1] === "number")
            .map(field => (field.name[1] as number) + 1)
        );

        if (isNotEmptyArray(errorInsurancesNumbers)) {
          messageUtils.errorNotification({
            message: t("common.error"),
            description: t("contract.validations.formErrorWithInsurances", {
              insurancesNumbers: errorInsurancesNumbers.join(", ")
            })
          });
        } else {
          messageUtils.errorNotification({
            message: t("common.error"),
            description: t("contract.validations.formError")
          });
        }

        resolveFormValidationError(errors);
      });
  };

  const handleClientChange = (index: number, client?: Client): void => {
    const updatedClients = [...clients];
    updatedClients[index] = client;
    setClients(updatedClients);

    if (client) {
      clientValidation.onValidate({ prefix: `clients[${index}]`, client: clientToCreateUpdateContractClient(client) });
    }

    if (index === 0) {
      const updatedInsurances = [...(form.getFieldValue(["insurances"]) || [])] as CreateUpdateInsurance[];
      form.setFieldsValue({
        insurances: updatedInsurances.map(updatedInsurance => {
          if (updatedInsurance.type === InsuranceType.TRAVEL) {
            const travel = { ...updatedInsurance } as CreateUpdateTravelInsurance;
            if (travel.insuranceData.policyHolderIsAlsoInsured) {
              const insuredClients: Record<string, any> = [...(travel.insuranceData.insuredClients || [])];
              if (client?.type === ClientType.NATURAL) {
                insuredClients[0] = {
                  firstName: (client as NaturalClient).firstName,
                  lastName: (client as NaturalClient).lastName,
                  birthDate: (client as NaturalClient).birthDate
                };
                travel.insuranceData = { ...travel.insuranceData, insuredClients: insuredClients as InsuredClient[] };
              } else {
                insuredClients[0] = {
                  firstName: undefined,
                  lastName: undefined,
                  birthDate: undefined
                };
                travel.insuranceData = {
                  ...travel.insuranceData,
                  policyHolderIsAlsoInsured: false,
                  insuredClients: insuredClients as InsuredClient[]
                };
              }
            }
            return travel;
          }
          return updatedInsurance;
        })
      });
    }
  };

  const handleClientDelete = (index: number): void => {
    const updatedClients = [...clients];
    updatedClients.splice(index, 1);
    setClients(updatedClients);
  };

  const handleInsuranceAnnualPremiumChange = (): void => {
    calculateAndSetInsurancesAnnualPremiumsSum(form.getFieldValue(["insurances"]));
  };

  const handleInsurancePartialPremiumChange = (): void => {
    calculateAndSetInsurancesPartialPremiumsSum(form.getFieldValue(["insurances"]));
  };

  const calculateAndSetInsurancesAnnualPremiumsSum = (insurances: CreateUpdateInsurance[]): void => {
    if (insurances) {
      let totalPremium = new Big(0);
      insurances.forEach(insurance => {
        totalPremium = totalPremium.plus(
          insurance.annualPremium && isNumber(insurance.annualPremium) ? insurance.annualPremium : 0
        );
      });
      setInsurancesAnnualPremiumsSum(bigToFloat(totalPremium));
    } else {
      setInsurancesAnnualPremiumsSum(0);
    }
  };

  const calculateAndSetInsurancesPartialPremiumsSum = (insurances: CreateUpdateInsurance[]): void => {
    if (insurances) {
      let totalPremium = new Big(0);
      insurances.forEach(insurance => {
        totalPremium = totalPremium.plus(
          insurance.partialPremium && isNumber(insurance.partialPremium) ? insurance.partialPremium : 0
        );
      });
      setInsurancesPartialPremiumsSum(bigToFloat(totalPremium));
    } else {
      setInsurancesPartialPremiumsSum(0);
    }
  };

  const findClientIndex = (identifier?: string): number | undefined => {
    if (identifier) {
      const index = clients.findIndex(client => client?.identifier === identifier);
      return index === -1 ? undefined : index;
    }
    return undefined;
  };

  return (
    <Form form={form} layout="vertical" name="insuranceContractForm">
      <HiddenInput name="type" initialValue={ContractType.INSURANCE_CONTRACT} />
      <HiddenInput name="optimisticLockVersion" />
      <HiddenInput name="status" />

      <InsuranceContractFormHeaderSection
        initialContract={initialContract}
        form={form}
        clients={clients}
        clientsCount={clientsCount}
        setClientsCount={setClientsCount}
        clientsViolationErrors={clientsViolationErrors}
        insurancesCount={insurancesCount}
        onClientChange={handleClientChange}
        onClientDelete={handleClientDelete}
        onClientViolationErrorsChange={setClientsViolationErrors}
      />

      <InsuranceContractFormInsurancesSection
        initialContract={initialContract}
        form={form}
        clients={clients}
        insurancesCount={insurancesCount}
        onInsurancesCountChange={setInsurancesCount}
        onAnnualPremiumChange={handleInsuranceAnnualPremiumChange}
        onPartialPremiumChange={handleInsurancePartialPremiumChange}
      />

      <InsuranceContractFormDataSection
        form={form}
        insurancesCount={insurancesCount}
        insurancesAnnualPremiumsSum={insurancesAnnualPremiumsSum}
        insurancesPartialPremiumsSum={insurancesPartialPremiumsSum}
      />

      <ContractFormAgentsSection
        initialContract={initialContract}
        privilegedChangesPermission={Permission.PRIVILEGED_CHANGES_ON_VERIFIED_INSURANCE}
        form={form}
        onFinish={handleFormFinish}
        onCancel={onCancelClick}
      />
    </Form>
  );
};

export default InsuranceContractForm;
