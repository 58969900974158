import { Button, Divider, Form, Space, TreeSelect, TreeSelectProps } from "antd";
import { FormItemProps } from "antd/lib/form";
import { DataNode } from "rc-tree-select/lib/interface";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { formatProductName } from "../../../../common/utils/formatUtils";
import { treeNodeFilterFunction } from "../../../../common/utils/formUtils";
import type { UUID } from "../../../../typings/global";
import { selectIsCurrentAgentTypePlus } from "../../../auth/ducks";
import { selectProductGroupsEnums } from "../../ducks";

interface Props {
  formItemProps: FormItemProps;
  selectProps?: TreeSelectProps<UUID>;
}

const ProductTreeSelect = ({ formItemProps, selectProps }: Props) => {
  const { t } = useTranslation();

  const form = Form.useFormInstance();
  const currentProductIds = Form.useWatch(formItemProps.name, form);

  const productGroupsEnums = useSelector(selectProductGroupsEnums);
  const isCurrentAgentTypePlus = useSelector(selectIsCurrentAgentTypePlus);

  const productsTree = useMemo(
    () =>
      productGroupsEnums.map<DataNode>(productGroup => ({
        value: productGroup.id,
        title: productGroup.name,
        children: productGroup.products.map<DataNode>(product => ({
          value: product.id,
          title: formatProductName(product)
        }))
      })),
    [productGroupsEnums]
  );

  const products = productGroupsEnums.map(group => group.products).flat();

  const coveredByNbsProducts = products.filter(product => product.coveredByNbs);

  const nonCoveredByNbsProducts = products.filter(product => !product.coveredByNbs);

  const shouldShowExtendedButtons = !!coveredByNbsProducts.length && !!nonCoveredByNbsProducts.length;

  const handleClickCoveredByNbs = () => {
    form.setFieldValue(formItemProps.name, [
      ...new Set([...currentProductIds, ...coveredByNbsProducts.map(product => product.id)])
    ]);
  };

  const handleClickNonCoveredByNbs = () => {
    form.setFieldValue(formItemProps.name, [
      ...new Set([...currentProductIds, ...nonCoveredByNbsProducts.map(product => product.id)])
    ]);
  };

  return (
    <Form.Item {...formItemProps}>
      <TreeSelect
        showCheckedStrategy={TreeSelect.SHOW_PARENT}
        treeCheckable
        maxTagCount="responsive"
        popupMatchSelectWidth={false}
        showSearch
        allowClear
        filterTreeNode={treeNodeFilterFunction}
        treeData={productsTree}
        {...selectProps}
        dropdownRender={menu => (
          <>
            {menu}
            {shouldShowExtendedButtons ? (
              <>
                <Divider style={{ margin: "4px 0" }} />

                <Space style={{ marginTop: 8, justifyContent: "center", width: "100%" }}>
                  <Button type="text" size="small" onClick={handleClickCoveredByNbs}>
                    {isCurrentAgentTypePlus
                      ? t("product.helpers.productsProfiPlus")
                      : t("product.helpers.productsCoveredByNbs")}
                  </Button>
                  <Divider type="vertical" style={{ margin: "0 2px" }} />
                  <Button type="text" size="small" onClick={handleClickNonCoveredByNbs}>
                    {isCurrentAgentTypePlus
                      ? t("product.helpers.productsProjectsPlus")
                      : t("product.helpers.productsNoCoveredByNbs")}
                  </Button>
                </Space>
              </>
            ) : undefined}
          </>
        )}
      />
    </Form.Item>
  );
};

export default ProductTreeSelect;
