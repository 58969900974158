import { Button, Col, Form, Input, Radio, Row } from "antd";
import { useEffect, useState } from "react";
import t from "../../../../../../app/i18n";
import AntIcon from "../../../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../../../common/constants";
import { resolveFormValidationError } from "../../../../../../common/utils/formUtils";
import { validations } from "../../../../../../common/utils/validationUtils";
import { SpecialCommissionsReport } from "../../../../special/enums";
import {
  SpecialCommission,
  SpecialCommissionsFilterPageRequest,
  SpecialCommissionsFilterPageResult
} from "../../../../special/types";

interface Props {
  filter: SpecialCommissionsFilterPageResult<SpecialCommission>;
  onReportSwitch: (filter: SpecialCommissionsFilterPageRequest) => void;
  onFilterSubmit: (filter: SpecialCommissionsFilterPageRequest) => void;
}

const BatchSpecialCommissionFilterView = ({ filter, onReportSwitch, onFilterSubmit }: Props) => {
  const [currentReport, setCurrentReport] = useState<SpecialCommissionsReport>(SpecialCommissionsReport.INCLUDED);

  const [form] = Form.useForm<SpecialCommissionsFilterPageRequest>();

  const colSpan = 6;

  useEffect(() => {
    if (filter.report) {
      form.setFieldsValue({
        report: filter.report,
        code: filter.code
      });
      setCurrentReport(filter.report);
    }
  }, []);

  const handleReportSwitch = (report: SpecialCommissionsReport): void => {
    form
      .validateFields()
      .then(values => {
        onReportSwitch({ ...values, report });
        setCurrentReport(report);
      })
      .catch(resolveFormValidationError);
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFilterSubmit} name="batchSpecialCommissionFilterForm">
      <Row gutter={rowGutter} justify="center" className="margin-bottom-medium">
        <Col span={24} className="center-align">
          <Radio.Group
            size="large"
            buttonStyle="solid"
            value={currentReport}
            onChange={e =>
              handleReportSwitch(SpecialCommissionsReport[e.target.value as keyof typeof SpecialCommissionsReport])
            }
          >
            <Radio.Button value={SpecialCommissionsReport.INCLUDED}>
              {t("commissions.special.enums.report.INCLUDED")}
            </Radio.Button>
            <Radio.Button value={SpecialCommissionsReport.EXCLUDED}>
              {t("commissions.special.enums.report.EXCLUDED")}
            </Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
      <Row gutter={rowGutter} justify="center">
        <Col span={colSpan}>
          <Form.Item name="code" label={t("commissions.special.attrs.code")} rules={[validations.size(1, 16)]}>
            <Input allowClear placeholder={t("commissions.batch.helpers.specialCommissionCodePlaceholder")} />
          </Form.Item>
        </Col>

        <Col flex="110px" className="no-title-space">
          <Form.Item>
            <Button type="primary" htmlType="submit" icon={<AntIcon type="search" />}>
              {t("common.filter")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default BatchSpecialCommissionFilterView;
