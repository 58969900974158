import { Button, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import t from "../../../../../app/i18n";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import InfoIcon from "../../../../../common/components/icons/InfoIcon";
import PopconfirmDeleteIcon from "../../../../../common/components/icons/PopconfirmDeleteIcon";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import TableActionsView from "../../../../../common/components/views/TableActionsView";
import { PageSizes } from "../../../../../common/constants";
import { formatLocaleDate } from "../../../../../common/utils/formatUtils";
import { cssVariables, paginationTableProps, tableStandardProps } from "../../../../../common/utils/utils";
import type { UUID } from "../../../../../typings/global";
import {
  createAgentCompetenceActions,
  deleteAgentCompetenceActions,
  getAgentCompetencesActions,
  updateAgentCompetenceActions
} from "../../../ducks";
import { AgentCompetenceType } from "../../../enums";
import { AgentCompetence } from "../../../types";
import AgentCompetenceForm from "../../forms/AgentCompetenceForm";

interface Props {
  agentId?: UUID;
  competences: AgentCompetence[];
  showAllColumns?: boolean;
  showActions?: boolean;
  onGet: typeof getAgentCompetencesActions.request;
  onCreate?: typeof createAgentCompetenceActions.request;
  onUpdate?: typeof updateAgentCompetenceActions.request;
  onDelete?: typeof deleteAgentCompetenceActions.request;
}

const AgentCompetenceTableView = ({ agentId, competences, showAllColumns, showActions, ...props }: Props) => {
  const [competenceFormOpen, setCompetenceFormOpen] = useState<boolean>(false);
  const [competenceToUpdate, setCompetenceToUpdate] = useState<AgentCompetence>();

  const today = dayjs();

  useEffect(() => {
    if (agentId) {
      props.onGet({ id: agentId });
    }
  }, []);

  const handleCompetenceUpdateClick = (competence: AgentCompetence): void => {
    setCompetenceToUpdate(competence);
    setCompetenceFormOpen(true);
  };

  const handleCompetenceFormCancel = (): void => {
    setCompetenceToUpdate(undefined);
    setCompetenceFormOpen(false);
  };

  const columns: ColumnsType<AgentCompetence> = [
    {
      key: "type",
      title: t("agent.competence.enums.competenceType._label"),
      ellipsis: { showTitle: false },
      width: 80,
      render: (_, record) => <Ellipsis>{t("agent.competence.enums.competenceType." + record.type)}</Ellipsis>
    },
    {
      key: "label",
      title: t("agent.competence.attrs.label"),
      ellipsis: { showTitle: false },
      render: (_, record) =>
        record.type === AgentCompetenceType.OTHER ? (
          <Ellipsis>{record.label}</Ellipsis>
        ) : (
          <Ellipsis>
            {`${t("agent.enums.financialSector." + record.sector)} | ${t(
              "agent.competence.helpers.competenceLevel." + record.level
            )}`}
          </Ellipsis>
        )
    },
    {
      key: "provider",
      title: t("agent.competence.enums.competenceProvider._label"),
      ellipsis: { showTitle: false },
      width: 180,
      render: (_, record) =>
        record.provider ? (
          <Ellipsis>{t("agent.competence.enums.competenceProvider." + record.provider)}</Ellipsis>
        ) : null
    },
    {
      key: "startDate",
      title: t("agent.competence.attrs.intervals.startDate"),
      width: 100,
      render: (_, record) => (
        <>
          {record.intervals.map((i, index) => (
            <React.Fragment key={index}>
              {formatLocaleDate(i.startDate)}
              <br />
            </React.Fragment>
          ))}
        </>
      )
    },
    {
      key: "endDate",
      title: t("agent.competence.attrs.intervals.endDate"),
      width: 100,
      render: (_, record) => (
        <>
          {record.intervals.map((i, index) => (
            <React.Fragment key={index}>
              {formatLocaleDate(i.endDate)}
              <br />
            </React.Fragment>
          ))}
        </>
      )
    },
    {
      key: "active",
      title: t("agent.competence.helpers.active"),
      align: "center",
      width: 70,
      render: (_, record) =>
        record.intervals.some(
          i => today.isSameOrAfter(i.startDate, "day") && today.isSameOrBefore(i.endDate, "day")
        ) ? (
          <AntIcon type="check-circle-two-tone" twoToneColor={cssVariables.colorGreen} />
        ) : (
          <AntIcon type="close-circle-two-tone" twoToneColor={cssVariables.colorRed} />
        )
    }
  ];

  if (showAllColumns) {
    columns.splice(5, 0, {
      key: "processed",
      title: t("agent.competence.attrs.intervals.processed"),
      align: "center",
      width: 100,
      render: (_, record) => (
        <>
          {record.intervals.map((i, index) => (
            <React.Fragment key={index}>
              <AntIcon type={i.processed ? "check" : "close-circle"} />
              <br />
            </React.Fragment>
          ))}
        </>
      )
    });

    columns.push({
      key: "note",
      align: "center",
      width: 40,
      render: (_, record) =>
        record.note ? <InfoIcon title={t("agent.competence.attrs.note")} tooltip={record.note} /> : null
    });

    if (showActions) {
      columns.push({
        key: "actions",
        align: "right",
        fixed: "right",
        width: 180,
        render: (_, record) => (
          <TableActionsView
            actions={[
              {
                color: "blue",
                text: t("common.edit"),
                icon: "edit",
                onClick: () => handleCompetenceUpdateClick(record)
              },
              {
                color: "red",
                text: t("common.delete"),
                icon: "delete",
                confirmDialog: {
                  icon: <PopconfirmDeleteIcon />,
                  title: t("agent.competence.helpers.deleteConfirm"),
                  okType: "danger",
                  okText: t("common.yes"),
                  cancelText: t("common.no")
                },
                onClick: () => (agentId ? props.onDelete?.({ id1: agentId, id2: record.id }) : undefined)
              }
            ]}
          />
        )
      });
    }
  }

  return (
    <>
      <Table<AgentCompetence>
        {...tableStandardProps()}
        columns={columns}
        dataSource={competences}
        pagination={{ ...paginationTableProps, pageSize: PageSizes.LARGE, total: competences.length }}
      />

      {showActions && (
        <>
          <div className={competences.length > 0 ? "table-footer-margin" : "margin-top-small"}>
            <Button
              type="primary"
              size="small"
              icon={<AntIcon type="plus" />}
              onClick={() => setCompetenceFormOpen(true)}
            >
              {t("common.add")}
            </Button>
          </div>

          <AgentCompetenceForm
            open={competenceFormOpen}
            agentId={agentId}
            competence={competenceToUpdate}
            onCreate={props.onCreate}
            onUpdate={props.onUpdate}
            onFormCancel={handleCompetenceFormCancel}
          />
        </>
      )}
    </>
  );
};

export default AgentCompetenceTableView;
