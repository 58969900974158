import { Table, Tag, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { generatePath, Link, useNavigate } from "react-router-dom";
import t from "../../../../app/i18n";
import ActionTextIcon from "../../../../common/components/icons/ActionTextIcon";
import Ellipsis from "../../../../common/components/views/Ellipsis";
import { PageSizes, TableSizes } from "../../../../common/constants";
import { PageResult } from "../../../../common/types";
import { formatLocaleCurrency, formatLocaleNettoPoints } from "../../../../common/utils/formatUtils";
import { tInterval } from "../../../../common/utils/translationUtils";
import {
  appendSearchParamsToURL,
  numberOrZero,
  paginationTableProps,
  tableStandardProps
} from "../../../../common/utils/utils";
import type { UUID } from "../../../../typings/global";
import { AGENT_ROUTE_PATHS } from "../../../agent/paths";
import { CommissionsBatchStep } from "../../batch/enums";
import { COMMISSIONS_BATCH_ROUTE_PATHS } from "../../batch/paths";
import { CommissionsBatchOutputAttachment } from "../../batch/types";
import { removeBatchNameCustomSuffix } from "../../batch/utils";
import { SpecialCommissionsReport } from "../../special/enums";
import {
  SpecialCommissionBase,
  SpecialCommissionsFilterPageRequest,
  SpecialCommissionsFilterPageResult
} from "../../special/types";
import {
  deleteStateCommissionsOutputsPageAction,
  deleteStateOutputCalculatedCommissionsPageAction,
  deleteStateOutputSpecialCommissionsPageAction,
  filterCommissionsOutputsActions,
  filterOutputCalculatedCommissionsActions,
  filterOutputSpecialCommissionsActions
} from "../ducks";
import { CalculatedCommissionsFilterPageRequest, CalculatedCommissionsFilterPageResult } from "../types";
import OutputCalculatedCommissionTableView from "./OutputCalculatedCommissionTableView";
import OutputSpecialCommissionTableView from "./OutputSpecialCommissionTableView";

interface Props {
  agentId: UUID;
  outputsPage: PageResult<CommissionsBatchOutputAttachment>;
  filterCommissionsOutputs: typeof filterCommissionsOutputsActions.request;
  deleteStateCommissionsOutputsPage: typeof deleteStateCommissionsOutputsPageAction;
  showFullBatchNameWithLink?: boolean;
  outputCalculatedCommissionsPage: CalculatedCommissionsFilterPageResult;
  filterOutputCalculatedCommissions: typeof filterOutputCalculatedCommissionsActions.request;
  deleteStateOutputCalculatedCommissions: typeof deleteStateOutputCalculatedCommissionsPageAction;
  outputSpecialCommissionsPage: SpecialCommissionsFilterPageResult<SpecialCommissionBase>;
  filterOutputSpecialCommissions: typeof filterOutputSpecialCommissionsActions.request;
  deleteStateOutputSpecialCommissions: typeof deleteStateOutputSpecialCommissionsPageAction;
  urlSearchQuery: string;
}

const CommissionsOutputsTableView = ({
  agentId,
  outputsPage,
  filterCommissionsOutputs,
  deleteStateCommissionsOutputsPage,
  showFullBatchNameWithLink,
  outputCalculatedCommissionsPage,
  filterOutputCalculatedCommissions,
  deleteStateOutputCalculatedCommissions,
  outputSpecialCommissionsPage,
  filterOutputSpecialCommissions,
  deleteStateOutputSpecialCommissions,
  urlSearchQuery
}: Props) => {
  const navigate = useNavigate();

  const [outputToShowCalculatedCommissionsFor, setOutputToShowCalculatedCommissionsFor] =
    useState<CommissionsBatchOutputAttachment>();

  const [outputToShowSpecialCommissionsFor, setOutputToShowSpecialCommissionsFor] =
    useState<CommissionsBatchOutputAttachment>();

  useEffect(() => {
    const urlParams = new URLSearchParams(urlSearchQuery);
    filterCommissionsOutputs({
      id: agentId,
      object: { pageIndex: numberOrZero(urlParams.get("pageIndex")), pageSize: PageSizes.SMALL }
    });
    return () => {
      deleteStateCommissionsOutputsPage();
    };
  }, []);

  const handleOutputsTablePageChange = (pageNumber: number): void => {
    const { pageSize } = outputsPage;

    navigate(appendSearchParamsToURL({ pageIndex: pageNumber - 1 }), { replace: true });
    filterCommissionsOutputs({
      id: agentId,
      object: { pageIndex: pageNumber - 1, pageSize }
    });
  };

  const handleShowOutputCalculatedCommissions = (output: CommissionsBatchOutputAttachment): void => {
    filterOutputCalculatedCommissions({
      id1: agentId,
      id2: output.id,
      object: {
        pageIndex: 0,
        pageSize: PageSizes.SMALL,
        keyword: undefined,
        institutionIds: []
      }
    });
    setOutputToShowCalculatedCommissionsFor(output);
  };

  const handleOutputCalculatedCommissionsFilterSubmit = (filter: CalculatedCommissionsFilterPageRequest): void => {
    if (outputToShowCalculatedCommissionsFor) {
      filterOutputCalculatedCommissions({
        id1: agentId,
        id2: outputToShowCalculatedCommissionsFor.id,
        object: {
          ...filter,
          pageIndex: 0,
          pageSize: outputCalculatedCommissionsPage.pageSize
        }
      });
    }
  };

  const handleOutputCalculatedCommissionsTablePageChange = (pageNumber: number): void => {
    const { pageSize, keyword, institutionIds } = outputCalculatedCommissionsPage;
    if (outputToShowCalculatedCommissionsFor) {
      filterOutputCalculatedCommissions({
        id1: agentId,
        id2: outputToShowCalculatedCommissionsFor.id,
        object: {
          pageIndex: pageNumber - 1,
          pageSize,
          keyword,
          institutionIds
        }
      });
    }
  };

  const handleShowOutputSpecialCommissions = (output: CommissionsBatchOutputAttachment): void => {
    filterOutputSpecialCommissions({
      id1: agentId,
      id2: output.id,
      object: {
        pageIndex: 0,
        pageSize: PageSizes.SMALL,
        report: SpecialCommissionsReport.INCLUDED,
        code: undefined
      }
    });
    setOutputToShowSpecialCommissionsFor(output);
  };

  const handleOutputSpecialCommissionsFilterSubmit = (filter: SpecialCommissionsFilterPageRequest): void => {
    if (outputToShowSpecialCommissionsFor) {
      filterOutputSpecialCommissions({
        id1: agentId,
        id2: outputToShowSpecialCommissionsFor.id,
        object: {
          ...filter,
          pageIndex: 0,
          pageSize: outputSpecialCommissionsPage.pageSize
        }
      });
    }
  };

  const handleOutputSpecialCommissionsTablePageChange = (pageNumber: number): void => {
    const { pageSize, report, code } = outputSpecialCommissionsPage;
    if (outputToShowSpecialCommissionsFor) {
      filterOutputSpecialCommissions({
        id1: agentId,
        id2: outputToShowSpecialCommissionsFor.id,
        object: {
          pageIndex: pageNumber - 1,
          pageSize,
          report,
          code
        }
      });
    }
  };

  const columns: ColumnsType<CommissionsBatchOutputAttachment> = [
    {
      key: "commissionsBatch",
      title: t("commissions.batch.attrs.commission.batch"),
      width: 160,
      ellipsis: { showTitle: false },
      render: (_, record) =>
        showFullBatchNameWithLink ? (
          <Ellipsis tooltip={record.batch.name}>
            <Link to={generatePath(COMMISSIONS_BATCH_ROUTE_PATHS.detail.to, { id: record.batch.id })} target="_blank">
              {record.batch.name}
            </Link>
          </Ellipsis>
        ) : (
          removeBatchNameCustomSuffix(record.batch)
        )
    },
    {
      key: "preliminaryOutputTag",
      width: 120,
      align: "center",
      render: (_, record) =>
        record.batch.step === CommissionsBatchStep.REVIEW && (
          <Tag color="purple" style={{ marginRight: 0 }}>
            {t("commissions.batch.helpers.preliminaryCommissionsOutput")}
          </Tag>
        )
    },
    {
      key: "calculatedCommissionAmount",
      title: t("commissions.batch.attrs.attachment.calculatedCommissionAmount"),
      align: "right",
      width: 110,
      render: (_, record) =>
        !record.batch.stepChangeInProgress ? (
          <span>
            <ActionTextIcon
              text={formatLocaleCurrency(record.calculatedCommissionAmount)}
              onClick={() => handleShowOutputCalculatedCommissions(record)}
              color="blue"
            />
          </span>
        ) : (
          formatLocaleCurrency(record.calculatedCommissionAmount)
        )
    },
    {
      key: "nettoPointAmount",
      title: t("commissions.batch.attrs.attachment.nettoPointAmount"),
      align: "right",
      width: 110,
      render: (_, record) => formatLocaleNettoPoints(record.nettoPointAmount)
    },
    {
      key: "specialCommissionAmount",
      title: t("commissions.batch.attrs.attachment.specialCommissionAmount"),
      align: "right",
      width: 100,
      render: (_, record) => (
        <Tooltip
          title={tInterval(
            "commissions.batch.helpers.specialCommissionCountTooltip_interval",
            record.specialCommissionCount
          )}
        >
          {!record.batch.stepChangeInProgress && record.specialCommissionCount > 0 ? (
            <span>
              <ActionTextIcon
                text={formatLocaleCurrency(record.specialCommissionAmount)}
                onClick={() => handleShowOutputSpecialCommissions(record)}
                color="blue"
              />
            </span>
          ) : (
            formatLocaleCurrency(record.specialCommissionAmount)
          )}
        </Tooltip>
      )
    },
    {
      key: "bailAccount",
      title: t("commissions.batch.attrs.attachment.bailAccount"),
      children: [
        {
          key: "bailAccountOpeningBalance",
          title: t("commissions.batch.attrs.attachment.bailAccountOpeningBalance"),
          align: "right",
          width: 100,
          render: (_, record) => formatLocaleCurrency(record.bailAccountOpeningBalance)
        },
        {
          key: "bailAccountAutomaticMovement",
          title: t("commissions.batch.attrs.attachment.bailAccountAutomaticMovement"),
          align: "right",
          width: 100,
          render: (_, record) => formatLocaleCurrency(record.bailAccountAutomaticMovement)
        },
        {
          key: "bailAccountManualMovement",
          title: t("commissions.batch.attrs.attachment.bailAccountManualMovement"),
          align: "right",
          width: 100,
          render: (_, record) => formatLocaleCurrency(record.bailAccountManualMovement)
        },
        {
          key: "bailAccountClosingBalance",
          title: t("commissions.batch.attrs.attachment.bailAccountClosingBalance"),
          align: "right",
          width: 100,
          render: (_, record) => formatLocaleCurrency(record.bailAccountClosingBalance)
        }
      ]
    },
    {
      key: "debtAccount",
      title: t("commissions.batch.attrs.attachment.debtAccount"),
      children: [
        {
          key: "debtAccountOpeningBalance",
          title: t("commissions.batch.attrs.attachment.debtAccountOpeningBalance"),
          align: "right",
          width: 100,
          render: (_, record) => formatLocaleCurrency(record.debtAccountOpeningBalance)
        },
        {
          key: "debtAccountMovement",
          title: t("commissions.batch.attrs.attachment.debtAccountMovement"),
          align: "right",
          width: 100,
          render: (_, record) => formatLocaleCurrency(record.debtAccountMovement)
        },
        {
          key: "debtAccountClosingBalance",
          title: t("commissions.batch.attrs.attachment.debtAccountClosingBalance"),
          align: "right",
          width: 100,
          render: (_, record) => formatLocaleCurrency(record.debtAccountClosingBalance)
        }
      ]
    },
    {
      key: "paidCommissionAmount",
      title: t("commissions.batch.attrs.attachment.paidCommissionAmount"),
      align: "right",
      fixed: "right",
      width: 110,
      render: (_, record) => <b>{formatLocaleCurrency(record.paidCommissionAmount)}</b>
    },
    {
      key: "actions",
      align: "right",
      fixed: "right",
      width: 90,
      render: (_, record) => (
        <ActionTextIcon
          path={generatePath(AGENT_ROUTE_PATHS.commissionsOutput.to, { id1: agentId, id2: record.id })}
          target="_blank"
          icon="eye"
          color="blue"
          text={t("common.show")}
        />
      )
    }
  ];

  return (
    <>
      <Table<CommissionsBatchOutputAttachment>
        {...tableStandardProps()}
        columns={columns}
        scroll={{ x: TableSizes.HUGE }}
        dataSource={outputsPage.pageData}
        pagination={{
          ...paginationTableProps,
          current: outputsPage.pageIndex + 1,
          pageSize: outputsPage.pageSize,
          total: outputsPage.totalElementsCount,
          onChange: handleOutputsTablePageChange
        }}
      />
      <OutputCalculatedCommissionTableView
        commissionsOutput={outputToShowCalculatedCommissionsFor}
        commissionsPage={outputCalculatedCommissionsPage}
        onClose={() => setOutputToShowCalculatedCommissionsFor(undefined)}
        onAfterClose={() => deleteStateOutputCalculatedCommissions()}
        onFilterSubmit={handleOutputCalculatedCommissionsFilterSubmit}
        onPageChange={handleOutputCalculatedCommissionsTablePageChange}
      />
      <OutputSpecialCommissionTableView
        commissionsOutput={outputToShowSpecialCommissionsFor}
        commissionsPage={outputSpecialCommissionsPage}
        onClose={() => setOutputToShowSpecialCommissionsFor(undefined)}
        onAfterClose={() => deleteStateOutputSpecialCommissions()}
        onFilterSubmit={handleOutputSpecialCommissionsFilterSubmit}
        onPageChange={handleOutputSpecialCommissionsTablePageChange}
      />
    </>
  );
};

export default CommissionsOutputsTableView;
