import { Button, Card, Result, Space } from "antd";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import t from "../../../app/i18n";
import { hasUserMultipleAccounts } from "../../../modules/auth/ducks";
import { DASHBOARD_ROUTE_PATHS } from "../../../modules/dashboard/paths";
import { setNotFoundPageRenderedAction } from "../../../modules/ducks";
import AntIcon from "../../components/icons/AntIcon";
import { RootState } from "../../types";
import { useToken } from "../../utils/hooksUtils";

const NotFound = () => {
  const navigate = useNavigate();
  const { token } = useToken();

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          setNotFoundPageRendered: setNotFoundPageRenderedAction
        },
        dispatch
      ),
    [dispatch]
  );

  const hasMultipleAccounts = useSelector<RootState, boolean>(hasUserMultipleAccounts);

  useEffect(() => {
    actions.setNotFoundPageRendered(true);
    return () => {
      actions.setNotFoundPageRendered(false);
    };
  }, []);

  return (
    <Card style={{ maxWidth: 600, margin: "0 auto" }}>
      <Result
        status="404"
        title={<span style={{ color: token.colorPrimary }}>{t("navigation.notFound.title")}</span>}
        subTitle={
          <>
            <strong>{t("navigation.notFound.text")}</strong>
            {hasMultipleAccounts && (
              <span className="margin-top-small" style={{ display: "block" }}>
                {t("navigation.notFound.additionalText")}
              </span>
            )}
          </>
        }
        extra={
          <Space className="margin-top-large">
            <Button onClick={() => navigate(-1)}>
              <AntIcon type="double-left" />
              {t("navigation.notFound.backAction")}
            </Button>
            <Button onClick={() => navigate(DASHBOARD_ROUTE_PATHS.homepage.to, { replace: true })}>
              {t("navigation.notFound.homeAction")}
              <AntIcon type="home" />
            </Button>
          </Space>
        }
      />
    </Card>
  );
};

export default NotFound;
