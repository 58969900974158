import { Checkbox, Col, DatePicker, Divider, Form, FormInstance, InputNumber, Row, Select } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { StoreValue } from "rc-field-form/lib/interface";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import ActionButton from "../../../../../common/components/buttons/ActionButton";
import InputAddon from "../../../../../common/components/form/addons/InputAddon";
import HiddenInput from "../../../../../common/components/form/components/HiddenInput";
import LabelWithTooltip from "../../../../../common/components/form/labels/LabelWithTooltip";
import DeleteIcon from "../../../../../common/components/icons/DeleteIcon";
import { rowGutter } from "../../../../../common/constants";
import {
  datePickerFormItemProps,
  datePickerStandardProps,
  dateToIsoDateString,
  selectStandardProps,
  toDate
} from "../../../../../common/utils/formUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import { CommissionDivisionType } from "../../enum";
import { CreateUpdateProductCommissionsSettingsInterval, UpdateProductCommissionsSettings } from "../../types";

type Props = {
  form: FormInstance<UpdateProductCommissionsSettings>;
};

export const ProductCommissionsSettingsForm = ({ form }: Props) => {
  const { t } = useTranslation();

  const divisionType = Form.useWatch("divisionType", form);
  const year1PaymentRatio = Form.useWatch("year1PaymentRatio", form);
  const year2PaymentRatio = Form.useWatch("year2PaymentRatio", form);
  const year3PaymentRatio = Form.useWatch("year3PaymentRatio", form);
  const intervalsStartDates = Form.useWatch("intervals", form)?.map(interval => interval?.startDate);
  const intervalsEndDates = Form.useWatch("intervals", form)?.map(interval => interval?.endDate);

  const handleIntervalAdd = (add: (value?: StoreValue) => void) => {
    const intervals = [...form.getFieldValue(["intervals"])] as UpdateProductCommissionsSettings["intervals"];
    const lastEndDate = intervals[intervals.length - 1]?.endDate;

    add({ startDate: lastEndDate ? dateToIsoDateString(dayjs(lastEndDate).add(1, "days")) : undefined });
  };

  const handleIntervalEndDateChange = (endDate: Dayjs, index: number) => {
    const intervals = [...form.getFieldValue("intervals")] as UpdateProductCommissionsSettings["intervals"];

    if (intervals.length > index + 1) {
      intervals[index + 1] = {
        ...intervals[index + 1],
        startDate: dateToIsoDateString(dayjs(endDate).add(1, "days"))
      } as CreateUpdateProductCommissionsSettingsInterval;

      form.setFieldsValue({ intervals });
    }
  };

  return (
    <>
      <Divider orientation="left">{t("commissions.productCommissionsSettings.sections.commissionSettings")}</Divider>

      <Form form={form} layout="vertical" name="productCommissionsSettingsForm">
        <HiddenInput name="optimisticLockVersion" />

        <Row gutter={rowGutter}>
          <Col span={8}>
            <Form.Item
              name="amountAdjustmentRatio"
              label={
                <LabelWithTooltip
                  tooltip={t("commissions.productCommissionsSettings.helpers.amountAdjustmentRatioHint")}
                  label={t("commissions.productCommissionsSettings.attrs.amountAdjustmentRatio")}
                />
              }
              rules={[validations.notNull, validations.minNumber(0.01), validations.maxNumber(200)]}
            >
              <InputNumber style={{ width: "100%" }} addonAfter={<InputAddon type="percentage" />} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={6}>
            <Form.Item
              name="divisionType"
              label={t("commissions.productCommissionsSettings.enums.commissionDivisionType._label")}
              rules={[validations.notNull]}
            >
              <Select
                {...selectStandardProps}
                options={Object.keys(CommissionDivisionType).map(type => ({
                  value: type,
                  label: t("commissions.productCommissionsSettings.enums.commissionDivisionType." + type)
                }))}
              />
            </Form.Item>
          </Col>

          {divisionType && divisionType !== CommissionDivisionType.NONE ? (
            <Col span={6}>
              <Form.Item
                name="year1PaymentRatio"
                label={t("commissions.productCommissionsSettings.attrs.year1PaymentRatio")}
                rules={[
                  validations.notNull,
                  validations.minNumber(0.01),
                  validations.maxNumber(100),
                  validations.sumOneHundred([year1PaymentRatio, year2PaymentRatio, year3PaymentRatio])
                ]}
                dependencies={
                  divisionType === CommissionDivisionType.THREE_YEARS
                    ? ["year2PaymentRatio", "year3PaymentRatio"]
                    : ["year2PaymentRatio"]
                }
              >
                <InputNumber style={{ width: "100%" }} addonAfter={<InputAddon type="percentage" />} />
              </Form.Item>
            </Col>
          ) : undefined}

          {divisionType === CommissionDivisionType.TWO_YEARS || divisionType === CommissionDivisionType.THREE_YEARS ? (
            <Col span={6}>
              <Form.Item
                name="year2PaymentRatio"
                label={t("commissions.productCommissionsSettings.attrs.year2PaymentRatio")}
                rules={[
                  validations.notNull,
                  validations.minNumber(0.01),
                  validations.maxNumber(100),
                  validations.sumOneHundred([year1PaymentRatio, year2PaymentRatio, year3PaymentRatio])
                ]}
                dependencies={
                  divisionType === CommissionDivisionType.THREE_YEARS
                    ? ["year1PaymentRatio", "year3PaymentRatio"]
                    : ["year1PaymentRatio"]
                }
              >
                <InputNumber style={{ width: "100%" }} addonAfter={<InputAddon type="percentage" />} />
              </Form.Item>
            </Col>
          ) : undefined}

          {divisionType === CommissionDivisionType.THREE_YEARS ? (
            <Col span={6}>
              <Form.Item
                name="year3PaymentRatio"
                label={t("commissions.productCommissionsSettings.attrs.year3PaymentRatio")}
                rules={[
                  validations.notNull,
                  validations.minNumber(0.01),
                  validations.maxNumber(100),
                  validations.sumOneHundred([year1PaymentRatio, year2PaymentRatio, year3PaymentRatio])
                ]}
                dependencies={["year1PaymentRatio", "year2PaymentRatio"]}
              >
                <InputNumber style={{ width: "100%" }} addonAfter={<InputAddon type="percentage" />} />
              </Form.Item>
            </Col>
          ) : undefined}
        </Row>

        <Row gutter={rowGutter}>
          <Col span={8}>
            <Form.Item
              name="pointsAmount"
              label={
                <LabelWithTooltip
                  tooltip={t("commissions.productCommissionsSettings.helpers.pointsAmountHint")}
                  label={t("commissions.productCommissionsSettings.attrs.pointsAmount")}
                />
              }
              rules={[validations.minNumber(0)]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="paymentAmount"
              label={
                <LabelWithTooltip
                  tooltip={t("commissions.productCommissionsSettings.helpers.paymentAmountHint")}
                  label={t("commissions.productCommissionsSettings.attrs.paymentAmount")}
                />
              }
              rules={[validations.minNumber(0)]}
            >
              <InputNumber style={{ width: "100%" }} addonAfter={<InputAddon type="euro" />} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="omitManagerialCommissions"
              className="form-item-without-label"
              valuePropName="checked"
              initialValue={false}
              rules={[validations.notNull]}
            >
              <Checkbox>{t("commissions.productCommissionsSettings.attrs.omitManagerialCommissions")}</Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left">{t("commissions.productCommissionsSettings.sections.intervalSettings")}</Divider>

        <Row gutter={rowGutter}>
          <Form.List name={["intervals"]} initialValue={[{}]}>
            {(fields, { add, remove }) => (
              <>
                <Col span={24} style={{ maxHeight: 300, overflowX: "auto" }}>
                  <Row gutter={rowGutter}>
                    {fields.map(field => {
                      const startDate = intervalsStartDates ? intervalsStartDates[field.name] : undefined;
                      const endDate = intervalsEndDates ? intervalsEndDates[field.name] : undefined;
                      const isNotOnlyOneAndLastField = fields.length > 1 && field.name < fields.length - 1;

                      return (
                        <Fragment key={field.key}>
                          <Col span={23}>
                            <HiddenInput name={[field.name, "id"]} />
                            <HiddenInput name={[field.name, "optimisticLockVersion"]} />

                            <Row gutter={rowGutter}>
                              <Col span={8}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "startDate"]}
                                  label={t("commissions.productCommissionsSettings.attrs.intervals.startDate")}
                                  rules={[validations.notNull]}
                                  {...datePickerFormItemProps}
                                >
                                  <DatePicker {...datePickerStandardProps} disabled={field.name >= 1} />
                                </Form.Item>
                              </Col>

                              <Col span={8}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "endDate"]}
                                  label={t("commissions.productCommissionsSettings.attrs.intervals.endDate")}
                                  rules={[
                                    isNotOnlyOneAndLastField ? validations.notNull : validations.none,
                                    validations.notBefore(
                                      startDate,
                                      t("commissions.productCommissionsSettings.attrs.intervals.startDate")
                                    )
                                  ]}
                                  {...datePickerFormItemProps}
                                >
                                  <DatePicker<Dayjs>
                                    {...datePickerStandardProps}
                                    defaultPickerValue={toDate(endDate || startDate)}
                                    allowClear={!isNotOnlyOneAndLastField}
                                    minDate={dayjs(startDate)}
                                    onChange={value =>
                                      value ? handleIntervalEndDateChange(value, field.name) : undefined
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  name={[field.name, "pointsCoefficient"]}
                                  label={t("commissions.productCommissionsSettings.attrs.intervals.pointsCoefficient")}
                                  rules={[validations.notNull, validations.minNumber(0)]}
                                >
                                  <InputNumber style={{ width: "100%" }} />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={1}>
                            {fields.length > 1 ? <DeleteIcon onClick={() => remove(field.name)} /> : undefined}
                          </Col>
                        </Fragment>
                      );
                    })}
                  </Row>
                </Col>

                <ActionButton icon="plus" className="margin-top-small" onClick={() => handleIntervalAdd(add)}>
                  {t("commissions.settings.actions.addInterval")}
                </ActionButton>
              </>
            )}
          </Form.List>
        </Row>
      </Form>
    </>
  );
};
