import { theme } from "antd";
import { Pathname } from "history";
import { RefObject, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  selectIsAnyRequestInProgress,
  selectRouterLocationPathname,
  selectRunningRequestKey
} from "../../modules/ducks";
import type { ApiRequest } from "../api/ApiRequestAdapter";
import { PageSizes } from "../constants";
import type { EntityIdObject, RootState, SearchPageRequest, ThreeLevelEntityIdObject } from "../types";
import { resolvePageTitle } from "./navigationUtils";
import { FilterQueryKeys } from "./queryUtils";
import { isNumber, numberOrZero } from "./utils";
import { regexPatterns } from "./validationUtils";

export const useRequestFinishedCallback = (requests: ApiRequest[], callback?: VoidFunction): boolean => {
  const requestInProgress = useSelector<RootState, boolean>(state => selectIsAnyRequestInProgress(state, requests));
  const runningRequestKey = useSelector<RootState, string | undefined>(selectRunningRequestKey);

  const [requestStarted, setRequestStarted] = useState<boolean>(false);

  useEffect(() => {
    if (requestInProgress) {
      setRequestStarted(true);
    }
  }, [requestInProgress]);

  useEffect(() => {
    if (requestStarted) {
      setRequestStarted(false);
      callback?.();
    }
  }, [runningRequestKey]);

  return requestInProgress;
};

export const useBackNavigation = (): VoidFunction => {
  const navigate = useNavigate();

  return (): void => {
    if (window.history.length <= 1) {
      navigate("..");
    } else {
      navigate(-1);
    }
  };
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<{ innerWidth: number; outerWidth: number; height: number }>({
    innerWidth: window.innerWidth,
    outerWidth: window.outerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        innerWidth: window.innerWidth,
        outerWidth: window.outerWidth,
        height: window.innerHeight
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

export const useScrollToTopOnLoad = (): void => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
};

export const useDocumentTitle = (): void => {
  const pathname = useSelector<RootState, Pathname>(selectRouterLocationPathname);
  useEffect(() => {
    document.title = resolvePageTitle(pathname);
  }, [pathname]);
};

export const useAreParamsIdsValid = (): boolean => {
  const { id, id1, id2, id3 } = useParams<EntityIdObject & ThreeLevelEntityIdObject>();
  return (
    (!id || regexPatterns.exactUuidRegex.test(id)) &&
    (!id1 || regexPatterns.exactUuidRegex.test(id1)) &&
    (!id2 || regexPatterns.exactUuidRegex.test(id2)) &&
    (!id3 || regexPatterns.exactUuidRegex.test(id3))
  );
};

export const useGetWidth = (ref: RefObject<any>) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const recalculateWidth = () => {
      const referenceWidth = ref?.current?.offsetWidth ?? 0;
      setWidth(referenceWidth);
    };

    window.addEventListener("resize", recalculateWidth);
    recalculateWidth();

    return () => {
      window.removeEventListener("resize", recalculateWidth);
    };
  }, [ref]);

  return width;
};

export const { useToken } = theme;

const getGenericParams = <T>(searchParams: URLSearchParams, paramKeys: Partial<keyof T>[]): Partial<T> => {
  let result: Partial<T> = {};
  paramKeys.forEach(param => {
    const paramString = param.toString();

    if (searchParams.has(paramString)) {
      const value = searchParams.getAll(paramString);

      if (value.length > 1) {
        result = { ...result, [param]: value };
      } else {
        result = { ...result, [param]: isNumber(value) ? numberOrZero(value) : value[0] };
      }
    }
  });

  return result;
};

type FilterParams<T> = {
  params?: Partial<keyof (T & SearchPageRequest)>[];
  [FilterQueryKeys.PAGE_SIZE]?: number;
};

type FilterSearchParams<T> = T & SearchPageRequest;

export const useFilterSearchParams = <T extends Record<string, any>>(
  options: FilterParams<T> = {}
): Partial<FilterSearchParams<T>> => {
  const { params = [], pageSize = PageSizes.LARGE } = options;
  const [searchParams] = useSearchParams();

  const [filter, setFilter] = useState<Partial<T>>({
    [FilterQueryKeys.PAGE_INDEX]: numberOrZero(searchParams.get(FilterQueryKeys.PAGE_INDEX)),
    [FilterQueryKeys.PAGE_SIZE]: pageSize,
    [FilterQueryKeys.KEYWORD]: searchParams.get(FilterQueryKeys.KEYWORD) ?? undefined,
    ...getGenericParams(searchParams, params)
  });

  useEffect(() => {
    setFilter({
      [FilterQueryKeys.PAGE_INDEX]: numberOrZero(searchParams.get(FilterQueryKeys.PAGE_INDEX)),
      [FilterQueryKeys.PAGE_SIZE]: pageSize,
      [FilterQueryKeys.KEYWORD]: searchParams.get(FilterQueryKeys.KEYWORD) ?? undefined,
      ...getGenericParams(searchParams, params)
    });
  }, [searchParams]);

  return filter;
};
