import dayjs, { Dayjs } from "dayjs";
import { contains } from "../../../../common/utils/utils";
import { ClientType } from "../../../client/enums";
import { Client, CreateUpdateContractClient, NaturalClient } from "../../../client/types";
import { createUpdateContractClientToClient } from "../../../client/utils";
import { VehicleCategory } from "../../../contract/enums";
import { CalcType, ClientExperience } from "../../enums";
import { OfferType } from "../enums";
import { CalcResult } from "../types";
import { GapCoverageLimit, HolderAccidentsYearsKey, TimeWithoutAccident, VehiclePolicyHolderRelation } from "./enums";
import {
  CrashCalcResultData,
  GapCalcResultData,
  MtplCalcResultData,
  MtplCrashCalcResultData,
  PasCalcResultData,
  VehicleCalc,
  VehicleCalcClientsData,
  VehicleCalcDraft,
  VehicleCalcResultData,
  VehicleCalcType,
  VehicleFormClients,
  VehicleGen,
  VehicleGenForm,
  VehicleOfferType,
  VehicleSecurityInfo
} from "./types";

export const resolveVehiclePolicyHolder = (
  clients: VehicleFormClients,
  policyHolderRelation: VehiclePolicyHolderRelation
): Client | undefined => {
  return policyHolderRelation === VehiclePolicyHolderRelation.DIFFERENT_FROM_VEHICLE_HOLDER
    ? clients.policyHolder
    : clients.holder;
};

export const resolveVehiclePolicyHolderType = (
  clients: VehicleFormClients,
  policyHolderRelation: VehiclePolicyHolderRelation
): ClientType | undefined => {
  return resolveVehiclePolicyHolder(clients, policyHolderRelation)?.type;
};

export const resolveVehicleOfferType = (calcType: CalcType): VehicleOfferType => {
  switch (calcType) {
    case CalcType.MTPL:
      return OfferType.MTPL;
    case CalcType.CRASH:
      return OfferType.CRASH;
    case CalcType.MTPL_CRASH:
      return OfferType.MTPL_CRASH;
    default:
      return OfferType.MTPL;
  }
};

export const getVehicleCalcResultsByType = <T extends VehicleCalcType>(
  calcResults: CalcResult<VehicleCalcResultData>[],
  calcType: T
): CalcResult<
  T extends CalcType.MTPL
    ? MtplCalcResultData
    : T extends CalcType.CRASH
      ? CrashCalcResultData
      : T extends CalcType.MTPL_CRASH
        ? MtplCrashCalcResultData
        : T extends CalcType.GAP
          ? GapCalcResultData
          : T extends CalcType.PAS
            ? PasCalcResultData
            : VehicleCalcResultData
>[] => {
  if (isMtplCalcType(calcType)) {
    return calcResults.filter(isMtplCalcResult) as any;
  } else if (isCrashCalcType(calcType)) {
    return calcResults.filter(isCrashCalcResult) as any;
  } else if (isMtplCrashCalcType(calcType)) {
    return calcResults.filter(isMtplCrashCalcResult) as any;
  } else if (isGapCalcType(calcType)) {
    return calcResults.filter(isGapCalcResult) as any;
  } else if (isPasCalcType(calcType)) {
    return calcResults.filter(isPasCalcResult) as any;
  }

  return [] as any;
};

type HolderAccidentsYearsKeyType = keyof Pick<
  VehicleCalcClientsData,
  | "holderAccidentsIn2Years"
  | "holderAccidentsIn3Years"
  | "holderAccidentsIn5Years"
  | "holderAccidentsIn8Years"
  | "holderAccidentsIn10Years"
  | "holderAccidentsInAllYears"
>;

export const createVehicleCalcObjectFromCalcData = (calcData: VehicleCalc): VehicleCalc => {
  const data = structuredClone(calcData);

  Object.values(HolderAccidentsYearsKey).forEach(years => {
    if (
      !contains(
        YEARS_TO_ACCIDENT_TIMES_MAP.get(years as HolderAccidentsYearsKey) as TimeWithoutAccident[],
        data.clientsData.holderTimeWithoutAccident
      )
    ) {
      delete data.clientsData[`holderAccidentsIn${years}Years` as HolderAccidentsYearsKeyType];
    }
  });

  return data;
};

export const createVehicleFormClientsObjectFromCalcData = (calcData: VehicleCalc): VehicleFormClients => ({
  holder: createUpdateContractClientToClient(
    calcData.clientsData.clients[calcData.clientsData.holderIndex] as CreateUpdateContractClient
  ),
  policyHolder: undefined,
  owner: undefined,
  representative: undefined
});

export const createVehicleFormClientsObjectFromGenData = (
  genData: VehicleGen | VehicleCalcDraft["draftData"]
): VehicleFormClients => {
  const { clients, holderIndex, policyHolderIndex, ownerIndex, representativeIndex } = genData.clientsData;

  return {
    holder: createUpdateContractClientToClient(clients[holderIndex] as CreateUpdateContractClient),
    policyHolder:
      policyHolderIndex !== holderIndex
        ? createUpdateContractClientToClient(clients[policyHolderIndex] as CreateUpdateContractClient)
        : undefined,
    owner:
      ownerIndex !== holderIndex && ownerIndex !== policyHolderIndex
        ? createUpdateContractClientToClient(clients[ownerIndex] as CreateUpdateContractClient)
        : undefined,
    representative: representativeIndex
      ? createUpdateContractClientToClient(clients[representativeIndex] as CreateUpdateContractClient)
      : undefined
  };
};

export const createVehicleCalcObjectFromGenData = (
  genData: VehicleGen | VehicleCalcDraft["draftData"]
): VehicleCalc => {
  const data: Record<string, any> = structuredClone(genData);

  data.type = data.type === CalcType.GAP || data.type === CalcType.PAS ? CalcType.MTPL_CRASH : data.type;

  delete data.insuranceInstitutionId;
  delete data.coverage;
  delete data.calcResult;
  delete data.calcResponse;
  delete data.draftId;

  delete data.vehicleData.damaged;
  delete data.vehicleData.damageDescription;
  delete data.vehicleData.allKeysAvailable;
  delete data.vehicleData.allServiceInspectionsPassed;
  delete data.vehicleData.priceSource;
  delete data.vehicleData.invoiceIssueDate;
  delete data.vehicleData.slovexpertaIssuerName;
  delete data.vehicleData.slovexpertaIssueDate;
  delete data.vehicleData.key;
  delete data.vehicleData.equipment;
  delete data.vehicleData.odometerPerYear;
  delete data.vehicleData.purchaseDate;

  if (data.vehicleData.security) {
    data.vehicleData.security.forEach((security: VehicleSecurityInfo) => delete security.numberOfKeysOrControllers);
  }

  delete data.clientsData.policyHolderIndex;
  delete data.clientsData.ownerIndex;
  delete data.clientsData.representativeIndex;
  delete data.clientsData.policyHolderEmail;
  delete data.clientsData.policyHolderPhone;
  delete data.clientsData.policyHolderMarketingConsent;
  delete data.clientsData.representativeFunction;

  delete data.generalData.signCity;
  delete data.generalData.loanNumber;
  delete data.generalData.mtplInsuranceEffectiveBeginningDate;
  delete data.generalData.childrenUnder15InVehicle;
  delete data.generalData.agentIsClosePersonToClient;
  delete data.generalData.gapComplicityReinsurance;
  delete data.generalData.gapDuration;
  delete data.generalData.gapPaymentFrequency;
  delete data.generalData.crossSelling.unionHealthContractNumber;
  delete data.financialMediationData;

  if (data.reinsurancesData) {
    delete data.reinsurancesData.uniqaSidecarMtplData;
  }

  return createVehicleCalcObjectFromCalcData(data as VehicleCalc);
};

export const createVehicleGenFormDataObject = (
  genData: VehicleGen | VehicleCalcDraft["draftData"],
  clients: VehicleFormClients
): VehicleGenForm => {
  const { vehicleData, clientsData, generalData, reinsurancesData, financialMediationData } = genData;
  return {
    vehicleData: {
      registrationCertificatePresent: !!vehicleData.registrationCertificatePresent,
      registrationCertificateNumber: vehicleData.registrationCertificateNumber,
      colorId: vehicleData.colorId ?? "",
      damaged: !!vehicleData.damaged,
      damageDescription: vehicleData.damageDescription,
      allKeysAvailable: !!vehicleData.allKeysAvailable,
      allServiceInspectionsPassed: !!vehicleData.allServiceInspectionsPassed,
      invoiceIssueDate: vehicleData.invoiceIssueDate,
      slovexpertaIssuerName: vehicleData.slovexpertaIssuerName,
      slovexpertaIssueDate: vehicleData.slovexpertaIssueDate,
      priceSource: vehicleData.priceSource,
      key: vehicleData.key,
      equipment: vehicleData.equipment,
      security: vehicleData.security,
      odometer: vehicleData.odometer,
      odometerPerYear: vehicleData.odometerPerYear,
      purchaseDate: vehicleData.purchaseDate
    },
    clientsData: {
      policyHolderIdentifier: clients.policyHolder?.identifier,
      ownerIdentifier: clients.owner?.identifier,
      representativeIdentifier: clients.representative?.identifier,
      holderIdentityCardNumber: (clients.holder as NaturalClient)?.identityCardNumber,
      policyHolderEmail: clientsData.policyHolderEmail,
      policyHolderPhone: clientsData.policyHolderPhone,
      policyHolderMarketingConsent: clientsData.policyHolderMarketingConsent,
      representativeFunction: clientsData.representativeFunction
    },
    generalData: {
      signCity: generalData.signCity,
      loanNumber: generalData.loanNumber,
      mtplInsuranceEffectiveBeginningDate: generalData.mtplInsuranceEffectiveBeginningDate,
      childrenUnder15InVehicle: !!generalData.childrenUnder15InVehicle,
      agentIsClosePersonToClient: !!generalData.agentIsClosePersonToClient,
      gapComplicityReinsurance: generalData.gapComplicityReinsurance,
      gapPaymentFrequency: generalData.gapPaymentFrequency,
      crossSelling: {
        unionHealthContractNumber: generalData.crossSelling.unionHealthContract
          ? generalData.crossSelling.unionHealthContractNumber
          : undefined
      }
    },
    reinsurancesData: {
      uniqaSidecarMtplData: reinsurancesData?.uniqaSidecarMtplData
    },
    financialMediationData: financialMediationData ?? { clientExperience: "" as ClientExperience }
  };
};

export const resolveGapCoverageLimitOptions = (holderRegistrationDate?: Dayjs): GapCoverageLimit[] => {
  if (holderRegistrationDate) {
    return holderRegistrationDate.isBefore(dayjs().subtract(120, "day"))
      ? [GapCoverageLimit.LIMIT_5000, GapCoverageLimit.LIMIT_10000, GapCoverageLimit.LIMIT_20000]
      : [GapCoverageLimit.LIMIT_20000, GapCoverageLimit.LIMIT_35000, GapCoverageLimit.LIMIT_50000];
  }

  return Object.values(GapCoverageLimit);
};

export const CALC_OMITTED_CATEGORIES = [
  VehicleCategory.MOTOR_SLEDGE_LS,
  VehicleCategory.TRACTOR_C,
  VehicleCategory.MACHINE_P,
  VehicleCategory.BUS_CITY_M
];

export const YEARS_TO_ACCIDENT_TIMES_MAP = new Map<HolderAccidentsYearsKey, TimeWithoutAccident[]>([
  [HolderAccidentsYearsKey.TWO, [TimeWithoutAccident.LESS_THAN_1_YEAR, TimeWithoutAccident.AT_LEAST_1_YEAR]],
  [
    HolderAccidentsYearsKey.THREE,
    [TimeWithoutAccident.LESS_THAN_1_YEAR, TimeWithoutAccident.AT_LEAST_1_YEAR, TimeWithoutAccident.AT_LEAST_2_YEARS]
  ],
  [
    HolderAccidentsYearsKey.FIVE,
    [
      TimeWithoutAccident.LESS_THAN_1_YEAR,
      TimeWithoutAccident.AT_LEAST_1_YEAR,
      TimeWithoutAccident.AT_LEAST_2_YEARS,
      TimeWithoutAccident.AT_LEAST_3_YEARS,
      TimeWithoutAccident.AT_LEAST_4_YEARS
    ]
  ],
  [
    HolderAccidentsYearsKey.EIGHT,
    [
      TimeWithoutAccident.LESS_THAN_1_YEAR,
      TimeWithoutAccident.AT_LEAST_1_YEAR,
      TimeWithoutAccident.AT_LEAST_2_YEARS,
      TimeWithoutAccident.AT_LEAST_3_YEARS,
      TimeWithoutAccident.AT_LEAST_4_YEARS,
      TimeWithoutAccident.AT_LEAST_5_YEARS,
      TimeWithoutAccident.AT_LEAST_6_YEARS,
      TimeWithoutAccident.AT_LEAST_7_YEARS
    ]
  ],
  [
    HolderAccidentsYearsKey.TEN,
    [
      TimeWithoutAccident.LESS_THAN_1_YEAR,
      TimeWithoutAccident.AT_LEAST_1_YEAR,
      TimeWithoutAccident.AT_LEAST_2_YEARS,
      TimeWithoutAccident.AT_LEAST_3_YEARS,
      TimeWithoutAccident.AT_LEAST_4_YEARS,
      TimeWithoutAccident.AT_LEAST_5_YEARS,
      TimeWithoutAccident.AT_LEAST_6_YEARS,
      TimeWithoutAccident.AT_LEAST_7_YEARS,
      TimeWithoutAccident.AT_LEAST_8_YEARS,
      TimeWithoutAccident.AT_LEAST_9_YEARS
    ]
  ],
  [
    HolderAccidentsYearsKey.ALL,
    [
      TimeWithoutAccident.LESS_THAN_1_YEAR,
      TimeWithoutAccident.AT_LEAST_1_YEAR,
      TimeWithoutAccident.AT_LEAST_2_YEARS,
      TimeWithoutAccident.AT_LEAST_3_YEARS,
      TimeWithoutAccident.AT_LEAST_4_YEARS,
      TimeWithoutAccident.AT_LEAST_5_YEARS,
      TimeWithoutAccident.AT_LEAST_6_YEARS,
      TimeWithoutAccident.AT_LEAST_7_YEARS,
      TimeWithoutAccident.AT_LEAST_8_YEARS,
      TimeWithoutAccident.AT_LEAST_9_YEARS
    ]
  ]
]);

export const M1_N1 = [VehicleCategory.PERSONAL_M1, VehicleCategory.UTILITY_N1];

export const SELF_MOVING = [
  VehicleCategory.PERSONAL_M1,
  VehicleCategory.UTILITY_N1,
  VehicleCategory.MOTORCYCLE_L,
  VehicleCategory.TRICYCLE_L,
  VehicleCategory.QUAD_BIKE_L,
  VehicleCategory.TRACTOR_T,
  VehicleCategory.TRACTOR_C,
  VehicleCategory.MACHINE_P,
  VehicleCategory.FORKLIFT_P,
  VehicleCategory.LORRY_N,
  VehicleCategory.SEMI_N3,
  VehicleCategory.BUS_CITY_M,
  VehicleCategory.BUS_OTHER_M,
  VehicleCategory.RV
];

export const SPECS_MAP = new Map<string, VehicleCategory[]>([
  ["fuelType", SELF_MOVING],
  ["transmission", M1_N1],
  ["bodywork", M1_N1],
  ["seatsNumber", SELF_MOVING],
  [
    "doorsNumber",
    [VehicleCategory.PERSONAL_M1, VehicleCategory.UTILITY_N1, VehicleCategory.BUS_CITY_M, VehicleCategory.BUS_OTHER_M]
  ],
  ["engineDisplacement", SELF_MOVING],
  ["enginePower", SELF_MOVING],
  ["odometer", SELF_MOVING],
  [
    "steeringWheelOnTheRight",
    [
      VehicleCategory.PERSONAL_M1,
      VehicleCategory.UTILITY_N1,
      VehicleCategory.TRACTOR_T,
      VehicleCategory.TRACTOR_C,
      VehicleCategory.MACHINE_P,
      VehicleCategory.FORKLIFT_P,
      VehicleCategory.LORRY_N,
      VehicleCategory.SEMI_N3,
      VehicleCategory.BUS_CITY_M,
      VehicleCategory.BUS_OTHER_M,
      VehicleCategory.RV
    ]
  ],
  ["emergencyBrakingSystem", M1_N1],
  ["parkingAssistant", M1_N1]
]);

export const REINSURANCES_MAP = new Map<string, VehicleCategory[]>([
  [
    "extendedAssistance",
    [
      VehicleCategory.PERSONAL_M1,
      VehicleCategory.UTILITY_N1,
      VehicleCategory.MOTORCYCLE_L,
      VehicleCategory.TRICYCLE_L,
      VehicleCategory.QUAD_BIKE_L
    ]
  ],
  [
    "glass",
    [
      VehicleCategory.PERSONAL_M1,
      VehicleCategory.UTILITY_N1,
      VehicleCategory.TRACTOR_T,
      VehicleCategory.TRACTOR_C,
      VehicleCategory.MACHINE_P,
      VehicleCategory.FORKLIFT_P,
      VehicleCategory.LORRY_N,
      VehicleCategory.SEMI_N3,
      VehicleCategory.BUS_CITY_M,
      VehicleCategory.BUS_OTHER_M,
      VehicleCategory.RV
    ]
  ],
  ["animal", M1_N1],
  [
    "element",
    [
      VehicleCategory.PERSONAL_M1,
      VehicleCategory.UTILITY_N1,
      VehicleCategory.MOTORCYCLE_L,
      VehicleCategory.TRICYCLE_L,
      VehicleCategory.QUAD_BIKE_L
    ]
  ],
  ["theftAndVandalism", M1_N1],
  [
    "injury",
    [
      VehicleCategory.PERSONAL_M1,
      VehicleCategory.UTILITY_N1,
      VehicleCategory.MOTORCYCLE_L,
      VehicleCategory.TRICYCLE_L,
      VehicleCategory.QUAD_BIKE_L
    ]
  ],
  ["gap", M1_N1],
  ["replacementVehicle", M1_N1],
  ["generaliAbroadVehicleRepair", M1_N1],
  ["koopExtendedWarranty", M1_N1],
  ["uniqaSmallDamage", [VehicleCategory.PERSONAL_M1, VehicleCategory.UTILITY_N1, VehicleCategory.RV]],
  ["uniqaTotalDamage", [VehicleCategory.PERSONAL_M1, VehicleCategory.UTILITY_N1, VehicleCategory.RV]],
  ["uniqaLawyerAssistance", M1_N1],
  ["uniqaSidecarMtpl", M1_N1],
  ["uniqaChildrenInjury", M1_N1],
  ["uniqaUnlimitedTow", M1_N1]
]);

export const isMtplCalcType = (calcType: CalcType): calcType is CalcType.MTPL => calcType === CalcType.MTPL;

export const isCrashCalcType = (calcType: CalcType): calcType is CalcType.CRASH => calcType === CalcType.CRASH;

export const isMtplCrashCalcType = (calcType: CalcType): calcType is CalcType.MTPL_CRASH =>
  calcType === CalcType.MTPL_CRASH;

export const isGapCalcType = (calcType: CalcType): calcType is CalcType.GAP => calcType === CalcType.GAP;

export const isPasCalcType = (calcType: CalcType): calcType is CalcType.PAS => calcType === CalcType.PAS;

export const isMtplCalcResult = (calcResult: CalcResult): calcResult is CalcResult<MtplCalcResultData> =>
  isMtplCalcType(calcResult.calcType);

export const isCrashCalcResult = (
  calcResult: CalcResult
): calcResult is CalcResult<CrashCalcResultData> & { type: CalcType.CRASH } => isCrashCalcType(calcResult.calcType);

export const isGapCalcResult = (
  calcResult: CalcResult
): calcResult is CalcResult<GapCalcResultData> & { type: CalcType.GAP } => isGapCalcType(calcResult.calcType);

export const isMtplCrashCalcResult = (
  calcResult: CalcResult
): calcResult is CalcResult<MtplCrashCalcResultData> & { type: CalcType.MTPL_CRASH } =>
  isMtplCrashCalcType(calcResult.calcType);

export const isPasCalcResult = (
  calcResult: CalcResult
): calcResult is CalcResult<PasCalcResultData> & { type: CalcType.PAS } => isPasCalcType(calcResult.calcType);
